import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Link } from '@material-ui/core';
import FacebookLoginButton from '../common/facebookLoginButton';
import { useTablet } from '../../hooks/utilHooks';
import Loader from '../common/loader';
import EmailMobileLogin from '../common/emailMobileLogin';
import GoogleLoginNewButton from '../common/googleLoginNewButton';

const SocialSignUp = ({ history }) => {
  const classes = useStyles();
  const isTablet = useTablet();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <React.Fragment>
      <Box align={isTablet ? 'center' : 'left'}>
        {isLoading && (
          <Box
            bgcolor="rgba(0,0,0,0.4)"
            position="fixed"
            top="0"
            height={1}
            zIndex={999}
            left="0"
            width="100%"
          >
            <Box position="relative" top="25%">
              <Loader key={1} />
            </Box>
          </Box>
        )}

        <Box className="loginBtnContainer" textAlign="center">
          <EmailMobileLogin />
          <FacebookLoginButton history={history} setIsLoading={setIsLoading} />
          <GoogleLoginNewButton history={history} setIsLoading={setIsLoading} />

          <Box>
            <Typography
              component="span"
              variant="body1"
              className={classes.spaceRight}
            >
              By continuing, you agree to our
            </Typography>
            <Box mt={0} pt={0} style={{ position: 'relative', zIndex: 2 }}>
              <Link
                target="_top"
                className="linkColor"
                href="/Home/tnc"
                style={{ textDecoration: 'none' }}
              >
                Terms
              </Link>
              <Typography
                component="span"
                variant="body1"
                className={classes.spaceRight}
              >
                and
              </Typography>

              <Link
                target="_top"
                className="linkColor"
                href="/Home/policy"
                style={{ textDecoration: 'none' }}
              >
                Privacy policy
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};
export default SocialSignUp;

const useStyles = makeStyles((theme) => ({
  topHeading: {
    '& p': {
      fontSize: '22px',
      letterSpacing: '0.5px',
      lineHeight: '30px',
      fontWeight: 'bold',
    },
  },
  isDesktopHeading: {
    '& p': {
      fontSize: theme.spacing(9),
      letterSpacing: '0.5px',
      lineHeight: '48px',
      fontWeight: '600',
    },
  },
  spaceRight: {
    margin: theme.spacing(1, 1, 0, 1),
    display: 'inline-block',
  },
}));
