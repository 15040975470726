import { action } from 'easy-peasy';

export default {
  videoStatus: '',
  lectureData: '',
  setVideoStatus: action((state, payload) => {
    state.videoStatus = payload;
  }),
  setLectureData: action((state, payload) => {
    state.lectureData = payload;
  }),
};
