import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useNavBar } from '../hooks/navBarHooks';
import Header from '../components/home/header';
import HomeScreen from '../components/common/homeScreen';
import HomeSlider from '../components/common/homeSlider';
import { setOnboardingBlurEffect } from '../services/onboardingService';
import MoengageService from '../services/moEngageService';
import { getPreviousLocation, screenNamesSet } from '../utils/screenUtils';
import { getSpentTime, getReferrerUrl } from '../services/authService';
import { useLastLocation } from 'react-router-last-location';
import { useTablet } from '../hooks/utilHooks';
import CommunitiesInterests from '../components/home/communitiesInterests';
import Testimonial from '../components/home/testimonial';
import Footer from '../components/home/footer';
import AppDownload from '../components/home/appDownload';
import { getPaytmToken } from '../utils/paytmUtil';
import { useSnackBar } from '../hooks/snackBarHooks';

const Home = () => {
  const isTablet = useTablet();
  const [isPayTmLoading, setIsPayTmLoading] = useState(false);
  useNavBar({
    hideNavBar: true,
    disableCustomSideBarGrid: true,
  });
  const setToastMessage = useSnackBar('');
  const lastLocation = useLastLocation();
  const sourceName = getPreviousLocation(lastLocation);
  const spentTime = getSpentTime();
  const referrerUrl = getReferrerUrl();
  useEffect(() => {
    MoengageService.trackLogin(
      sourceName,
      screenNamesSet.loginOptions,
      spentTime,
      referrerUrl
    );
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const explode = referrerUrl && referrerUrl.split('?');
    if (explode && explode[1] === 'source=paytm') {
      setIsPayTmLoading(true);
      getPaytmToken(setToastMessage);
    }
  }, [referrerUrl, setToastMessage]);
  return (
    <React.Fragment>
      <Box>
        <Header />
        <Box height="100vh">
          {!isTablet && <HomeSlider />}
          <Box className="rightSection">
            <Box
              height={isTablet ? 'auto !important' : 'auto'}
              className="rightBox"
              style={{ position: 'inherit' }}
              onClick={() => setOnboardingBlurEffect(true)}
            >
              <HomeScreen />
            </Box>
          </Box>
        </Box>

        <Box bgcolor="#FFF">
          <CommunitiesInterests />
          <Testimonial />
          {isPayTmLoading !== true && <AppDownload />}
          <Footer />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default Home;
