import React, { useEffect, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { Box, Grid } from '@material-ui/core';
import { useScrollToBottom, useTablet } from '../../hooks/utilHooks';
import {
  useEditComment,
  useLikeOnPost,
  usePostEngagement,
  useRepost,
} from '../../hooks/postHooks';
import CommentSection from './commentSection';
import PostEngagementSection from './postEngagementSection';

const PostEngagement = ({
  post,
  focusComment: triggerComment,
  isPostDetail,
  isArticleDetail,
  lectureEntityId,
  isLecture,
  lectureObj,
  chapterTitle,
  showCommentBox,
}) => {
  const isTablet = useTablet();
  const [focusComment, setFocusComment] = useState(triggerComment);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  useScrollToBottom(scrollToBottom);
  const postId = post.id;
  const { isLiked, likesCount, likePost } = useLikeOnPost(postId);
  const { isReposted, repostsCount, repost } = useRepost(postId);
  const commentsCount = useStoreState(
    (state) => state.posts.postsObj[postId].commentsCount
  );
  const stickEngagementDetails = isArticleDetail && !focusComment;
  const { editCommentObj } = useEditComment();

  useEffect(() => {
    editCommentObj && editCommentObj.isCommentEditable && setFocusComment(true);
    // eslint-disable-next-line
  }, [editCommentObj.isCommentEditable]);

  const { handleCommentClick, handleLike, handleRepost } = usePostEngagement({
    likePost,
    isLiked,
    post,
    repost,
    isReposted,
    isPostDetail,
    postId,
    isArticleDetail,
    setScrollToBottom,
    setFocusComment,
  });

  return (
    <React.Fragment>
      {!isLecture && (
        <Box
          width="1200px"
          maxWidth={1}
          className={stickEngagementDetails ? 'stickToBottom' : ''}
          left="auto!important"
        >
          <Grid>
            <Grid
              item
              sm={stickEngagementDetails ? 6 : 12}
              md={stickEngagementDetails ? 6 : 12}
              lg={stickEngagementDetails ? 6 : 12}
              className={!isArticleDetail ? 'mxAuto' : ''}
            >
              <Box bgcolor="white" mr={!isTablet && isArticleDetail ? 12 : ''}>
                <PostEngagementSection
                  handleLike={handleLike}
                  focusComment={focusComment}
                  handleRepost={handleRepost}
                  likesCount={likesCount}
                  commentsCount={commentsCount}
                  repostsCount={repostsCount}
                  isLiked={isLiked}
                  isReposted={isReposted}
                  post={post}
                  isPostDetail={isPostDetail}
                  isArticleDetail={isArticleDetail}
                  handleCommentClick={handleCommentClick}
                  lectureObj={lectureObj}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      <CommentSection
        showCommentBox={showCommentBox}
        isPostDetail={isPostDetail}
        focusComment={focusComment}
        post={post}
        isArticleDetail={isArticleDetail}
        setScrollToBottom={setScrollToBottom}
        handleCommentClick={handleCommentClick}
        lectureEntityId={lectureEntityId}
        isLecture={isLecture}
        lectureObj={lectureObj}
        chapterTitle={chapterTitle}
      />
    </React.Fragment>
  );
};

export default PostEngagement;
