import React, { useEffect, useState } from 'react';
import { List } from '@material-ui/core';
import SideBarMenuItem from './sideBarMenuItem';
import { useLoggedInUser } from '../../hooks/userHooks';
import SideBarProfileSection from './sideBarProfileSection';
import { getMenu } from '../../services/authService';
import { getIsOnboardingUrl } from '../../utils/redirectUtil';
import { useSnackBar } from '../../hooks/snackBarHooks';
import { menuMapper } from '../../mappers/userMapper';
import { NavLink } from 'react-router-dom';
import { useTablet } from '../../hooks/utilHooks';
import { useMenuItems } from '../../hooks/menuHooks';

const NavigationMenu = () => {
  const user = useLoggedInUser();
  const [menuData, setMenuData] = useState([]);
  const setToastMessage = useSnackBar('');
  const [activeMenuIndex, setActiveMenuIndex] = useState(-1);
  const isTablet = useTablet();
  const isOnboardingUrl = getIsOnboardingUrl();
  const { removedMenuKeys, insertMenuItems } = useMenuItems();

  async function getMenuData() {
    const res = await getMenu();
    if (
      res &&
      res.status === 'SUCCESS' &&
      res.menu_items &&
      res.menu_items.length > 0
    ) {
      const menuItems = res.menu_items.map(menuMapper);
      const filteredMenu = menuItems.filter(
        (item) => !removedMenuKeys.includes(item.menuKey)
      );
      setMenuData([...insertMenuItems, ...filteredMenu]);
      // merging the static menu data and api data
    } else {
      setToastMessage(
        (res && res.error_message) || 'Please try again after sometime.'
      );
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      user && menuData.length === 0 && getMenuData();
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [isTablet]);

  return (
    <React.Fragment>
      {isTablet && <SideBarProfileSection />}
      {isOnboardingUrl && user && menuData && menuData.length > 0 && (
        <List component="nav" className="navLink">
          {menuData.map((menu, i) => {
            const {
              isActive,
              menuUrl,
              menuName,
              selectedIconUrl,
              iconUrl,
              menuKey,
            } = menu;
            const splitUrl = menuUrl && menuUrl.split('/');
            const isFullUrl =
              splitUrl && (splitUrl[0] === 'https:' || splitUrl[0] === 'http:');

            const SideBarMenu = () => {
              return (
                <SideBarMenuItem
                  onClick={() => setActiveMenuIndex(i)}
                  text={menuName}
                  icon={
                    <img
                      src={activeMenuIndex === i ? selectedIconUrl : iconUrl}
                      width="30"
                      height="30"
                      alt=""
                      style={{
                        borderRadius:
                          menuKey === 'profile'
                            ? '50%'
                            : (menu && menu.isStatic && menu.isStatic) || false
                            ? '9px'
                            : '0px',
                      }}
                    />
                  }
                />
              );
            };
            return isActive && menuUrl ? (
              isFullUrl ? (
                <a
                  href={menuUrl || '/'}
                  key={i}
                  onClick={menu.onClick && menu.onClick}
                >
                  {SideBarMenu()}
                </a>
              ) : (
                <NavLink
                  to={menuUrl}
                  key={i}
                  onClick={menu.onClick && menu.onClick}
                >
                  {SideBarMenu()}
                </NavLink>
              )
            ) : (
              ''
            );
          })}
        </List>
      )}
    </React.Fragment>
  );
};
export default NavigationMenu;
