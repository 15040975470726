import http from './httpService';
import APIURL from '../utils/apiUrl';
import { userMap, userErrorMap } from '../mappers/userMapper';
import { setOnboardingStatus } from './onboardingService';
import analytics from './analyticsService';
import { setCookie, getCookie, deleteCookie } from '../utils/cookies';
import userService, { getUserDetailsByToken } from './userService';
import { cookieOptions, deviceData, urlMap } from '../utils/constants';
import { getRedirectUrl } from '../utils/userUtil';
import { womenWillCommunityId } from '../utils/textUtil';
import communityService from './communityService';

const userKey = 'user';
const loginFromKey = 'loginFrom';
const deactivatedUserKey = 'deactivatedUser';
const deactivatedUserMessageKey = 'deactivatedUserMessage';
const screenTypeKey = 'feedScreen';
const userDeviceInfoKey = 'userDeviceInfo';
const userFromKey = 'userFromMM';
http.setUserToken(getUserToken());

export function getCurrentUser() {
  try {
    const userStr = localStorage.getItem(userKey);
    return JSON.parse(userStr);
  } catch (ex) {
    return null;
  }
}

export function setCurrentUser(data) {
  localStorage.setItem(userKey, JSON.stringify(data));
  setCookie(
    cookieOptions.USER_ID,
    data && data.id,
    cookieOptions.COOKIE_EXPIRE_DAYS
  );
}

export async function login(
  username,
  password,
  callback,
  history,
  setToastMessage
) {
  const dData = {
    advertisementid: 'string',
    devicetype: 'htc HTC Desire 626 dual sim',
    source: 'web',
    gcmorapnsid: '5.0.7',
    deviceid: 'web-pwa',
  };
  try {
    const { data } = await http.post(APIURL.SIGN_IN_BY_EMAIL, {
      ...dData,
      password,
      username,
    });
    if (data && data.status === 'FAILED') {
      callback({
        errors: data.error_message
          ? data.error_message
          : 'Username or password is invalid',
      });
    } else {
      callback({}); // Empty response for a successful login
      return processLoginResponse(data, 'emailId', history, setToastMessage);
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      callback({ errors: ex.response.data.error_message });
    } else {
      callback({ errors: 'Username or password is invalid' });
    }
  }
}

export function logout() {
  analytics.reset();
  localStorage.clear();
  deleteCookie(cookieOptions.AUTH_TOKEN);
  deleteCookie(cookieOptions.USER_ID);
  deleteCookie(cookieOptions.LOGIN_EVENT_FIRED);
}

export function getUserToken() {
  return getCookie(cookieOptions.AUTH_TOKEN);
}

export function setUserAuthToken(data) {
  setCookie(cookieOptions.AUTH_TOKEN, data, cookieOptions.COOKIE_EXPIRE_DAYS);
}

export async function socialLogin(token, type, history, setToastMessage) {
  let loginAPIUrl = null;
  const dData = {
    advertisementid: 'string',
    devicetype: 'htc HTC Desire 626 dual sim',
    source: 'web',
    gcmorapnsid: '5.0.7',
    deviceid: 'web-pwa',
  };
  loginAPIUrl =
    type === 'facebook' ? APIURL.SIGN_IN_BY_FACEBOOK : APIURL.SIGN_IN_BY_GOOGLE;
  const { data } = await http.post(loginAPIUrl, {
    ...dData,
    accees_token: token,
  });
  if (data && data.status === 'FAILED') {
    if (data?.gender === 'male') {
      history.push(urlMap.MALE_RESTRICTED);
    } else {
      setToastMessage(data?.error_message);
    }
  } else {
    return processLoginResponse(data, type, history, setToastMessage);
  }
}

export async function processLoginResponse(
  data,
  signupType,
  history,
  setToastMessage
) {
  if (data.status === 'SUCCESS') {
    const responseData = userMap(data);
    setOnboardingStatus(responseData.isOnboardingDone, responseData);
    setUserAuthToken(responseData.token);
    setCurrentUser(responseData);
    setUserDeviceData(responseData);
    await getUserDetailsNew();
    const referrerUrl = getReferrerUrl();
    const splitUrl = referrerUrl && referrerUrl.split('/');
    const isSheroesReferrerUrl = splitUrl && splitUrl[2] === 'sheroes.com';
    responseData &&
      responseData.nextActivityEvent === 'create_post_pop_up' &&
      (await userService.markGender(responseData.id, true));
    const redirectUrl = getRedirectUrl(responseData);
    if (referrerUrl !== null) localStorage.removeItem('referrerUrl');
    responseData.nextScreen === 'feed_blt' ||
    responseData.nextScreen === 'questionnaire' ||
    responseData.nextScreen === 'language_selection'
      ? setFeedScreen('ww')
      : setFeedScreen('social');
    await handleJoinWWCommunity(history, setToastMessage);
    const isLogout = false;
    await updateUserDevice(isLogout);

    setTimeout(() => {
      const user = getCurrentUser();
      const updatedData = { loginMethod: signupType };
      setCurrentUser({ ...user, ...updatedData });
      const splitUrl = referrerUrl && referrerUrl.split('/');
      const isLogoutUrl =
        splitUrl &&
        ((splitUrl[3] === 'users' && splitUrl[4] === 'logout') ||
          splitUrl[3] === 'logout');

      if (referrerUrl && isSheroesReferrerUrl && !isLogoutUrl) {
        document.location = referrerUrl;
      } else if (history && redirectUrl !== '/feed') {
        history.replace(redirectUrl);
      } else {
        document.location = redirectUrl;
      }
    }, 1000);

    return responseData;
  } else {
    const responseData = userErrorMap(data);
    if (data.fieldErrorMessageMap.IS_DEACTIVATED) {
      localStorage.setItem(deactivatedUserKey, responseData.isDeactivated);
      localStorage.setItem(deactivatedUserMessageKey, responseData.message);
      document.location = responseData.nextScreenUrl;
    }
    return responseData;
  }
}
export async function getUserDetailsNew(state) {
  const fromGwwScreen = state && state?.fromGwwScreen;
  http.setUserToken(getUserToken());
  const { data } = await http.post(APIURL.GET_USER_DETAILS_NEW);
  let storageData = {};
  const user = getCurrentUser();
  if (data) {
    const userData = data?.user_details;
    const language =
      userData.currentAppLanguage === 'undefined' ||
      userData.currentAppLanguage === 'null' ||
      !userData.currentAppLanguage
        ? 'en'
        : userData.currentAppLanguage;
    userData && !fromGwwScreen && setPostLanguage(language); //set User Post Language
    storageData = {
      isEmailVerified: userData.isEmailVerified,
      partnerId: userData.partnerId,
      userCategory: userData.userCategory,
      verificationLevel: userData.verificationLevel,
      courseId: userData.courseId === null ? '3' : userData.courseId,
      isCourseComplete: userData.isCourseComplete,
      isGwwBltCertificateAssigned: userData?.isGwwBltCertificateAssigned,
      isCertificateDownload: false,
      name: userData.name,
      firstName: userData.firstName,
      lastName: userData.lastName,
      isDashboard:
        userData.userCategory === 'gm' || userData.userCategory === 'fc',
      isWWCommMember: userData.isCommMember,
      currentLanguage: fromGwwScreen ? user.currentLanguage : language,
      hasInterests: userData?.hasInterests || false,
    };
    setCurrentUser({ ...user, ...storageData });
  }
}
export async function updateUserDevice(isLogout) {
  await updateDevice(deviceData, isLogout);
}
export async function handleJoinWWCommunity(history, setToastMessage) {
  const user = getCurrentUser();
  if (user && !user.isWWCommMember) {
    const wwCid = womenWillCommunityId;
    const response = await communityService.joinCommunity(
      wwCid,
      user?.id,
      true,
      history,
      setToastMessage
    );
    if (response.status === 'SUCCESS') {
      const updatedUser = { isWWCommMember: true };
      setCurrentUser({ ...user, ...updatedUser });
    }
  }
}
export function isUserDeactivated() {
  return localStorage.getItem(deactivatedUserKey);
}
export function getUserDeactivateMessage() {
  const userDeactivatedMessage = localStorage.getItem(
    deactivatedUserMessageKey
  );
  return userDeactivatedMessage;
}

/**
 * If token is there, but user object not there.
 * Fetch user object from server (Login from old sheroes.com)
 * @param {*} currentPath
 */
async function loginUserByToken(currentPath, setToastMessage) {
  const token = getUserToken();
  const clientSource = null;
  if (!token || getCurrentUser()) return;
  const data = await getUserDetailsByToken(
    token,
    clientSource,
    setToastMessage
  );
  if (data && data.status === 'SUCCESS') {
    data && setCurrentUser(data);
    token && setUserAuthToken(token);
    setOnboardingStatus(true);
    document.location = currentPath;
  }
}
export function setReferrerUrl(url) {
  localStorage.setItem('referrerUrl', url);
}
export function getReferrerUrl() {
  const referrerUrl = localStorage.getItem('referrerUrl');
  return referrerUrl;
}
export function setLoginEventFired() {
  setCookie(
    cookieOptions.LOGIN_EVENT_FIRED,
    true,
    cookieOptions.COOKIE_EXPIRE_DAYS
  );
}
export function getLoginEventFired() {
  return getCookie(cookieOptions.LOGIN_EVENT_FIRED);
}
export function setPostLanguage(language) {
  localStorage.setItem('postLanguage', language);
}
export function getPostLanguage() {
  const user = getCurrentUser();
  const currentLanguage = user?.currentLanguage;
  return currentLanguage || localStorage.getItem('postLanguage');
}
export function setLoginSource(source) {
  localStorage.setItem(loginFromKey, source);
}
export function getLoginSource() {
  return localStorage.getItem(loginFromKey);
}

export async function payTmLoginByToken(token, clientSource, setToastMessage) {
  const data =
    token &&
    (await getUserDetailsByToken(token, clientSource, setToastMessage));
  if (data) {
    setCurrentUser(data);
    setUserAuthToken(token);
    setOnboardingStatus(true);
    document.location = '/feed';
  } else {
    logout();
  }
}
export function setFeedScreen(screenType) {
  localStorage.setItem(screenTypeKey, screenType);
}
export function getFeedScreen() {
  return localStorage.getItem(screenTypeKey);
}
export function getIsShowWWFeed() {
  try {
    return localStorage.getItem('isWWFeed');
  } catch (ex) {
    return null;
  }
}

export function setIsShowWWFeed(status) {
  localStorage.setItem('isWWFeed', status);
}

export async function updateDevice(deviceData, isLogout) {
  const currentUser = getCurrentUser();
  const currentLanguage = currentUser && currentUser.currentLanguage;
  const request = {
    ...deviceData,
    is_logged_out: isLogout,
  };

  const data = await userService.updateUserLanguage(currentLanguage, request);
  return data;
}
export async function setUserDeviceData(data) {
  const userDeviceInfo = { ...deviceData, user_id: data && data.id };
  localStorage.setItem(
    userDeviceInfoKey,
    JSON.stringify({ ...userDeviceInfo })
  );
}
export function getUserDeviceData() {
  try {
    return JSON.parse(localStorage.getItem(userDeviceInfoKey));
  } catch (ex) {
    return null;
  }
}

export function setThirdPartyUser(userFromMM) {
  localStorage.setItem(userFromKey, userFromMM);
}
export function getThirdPartyUser() {
  return localStorage.getItem(userFromKey);
}

export function getSpentTime() {
  return localStorage.getItem('spentTime');
}
export async function getMenu() {
  const user = getCurrentUser();
  const reqData = {
    appLanguage: 'string',
    appVersion: '16.0.2',
    client_name: 'string',
    display_default_on_failure: true,
    last_screen_name: 'string',
    lat: 0,
    long: 0,
    next_token: 'string',
    os: 'string',
    os_version: 'string',
    partner_id: user && user.partnerId && user.partnerId,
    partner_name: 'string',
    screen_name: 'string',
    source: 'web',
    test_user_type: 'web_user',
    user_agent: 'string',
    ios_version: '1.0.0',
    user_type: user && user.userTypeId && user.userTypeId,
  };

  const { data } = await http.post(APIURL.APP_MENU, reqData);
  return data;
}
export default {
  login,
  logout,
  getCurrentUser,
  getUserToken,
  socialLogin,
  getUserDeactivateMessage,
  isUserDeactivated,
  setCurrentUser,
  setUserAuthToken,
  loginUserByToken,
  setReferrerUrl,
  getReferrerUrl,
  setLoginEventFired,
  getLoginEventFired,
  setPostLanguage,
  getPostLanguage,
  processLoginResponse,
  payTmLoginByToken,
  setLoginSource,
  getLoginSource,
  setIsShowWWFeed,
  getIsShowWWFeed,
  setUserDeviceData,
  getUserDeviceData,
  updateDevice,
  getSpentTime,
  getMenu,
};
