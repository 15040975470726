import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField } from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../img/search-white.svg';
import CancelIcon from '@material-ui/icons/Cancel';
import { useTablet } from '../../hooks/utilHooks';
import { getLoginSource, getPostLanguage } from '../../services/authService';
import languages from '../../utils/languages';

const SearchBar = ({
  handleSubmit,
  searchValue: defaultValue,
  fixRight = false,
}) => {
  const webLanguage = getPostLanguage();
  const languageText = languages[webLanguage ? webLanguage : 'en'];
  const [showBorder, setShowBorder] = useState(false);
  const classes = useStyles({ fixRight });
  const isTablet = useTablet();
  const ref = useRef();
  const loginSource = getLoginSource();

  const handleBorder = (onFocus) => {
    onFocus ? setShowBorder(true) : setShowBorder(false);
  };
  if (ref.current && defaultValue) ref.current.value = defaultValue;
  return (
    <React.Fragment>
      <form
        className={classes.setMargin}
        onSubmit={(e) => {
          ref.current.blur();
          e.preventDefault();
          handleSubmit(ref.current.value);
        }}
      >
        <Box
          onFocus={() => handleBorder(true)}
          onBlur={() => handleBorder(false)}
          style={showBorder ? { border: '1px solid #fff' } : { border: 'none' }}
          className={
            loginSource === 'paytm' ? classes.paytmSearchBar : classes.main
          }
          width={isTablet ? 0.72 : 0.43}
          top={10}
        >
          <SearchIcon />
          <TextField
            type="text"
            inputRef={ref}
            defaultValue={defaultValue}
            placeholder={languageText?.SEARCH}
            className={classes.inputSet}
          />
          {defaultValue && defaultValue.length > 0 && (
            <CancelIcon
              className={classes.closeIcon}
              onClick={(e) => {
                ref.current.value = '';
                ref.current.blur();
                handleSubmit(null);
              }}
            />
          )}
        </Box>
      </form>
    </React.Fragment>
  );
};

export default SearchBar;

const useStyles = makeStyles((theme) => ({
  main: {
    height: '28px',
    background: 'rgba(0,0,0,0.3)',
    position: (props) => (props.fixRight ? null : 'fixed'),
    width: (props) => (props.fixRight ? '180px' : null),
    left: theme.spacing(0),
    right: theme.spacing(0),
    zIndex: '5000',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 2),
    borderRadius: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  paytmSearchBar: {
    height: '28px',
    background: 'rgba(0,0,0,0.3)',
    position: (props) => (props.fixRight ? null : 'fixed'),
    width: (props) => (props.fixRight ? '180px' : null),
    left: theme.spacing(0),
    right: theme.spacing(0),
    zIndex: '5000',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 2),
    borderRadius: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    marginTop: '-73px',
  },
  inputSet: {
    color: 'white',
    width: '100%',
    padding: theme.spacing(0, 2),
    '& div': {
      color: 'white',
      height: theme.spacing(10),
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
    },
  },
  closeIcon: {
    color: 'white',
    width: '20px',
  },
  setMargin: {
    marginRight: theme.spacing(2),
  },
}));
