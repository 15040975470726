import axios from 'axios';
import axiosUtil from '../utils/axiosUtil';
const userKey = 'user';
const internalAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
//internalAxios.interceptors.response.use(axiosUtil.responseInterceptor);
internalAxios.interceptors.response.use(
  (res) => axiosUtil.responseInterceptor(res, ''),
  (err) => axiosUtil.responseInterceptor('', err)
);

const user = getCurrentUser();
const selectedLanguage = user?.currentLanguage || getPostLanguage() || 'en';

internalAxios.interceptors.request.use(
  (config) => {
    config.headers.common['X-Language'] = selectedLanguage;
    return config;
  },
  (error) => Promise.reject(error)
);
function setUserToken(token) {
  internalAxios.defaults.headers.common['Authorization'] = token;
}
function getPostLanguage() {
  return localStorage.getItem('postLanguage');
}
export function getCurrentUser() {
  try {
    const userStr = localStorage.getItem(userKey);
    return JSON.parse(userStr);
  } catch (ex) {
    return null;
  }
}

export default {
  get: internalAxios.get,
  post: internalAxios.post,
  put: internalAxios.put,
  delete: internalAxios.delete,
  setUserToken,
};
