import { useState, useEffect, useCallback } from 'react';
import { pageSize } from '../utils/constants';
import { useSnackBar } from './snackBarHooks';

function useInfiniteScrollThunkWithPageNumber(
  serviceThunk,
  options = { triggerPagination: true, shouldPrepend: false }
) {
  const [pageNumber, setPageNumber] = useState(1);
  const [loadData, setLoadData] = useState(options.triggerPagination);
  useEffect(() => {
    async function fetchData() {
      await serviceThunk(pageNumber);
      setPageNumber(pageNumber + 1);
    }
    setLoadData(false);
    loadData && fetchData();
  }, [loadData, pageNumber, serviceThunk]);

  const handleLoadMore = useCallback(() => {
    setLoadData(true);
  }, []);
  return { handleLoadMore };
}

function useInfiniteScrollApiWithPageNumber(
  serviceApi,
  options = { triggerPagination: true, shouldPrepend: false }
) {
  const [pageNumber, setPageNumber] = useState(1);
  const [loadData, setLoadData] = useState(options.triggerPagination);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const setToastMessage = useSnackBar();

  useEffect(() => {
    async function fetchData() {
      const newData = await serviceApi(pageNumber, setToastMessage);
      newData &&
        setData(
          options.shouldPrepend ? [...newData, ...data] : [...data, ...newData]
        );
      setPageNumber(pageNumber + 1);
      if (newData && newData.length < pageSize - 1) setHasMore(false); //for now going with length
    }
    setLoadData(false);
    loadData && fetchData();
    // eslint-disable-next-line
  }, [data, loadData, options.shouldPrepend, pageNumber, serviceApi]);

  const handleLoadMore = useCallback(() => {
    setLoadData(true);
  }, []);
  return { data, handleLoadMore, hasMore, setData };
}

export {
  useInfiniteScrollThunkWithPageNumber,
  useInfiniteScrollApiWithPageNumber,
};
