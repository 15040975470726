import logService from '../services/logService';

function responseInterceptor(response, error) {
  if (response) {
    if (
      response &&
      response.data &&
      (response?.data?.status === 'FAILED' ||
        response?.data[0]?.status === 'FAILED')
    ) {
      const errorRes = response.data;
      const error = errorRes ? errorRes : new Error('API response FAILED');
      const url = response?.config?.url;

      if (
        (errorRes?.status === 'FAILED' || errorRes[0].status === 'FAILED') &&
        url === '/participant/feed/'
      ) {
        window.location.href = '/not-found';
      }
      const request = response.config && response.config.data;
      const apiUrl = response.config && response.config.url;
      logService.error(error, { response: response.data, request, apiUrl });
    }
    return response;
  }
  if (error) {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500;

    if (!expectedError) {
      logService.error(error, { response: error.response });
    }

    return Promise.reject(error);
  }
}
export default { responseInterceptor };
