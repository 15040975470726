import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const TestimonialCarouselBuilt = ({ testimonialObj, activeStep }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <ul className={classes.carouseBuilt}>
        {testimonialObj &&
          testimonialObj.map((item, index) => (
            <li
              key={index}
              className={`${classes.carouselPosition} ${
                activeStep === index && classes.activeBuilt
              }`}
            ></li>
          ))}
      </ul>
    </React.Fragment>
  );
};
export default TestimonialCarouselBuilt;

const useStyles = makeStyles((theme) => ({
  carouselPosition: {
    border: '1px solid' + theme.palette.grey['50'],
    listStyle: 'none',
    width: '8px',
    height: '8px',
    marginRight: theme.spacing(1),
    borderRadius: '50%',
  },
  activeBuilt: {
    background: theme.palette.primary.main,
    border: '1px solid' + theme.palette.primary.main,
  },
  carouseBuilt: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    left: 0,
    right: 0,
    top: theme.spacing(3),
    padding: theme.spacing(4, 0),
    display: 'flex',
    justifyContent: 'center',
  },
}));
