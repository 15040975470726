import React from 'react';
import { AppBar, Box } from '@material-ui/core';
import { useStoreState } from 'easy-peasy';
import BottomNavbar from '../bottomNavbar';
import { getLoginSource } from '../../services/authService';
import NavbarItem from './navbarItem';
import { useTablet } from '../../hooks/utilHooks';
import { useNavbarItems } from '../../hooks/navBarHooks';

const Navbar = () => {
  const locationUrl = window.location.pathname;
  const splitData = locationUrl.split('/');
  let isSurvey = splitData[3] === 'survey';
  let isLecture = splitData[1] === 'lecture';
  const hideNavBar = useStoreState((state) => state.navBar.options.hideNavBar);
  const isTablet = useTablet();
  const loginSource = getLoginSource();
  const { showBottomNavbar } = useStoreState((state) => state.navBar.options);
  const options = useStoreState((state) => state.navBar.options);
  const {
    isOnboardingDone,
    userFromMM,
    isCreatePost,
    handleBackNavigation,
    notificationsCount,
    transparentHeader,
  } = useNavbarItems();

  // useEffect(() => {
  //   async function getSumoBar() {
  //     try {
  //       const sumoData = await postService.sumoBar();
  //       setSumoObj(sumoData.images[0]);
  //     } catch (error) {}
  //   }
  //   !userFromMM && getSumoBar();
  // }, [userFromMM]);

  return (
    <React.Fragment>
      {!hideNavBar &&
        ((isCreatePost && userFromMM === 'true') ||
          (isSurvey && userFromMM === 'true') ||
          (isLecture && userFromMM === 'true') ||
          userFromMM === null) && (
          <Box mb={userFromMM === 'true' ? 14 : 0}>
            <AppBar
              position={isTablet && !isOnboardingDone ? 'static' : 'fixed'}
              style={{
                display: isTablet && !isOnboardingDone ? 'none' : 'block',
                height: '50px',
              }}
              className={transparentHeader()}
            >
              <NavbarItem />{' '}
            </AppBar>
          </Box>
        )}
      {userFromMM === 'true' ? (
        <Box mb={1} />
      ) : loginSource !== 'paytm' ? (
        <Box mb={isTablet && !isOnboardingDone ? 0 : 14} />
      ) : (
        <Box mb={14} />
      )}
      {showBottomNavbar && isTablet && (
        <BottomNavbar
          handleBackNavigation={handleBackNavigation}
          options={options}
          notificationsCount={notificationsCount}
        />
      )}
    </React.Fragment>
  );
};

export default Navbar;
