import { lazyPreload } from '../utils/lazyUtil';

const AboutMars = lazyPreload(() => import('../components/mars/aboutMars'));
const PersonalDetails = lazyPreload(() =>
  import('../components/mars/personalDetails')
);
const Instructions = lazyPreload(() =>
  import('../components/mars/instructions')
);
const EducationQualification = lazyPreload(() =>
  import('../components/mars/educationQualification')
);

const Questions = lazyPreload(() => import('../components/mars/Questions'));
const MarsSuccess = lazyPreload(() => import('../components/mars/marsSuccess'));

const MarsRegister = lazyPreload(() =>
  import('../components/marsRegister/marsRegister')
);
const PersonalDetailsRegister = lazyPreload(() =>
  import('../components/marsRegister/personalDetailsRegister')
);

const EducationQualificationRegister = lazyPreload(() =>
  import('../components/marsRegister/educationQualificationRegister')
);
const MarsSuccessRegister = lazyPreload(() =>
  import('../components/marsRegister/marsSuccessRegister')
);

const routes = [
  {
    path: '/mars/about',
    component: AboutMars,
  },
  {
    path: '/mars/personal-details',
    component: PersonalDetails,
  },
  {
    path: '/mars/education-qualification',
    component: EducationQualification,
  },
  {
    path: '/mars/instructions',
    component: Instructions,
  },
  {
    path: '/mars/questions',
    component: Questions,
  },
  {
    path: '/mars/success',
    component: MarsSuccess,
  },
  {
    path: '/mars/register',
    component: MarsRegister,
  },
  {
    path: '/mars/register/personal-details',
    component: PersonalDetailsRegister,
  },
  {
    path: '/mars/register/education-qualification',
    component: EducationQualificationRegister,
  },
  {
    path: '/mars/register/success',
    component: MarsSuccessRegister,
  },
];
export default routes;
