import React, { useState } from 'react';
import {
  Box,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { ReactComponent as MenuVertIcon } from '../../img/menu-kebab.svg';
import { ReactComponent as RedMenuVertIcon } from '../../img/menu-kebab-red.svg';
import { RWebShare } from 'react-web-share';
import { getPostLanguage } from '../../services/authService';
import languages from '../../utils/languages';

const ActionToggle = ({ options, postId, isUserProfile }) => {
  const classes = useStyles();
  const webLanguage = getPostLanguage();
  const languageText = languages[webLanguage ? webLanguage : 'en'];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 40;
  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl();
  };

  const handleCloseMenu = (e) => {
    e.stopPropagation();
    setAnchorEl();
  };

  return (
    <div>
      <Box className={isUserProfile && classes.optionMenu}>
        <IconButton
          aria-label="More"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {isUserProfile ? <RedMenuVertIcon /> : <MenuVertIcon />}
        </IconButton>
      </Box>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'auto',
            marginTop: isUserProfile ? '60px' : '80px',
            cursor: 'pointer',
          },
        }}
      >
        <Box>
          {options &&
            options.map((option) => {
              const isShareOption =
                option.name === languageText.SHARE_POST && option.shareData;
              return (
                <Box key={option.name}>
                  {option && option.access && (
                    <MenuItem onClick={(e) => handleClose(e, option)}>
                      {isShareOption ? (
                        <RWebShare
                          data={option.shareData}
                          onClick={() => console.log('shared successfully!')}
                        >
                          <Box className={classes.menuItemWrapper}>
                            {option.icon && option.icon()}
                            <Typography>{option.name}</Typography>
                          </Box>
                        </RWebShare>
                      ) : (
                        <Box
                          className={classes.menuItemWrapper}
                          onClick={option.action && option.action}
                        >
                          {option.icon && option.icon()}
                          <Typography>{option.name}</Typography>
                        </Box>
                      )}
                    </MenuItem>
                  )}
                </Box>
              );
            })}
        </Box>
      </Menu>
    </div>
  );
};

export default ActionToggle;

const useStyles = makeStyles((theme) => ({
  menuItemWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '3px',
  },
  optionMenu: {
    '& button': {
      border: '1px solid rgba(220, 69, 65, 0.5)',
      marginLeft: '6px',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2.5, 3.5),
      color: '#DC4541',
      '&:hover': {
        backgroundColor: 'rgb(246 5 5 / 4%)',
      },
    },
  },
}));
