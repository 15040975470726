import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid } from '@material-ui/core';
import { useMobile, useTablet } from '../../hooks/utilHooks';
import { communitiesInterestsObj } from '../../utils/homeUtils';
import { useStoreActions } from 'easy-peasy';
import AnyLink from '../common/anyLink';
import authService from '../../services/authService';
import { useSnackBar } from '../../hooks/snackBarHooks';

const CommunitiesInterests = () => {
  const { userLoginModal } = useStoreActions((actions) => actions.user);
  const classes = useStyles();
  const isMobile = useMobile();
  const isTablet = useTablet();
  const loginScreen = authService.getLoginSource();
  const setToastMessage = useSnackBar();
  function handleClick() {
    loginScreen &&
      loginScreen !== 'paytm' &&
      userLoginModal({
        showModal: true,
        referrerUrl: '/communities',
        setToastMessage,
      });
  }

  return (
    <React.Fragment>
      <Box px={isMobile ? 4 : 4} className={classes.interestsSection}>
        <Box className={isTablet ? 'width100' : 'mxAuto homeContainer'}>
          <Grid container>
            <Grid item xs={12} sm={5} md={5}>
              <Box align={isMobile ? 'center' : 'left'} mt={isMobile ? 0 : 12}>
                <Typography className={isMobile ? '' : classes.interestHeading}>
                  Follow communities
                </Typography>
                <Typography className={isMobile ? '' : classes.interestHeading}>
                  of your interests
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={7} md={7}>
              <Box className={classes.interestsText} mt={4}>
                {communitiesInterestsObj &&
                  communitiesInterestsObj.map((item, index) => (
                    <AnyLink key={index} to={item.url}>
                      <Typography variant="body1">
                        <img
                          src={item.imgUrl}
                          alt="icon"
                          className={classes.interestsIcon}
                          width="24px"
                          height="24px"
                        />
                        {item.text}
                      </Typography>
                    </AnyLink>
                  ))}
              </Box>
              <Typography
                className={classes.moreLink}
                variant="caption"
                onClick={handleClick}
              >
                & more communities...
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};
export default CommunitiesInterests;

const useStyles = makeStyles((theme) => ({
  interestsSection: {
    // background: '#f6f6f6',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  interestsText: {
    '& p': {
      borderRadius: theme.spacing(3.5),
      border: '1px solid #dcdcdc',
      display: 'inline-block',
      margin: theme.spacing(0, 2, 4, 0),
      padding: theme.spacing(0.5, 1, 0.5, 0),
    },
  },
  interestsIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
  },
  interestHeading: {
    fontSize: '28px',
    fontWeight: 600,
    lineHeight: 1.6,
  },
  moreLink: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));
