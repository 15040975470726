import { action } from 'easy-peasy';
import { urlMap } from '../utils/constants';

export default {
  open: false,
  errorMessage: '',
  btnPrimaryText: '',
  btnSecondaryText: '',
  dialogIcon: null,
  dialogTitle: '',
  urlToRedirect: null,
  btnPrimaryAction: false,
  btnSecondaryAction: true,
  setSaathiDialogBoxOpen: action((state, payload) => {
    console.log('payload:', payload);
    state.open = payload;
  }),
  setSaathiDialogContent: action(
    (
      state,
      {
        btnPrimaryText,
        btnSecondaryText,
        dialogIcon,
        dialogTitle,
        btnSecondaryAction,
        btnPrimaryAction,
      }
    ) => {
      state.btnPrimaryText = btnPrimaryText;
      state.btnSecondaryText = btnSecondaryText;
      state.dialogIcon = dialogIcon;
      state.dialogTitle = dialogTitle;
      state.btnSecondaryAction = btnSecondaryAction;
      state.btnPrimaryAction = btnPrimaryAction;
    }
  ),
  setHandleErrorMessage: action((state, payload) => {
    console.log('payload:', payload);
    if (payload.next_screen === 'selfie') {
      state.errorMessage = payload.error_message;
      state.urlToRedirect = urlMap.ONBOARDING_GENDER_VERIFICATION;
    }
  }),
};
