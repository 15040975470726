import { eventProps, eventNames } from './eventConstants';
import analytics from '../services/analyticsService';
import apiUrl from './apiUrl';
import { getSlug } from '../utils/textUtil';
import MoengageService from '../services/moEngageService';

function stripDomain(deepLinkUrl) {
  const postSlug =
    deepLinkUrl && deepLinkUrl.replace('https://sheroes.com', '');
  return postSlug;
}

async function sharePost(e, options, postObj, isMobile) {
  e && e.stopPropagation(); //to prevent going into detail screen if clicked from feed.
  if (!isMobile) {
    return;
  }
  try {
    navigator.share &&
      (await navigator.share({
        title: options.title,
        text: options.text,
        url: options.url,
      }));
    if (postObj.id) {
      //if its a post.
      const eventPropsObj = getPostObjForEventTracking(postObj);
      analytics.track(eventNames.postShared, eventPropsObj);
      MoengageService.trackPostEngaged(postObj, 'Share');
    } else if (postObj.isCommunity) {
      // if its a community
      analytics.track(eventNames.communityShared, {
        [eventProps.communityId]: postObj.communityId,
        [eventProps.communityName]: postObj.communityName,
      });
    }
  } catch (error) {}
}

function scrollToBottom() {
  window.scrollTo(0, document.body.offsetHeight);
}
function scrollToTop() {
  window.scrollTo(0, 0);
}

function getUserProfileLink(userName, userId) {
  const profileLink = '/users/' + getSlug(userName) + '/' + btoa(userId);
  return profileLink;
}

function getPostObjForEventTracking(postObj) {
  return {
    [eventProps.communityName]: postObj.communityName,
    [eventProps.communityId]: postObj.communityId,
    [eventProps.postId]: postObj.entityId,
    [eventProps.subType]: postObj.subType,
    [eventProps.streamType]: postObj.streamType,
  };
}
function getArticleStreamApi(articleCategoryId) {
  let articlesStreamApi = apiUrl.GET_ARTICLES;
  if (articleCategoryId > 0) {
    articlesStreamApi =
      articlesStreamApi + '&category_ids=' + articleCategoryId;
  } else if (articleCategoryId === '/articles/trending') {
    return apiUrl.GET_TRENDING_ARTICLES;
  }
  return articlesStreamApi;
}

function getCategoryUrl(data) {
  if (!data) return;
  return '/articles/category/' + getSlug(data.label) + '/' + btoa(data.value);
}

function scrollToPosition(ref) {
  window.scrollTo({
    top: ref.current.offsetTop,
    behavior: 'smooth',
  });
}
function getTabIndex(categoriesTabs, categorySlug) {
  if (!categoriesTabs) return 0;
  const tabs = categoriesTabs.filter(
    (category) => category.key === categorySlug
  );
  return tabs.length > 0 ? tabs[0].tabIndex : false;
}
function showActionButton(post, getCurrentUserId) {
  return (
    getCurrentUserId !== post.createdById &&
    !post.isCommunityPost &&
    !post.isAnonymous &&
    post.subType !== 'PL' &&
    !post.isShop
  );
}

function findHashtags(searchText) {
  var replace = searchText.replace(
    /#(\w+)/gi,
    "<a class='linkColor' href='/search?q=$1&type=hashtags'>#$1</a>"
  );
  return replace;
}

function getSubType() {
  const locationUrl = window.location.pathname;
  const splitData = locationUrl.split('/');
  let isPoll = splitData[4] === 'polls';
  let isShopType = splitData[1] === 'shop-on-sheroes';
  let isLecture = splitData[1] === 'lecture';
  let isArticle = splitData[1] === 'articles';

  let subType = 'P';
  if (isPoll) {
    subType = 'PL';
  } else if (isShopType) {
    subType = 'SHOP';
  } else if (isLecture) {
    subType = 'CH';
  } else if (isArticle) {
    subType = 'A';
  }
  return subType;
}

export {
  stripDomain,
  sharePost,
  scrollToTop,
  scrollToBottom,
  getUserProfileLink,
  getPostObjForEventTracking,
  getArticleStreamApi,
  getCategoryUrl,
  scrollToPosition,
  getTabIndex,
  showActionButton,
  findHashtags,
  getSubType,
};
