import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import auth from '../../services/authService';
import GoogleIcon from '../../img/google.svg';
import CustomButton from './customButton';
import { useHistory } from 'react-router-dom';
import { getPostLanguage } from '../../services/authService';
import languages from '../../utils/languages';
import { useGoogleLogin } from '@react-oauth/google';
import { useSnackBar } from '../../hooks/snackBarHooks';

const GoogleLoginNewButton = ({ setIsLoading }) => {
  const history = useHistory();
  const classes = useStyles();
  const webLanguage = getPostLanguage();
  const languageText = languages[webLanguage ? webLanguage : 'en'];
  const setToastMessage = useSnackBar();

  const googleSignUp = async (response) => {
    setIsLoading(true);
    if (response && response.access_token) {
      await auth.socialLogin(
        response.access_token,
        'google',
        history,
        setToastMessage
      );
      setIsLoading(false);
    }
  };
  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      googleSignUp(codeResponse);
    },
    onError: (error) => console.log('Google Login Failed:', error),
  });
  return (
    <React.Fragment>
      <CustomButton
        variant="primary"
        color="primary"
        size="large"
        className={`loginBtn ${classes.socialBtn}`}
        onClick={() => googleLogin()}
        imgIcon={GoogleIcon}
        text={languageText.CONTINUE_WITH_GOOGLE}
      />
    </React.Fragment>
  );
};
export default GoogleLoginNewButton;

const useStyles = makeStyles((theme) => ({
  socialBtn: {
    background: '#FFFFFF',
    color: '#484747',
    verticalAlign: 'inherit',
    '&:hover': {
      background: '#FFFFFF',
      color: '#484747',
    },
  },
}));
