import React, { useState, useEffect } from 'react';
import { useInfiniteScrollThunkWithPageNumber } from '../../hooks/infiniteScrollHook';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useCommentOnPost } from '../../hooks/postHooks';
import CommentList from './commentList';
import CreateComment from './createCommentCard';
import { useScrollToBottom } from '../../hooks/utilHooks';
import { useLoggedInUser } from '../../hooks/userHooks';
import { useTablet } from '../../hooks/utilHooks';
import { useSnackBar } from '../../hooks/snackBarHooks';

const CommentSection = ({
  isPostDetail,
  focusComment,
  post,
  isArticleDetail,
  handleCommentClick,
  lectureEntityId,
  isLecture,
  lectureObj,
  chapterTitle,
  showCommentBox,
}) => {
  const postId = post.id;
  const parentId = post.parentId;
  const communityId = post.communityId;
  const loggedInUser = useLoggedInUser();
  const [showComment, setShowComment] = useState();
  const lectureData = post && post.lecture && post.lecture[0];
  const isBonus = lectureData?.isBonus;
  const isTablet = useTablet();
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const setToastMessage = useSnackBar('');
  useEffect(() => {
    if (loggedInUser?.isCourseComplete) {
      if (isArticleDetail || isPostDetail) {
        if (post?.showActionsPostCompletion === false) {
          setShowComment(false);
        } else {
          setShowComment(true);
        }
      }
    }
    if (isLecture && !showCommentBox) {
      setShowComment(false);
    } else {
      setShowComment(true);
    }
  }, [
    isBonus,
    isLecture,
    showCommentBox,
    loggedInUser,
    isArticleDetail,
    isPostDetail,
    lectureData,
    post,
  ]);
  useScrollToBottom(scrollToBottom);
  const fetchPostComments = useStoreActions(
    (actions) => actions.posts.fetchPostComments
  ); // thunk.
  const postComments = useStoreState(
    (state) => state.posts.postsObj[postId].comments
  ); // store.
  const triggerPagination =
    (isPostDetail || isArticleDetail) && !postComments.length ? true : false;
  const { handleLoadMore, hasMore } = useInfiniteScrollThunkWithPageNumber(
    (pageNum) => {
      return fetchPostComments({
        postId: post.id,
        postEntityId: post.parentId,
        pageNum: pageNum,
        shouldPrepend: true,
        lectureEntityId: lectureEntityId,
        isLecture: isLecture,
        setToastMessage: setToastMessage,
      });
    },
    { triggerPagination }
  );
  const {
    handleSaveComment,
    commentDescription,
    commentUserMention,
    handleSetCommentEditor,
    isCommentSubmit,
    setIsCommentSubmit,
    handleCommentChange,
    handleSetMention,
  } = useCommentOnPost(
    post,
    setScrollToBottom,
    lectureEntityId,
    isLecture,
    lectureObj,
    chapterTitle,
    isTablet
  );
  return (
    <React.Fragment>
      <CommentList
        isPostDetail={isPostDetail}
        post={post}
        hasMore={hasMore}
        handleLoadMore={handleLoadMore}
        isArticleDetail={isArticleDetail}
        isCommentDetail={false}
        lectureEntityId={lectureEntityId}
        isLecture={isLecture}
      />

      {((isArticleDetail && focusComment) || isPostDetail) && showComment && (
        <CreateComment
          handleCommentClick={handleCommentClick}
          handleSaveComment={handleSaveComment}
          post={post}
          focusComment={focusComment}
          isPostDetail={isPostDetail}
          isArticleDetail={isArticleDetail}
          commentDescription={commentDescription}
          commentUserMention={commentUserMention}
          handleSetCommentEditor={handleSetCommentEditor}
          isCommentSubmit={isCommentSubmit}
          setIsCommentSubmit={setIsCommentSubmit}
          entityId={parentId}
          communityId={communityId}
          lectureEntityId={lectureEntityId}
          isLecture={isLecture}
          lectureObj={lectureObj}
          handleCommentChange={handleCommentChange}
          handleSetMention={handleSetMention}
        />
      )}
    </React.Fragment>
  );
};

export default CommentSection;
