import { action } from 'easy-peasy';

export default {
  imgUrl: '',
  dialogTitle: '',
  dialogContentText: '',
  btnPrimaryText: '',
  btnPrimaryAction: false,
  btnSecondaryText: '',
  btnSecondaryAction: true,
  isAdminPost: false,
  open: false,
  showCalendar: false,
  showSchedulePostMessage: false,
  isSchedulePost: false,
  contentAlign: 'left',
  setDialogBoxOpen: action((state, payload) => {
    state.open = payload;
  }),
  setDialogContent: action(
    (
      state,
      {
        imgUrl,
        dialogTitle,
        dialogContentText,
        btnPrimaryText,
        btnSecondaryText,
        isAdminPost,
        showCalendar,
        showSchedulePostMessage,
        isSchedulePost,
        contentAlign,
        btnSecondaryAction,
      }
    ) => {
      state.imgUrl = imgUrl;
      state.dialogTitle = dialogTitle;
      state.dialogContentText = dialogContentText;
      state.btnPrimaryText = btnPrimaryText;
      state.btnSecondaryText = btnSecondaryText;
      state.isAdminPost = isAdminPost;
      state.showCalendar = showCalendar;
      state.showSchedulePostMessage = showSchedulePostMessage;
      state.isSchedulePost = isSchedulePost;
      state.contentAlign = contentAlign;
      state.btnSecondaryAction = btnSecondaryAction || true;
    }
  ),
};
