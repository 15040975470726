import http from './httpService';
import apiUrl from '../utils/apiUrl';
import { pollMap } from '../mappers/modelMapper';
import { getPostLanguage } from './authService';
import language from '../utils/languages';

const webLanguage = getPostLanguage();
const languageText = language[webLanguage ? webLanguage : 'en'];

export async function submitPoll(pollId, pollOptionId, setToastMessage) {
  try {
    const { data } = await http.post(apiUrl.SUBMIT_USER_POLL, {
      poll_id: pollId,
      poll_option_id: pollOptionId,
    });

    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return pollMap(data.poll_reaction_model.poll_doc);
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}

async function getRichLinkData(url) {
  const { data } = await http.post(apiUrl.LINK_RENDER, {
    og_link_url: url,
  });
  return data;
}
async function toggleLike(entityId, toLike, setToastMessage) {
  try {
    const api = toLike ? apiUrl.POST_LIKE_URL : apiUrl.POST_UNLIKE_URL;
    const { data } = await http.post(api, {
      entity_id: entityId,
      value: '10',
    });
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}
async function toggleBlockedUser(userId, toBlock, setToastMessage) {
  try {
    const api = toBlock ? apiUrl.BLOCKED_USER_URL : apiUrl.UNBLOCKED_USER_URL;
    const { data } = await http.post(api, {
      userIds: [userId],
      commIds: null,
    });
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}
async function toggleBookmark(entityId, isBookmark, setToastMessage) {
  try {
    const api = isBookmark
      ? apiUrl.POST_BOOKMARK_URL
      : apiUrl.POST_UNBOOKMARK_URL;
    const { data } = await http.post(api, {
      entity_id: entityId,
    });
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}

async function toggleRepost(entityId, toRepost, setToastMessage) {
  try {
    const api = toRepost ? apiUrl.REPOST : apiUrl.UNREPOST;
    const { data } = await http.post(api, {
      entity_id: entityId,
      is_repost: toRepost,
      value: '10',
    });
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}
async function toggleCommentLike(entityId, commentId, toLike, setToastMessage) {
  try {
    const api = toLike ? apiUrl.POST_LIKE_URL : apiUrl.POST_UNLIKE_URL;
    const { data } = await http.post(api, {
      entity_id: entityId,
      parent_participation_id: commentId,
      value: '10',
    });
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}
export default {
  submitPoll,
  getRichLinkData,
  toggleLike,
  toggleBlockedUser,
  toggleBookmark,
  toggleRepost,
  toggleCommentLike,
};
