import http from './httpService';
import APIURL from '../utils/apiUrl';
import {
  communityDetailMap,
  communityItemMap,
  communityCategoryMap,
  themeItemMap,
  mapInterestData,
  mutualCommunityItemMap,
} from '../mappers/communityMapper';
import {
  joinCommunityByFeed,
  joinCommunityByPostDetail,
  joinCommunityByCommunityDetail,
  joinCommunityByCommunityList,
} from '../utils/communityUtil';
import { getCurrentUser, getPostLanguage, getUserToken } from './authService';
import language from '../utils/languages';

const webLanguage = getPostLanguage();
const languageText = language[webLanguage ? webLanguage : 'en'];

async function getCommunityInterest(setToastMessage) {
  try {
    const { data } = await http.get(APIURL.GET_COMMUNITY_INTEREST);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return mapInterestData(data.tags);
    } else {
      return mapInterestData(data.tags);
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}
async function joinCommunityInterest(interestTags, setToastMessage) {
  try {
    const { data } = await http.post(APIURL.JOIN_COMMUNITY_INTEREST, {
      source: 'web',
      tag_ids: interestTags,
      interest_type: 'COMMUNITY_JOIN',
      is_invited_to_join: true,
    });

    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}

async function getCommunityList(userId) {
  const currentUserId = userId || getCurrentUser()?.id;
  const token = getUserToken();
  const requestBody = { page_no: 1, page_size: 60, user_id: currentUserId };
  const data = await fetch(
    process.env.REACT_APP_API_URL + APIURL.MY_COMMUNITIES,
    {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return data?.docs
    ? data?.docs?.map(communityItemMap)
    : communityItemMap(data);
}

async function getCommunityDetail(communityId) {
  const requestBody = {
    id_of_entity_or_participant: communityId,
    sub_type: 'C',
  };
  const { data } = await http.post(APIURL.GET_POST, requestBody);
  return data.docs && data.docs.length > 0 && communityDetailMap(data.docs[0]);
}
async function getCommunityCategory() {
  const { data } = await http.post(APIURL.COMMUNITY_CATEGORY, {});
  return data.docs.map(communityCategoryMap);
}

/**
 * Function for join the community with different ways
 */
async function requestBody(communityId, userId, toJoin, history) {
  if (toJoin) {
    const trackingUrl = history?.location?.state?.community_tracking_url;
    if (trackingUrl === '/feed') {
      return joinCommunityByFeed(communityId, userId);
    } else if (trackingUrl === history?.location?.state?.slug) {
      return joinCommunityByPostDetail(communityId, userId);
    } else if (trackingUrl === '/communities') {
      return joinCommunityByCommunityDetail(communityId, userId);
    } else if (trackingUrl === '/communities/interests') {
      return joinCommunityByCommunityList(communityId, userId);
    } else {
      return {
        apiUrl: APIURL.JOIN_COMMUNITY,
        requestBody: {
          community_id: communityId,
          user_ids: [userId],
        },
      };
    }
  } else {
    return {
      apiUrl: APIURL.UNJOIN_COMMUNITY,
      requestBody: { community_id: communityId, user_id: userId },
    };
  }
}

async function joinCommunity(
  communityId,
  userId,
  toJoin,
  history,
  setToastMessage
) {
  const reqData = await requestBody(communityId, userId, toJoin, history);
  try {
    const { data } = await http.post(reqData.apiUrl, reqData.requestBody);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}

/**
 * Function for set theme object response in local storage
 */
async function getThemesFromServices() {
  const { data } = await http.get(APIURL.GET_ALL_THEME);
  localStorage.setItem('themeList', JSON.stringify(data.theme_object_list));
}
/**
 * Function for get theme object from local storage
 */
function getThemesFromLocalStorage() {
  return JSON.parse(localStorage.getItem('themeList'));
}
/**
 * Function for merge all theme object with selected community theme obj and then return one object
 */
function mergeAllThemesObj(communityId) {
  const selectedThemeObj = getSelectedCommunityThemesObj(communityId);
  const themeObj = getThemesFromLocalStorage();
  const defaultThemeObj = themeObj && themeObj[0].theme_list[0];
  const commonThemes = getCommonThemesObj();
  var allThemeObj = [];
  allThemeObj.push(defaultThemeObj);
  if (selectedThemeObj) {
    allThemeObj = allThemeObj.concat(selectedThemeObj);
  }
  allThemeObj = allThemeObj.concat(commonThemes);
  return allThemeObj;
}
/**
 * Function get common theme and remove default theme form list
 */
function getCommonThemesObj() {
  const themeObj = getThemesFromLocalStorage();
  themeObj[0].theme_list.splice(0, 1);
  return themeObj[0].theme_list;
}
/**
 *  Function for get theme by community id and return theme object
 * @param {*} communityId
 */
function getSelectedCommunityThemesObj(communityId) {
  const themeObj = getThemesFromLocalStorage();
  if (themeObj) {
    for (let i = 0; i < themeObj.length; i++) {
      if (themeObj[i].community_id === communityId) {
        return themeObj[i].theme_list;
      }
    }
  }
}
/**
 * function for get all theme which community id is '-1'
 * when create post open first time
 */
function getAllThemes() {
  const themObjList = getThemesFromLocalStorage();
  const commonThemeObj = themObjList[0].theme_list.map(themeItemMap);
  return commonThemeObj;
}
/**
 * function for get Theme by community id
 * @param {*} communityId
 */
function getThemesByCommunityId(communityId) {
  const getAllThemeObj = mergeAllThemesObj(communityId);
  return getAllThemeObj.map(themeItemMap);
}
function getThemeById(themeId) {
  const themObjList = getThemesFromLocalStorage();
  const theme = themObjList[0]?.theme_list?.filter(
    (item) => item.id === parseInt(themeId)
  );
  const themeObj = theme[0];
  return themeObj && themeItemMap(themeObj);
}

//This Function is used for getting the mutual community data and community joined for others profile.
//The API used in this function is returning two docs one is for mutual community and another one is for getting community joined for others profile.
async function getMutualCommunities(userId) {
  const token = getUserToken();
  const requestBody = {
    user_id: userId,
    page_no: 1,
    page_size: 10,
  };
  const data = await fetch(
    process.env.REACT_APP_API_URL + APIURL.MUTUAL_COMMUNITIES,
    {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  const response = mutualCommunityItemMap(data);
  return response;
}
async function getAllCommunities() {
  const token = getUserToken();
  const data = await fetch(
    process.env.REACT_APP_API_URL + APIURL.ALL_COMMUNITIES,
    {
      method: 'POST',
      body: JSON.stringify({
        sub_type: 'C',
        page_no: 1,
        page_size: 10,
      }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  return data?.docs
    ? data?.docs?.map(communityItemMap)
    : communityItemMap(data);
}

export default {
  getCommunityInterest,
  joinCommunityInterest,
  getCommunityList,
  getCommunityCategory,
  getCommunityDetail,
  joinCommunity,
  getThemesFromServices,
  getThemesByCommunityId,
  getAllThemes,
  getThemeById,
  getMutualCommunities,
  getAllCommunities,
};
