import { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { initCommunity } from '../stores/communityStore';
import { usePostTitle } from './postHooks';
import { getSpentTime, getThirdPartyUser } from '../services/authService';
import MoengageService from '../services/moEngageService';
import communityService from '../services/communityService';
import { useSnackBar } from './snackBarHooks';
import { useLastLocation } from 'react-router-last-location';
import { getPreviousLocation } from '../utils/screenUtils';
import { makeStyles } from '@material-ui/core/styles';
import { getQueryParam } from '../utils/routeUtils';
import { getCurrentUser } from '../services/httpService';

// use to get community detail page data
function useGetCommunityDetails(communityId, setIsTabSwitched) {
  const spentTime = getSpentTime();
  const setActiveTabInfo = useStoreActions(
    (actions) => actions.community.setTabInfo
  );

  const fetchCommunityDetails = useStoreActions(
    (actions) => actions.community.fetchCommunityDetails
  );
  let communityObj = useStoreState(
    (state) => state.community.communities[communityId]
  );
  if (!communityObj) {
    communityObj = initCommunity();
  }

  // Get CommunityDetails
  useEffect(() => {
    !communityObj.communityId &&
      communityId &&
      fetchCommunityDetails(communityId);
  }, [communityId, fetchCommunityDetails, communityObj]);

  const { communityDetails, activeTab } = communityObj;
  usePostTitle(communityDetails);

  function handleTabSwitch(tabInfo) {
    setIsTabSwitched(true);
    let communitySource = '';
    let communityScreenName = '';
    if (activeTab.tabKey === 'about') {
      communitySource = 'Community About';
    } else if (activeTab.tabKey === 'top_post') {
      communitySource = 'Community Top Posts';
    } else if (activeTab.tabKey === 'feed') {
      communitySource = 'Community Feed';
    } else if (activeTab.tabKey === 'leaderboard') {
      communitySource = 'Community Leaderboard';
    } else if (activeTab.tabKey === 'admin') {
      communitySource = 'Community Admin';
    } else if (activeTab.tabKey === 'members') {
      communitySource = 'Community Members';
    } else {
      communitySource = 'Community Others';
    }

    /** Community ScreenName **/
    if (tabInfo.tabKey === 'about') {
      communityScreenName = 'Community About';
    } else if (tabInfo.tabKey === 'top_post') {
      communityScreenName = 'Community Top Posts';
    } else if (tabInfo.tabKey === 'feed') {
      communityScreenName = 'Community Feed';
    } else if (tabInfo.tabKey === 'leaderboard') {
      communityScreenName = 'Community Leaderboard';
    } else if (tabInfo.tabKey === 'admin') {
      communityScreenName = 'Community Admin';
    } else if (tabInfo.tabKey === 'members') {
      communityScreenName = 'Community Members';
    } else {
      communityScreenName = 'Community Others';
    }
    communityObj.communityDetails.name && setActiveTabInfo(tabInfo);
    MoengageService.trackCommunityTabs(
      activeTab.tabTitle,
      tabInfo?.title,
      spentTime,
      communityObj?.communityDetails?.id,
      communitySource,
      communityScreenName
    );
  }
  return {
    communityDetails,
    activeTab,
    handleTabSwitch,
  };
}

// use to get my communities data
function useGetMyCommunities(userId) {
  const [myCommunities, setMyCommunities] = useState(null);
  async function callAPi() {
    const communitiesData = await communityService.getCommunityList(userId);
    !myCommunities &&
      setMyCommunities(
        communitiesData && communitiesData.length > 0 ? communitiesData : []
      );
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      !myCommunities && callAPi();
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, []);
  return myCommunities;
}

function useCommunityListItem({ item }) {
  const [isJoined, setJoined] = useState(item.isJoined);
  const pathname = window && window.location && window.location.pathname;
  const setToastMessage = useSnackBar();
  const lastLocation = useLastLocation();
  const sourceName = getPreviousLocation(lastLocation);
  var joinOnMilliseconds = new Date().getTime();

  async function clickToJoin(communityId, userId, history) {
    pathname.includes('/communities/interests')
      ? history.push(pathname, {
          community_tracking_url: '/communities/interests',
        })
      : history.push(pathname, { community_tracking_url: pathname });

    if (isJoined) {
      MoengageService.trackCommunityLeft(joinOnMilliseconds, communityId);
    } else {
      MoengageService.trackCommunityJoined(
        joinOnMilliseconds,
        communityId,
        sourceName,
        'Joined'
      );
    }
    try {
      setJoined(!isJoined);
      await communityService.joinCommunity(
        communityId,
        userId,
        !isJoined,
        history,
        setToastMessage
      );
    } catch (error) {
      setToastMessage('Please try again after sometime.');
      setJoined(isJoined);
    }
  }
  const useStyles = makeStyles((theme) => ({
    mainListItem: {
      background: 'white',
      listStyle: 'none',
    },
    listItem: {
      padding: theme.spacing(0),
    },
    listItemAction: {
      right: theme.spacing(0),
    },
    joinBtn: {
      width: '68px',
      fontSize: '14px',
      paddingTop: theme.spacing(0.2),
      paddingBottom: theme.spacing(0.2),
      right: theme.spacing(0),
    },
    unJoinBtn: {
      border: '1px solid #000',
      color: theme.palette.text.primary,
    },
    memberText: {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1),
    },
    memberIcon: {
      marginRight: theme.spacing(1),
    },
    commName: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      paddingRight: theme.spacing(10),
      textDecoration: 'none',
    },
    itemDescription: {
      borderBottom: '1px solid #f1f1f1',
      float: 'right',
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(1.5),
    },
  }));
  const classes = useStyles();

  return {
    isJoined,
    clickToJoin,
    classes,
  };
}

function useCommunitiesModal({
  isEditablePost,
  createPostFromCommunityDetail,
  isFromCommunityDetails,
  postOnWomenWill,
  askYourFacilitator,
  selectedCommunity,
  communitiesListObj,
}) {
  const [othersCommunity, setOthersCommunity] = useState(null);
  const branchMatchId = getQueryParam('_branch_match_id');
  const userFromMM = getThirdPartyUser();
  const user = getCurrentUser();
  const [drawerPosition, setDrawerPosition] = useState({
    bottom:
      isEditablePost ||
      createPostFromCommunityDetail ||
      isFromCommunityDetails ||
      (user && user.partnerId === 8) ||
      branchMatchId !== null ||
      userFromMM === 'true'
        ? false
        : true,
  });

  const [openModal, setOpenModal] = useState(false);
  async function fetchAllCommunities() {
    const otherCommunitiesData = await communityService.getAllCommunities();
    setOthersCommunity(otherCommunitiesData);
  }

  useEffect(() => {
    isEditablePost ||
    createPostFromCommunityDetail ||
    isFromCommunityDetails ||
    (user && user.partnerId === 8) ||
    userFromMM === 'true' ||
    branchMatchId !== null ||
    postOnWomenWill ||
    askYourFacilitator
      ? setOpenModal(false)
      : setOpenModal(true);

    const timer = setTimeout(() => {
      !othersCommunity && fetchAllCommunities();
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [
    branchMatchId,
    isEditablePost,
    userFromMM,
    postOnWomenWill,
    askYourFacilitator,
    selectedCommunity,
    user,
  ]);

  const toggleDrawer = (side, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerPosition({ ...drawerPosition, [side]: open });
  };

  const useStyles = makeStyles((theme) => ({
    myCommunityHead: {
      margin: theme.spacing(3, 0, 4, 3),
      padding: theme.spacing(2, 2, 0, 2),
    },
    selectCommunityText: {
      textTransform: 'none',
    },
    divider: {
      marginTop: '15px',
      backgroundColor: '#ececec',
      height: '4px',
    },
    paperDrawer: {
      borderTopLeftRadius: theme.spacing(2.5),
      borderTopRightRadius: theme.spacing(2.5),
    },
    desktopDrawer: {
      width: '40%',
      margin: '0px auto',
      borderTopLeftRadius: theme.spacing(2.5),
      borderTopRightRadius: theme.spacing(2.5),
    },
    fullList: {
      height: '371px',
    },
  }));
  const classes = useStyles();

  return {
    classes,
    toggleDrawer,
    drawerPosition,
    openModal,
    othersCommunity,
  };
}

export {
  useGetCommunityDetails,
  useGetMyCommunities,
  useCommunityListItem,
  useCommunitiesModal,
};
