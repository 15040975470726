import * as Sentry from '@sentry/browser';

function init() {}

function error(error, extras) {
  extras && Sentry.setExtras(extras);
  Sentry.captureException(error);

  //Throw an error if anything fails.
  if (process.env.NODE_ENV !== 'production') {
    throw error;
  }
}

function log(...args) {
  //Disable logs in production
  if (process.env.NODE_ENV !== 'production') console.log(...args);
}

export default {
  init,
  log,
  error,
};
