import React, { useRef, useState } from 'react';
import {
  Typography,
  Box,
  TextField,
  Grid,
  makeStyles,
  Avatar,
} from '@material-ui/core';
import { useStoreState, useStoreActions } from 'easy-peasy';
import UserAvatar from '../common/userAvatar';
import { ReactComponent as SendIcon } from '../../img/send.svg';
import { ReactComponent as DisabledSendIcon } from '../../img/disabledSend.svg';
import CustomButton from '../common/customButton';
import { imageUrls } from '../../utils/constants';
import imgParams from '../../utils/imageUtil';
import { useTablet } from '../../hooks/utilHooks';
import mentionStyles from '../common/mentionStyles';
import { getPostLanguage } from '../../services/authService';
import languages from '../../utils/languages';
import { MentionsInput, Mention } from 'react-mentions';
import MentionInputStyles from '../../utils/mentionInputStyles';
import useOutsideClick from '../../hooks/useOutsideClick';

const CreateComment = ({
  isPostDetail,
  focusComment,
  handleCommentClick,
  handleSaveComment,
  isArticleDetail,
  commentDescription,
  commentUserMention,
  commentId,
  lectureEntityId,
  isLecture,
  isReplies,
  isMentorReply,
  commentEntityId,
  handleMentorSaveComment,
  handleCommentChange,
  handleSetMention,
}) => {
  mentionStyles();
  const [userAnonymity, setUserAnonymity] = useState(false);
  const webLanguage = getPostLanguage();
  const languageText = languages[webLanguage ? webLanguage : 'en'];
  const classes = useStyles();
  const user = useStoreState((state) => state.user.user);
  const isDetailPage = isPostDetail || isArticleDetail;
  const isTablet = useTablet();
  const mentionUsers = user?.mentionUsers;
  const setEditCommentObj = useStoreActions(
    (state) => state.posts.setEditCommentObj
  );
  const commentBoxRef = useRef();
  function handleUserAnonymity(bool) {
    setUserAnonymity(bool);
  }
  useOutsideClick(commentBoxRef, () =>
    setEditCommentObj({
      isCommentEditable: false,
    })
  );

  return (
    <React.Fragment>
      <Box
        ref={commentBoxRef}
        onClick={handleCommentClick}
        width="1200px"
        maxWidth={1}
        bgcolor={isDetailPage && 'background.secondary'}
        px={4}
        py={2}
        left="auto!important"
        className={
          (isPostDetail || (isArticleDetail && focusComment)) && 'stickToBottom'
        }
      >
        <Grid>
          <Grid
            item
            sm={isDetailPage ? 6 : 12}
            md={isDetailPage ? 6 : 12}
            lg={isDetailPage ? 6 : 12}
          >
            <Box
              alignItems="center"
              display="flex"
              pr={!isTablet && isDetailPage ? 12.5 : ''}
            >
              <Box pr={2}>
                <UserAvatar
                  userImgUrl={imgParams(
                    user
                      ? !userAnonymity
                        ? user.image
                        : imageUrls.ANONYMOUS_IMG
                      : imageUrls.DEFAULT_USER_IMG,
                    40,
                    40,
                    'auto'
                  )}
                />
              </Box>

              {isDetailPage && user ? (
                <MentionsInput
                  style={MentionInputStyles}
                  className="mentionsInput"
                  placeholder={languageText?.ADD_YOUR_COMMENT}
                  value={commentDescription}
                  onChange={handleCommentChange}
                  forceSuggestionsAboveCursor={true}
                >
                  <Mention
                    trigger="@"
                    appendSpaceOnAdd={true}
                    data={mentionUsers}
                    onAdd={(e) => handleSetMention(e, mentionUsers)}
                    renderSuggestion={(suggestion) => (
                      <Box display="flex" alignItems="center">
                        <Avatar
                          src={suggestion?.avatar}
                          className={classes.mentionAvatar}
                        ></Avatar>
                        <div>{suggestion?.name}</div>
                      </Box>
                    )}
                  />
                </MentionsInput>
              ) : (
                <TextField
                  id="standard-textarea"
                  placeholder={languageText?.ADD_YOUR_COMMENT}
                  value={commentDescription}
                  multiline
                  //TODO optimise this input box later. https://reactjs.org/docs/uncontrolled-components.html
                  autoFocus={focusComment}
                  rowsMax={5}
                  variant="outlined"
                  className={`${classes.commentBox} ${
                    isPostDetail ? classes.resetBorder : classes.staticBorder
                  }`}
                  fullWidth
                  InputProps={{
                    className: classes.textareaSet,
                  }}
                />
              )}

              {isDetailPage && (
                <Box pl={3}>
                  <CustomButton
                    onClick={() =>
                      isMentorReply
                        ? handleMentorSaveComment(
                            userAnonymity,
                            commentDescription,
                            commentUserMention,
                            commentId,
                            lectureEntityId,
                            isLecture,
                            isReplies,
                            isMentorReply,
                            commentEntityId
                          )
                        : handleSaveComment(
                            userAnonymity,
                            commentDescription,
                            commentUserMention,
                            commentId,
                            lectureEntityId,
                            isLecture,
                            isReplies,
                            isMentorReply,
                            commentEntityId
                          )
                    }
                    variant="iconButton"
                    disabled={commentDescription === '' ? true : false}
                    icon={
                      commentDescription !== '' ? (
                        <SendIcon />
                      ) : (
                        <DisabledSendIcon />
                      )
                    }
                  />
                </Box>
              )}
            </Box>
            {isPostDetail && (
              <Box mt={2}>
                {!isLecture && (
                  <Grid spacing={4} container>
                    <Grid item>
                      <Typography variant="body1" component="span">
                        {languageText?.ID_POST}
                      </Typography>
                    </Grid>
                    <Grid onClick={() => handleUserAnonymity(false)} item>
                      <Typography
                        variant="body2"
                        color={userAnonymity ? 'secondary' : 'primary'}
                        component="span"
                      >
                        {user && user.firstName}
                      </Typography>
                    </Grid>
                    <Grid onClick={() => handleUserAnonymity(true)} item>
                      <Typography
                        variant="body2"
                        color={userAnonymity ? 'primary' : 'secondary'}
                        component="span"
                      >
                        {languageText?.ANONYMOUS}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  textareaSet: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 400,
    backgroundColor: 'white',
    padding: theme.spacing(2, 3),
    borderRadius: '4px',
  },
  commentBox: {
    caretColor: theme.palette.primary.main,
  },
  resetBorder: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 0,
      },
    },
  },
  staticBorder: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey['100'],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey['100'],
      },
      '&.Mui-focused fieldset': {
        border: 0,
      },
    },
  },
  mentionAvatar: {
    marginRight: '12px',
    height: theme.spacing(8),
    width: theme.spacing(8),
  },
}));

export default CreateComment;
