import http from './httpService';
import { postMap, bookmarkMap } from '../mappers/modelMapper';
import { chapterMap } from '../mappers/lectureMapper';

async function getFeedStream(
  feedStreamApi,
  nextToken,
  isGetRequest,
  isCourse,
  isBookmark
) {
  let feedData = {};
  const { apiRequest } = getRequestData(feedStreamApi, nextToken, isGetRequest);

  const { data } = await apiRequest;
  if (isCourse) {
    feedData.docs = data && data.chapters && data.chapters.map(chapterMap);
  } else if (isBookmark) {
    feedData.docs = data && data.docs && data.docs.map(bookmarkMap);
  } else {
    feedData.docs = data?.docs?.map(postMap);
  }

  feedData.nextToken = data.next_token;
  feedData.setOrderKey = data.set_order_key;
  feedData.feedConfigVersion = data.server_feed_config_version;
  feedData.status = data.status;
  return feedData;
}

export default {
  getFeedStream,
};

function getRequestData(feedStreamApi, nextToken, isGetRequest) {
  let requestBody, streamApi, apiRequest;

  if (isGetRequest) {
    const nextTokenKey = 'next_token';
    streamApi =
      feedStreamApi + (nextToken ? '&' + nextTokenKey + '=' + nextToken : '');
    apiRequest = http.get(streamApi, requestBody);
  } else {
    requestBody = { next_token: nextToken };
    streamApi = feedStreamApi;
    apiRequest = http.post(streamApi, requestBody);
  }
  return { streamApi, requestBody, apiRequest };
}
