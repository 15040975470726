import React, { useRef } from 'react';
import { Drawer, Box, Typography, makeStyles } from '@material-ui/core';
import LikedIcon from '../../img/heart-liked.svg';
import AdapterLink from '../common/adapterLink';
import Loader from '../../components/common/loader';
import imgParams from '../../utils/imageUtil';
import { getPostLanguage } from '../../services/authService';
import languages from '../../utils/languages';
import { useLikeList } from '../../hooks/createPostHook';

const LikeList = ({ post }) => {
  const webLanguage = getPostLanguage();
  const languageText = languages[webLanguage ? webLanguage : 'en'];
  const drawerRef = useRef();
  const openDrawerPosition = 'bottom';
  const classes = useStyles();
  const {
    toggleDrawer,
    isLoading,
    checkIfReachedBottom,
    likesData,
    drawerPosition,
  } = useLikeList({ post, drawerRef });
  return (
    <React.Fragment>
      <Typography
        variant="caption"
        color="textSecondary"
        component="span"
        onClick={toggleDrawer('bottom', true)}
      >
        {post.likesCount}{' '}
        {post.likesCount > 1 ? languageText?.LIKES : languageText?.LIKE}
      </Typography>
      <Drawer
        anchor={openDrawerPosition}
        open={drawerPosition.bottom}
        onClose={toggleDrawer('bottom', false)}
      >
        <Box px={4}>
          <Box className={classes.listHeader}>
            <img
              src={LikedIcon}
              width="20px"
              height="20px"
              alt="Liked"
              className={classes.likedIcon}
            />
            <Typography component="span" color="textSecondary">
              {post.likesCount}{' '}
              {post.likesCount > 1 ? languageText?.LIKES : languageText?.LIKE}
            </Typography>
          </Box>

          <Box
            className={classes.itemSection}
            ref={drawerRef}
            onScroll={checkIfReachedBottom}
          >
            {likesData &&
              likesData.length > 0 &&
              likesData.map((like, index) => (
                <Box
                  to={like.authorDeepLinkUrl || ''}
                  component={AdapterLink}
                  key={index}
                  className={classes.likeItem}
                >
                  <Box className={classes.imgContainer}>
                    <img
                      src={imgParams(like.authorImageUrl, 40, 40, 'auto')}
                      alt={like.authorName}
                      className={classes.itemImg}
                    />
                    {like.profileBadgeUrl && (
                      <img
                        src={like.profileBadgeUrl}
                        alt="Badge"
                        width="20"
                        height="20"
                        className={classes.badgeStyle}
                      />
                    )}
                  </Box>
                  <Typography variant="body2" color="textPrimary">
                    {like.authorName}
                  </Typography>
                </Box>
              ))}
            {isLoading && <Loader />}
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};
const useStyles = makeStyles((theme) => ({
  itemSection: {
    height: '300px',
    overflowY: 'auto',
  },
  listHeader: {
    borderBottom: '1px solid #f1f1f1',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3, 0),
    marginBottom: theme.spacing(2),
  },
  likedIcon: {
    marginRight: theme.spacing(1),
  },
  likeItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    textDecoration: 'none',
  },
  itemImg: {
    marginRight: theme.spacing(3),
    borderRadius: theme.spacing(3),
  },
  imgContainer: {
    float: 'left',
    position: 'relative',
  },
  badgeStyle: {
    position: 'absolute',
    bottom: theme.spacing(0),
    right: theme.spacing(5),
  },
}));
export default LikeList;
