import React from 'react';
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useStoreActions, useStoreState } from 'easy-peasy';
import useReactRouter from 'use-react-router';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomErrorHandler = () => {
  const classes = useStyles();
  const { history } = useReactRouter();
  const {
    open,
    errorMessage,
    btnPrimaryText,
    btnSecondaryText,
    dialogIcon,
    dialogTitle,
    urlToRedirect,
    btnPrimaryAction,
    btnSecondaryAction,
  } = useStoreState((state) => state.errorHandler);
  const { setErrorDialogBoxOpen } = useStoreActions(
    (actions) => actions.errorHandler
  );

  const handleDialogBox = (action) => {
    if (action) {
      setErrorDialogBoxOpen(false);
      urlToRedirect && history.push(urlToRedirect);
    } else {
      setErrorDialogBoxOpen(false);
    }
  };

  return (
    <React.Fragment>
      {errorMessage && (
        <Dialog
          TransitionComponent={Transition}
          open={open}
          classes={{ paper: classes.paperDialog }}
          onClose={() => setErrorDialogBoxOpen(false)}
        >
          <DialogTitle>
            <Box className={classes.dialogIconSet}>
              {dialogIcon}
              <Typography display="initial" className={classes.dialogTitleSet}>
                {dialogTitle}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogContentSet}>
            <DialogContentText color="textPrimary">
              {errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActionsSet}>
            <Button
              onClick={() => handleDialogBox(btnPrimaryAction)}
              color="primary"
              className={classes.dialogButton}
            >
              {btnPrimaryText}
            </Button>
            <Button
              onClick={() => handleDialogBox(btnSecondaryAction)}
              color="primary"
              className={classes.dialogButton}
            >
              {btnSecondaryText}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};
const useStyles = makeStyles((theme) => ({
  paperDialog: {
    margin: theme.spacing(4),
    padding: theme.spacing(4),
  },
  dialogTitleSet: {
    padding: theme.spacing(0),
    fontWeight: 'bold',
    display: 'inherit',
    marginLeft: theme.spacing(2.5),
    fontSize: '16px',
  },
  dialogContentSet: {
    padding: theme.spacing(0),
  },
  dialogActionsSet: {
    padding: theme.spacing(0),
  },
  dialogButton: {
    padding: theme.spacing(0),
  },
  dialogIconSet: {
    verticalAlign: 'text-top',
    display: 'inline-flex',
  },
}));
export default CustomErrorHandler;
