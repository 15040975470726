import React from 'react';
import Inspiration from '../img/home/inspiration.svg';
import Bazaar from '../img/home/bazaar.svg';
import SheCo from '../img/home/sheco.svg';
import Loans from '../img/home/loans.svg';
import Helpline from '../img/home/helpline.svg';
import facebookIcon from '../img/home/facebook.svg';
import instagramIcon from '../img/home/instagram.jpg';
import linkedInIcon from '../img/home/linkedin.svg';
import twitterIcon from '../img/home/twitter.svg';
import FooterPage from '../components/home/footerPage';
import { SHEROES_ADMIN_URL } from '../utils/constants';
import postService from '../services/postService';
import { getCurrentUser } from '../services/authService';

const currentUser = getCurrentUser();
const sheroesRedLogo =
  'https://img.sheroes.in/img/uploads/sheroespage/logoes/1654152493sheroes-logo-red.png';
const playStore =
  'https://img.sheroes.in/img/uploads/sheroespage/logoes/1678952466playstore-icon.jpg';
const appStore =
  'https://img.sheroes.in/img/uploads/sheroespage/logoes/1678952725appstore.svg';

const signUpType = [
  {
    text: 'Become an inspiration for millions of women',
    buttonText: 'Join & Connect',
    imgUrl: Inspiration,
    redirectUrl: '/communities/aspiring-writers/MzEx',
  },
  {
    text: 'Buy and Sell Products & Services',
    buttonText: 'Explore More',
    imgUrl: Bazaar,
    redirectUrl: '/communities/bazaar/MzA4',
  },
  {
    text: 'Become a SHECO Entrepreneur',
    buttonText: 'Apply Now',
    imgUrl: SheCo,
    redirectUrl: 'https://sheroes.com/go/public/go/sheco/verifiedseller',
  },
  {
    text: 'Get Quick Short Term Loans',
    buttonText: 'Join Now',
    imgUrl: Loans,
    redirectUrl: 'https://sheroes.com/go/public/go/landing/loan_form',
  },
  {
    text: 'Get Answers For Your Questions',
    buttonText: 'Join Now',
    imgUrl: Helpline,
    redirectUrl: 'https://sheroes.com/helpline',
  },
];

const testimonialObj = [
  {
    description:
      'Sheroes is the best app for any women. When you fell down or low just click to Sheroes, your mood gonna change instantly. You can get sisterhood feeling here. Safe app in comparison to other apps. Learn lot of things and help people learn as well. I would recommend it to every women.',
    authorUrl:
      'https://img.sheroes.in/img/user_4191936_20191109140735.jpeg?tr=w-40',
    authorName: 'Durba Dey',
  },
  {
    description:
      'The best women app, excellent for sharing content, feelings, milestones and experiences. Quite good for providing acknowledgement to many topics and many more things. Helped me to share my life, my triumphs, it connects me to many wonder woman! This time missed the conference but will definitely attend for my betterment towards my writing career. All the best! Literally of love.',
    authorUrl:
      'https://img.sheroes.in/img/user_3232272_20181009114211.jpeg?tr=w-40',
    authorName: 'Triveni Sidha',
  },
  {
    description:
      'Very good application for ladies and girls. This application are very helpful to me. All ladies and girl if u not share your problem with any person so this app very create help to you about all problem thanks so much Sheroes Team….Good Job',
    authorUrl:
      'https://img.sheroes.in/img/user_7561688_20190913065133.jpeg?tr=w-40',
    authorName: 'Kinjal Soni',
  },
];

const communitiesInterestsObj = [
  {
    imgUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1678877247love.svg',
    text: 'Love, Sex and Relationships',
    url: '/communities/love-sex-and-relationships/Mjgx',
  },
  {
    imgUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1678877326health.svg',
    text: 'Health',
    url: '/communities/health/MzQz',
  },
  {
    imgUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1678950688career.svg',
    text: 'Career and Education',
    url: '/communities/career-and-exams/Mjc5',
  },
  {
    imgUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1678950983cook.svg',
    text: 'Cooking & Recipes',
    url: '/communities/cooking-food-recipes-and-more/NDky',
  },
  {
    imgUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1678951100aspiring-writers.svg',
    text: 'Aspiring Writers',
    url: '/communities/aspiring-writers/MzEx',
  },
  {
    imgUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1678951530baby-care.svg',
    text: 'Parenting and BabyCare',
    url: '/communities/babygogo/NDk2',
  },
  {
    imgUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1678951721english.svg',
    text: ' English Speaking',
    url: '/communities/english-winglish/Mjk4OQ==',
  },
  {
    imgUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1678951805fashion.svg',
    text: 'Fashion and Beauty',
    url: '/communities/fashion-and-lifestyle/Mjgw',
  },
];

const socialLinkObj = [
  {
    url: 'https://www.facebook.com/sheroesindia',
    imgUrl: facebookIcon,
    altText: 'Facebook',
  },
  {
    url: 'https://twitter.com/sheroes',
    imgUrl: twitterIcon,
    altText: 'Twitter',
  },
  {
    url: 'https://www.linkedin.com/company/sheroesindia',
    imgUrl: linkedInIcon,
    altText: 'LinkedIn',
  },
  {
    url: 'https://www.instagram.com/sheroesindia',
    imgUrl: instagramIcon,
    altText: 'Instagram',
  },
];
const socialLinkObjSheroesMagazine = [
  {
    url: 'http://twitter.com/home?status=SHEROES Magazine 2nd edition via @SHEROESIndia +https%3A%2F%2Fsheroes.com%2Fsheroes-magazine%2F10&utm_source=facebook&utm_medium=shared-link&utm_campaign=sheroes-magazine',
    imgUrl: 'https://sheroes.com/img/twitter-icon-maz.svg',
    altText: 'Twitter',
  },
  {
    url: 'https://www.facebook.com/share.php?u=https%3A%2F%2Fsheroes.com%2Fsheroes-magazine%2F10&utm_source=facebook&utm_medium=shared-link&utm_campaign=sheroes-magazine',
    imgUrl: 'https://sheroes.com/img/facebook-icon-maz.svg',
    altText: 'Facebook',
  },
  {
    url: 'https://api.whatsapp.com/send?text=SHEROES Magazine 2nd edition https%3A%2F%2Fsheroes.com%2Fsheroes-magazine%2F10&utm_source=facebook&utm_medium=shared-link&utm_campaign=sheroes-magazine',
    imgUrl: 'https://sheroes.com/img/whatup-icon-maz.svg',
    altText: 'Whatsapp',
  },
];

const loggedInFooterObj = currentUser
  ? [
      {
        url: '/communities',
        text: 'Communities',
      },
      {
        url: '/search?q=TakeChargeStories&type=posts',
        text: '#TakeChargeStories',
      },
      {
        url: '/search?q=indian%20recipes&type=posts',
        text: 'Indian recipes',
      },
    ]
  : [];

const footerLinkObj = [
  {
    url: 'https://play.google.com/store/apps/details?id=appliedlife.pvtltd.SHEROES&hl=en',
    text: 'Sheroes App',
    noIframe: true,
  },
  {
    url: '/community-guidelines',
    text: 'Community Guidelines',
  },
  {
    url: '/helpline',
    text: 'Helpline',
  },
  {
    url: '/communities/glow-and-lovely-careers/NTA0NA==',
    text: 'Glow and Lovely',
  },
  {
    url: '/communities/career-and-exams/Mjc5',
    text: 'Career and Exams',
  },
  {
    url: '/articles/category/money-matters/MTc2',
    text: 'Money matters',
  },
  {
    url: '/communities/shriram-life-insurance/NDg5Nw==',
    text: 'SLIC',
  },
  {
    url: '/topics/recipes',
    text: 'Recipes',
  },

  ...loggedInFooterObj,
  {
    url: '/articles/category/motivation-inspiration/MTc4',
    text: 'Motivation and Inspiration',
  },
  {
    url: '/articles/category/women-in-tech/MTcy',
    text: 'Women In Tech',
  },
  {
    url: '/articles/category/health-fitness/MTI5',
    text: 'Health and Fitness',
  },
  {
    url: '/articles/category/pregnancy-parenthood/MzY=',
    text: 'Pregnancy and Parenthood',
  },
  {
    url: '/articles/category/health-fitness/MTI5',
    text: 'Health and Fitness',
  },
  {
    url: '/about',
    text: 'About Us',
  },
  {
    url: '/articles',
    text: 'Articles',
    noIframe: true,
  },
  {
    url: '/team',
    text: 'Team',
  },
  {
    url: '/advisory-council',
    text: 'Advisory Council',
  },
  {
    url: 'https://sheroes.tech',
    text: 'Blog',
    noIframe: true,
  },
  {
    url: '/Home/tnc',
    text: 'Terms & Conditions',
  },
  {
    url: '/Home/advertorial-policy',
    internalPhpUrl: '/Home/editorial-policy',
    text: 'Advertorial Policy',
  },
  {
    url: '/investors',
    text: 'Investors',
  },
  {
    url: '/Home/policy',
    text: 'Privacy Policy',
  },

  {
    url: '/contact',
    text: 'Contact Us',
  },
  {
    url: '/reports',
    text: 'AGM',
  },
];

const footerRoutes = footerLinkObj
  .filter((obj) => !obj.noIframe)
  .map((obj) => {
    return {
      path: obj.url,
      render: (props) => (
        <FooterPage
          {...props}
          title={obj.text}
          url={
            SHEROES_ADMIN_URL +
            `${obj.internalPhpUrl || obj.url}/?header=0&footer=0`
          }
        />
      ),
    };
  });
async function getPostId(postUrl) {
  var url = new URL(postUrl);
  const actualUrl = url.pathname;
  const postId =
    actualUrl && actualUrl.substring(actualUrl.lastIndexOf('/') + 1);
  const postRes = await postService.getHomePagePost(atob(postId), 'SHOP');
  return postRes ? postRes : '';
}
const homeSliderData = [
  {
    id: 0,
    slideUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1674030583redbg.svg',
    title: 'SHEROES',
    subTitle:
      'Women-only Social Network with communities, counselling, helpline,opportunities, resources, conversations,marketplace. Built on the values of trust, empathy, safety',
    bubble1:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1674115951lady-in-grey.png',
    bubble2:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1674116000lady-with-laptop.png',
    bubble3:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1674116044chat-icons.png',
  },
  {
    id: 1,
    slideUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1674030669blue.svg',
    title: 'WomenWill Programme',
    subTitle:
      "One woman's courage can inspire many. And our Internet Saathi program has created millions of stories. Listen to these courageous women across rural India and be part of their journey",
    bubble1:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1674219145certificate.png',
    bubble2:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1674219223play-video.png',
    bubble3:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1674219276badge.png',
  },
];

const mobileEmailCarousel = [
  {
    index: 0,
    title: 'Internet Based Communities',
    subTitle: 'Network & express yourself freely',
    imgUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1675229697emailcarousel-1.svg',
  },
  {
    index: 2,
    title: 'AskSHEROES Helpline',
    subTitle: 'Chat with counsellors & get help',
    imgUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1675229854emailcarousel-4.svg',
  },
  {
    index: 3,
    title: 'WomenWill',
    subTitle: 'Free business training program with certificate',
    imgUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1676892173shutterstock1865089795-photoroom-1.svg',
  },
];
export {
  signUpType,
  testimonialObj,
  communitiesInterestsObj,
  socialLinkObj,
  footerLinkObj,
  footerRoutes,
  getPostId,
  mobileEmailCarousel,
  homeSliderData,
  socialLinkObjSheroesMagazine,
  sheroesRedLogo,
  playStore,
  appStore,
};
