import { getUuid } from '../utils/userUtil';
import uuid from 'uuid';
import { getPostLanguage } from '../services/authService';
import languages from './languages';

const webLanguage = getPostLanguage();
const languageText = languages[webLanguage ? webLanguage : 'en'];
const urlMap = {
  HOME: '/',
  SIGNUP: '/signup',
  FEED: '/feed',
  LOGOUT: '/logout',
  ONBOARDING_GENDER: '/onboarding/gender',
  MALE_RESTRICTED: '/onboarding/male-restricted',
  ONBOARDING_INTEREST: '/onboarding/interest',
  ONBOARDING_BIRTHDAY: '/onboarding/birthday',
  ONBOARDING_GENDER_VERIFICATION: '/onboarding/gender-verification',
  ONBOARDING_SELFIE: '/onboarding/selfie',
  ONBOARDING_INTO_YOURSELF: '/onboarding/introduce-yourself',
  ONBOARDING_WOMEN_WILL_STARTED: '/onboarding/womenwill-started',
  ONBOARDING_LANGUAGE: '/onboarding/language',
  ONBOARDING_GLOBAL_LANGUAGE: '/onboarding/global-language',
  IMAGE_NOT_VERIFIED: '/onboarding/image-not-verified',
  ONBOARDING_WELCOME: '/onboarding/welcome',
  ONBOARDING_GWW_WELCOME: '/onboarding/gww-welcome',
  CERTIFICATE: '/course/certificate',
  LEARN_TAB: '/feed?tab=learn',
  COURSE_TAB: '/feed?tab=course',
  SOCIAL_TAB: '/feed?tab=social',
  SHOP_TAB: '/feed?tab=shop',
  FEED_TAB: '/feed?tab=feed',
};
const deviceGuid = getUuid() ? getUuid() : uuid();

const imageUrls = {
  DEFAULT_USER_IMG: 'https://img.sheroes.in/img/default/user.png',
  ANONYMOUS_IMG:
    'https://img.sheroes.in/img/uploads/sheroespage/logoes/1549951886group-14.svg',
  DEFAULT_COMMUNITY_LOGO:
    'https://img.sheroes.in/img/uploads/sheroespage/logoes/1553753187close-community.svg',
  IMAGE_UPLOAD_ACCEPT_TYPE: 'image/jpeg, image/png, image/gif',
};

const strings = {
  ANONYMOUS: 'Anonymous',
  USER: 'USER',
  COMMUNITY: 'Community',
  ADMIN: 'Admin',
};

const pageSize = 10;
const maxPageSize = 25;
const onboardingSteps = [
  urlMap.ONBOARDING_GLOBAL_LANGUAGE,
  urlMap.ONBOARDING_GENDER,
  urlMap.ONBOARDING_GENDER_VERIFICATION,
  urlMap.FEED,
];
const bltOnboardingSteps = [
  urlMap.ONBOARDING_GLOBAL_LANGUAGE,
  urlMap.ONBOARDING_INTO_YOURSELF,
  urlMap.FEED,
];
const userDeactivatedUrl = {
  USER_DEACTIVATED: '/user-deactivated',
};
const cookieOptions = {
  AUTH_TOKEN: 'sheRemMeCodeNew',
  USER_ID: 'userId',
  COOKIE_EXPIRE_DAYS: 365,
  LOGIN_EVENT_FIRED: 'loginEventFired',
};
const impressionConstants = {
  MAX_RETRY_COUNT: 4,
  INITIAL_NETWORK_RETRY_DELAY: 5000,
  MIN_ENGAGEMENT_TIME: 250,
};
const allScreen = {
  CREATE_POST: '/create-post',
  EDIT_POST: '/edit-post',
};
const firebaseConfig = {
  messagingSenderId: '409228886131',
};
const hiddenCommunityTabs = ['leaderboead', 'leaderboard'];
const themePostConfig = {
  maxCharLimit: 130,
  maxLineBreakLimit: 3,
  themeTextFontSize: 22,
};
const relationShipStatus = [
  {
    value: 'Select Status',
    label: languageText?.SELECT_STATUS,
  },
  {
    value: 'Single',
    label: 'Single',
  },
  {
    value: 'In a Relationship',
    label: 'In a Relationship',
  },
  {
    value: 'Married',
    label: 'Married',
  },
  {
    value: 'Divorced',
    label: 'Divorced',
  },
  {
    value: 'Widowed',
    label: 'Widowed',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];
const vernacularLanguageList = [
  {
    key: 'en',
    value: 'English',
    name: '',
    icon: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/1676890148englishicon.svg',
    selectedIcon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1676891209englishiconselected.svg',
  },
  {
    key: 'hi',
    value: 'Hindi',
    name: '(हिंदी)',
    icon: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/1676890786hindiicon.svg',
    selectedIcon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1676891244hindiiconselected.svg',
  },
  {
    key: 'te',
    value: 'Telugu',
    name: '(తెలుగు)',
    icon: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/1676890262teluguicon.svg',
    selectedIcon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1676891276teluguiconselected.svg',
  },
  {
    key: 'gu',
    value: 'Gujarati',
    name: '(ગુજરાતી)',
    icon: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/1676890876gujaratiicon1.svg',
    selectedIcon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1676891139gujaratiicon1selected.svg',
  },
  {
    key: 'mr',
    value: 'Marathi',
    name: '(मराठी)',
    icon: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/1676891030marathiicon.svg',
    selectedIcon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1676891096marathiiconselected.svg',
  },
  {
    key: 'as',
    value: 'Assamese',
    name: '(অসমীয়া)',
    icon: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/1676890642assameseicon.svg',
    selectedIcon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1676891172assameseiconselected.svg',
  },

  // {
  //   key: 'bn',
  //   value: 'Bangla',
  //   name: '(বাংলা)',
  //   icon:
  //     'https://img.sheroes.in/img/uploads/sheroespage/logoes/1644409179bangla-icon2.svg',
  // },
];

const userType = {
  isAdmin: 2,
  isCommunityMod: 13,
};

const defaultArticleCategory = [
  {
    title: 'Latest',
    key: '/articles',
    tabIndex: 0,
    categoryUrl: '/articles',
    dataUrl: '',
  },
  {
    title: 'Trending',
    key: '/articles/trending',
    tabIndex: 1,
    categoryUrl: '/articles/trending',
    dataUrl: '',
  },
];

const SHEROES_ADMIN_URL = 'https://web.sheroes.com';
const ISD_CODE = '+91';

const reOnboardingUrl = [
  {
    key: 'phoneEmail',
    value: 'sheroes/image/identity/verification/otp',
    redirectUrl: '/mobile-email-form',
  },
  {
    key: 'both',
    value: 'sheroes/image/identity/verification/selfie-and-otp',
    redirectUrl: '/mobile-email-form',
  },
  {
    key: 'selfie',
    value: 'sheroes/image/identity/verification/selfie',
    redirectUrl: urlMap.ONBOARDING_GENDER_VERIFICATION,
  },
];
const shopRedirectUrl = {
  CREATE_SHOP: '/shop-on-sheroes/create-shop',
  SHOP_DASHBOARD: '/shop-on-sheroes/shop-dashboard',
  BANK_DETAILS: '/shop-on-sheroes/bank-details',
  EDIT_PRODUCT: '/shop-on-sheroes/edit-product',
  ADD_PRODUCT: '/shop-on-sheroes/add-product',
  ADD_SERVICE: '/shop-on-sheroes/add-service',
  EDIT_SHOP: '/shop-on-sheroes/edit-shop',
};
const shopDataObj = {
  clapIcon:
    'https://img.sheroes.in/img/uploads/sheroespage/logoes/1630391552new-project-1.png',
  successMSG:
    'Your shop has been successfully created. It’s time to add your first product or service now.',
};
const rupeesIcon =
  'https://img.sheroes.in/img/uploads/sheroespage/logoes/1627564470rupeeicon24x24-01.svg';
const maxProductImage = 8;
const maxProductVideo = 1;
const noOfPostOnCard = 5;
const milestoneShareMsg =
  'I just unlocked a Badge of Honour! You can also win these exclusive badges on SHEROES:';
const galChampionEmailId = [
  'falcareerfoundation@google.com',
  'pinki.verma1501@gmail.com',
];
const deviceData = {
  advertisementid: 'string',
  device_type: 'htc HTC Desire 626 dual sim',
  source: 'web',
  device_guid: deviceGuid,
  device_id: 'web-pwa',
  device_name: 'web',
  locale: 'eng/en',
  time_zone: 'usa',
  app_version: '8.2.3',
};
const internetBasedLocationId = 306;
const sheroesMagazineItems = [
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/1538976388000_cover.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897657700_contents.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/15389766590_editors.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897671901.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897688002.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897695703.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897700204.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897705305.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897710306.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897715607.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897721208.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897728609.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897758710.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897764411.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897770012.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897779813.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897787114.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897802615.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897809716.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897821317.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897827718.jpg?tr=w-993',
    name: 'magazine',
  },
  {
    img: 'https://img.sheroes.in/img/uploads/sheroespage/logoes/153897833219.jpg?tr=w-993',
    name: 'magazine',
  },
];
const validMarsAudioFormats = ['mp3', 'mp4', 'wav', 'flac', 'ogg', 'aac'];
const validMarsImageFormats = ['jpeg', 'png', 'jpg'];
const validVideoFormats = ['mp3', 'mp4', 'webM', 'mov', 'ogv'];

export {
  urlMap,
  onboardingSteps,
  imageUrls,
  strings,
  pageSize,
  maxPageSize,
  userDeactivatedUrl,
  cookieOptions,
  allScreen,
  firebaseConfig,
  hiddenCommunityTabs,
  themePostConfig,
  relationShipStatus,
  vernacularLanguageList,
  impressionConstants,
  userType,
  defaultArticleCategory,
  SHEROES_ADMIN_URL,
  ISD_CODE,
  reOnboardingUrl,
  shopRedirectUrl,
  shopDataObj,
  rupeesIcon,
  maxProductImage,
  maxProductVideo,
  noOfPostOnCard,
  milestoneShareMsg,
  galChampionEmailId,
  deviceData,
  internetBasedLocationId,
  bltOnboardingSteps,
  sheroesMagazineItems,
  validMarsAudioFormats,
  validMarsImageFormats,
  validVideoFormats,
};
