import React from 'react';
import { Box, Typography } from '@material-ui/core';
import HomeBanner from '../../img/img_login_background.webp';
import { useTablet } from '../../hooks/utilHooks';
import { useHistory } from 'react-router-dom';
import SocialSignUp from '../home/socialSignUp';
import auth from '../../services/authService';
import { urlMap } from '../../utils/constants';
import { getHomeRedirectUrl } from '../../utils/userUtil';
import {
  getCompletedStepIndex,
  setOnboardingBlurEffect,
} from '../../services/onboardingService';
import { useLastLocation } from 'react-router-last-location';

const HomeScreen = () => {
  const isTablet = useTablet();
  const history = useHistory();
  const user = auth.getCurrentUser();
  const currentStepIndex = getCompletedStepIndex();
  const lastLocation = useLastLocation();

  if (user) {
    if (window.performance.navigation.type === 0) {
      // set blur effect false on tab closed after onboarding start
      setOnboardingBlurEffect(false);
    }
    if (user.isOnboardingDone) {
      history.push(urlMap.FEED);
    } else {
      history.push(getHomeRedirectUrl(currentStepIndex, user));
    }
    return null;
  } else {
    if (window.performance.navigation.type === 0 && !lastLocation) {
      // set blur effect false on tab closed before onboarding start
      setOnboardingBlurEffect(false);
    }
  }

  return (
    <React.Fragment>
      <Box className="homeMain">
        <Box className="loginSection">
          <Box textAlign="center" px={4}>
            <Typography
              style={{
                lineHeight: '25px',
                fontSize: isTablet ? '1.125rem' : '1.25rem',
                fontWeight: 400,
              }}
            >
              Join the largest
            </Typography>
            <Typography
              style={{
                fontWeight: '600',
                fontSize: isTablet ? '1.125rem' : '1.25rem',
                marginTop: '1px',
                lineHeight: '25px',
              }}
            >
              Social Network for Women
            </Typography>
          </Box>

          <Box display="block" textAlign="center">
            <SocialSignUp history={history} />
          </Box>
        </Box>
        <Box>
          <img
            src={HomeBanner}
            alt="Banner"
            width="346"
            height="210"
            style={
              isTablet
                ? {
                    borderBottomLeftRadius: '0px',
                    borderBottomRightRadius: '0px',
                  }
                : {
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px',
                  }
            }
            className="homeRightBanner"
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default HomeScreen;
