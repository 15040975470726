import React, { useState } from 'react';
import OptionsMenu from './optionsMenu';
import UserAvatar from '../common/userAvatar';
import { Typography, Box, CardHeader } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import imgParams from '../../utils/imageUtil';
import AdapterLink from '../common/adapterLink';
import ChampionIcon from '../../img/championIcon.svg';
import { useLikeOnComment } from '../../hooks/postHooks';
import { getReplySlug } from '../../utils/textUtil';
import CommentReply from './comment/commentReply';
import CommentLike from './comment/commentLike';
import { useSnackBar } from '../../hooks/snackBarHooks';
const CommentItem = ({
  comment,
  handleDeleteComment,
  postId,
  postEntityId,
  handleClick,
  shouldTruncate,
  commentIndex,
  post,
  isPostDetail,
  isArticleDetail,
  isShowViewReply,
  commentReplyIndex,
  parentCommentIndex,
  isCommentDetail,
  isShowLike,
  lectureEntityId,
  isLecture,
  isMentorReply,
  isFromReplies,
  isCommentActive,
  isParentComment,
  replyParentCommentIndex,
  isLastComment,
  postSlug,
}) => {
  const isDetails = isPostDetail || isArticleDetail;
  const { history } = useReactRouter();
  const { isLiked, likesCount, likeComment } = useLikeOnComment(
    postId,
    commentIndex,
    isDetails,
    isCommentDetail,
    commentReplyIndex,
    parentCommentIndex,
    isMentorReply,
    isArticleDetail
  );
  const setToastMessage = useSnackBar();
  const [likeStatus, setLikeStatus] = useState();

  async function likeOnComment(e, commentId) {
    e.stopPropagation();
    likeComment({
      postId: post.id,
      commentIndex: commentIndex,
      postEntityId: isLecture ? lectureEntityId : post.parentId,
      commentId: commentId,
      likeStatus: !isLiked,
      isPostDetail: isPostDetail,
      commentReplyIndex: commentReplyIndex,
      parentCommentIndex: parentCommentIndex,
      isCommentDetail: isCommentDetail,
      isArticleDetail: isArticleDetail,
      setToastMessage,
    });
    setLikeStatus(!isLiked);
  }
  const openReplies = (e, commentId) => {
    e.preventDefault();
    const pathname = history.location.pathname;
    const replyUrl = getReplySlug(pathname, commentId);
    const postObj = JSON.stringify(post);
    if (isPostDetail || isArticleDetail) {
      // If it's detail page then it will go to the replies page.
      history.push(
        { pathname: replyUrl, post },
        { commentIndex, postId, postObj, lectureEntityId, isLecture }
      );
    } else {
      // If it's feed, articles and communities home page then it will go to the postDetail page.
      history.push({ pathname: post.slug }, { postId, commentId });
    }
  };

  return (
    <React.Fragment>
      {comment && comment.description && (
        <Box>
          <CardHeader
            title={
              <Box
                onClick={handleClick}
                to={comment.authorDeepLinkUrl || ''}
                component={AdapterLink}
                className="removeUnderline"
                display="inline-block"
              >
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textPrimary"
                  component="span"
                >
                  {comment.authorName}
                </Typography>
              </Box>
            }
            avatar={
              <Box display="inline-block" position="relative">
                <UserAvatar
                  userImgUrl={imgParams(
                    comment && comment.authorImageUrl,
                    40,
                    40,
                    'auto'
                  )}
                  comment={comment}
                  onClick={(e) => e.stopPropagation()}
                />
                {comment.isAuthorMentor && (
                  <img
                    src={ChampionIcon}
                    alt="Champion Icon"
                    className="championIconStyle"
                  />
                )}

                {comment.profileBadgeUrl && comment.showProfileBadge && (
                  <img
                    className="postShareIcon"
                    src={comment.profileBadgeUrl}
                    alt="she badge"
                    style={{ bottom: '-8px' }}
                  />
                )}
              </Box>
            }
            action={
              <Box>
                <OptionsMenu
                  handleDeleteComment={handleDeleteComment}
                  comment={comment}
                  postId={postId}
                  postEntityId={postEntityId}
                  isCommentDetail={isCommentDetail}
                  commentReplyIndex={commentReplyIndex}
                  parentCommentIndex={parentCommentIndex}
                  lectureEntityId={lectureEntityId}
                  isLecture={isLecture}
                  isArticleDetail={isArticleDetail}
                  isFromReplies={isFromReplies}
                  commentIndex={commentIndex}
                  isCommentActive={isCommentActive}
                  isParentComment={isParentComment}
                  replyParentCommentIndex={replyParentCommentIndex}
                  isLastComment={isLastComment}
                  postSlug={postSlug}
                />
              </Box>
            }
            subheader={
              <React.Fragment>
                <Box whiteSpace="pre-line">
                  <Typography
                    gutterBottom
                    variant="body1"
                    color="textPrimary"
                    component="p"
                    style={{ whiteSpace: 'normal' }}
                    className="wordBreak"
                    dangerouslySetInnerHTML={{
                      __html: comment.description,
                    }}
                  ></Typography>
                  <CommentLike
                    isShowLike={true}
                    likesCount={likesCount}
                    isLiked={isLiked}
                    likeOnComment={likeOnComment}
                    likeStatus={likeStatus}
                    isShowViewReply={isShowViewReply}
                    comment={comment}
                    openReplies={openReplies}
                  />
                  {isShowViewReply && comment.repliesCount > 0 && (
                    <CommentReply comment={comment} openReplies={openReplies} />
                  )}
                </Box>
              </React.Fragment>
            }
          />
        </Box>
      )}
    </React.Fragment>
  );
};
export default CommentItem;
