import React from 'react';
import { useNavBar } from '../../hooks/navBarHooks';
import { useScreenOpenTracker } from '../../hooks/analyticsHook';
import { Box, Grid } from '@material-ui/core';
import { useMobile } from '../../hooks/utilHooks';
import RenderIframe from '../common/renderIframe';
import { makeStyles } from '@material-ui/styles';

import { getCurrentUser } from '../../services/authService';

const FooterPage = ({ title, url }) => {
  const isMobile = useMobile();
  const classes = useStyles();
  const user = getCurrentUser();

  let tokenUrl = '';
  if (user && user.token) {
    tokenUrl = url + '&token=' + user.token;
  } else {
    tokenUrl = url;
  }
  const getHelplineLogin = tokenUrl;

  useNavBar({
    title,
    showBack: true,
    showBell: false,
  });
  useScreenOpenTracker({
    screenName: title,
  });

  const helplineLoggedOut = (
    <Box
      className={classes.helplineBg}
      mt={1}
      display="flex"
      justifyContent="space-around"
    >
      <a
        href="https://shrs.me/Kt29cXYDY5"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src={
            'https://img.sheroes.in/img/uploads/sheroespage/logoes/1600771666counsellor-1.png'
          }
          alt="Play Store"
          width="100%"
        />
      </a>
    </Box>
  );

  return (
    <Box width={1} py={isMobile ? 2 : 3} px={4} bgcolor="white">
      <Grid>
        <Grid item lg={12} className="mxAuto">
          {title === 'Helpline' && user && (
            <RenderIframe url={getHelplineLogin} />
          )}
          {title === 'Helpline' && !user && <Box>{helplineLoggedOut}</Box>}
          {title !== 'Helpline' && <RenderIframe url={url} />}
        </Grid>
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  helplineBg: {
    background: '#e28574',
    height: '70vh',
    '& img': {
      marginTop: '25%',
    },
  },
}));

export default FooterPage;
