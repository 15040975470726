import { getPostLanguage } from '../services/authService';
import { getCurrentUser } from '../services/httpService';
const webLanguage = getPostLanguage();
const enHiLang = webLanguage === 'en' || webLanguage === 'hi';

const screenNamesSet = {
  feed: 'Feed',
  gwwFeed: 'GWW Feed',
  learn: 'Learn',
  course: 'Course',
  socialFeed: 'Social Feed',
  articles: 'Articles',
  articleDetail: 'Article Detail',
  userProfileFeed: 'User Profile Feed',
  userProfileAbout: 'User Profile About',
  lectureDetail: 'Lecture Detail',
  postDetail: 'Post Detail',
  courseProgress: 'Course Progress',
  helpline: 'Helpline',
  communityGuidelines: 'Community Guidelines',
  advertorialPolicy: 'Advertorial Policy',
  privacyPolicy: 'Privacy Policy',
  bell: 'Bell Notifications',
  communitiesList: 'Communities list',
  createPost: 'Create Post',
  editPost: 'Edit Post',
  assignment: 'Assignment',
  survey: 'Survey',
  exploreSearch: 'Explore Search',
  editProfile: 'Edit Profile',
  champions: 'Champions',
  friends: 'Friends',
  replyOnComment: 'Reply On Comment',
  home: 'Home',
  loginOptions: 'Login Options',
  communityDetail: 'Community Detail',
  myCommunities: 'My Communities',
  marsAbout: 'Mars About',
  marsPersonalDetails: 'Personal Details',
  blockedAccounts: 'Blocked Accounts',
  selfieVerification: 'Selfie Verification',
  splash: 'Splash',
  thirdPartyLogin: 'Third Party Login',
  facilitatorDashboard: 'Facilitator Dashboard',
  mentorDashboard: 'Mentor Dashboard',
  languageSelection: 'Language Selection',
  otpValidation: 'OTP Validation',
  otpLogin: 'OTP Login',
  logout: 'Logout',
  credentialLogin: 'Credential Login',
  seflieCapture: 'Selfie Capture',
  selfieGuide: 'Selfie Guide',
  maleError: 'Male Error',
  postCommunitySelection: 'Post Community Selection',
  topSearch: 'Top Search',
  peopleSearch: 'People Search',
  communitySearch: 'Community Search',
  hashtagSearch: 'Hashtag Search',
  menu: 'Menu',
  followers: 'Followers',
  following: 'Following',
  superSheroesBadges: 'SuperSheroes badges',
  mileStoneBadges: 'Milestone badges',
  welcomeScreen: 'Welcome',
  gwwWelcomeScreen: 'Gww Welcome',
  viewCertificate: 'View Certificate',
  notFound: '404',
  personalDetails: 'Personal Details',
};

function currentScreenOpen(qs, value, user) {
  if (qs.tab === 'gww') {
    if (value === 1) {
      return screenNamesSet.course;
    } else if (value === 0) {
      return screenNamesSet.learn;
    } else {
      return screenNamesSet.gwwFeed;
    }
  } else if (qs.tab === 'social') {
    return screenNamesSet.socialFeed;
  } else if (qs.tab === 'feed') {
    return screenNamesSet.gwwFeed;
  } else if (qs.tab === 'learn') {
    return screenNamesSet.learn;
  } else if (qs.tab === 'course') {
    return screenNamesSet.course;
  } else if (qs.type === 'posts') {
    return screenNamesSet.topSearch;
  } else if (qs.type === 'images') {
    return screenNamesSet.exploreSearch;
  } else if (qs.type === 'users') {
    return screenNamesSet.peopleSearch;
  } else if (qs.type === 'hashtags') {
    return screenNamesSet.hashtagSearch;
  } else if (qs.type === 'communities') {
    return screenNamesSet.communitySearch;
  } else if (qs.tab === 'dashboard') {
    if (user && user.userCategory === 'fc') {
      return screenNamesSet.facilitatorDashboard;
    } else if (user && user.userCategory === 'gm') {
      return screenNamesSet.mentorDashboard;
    }
  } else {
    if (enHiLang) {
      return screenNamesSet.socialFeed;
    } else {
      return screenNamesSet.course;
    }
  }
}

function getPreviousLocation(previousScreen) {
  const splitUrl = previousScreen && previousScreen.search.split('=');
  const user = getCurrentUser();
  let defaultPreviousScreen;
  if (previousScreen === null) {
    if (enHiLang) {
      defaultPreviousScreen = screenNamesSet.socialFeed;
    } else {
      defaultPreviousScreen = screenNamesSet.course;
    }
  } else {
    defaultPreviousScreen = previousScreen;
  }
  const setPreviousScreen = defaultPreviousScreen;
  const previousUrlSplit =
    previousScreen !== null && previousScreen?.pathname.split('/');
  if (
    previousScreen &&
    previousScreen.pathname === '/feed' &&
    !previousScreen.search
  ) {
    if (enHiLang) {
      return screenNamesSet.socialFeed;
    } else {
      return screenNamesSet.course;
    }
  } else {
    defaultPreviousScreen = previousScreen;
  }
  if (setPreviousScreen.search === '?tab=dashboard') {
    if (user && user.userCategory === 'fc') {
      return screenNamesSet.facilitatorDashboard;
    } else if (user && user.userCategory === 'gm') {
      return screenNamesSet.mentorDashboard;
    }
  } else if (setPreviousScreen.search === '?tab=social') {
    return screenNamesSet.socialFeed;
  } else if (
    setPreviousScreen.search === '?tab=gww' ||
    setPreviousScreen.search === '?tab=feed'
  ) {
    return screenNamesSet.gwwFeed;
  } else if (setPreviousScreen.search === '?tab=course') {
    return screenNamesSet.course;
  } else if (setPreviousScreen.search === '?tab=learn') {
    return screenNamesSet.learn;
  } else if (setPreviousScreen.pathname === '/mobile-email-form') {
    return screenNamesSet.otpLogin;
  } else if (setPreviousScreen.pathname === '/onboarding/selfie') {
    return screenNamesSet.selfieVerification;
  } else if (setPreviousScreen.pathname === '/onboarding/gender-verification') {
    return screenNamesSet.seflieCapture;
  } else if (setPreviousScreen.pathname === '/articles') {
    return screenNamesSet.articles;
  } else if (setPreviousScreen.pathname === '/verify-otp') {
    return screenNamesSet.otpValidation;
  } else if (setPreviousScreen.pathname === '/logout') {
    return screenNamesSet.logout;
  } else if (setPreviousScreen.pathname === '/onboarding/global-language') {
    return screenNamesSet.languageSelection;
  } else if (setPreviousScreen.pathname === '/onboarding/gender') {
    return screenNamesSet.personalDetails;
  } else if (setPreviousScreen.pathname === '/onboarding/image-not-verified') {
    return screenNamesSet.selfieGuide;
  } else if (setPreviousScreen.pathname === '/onboarding/male-restricted') {
    return screenNamesSet.maleError;
  } else if (setPreviousScreen.pathname === '/onboarding/welcome') {
    return screenNamesSet.welcomeScreen;
  } else if (setPreviousScreen.pathname === '/onboarding/gww-welcome') {
    return screenNamesSet.gwwWelcomeScreen;
  } else if (setPreviousScreen.pathname === '/menu') {
    return screenNamesSet.menu;
  } else if (splitUrl && (splitUrl[1] === 'posts' || splitUrl[2] === 'posts')) {
    return screenNamesSet.topSearch;
  } else if (
    splitUrl &&
    (splitUrl[1] === 'images' || splitUrl[2] === 'images')
  ) {
    return screenNamesSet.exploreSearch;
  } else if (splitUrl && (splitUrl[1] === 'users' || splitUrl[2] === 'users')) {
    return screenNamesSet.peopleSearch;
  } else if (
    splitUrl &&
    (splitUrl[1] === 'hashtags' || splitUrl[2] === 'hashtags')
  ) {
    return screenNamesSet.hashtagSearch;
  } else if (
    splitUrl &&
    (splitUrl[1] === 'communities' || splitUrl[2] === 'communities')
  ) {
    return screenNamesSet.communitySearch;
  } else if (setPreviousScreen.pathname === '/' && !user) {
    return screenNamesSet.loginOptions;
  } else if (
    setPreviousScreen.pathname === '/search' &&
    !setPreviousScreen.search
  ) {
    return screenNamesSet.topSearch;
  } else if (previousUrlSplit && previousUrlSplit[4] === 'followers-list') {
    return screenNamesSet.followers;
  } else if (previousUrlSplit && previousUrlSplit[4] === 'following-list') {
    return screenNamesSet.following;
  } else if (previousUrlSplit[1] === 'articles' && previousUrlSplit[2] !== '') {
    return screenNamesSet.articleDetail;
  } else if (previousUrlSplit[1] === 'users' && previousUrlSplit[2] !== '') {
    return screenNamesSet.userProfileFeed;
  } else if (
    previousUrlSplit[1] === 'communities' &&
    previousUrlSplit[2] !== ''
  ) {
    return screenNamesSet.postDetail;
  } else if (
    previousUrlSplit[5] === 'replies' ||
    previousUrlSplit[3] === 'replies'
  ) {
    return screenNamesSet.replyOnComment;
  } else if (
    previousUrlSplit[1] === 'lecture' &&
    previousUrlSplit[3] === 'quiz'
  ) {
    return screenNamesSet.assignment;
  } else if (
    previousUrlSplit[1] === 'lecture' &&
    previousUrlSplit[3] === 'survey'
  ) {
    return screenNamesSet.survey;
  } else if (previousUrlSplit[1] === 'lecture' && previousUrlSplit[2] !== '') {
    return screenNamesSet.lectureDetail;
  } else if (setPreviousScreen.pathname === '/course-progress') {
    return screenNamesSet.courseProgress;
  } else if (setPreviousScreen.pathname === '/helpline') {
    return screenNamesSet.helpline;
  } else if (setPreviousScreen.pathname === '/community-guidelines') {
    return screenNamesSet.communityGuidelines;
  } else if (setPreviousScreen.pathname === '/Home/advertorial-policy') {
    return screenNamesSet.advertorialPolicy;
  } else if (setPreviousScreen.pathname === '/Home/policy') {
    return screenNamesSet.privacyPolicy;
  } else if (setPreviousScreen.pathname === '/notifications') {
    return screenNamesSet.bell;
  } else if (previousUrlSplit[1] === 'communities') {
    return screenNamesSet.communitiesList;
  } else if (setPreviousScreen.pathname === '/create-post') {
    return screenNamesSet.createPost;
  } else if (setPreviousScreen.pathname === '/edit-post') {
    return screenNamesSet.editPost;
  } else if (
    previousUrlSplit[1] === 'users' &&
    previousUrlSplit[2] === 'edit-profile'
  ) {
    return screenNamesSet.editProfile;
  } else if (previousUrlSplit[2] === 'champions') {
    return screenNamesSet.champions;
  } else if (previousUrlSplit[2] === 'champions') {
    return screenNamesSet.champions;
  } else if (previousUrlSplit[1] === 'friend-list') {
    return screenNamesSet.friends;
  } else if (
    previousUrlSplit[1] === 'mars' &&
    previousUrlSplit[2] === 'about'
  ) {
    return screenNamesSet.marsAbout;
  } else if (
    previousUrlSplit[1] === 'mars' &&
    previousUrlSplit[2] === 'personal-details'
  ) {
    return screenNamesSet.marsPersonalDetails;
  } else if (setPreviousScreen.pathname === '/blocked-accounts') {
    return screenNamesSet.blockedAccounts;
  } else if (
    previousScreen &&
    previousScreen.pathname === '/course/certificate'
  ) {
    return screenNamesSet.viewCertificate;
  }
}

export { screenNamesSet, currentScreenOpen, getPreviousLocation };
