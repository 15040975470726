import { getPostLanguage } from '../services/authService';

function mobileViewSteps(postLang) {
  return [
    {
      selector: postLang === 'mr' ? '' : '.coach-mark-first-step',
      content:
        'Your Google Certificate Is Here! Watch course videos here to claim',
      position: 'bottom',
    },
    {
      selector:
        postLang === 'gu' || postLang === 'as' || postLang === 'mr'
          ? ''
          : '.coach-mark-second-step',
      content: 'Learn about different forms of online safety here',
      position: 'bottom',
    },
    {
      selector: postLang === 'mr' ? '' : '.coach-mark-third-step',
      content: 'See what other course learners are discussing',
      position: 'bottom',
    },
    {
      selector: postLang === 'mr' ? '' : '.coach-mark-fabIcon-step',
      content:
        'Ask a question here or share your course progress. Create a post now.',
      position: 'top',
    },
    {
      selector: '.coach-mark-fourth-step',
      content: 'Tap here to watch level 1 Business Course Videos',
      position: 'bottom',
    },
    {
      selector: '.coach-mark-fifth-step',
      content: 'Tap here to watch Level 2 Business Course Videos.',
      position: 'bottom',
    },
    {
      selector: '.coach-mark-sixth-step',
      content:
        'Special Badges Await You!\nTake Assignment, Watch the Next Video',
      position: 'top',
    },
  ];
}

function webViewSteps(postLang) {
  return [
    {
      selector: postLang === 'mr' ? '' : '.coach-mark-first-step',
      content:
        'Your Google Certificate Is Here! Watch course videos here to claim',
      position: 'bottom',
    },
    {
      selector:
        postLang === 'gu' || postLang === 'as' || postLang === 'mr'
          ? ''
          : '.coach-mark-second-step',
      content: 'Learn about different forms of online safety here',
      position: 'bottom',
    },
    {
      selector: postLang === 'mr' ? '' : '.coach-mark-third-step',
      content: 'See what other course learners are discussing',
      position: 'bottom',
    },
    {
      selector: '.coach-mark-fourth-step',
      content: 'Tap here to watch level 1 Business Course Videos',
      position: 'bottom',
    },
    {
      selector: '.coach-mark-fifth-step',
      content: 'Tap here to watch Level 2 Business Course Videos.',
      position: 'bottom',
    },
    {
      selector: '.coach-mark-sixth-step',
      content:
        'Special Badges Await You!\nTake Assignment, Watch the Next Video',
      position: 'top',
    },
  ];
}

function isUserComesFromLoginScreen({ user, history }) {
  const pathname = window.location.pathname;
  const tab = window.location.search;
  const webLanguage = getPostLanguage();
  const engHiUser = webLanguage === 'en' || webLanguage === 'hi';

  if (engHiUser) {
    if (tab === '?tab=course') {
      if (user?.isNewUser) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    if (
      pathname === '/feed' &&
      history &&
      history.location &&
      history.location.state &&
      history.location.state.state &&
      history.location.state.state === 'interest'
    ) {
      if (user?.isNewUser) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
export { webViewSteps, mobileViewSteps, isUserComesFromLoginScreen };
