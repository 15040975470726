import { useStoreActions, useStoreState } from 'easy-peasy';
import { getOnboardingStatus } from '../services/onboardingService';
import {
  getCurrentUser,
  getPostLanguage,
  getThirdPartyUser,
} from '../services/authService';
import { getIsOnboardingUrl } from '../utils/redirectUtil';
import { makeStyles } from '@material-ui/core/styles';
import { urlMap } from '../utils/constants';
import { useLastLocation } from 'react-router-last-location';
import language from '../utils/languages';
import notificationService from '../services/notificationService';
import { useFetchData } from './fetchDataHooks';
import { useHistory } from 'react-router-dom';

function useNavBar(options) {
  const setOptions = useStoreActions((actions) => actions.navBar.setOptions);
  setOptions(options);
}

function useNavbarItems() {
  const user = getCurrentUser();
  const history = useHistory();
  const isOnboardingDone = getOnboardingStatus();
  const userFromMM = getThirdPartyUser();
  const isOnboardingUrl = getIsOnboardingUrl();
  const locationUrl = window.location.pathname;
  const splitData = locationUrl.split('/');
  const lastLocation = useLastLocation();
  const webLanguage = getPostLanguage();
  const languageText = language[webLanguage ? webLanguage : 'en'];

  let isCreatePost =
    splitData[1] === 'create-post' || splitData[1] === 'create-poll';
  let isSheroesMagazine = splitData[1] === 'sheroes-magazine';

  const { data: notificationsCount, setData: setNotificationsCount } =
    useFetchData(user && notificationService.getNotificationCount, 0);

  function transparentHeader() {
    if (history && history.location && history.location.pathname) {
      const transparentPaths = [
        '/mobile-email-form',
        '/verify-otp',
        '/onboarding/gender-verification',
        '/onboarding/selfie',
        '/onboarding/global-language',
        '/onboarding/gender',
        '/onboarding/male-restricted',
        '/onboarding/image-not-verified',
        '/onboarding/welcome',
        '/language_selection',
      ];
      return transparentPaths.includes(history.location.pathname)
        ? 'transparentHeader'
        : 'navbarBorder';
    }
  }
  const { setDialogBoxOpen, setDialogContent } = useStoreActions(
    (actions) => actions.dialogBox
  );
  const { showDialogOnBack, showFeedOnBack } = useStoreState(
    (state) => state.navBar.options
  );
  const handleGoBack = useStoreActions((state) => state.navBar.handleGoBack);

  const useStyles = makeStyles((theme) => ({
    title: {
      flexGrow: 1,
      textDecoration: 'none',
    },
    titleForMM: {
      flexGrow: 1,
      textDecoration: 'none',
      marginLeft: theme.spacing(75),
    },
    titleForMMMobile: {
      flexGrow: 1,
      textDecoration: 'none',
      marginLeft: theme.spacing(3),
    },
    notificationBell: {
      color: 'inherit',
    },
    customBadge: {
      backgroundColor: '#FF0',
      color: theme.palette.text.primary,
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(0.5),
    },
    menuStyle: {
      minWidth: 'auto',
      padding: 0,
      margin: theme.spacing(1, 2, 0, 0),
      color: 'white',
    },
    shareButton: {
      padding: theme.spacing(0),
      minWidth: 'inherit',
      marginLeft: theme.spacing(4),
    },
    tollBar: {
      minHeight: '50px',
    },
    tollBarForMM: {
      minHeight: '50px',
      display: 'block',
      paddingTop: '12px',
    },
    buttonBackground: {
      background: 'rgb(0,0,0, 0.295596)',
      borderRadius: '50%',
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      width: '28px',
      height: '28px',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));
  const classes = useStyles();
  function showDiscardModal() {
    setDialogBoxOpen(true);
    setDialogContent({
      dialogTitle: languageText.DISCARD_POST,
      dialogContentText: languageText.DISCARD_CHANGES,
      btnPrimaryText: languageText.NO,
      btnSecondaryText: languageText.YES,
    });
  }
  function handleBackNavigation() {
    showDialogOnBack
      ? showDiscardModal()
      : showFeedOnBack
      ? history.push(urlMap.FEED)
      : handleGoBack({
          history: history,
          lastLocation: lastLocation,
        });
  }
  return {
    isOnboardingDone,
    userFromMM,
    isOnboardingUrl,
    history,
    transparentHeader,
    isCreatePost,
    isSheroesMagazine,
    classes,
    handleBackNavigation,
    notificationsCount,
    setNotificationsCount,
  };
}

export { useNavBar, useNavbarItems };
