import { useState, useEffect, useCallback } from 'react';
import { useSnackBar } from './snackBarHooks';

/**
 * Hook to fetch data from the `serviceApi` function.
 *
 * Pass an optional `deps` dependency array for the `serviceApi`,
 *   if you want to refetch the data when `deps` changes.
 * By default, `deps` is an empty array, meaning data is fetched only once
 */
function useFetchData(serviceApi, defaultValue, deps = []) {
  const [data, setData] = useState(defaultValue);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const serviceCallback = useCallback(serviceApi, deps);
  const setToastMessage = useSnackBar();
  useEffect(() => {
    let current = true;
    async function fetchData() {
      try {
        setLoading(true);
        const res = await serviceCallback(setToastMessage);
        if (current) {
          setData(res);
          setLoading(false);
          setError(null);
        }
      } catch (e) {
        if (current) {
          setLoading(false);
          setError(e);
        }
      }
    }

    fetchData();
    return () => {
      //Cleanup function when component unmounts
      current = false;
    };
    // eslint-disable-next-line
  }, [serviceCallback]);
  return { data, isLoading, error, setData };
}

export { useFetchData };
