import { action } from 'easy-peasy';
import { urlMap } from '../utils/constants';

export default {
  open: false,
  errorMessage: '',
  btnPrimaryText: '',
  btnSecondaryText: '',
  dialogIcon: null,
  dialogTitle: '',
  urlToRedirect: null,
  btnPrimaryAction: false,
  btnSecondaryAction: true,
  setErrorDialogBoxOpen: action((state, payload) => {
    state.open = payload;
  }),
  setErrorDialogContent: action(
    (state, { btnPrimaryText, btnSecondaryText, dialogIcon, dialogTitle }) => {
      state.btnPrimaryText = btnPrimaryText;
      state.btnSecondaryText = btnSecondaryText;
      state.dialogIcon = dialogIcon;
      state.dialogTitle = dialogTitle;
    }
  ),
  setHandleErrorMessage: action((state, payload) => {
    if (payload?.next_screen === 'selfie') {
      state.errorMessage = payload.error_message;
      state.urlToRedirect = urlMap.ONBOARDING_GENDER_VERIFICATION;
    } else if (payload?.error_message) {
      state.errorMessage = payload.error_message;
    }
  }),
  setSuccessMessage: action((state, payload) => {
    state.errorMessage = payload;
  }),
};
