import { action, thunk, thunkOn } from 'easy-peasy';
import feedService from '../services/feedService';
import remove from 'lodash/remove';
import apiUrl from '../utils/apiUrl';
import gWW from '../services/gWW';
import userService from '../services/userService';

export function initFeed(createCellMeasurerCache) {
  return {
    docs: [],
    nextToken: null,
    status: '',
    setOrderKey: null,
    feedConfigVersion: null,
    scrollPosition: 0,
    feedCache: {
      cacheObj: createCellMeasurerCache(),
      listRef: null,
    },
    showFab: true,
  };
}
export default {
  //Feeds is a map where the key is the feedStreamApi url and value is the above initFeed Obj. For different streams new feed is created & stored in globalstate.
  //Ex. feeds['participant/feed/stream'] = {docs:[],...}
  feeds: {},
  currentApi: '',
  isFeedLoading: false,
  dashboard: {},
  course: {},
  learn: {},
  userObj: {},
  //use this action inorder to stop multiple stream calls to indicate an inflight request.
  setLoading: action((state, isLoading) => {
    // console.log('isLoading:', isLoading);
    state.isFeedLoading = isLoading;
  }),

  //create the feed store for any stream if its not already created.
  createFeed: action((state, { feedStreamApi, createCellMeasurerCache }) => {
    state.currentApi = feedStreamApi;
    if (!state.feeds[feedStreamApi]) {
      state.feeds[feedStreamApi] = initFeed(createCellMeasurerCache);
    }
  }),
  renderFab: action((state, { showFab, streamUrl }) => {
    state.feeds[streamUrl].showFab = showFab;
  }),
  setScrollPostId: action((state, postId) => {
    const currentFeed = state.feeds[state.currentApi];
    const postPosition =
      postId && currentFeed && currentFeed.docs.indexOf(postId);
    state.feeds[state.currentApi].scrollPosition = postPosition
      ? postPosition
      : 0;
  }),
  setCacheListRef: action((state, ref) => {
    if (ref && state.feeds[state.currentApi])
      state.feeds[state.currentApi].feedCache.listRef = ref;
  }),
  updateItemCardHeight: action(
    (state, { postId, clearAll, shouldRecompute }) => {
      const currentFeed = state.feeds[state.currentApi];
      const postPosition = currentFeed ? currentFeed.docs.indexOf(postId) : -1;
      //If any action happens on a post card apart from delete.. Height for that UI card is recomputed.
      if (postPosition > -1 && shouldRecompute && currentFeed) {
        currentFeed.feedCache.cacheObj.clear(postPosition);
        currentFeed.feedCache.listRef &&
          currentFeed.feedCache.listRef.recomputeRowHeights(postPosition);
      }
      //If a post is deleted or created, as the card is removed from feed, gaps are formed. So we are clearing the entire cache for that feed.
      clearAll && currentFeed && currentFeed.feedCache.cacheObj.clearAll(0);
    }
  ),
  setFeedState: action(
    (
      state,
      { docs, nextToken, setOrderKey, feedConfigVersion, status, feedStreamApi }
    ) => {
      if (feedStreamApi === state?.currentApi) {
        typeof docs !== 'undefined' &&
          docs.map((doc) => {
            const feedDocs = state.feeds[state.currentApi].docs;
            return feedDocs.indexOf(doc.id) < 0
              ? feedDocs.push(doc.id)
              : feedDocs;
          });
        state.feeds[state.currentApi].nextToken = nextToken;
        state.feeds[state.currentApi].setOrderKey = setOrderKey;
        state.feeds[state.currentApi].status = status;
        state.feeds[state.currentApi].feedConfigVersion = feedConfigVersion;
      }
    }
  ),
  updateFeedState: action((state, feedDocs) => {
    const currentFeed = state.feeds[state.currentApi];
    if (currentFeed) currentFeed.docs = [...feedDocs];
  }),
  removePostFromFeedState: action((state, postId) => {
    remove(state.feeds[state.currentApi].docs, (item) => {
      return item === postId;
    });
  }),
  /** adding instant post in my bookmarks screen on clicking the bookmark option
   */
  addBookmarkPostFromFeedState: action((state, postId) => {
    if (state.currentApi === apiUrl.BOOKMARK_STREAM_URL) {
      state.feeds[apiUrl.BOOKMARK_STREAM_URL].docs = [
        postId,
        ...state.feeds[apiUrl.BOOKMARK_STREAM_URL].docs,
      ];
    }
  }),
  /** removing instant post from my bookmarks screen on clicking the remove bookmark option
   */
  removeBookmarkPostFromFeedState: action((state, postId) => {
    if (state.currentApi === apiUrl.BOOKMARK_STREAM_URL) {
      state.feeds[apiUrl.BOOKMARK_STREAM_URL].docs =
        state.feeds[apiUrl.BOOKMARK_STREAM_URL].docs &&
        state.feeds[apiUrl.BOOKMARK_STREAM_URL].docs.filter(
          (item) => item !== postId
        );
    }
  }),
  fetchFeed: thunk(
    async (
      actions,
      {
        feedStreamApi,
        isGetRequest,
        createCellMeasurerCache,
        isCourse,
        isMentor,
        isBookmark,
      },
      { getState, getStoreActions }
    ) => {
      actions.createFeed({ feedStreamApi, createCellMeasurerCache });
      actions.setLoading(true);
      const currentToken = getState().feeds[feedStreamApi].nextToken;
      const { docs, nextToken, feedConfigVersion, setOrderKey, status } =
        await feedService.getFeedStream(
          feedStreamApi,
          currentToken,
          isGetRequest,
          isCourse,
          isBookmark
        );
      // console.log('docs:', docs);
      getStoreActions().posts.setPostState(docs);
      actions.setFeedState({
        docs,
        nextToken,
        feedConfigVersion,
        setOrderKey,
        status,
        feedStreamApi,
      });
      actions.setLoading(false);
    }
  ),
  //Listeners.
  onPostAction: thunkOn(
    (actions, storeActions) =>
      storeActions.posts && [storeActions.posts.updatePostState],
    (actions, target) => {
      actions.updateItemCardHeight({
        postId: target.payload.id || target.payload.postId,
        shouldRecompute: target.payload.shouldRecompute,
      });
      target.payload.shouldRecompute && delete target.payload.shouldRecompute;
      if (target.payload.isNewPost) {
        actions.updateFeedState([target.payload.id]);
        actions.updateItemCardHeight({
          clearAll: true,
        });
        delete target.payload.isNewPost;
      }
    }
  ),
  onPostDelete: thunkOn(
    (actions, storeActions) =>
      storeActions.posts && [
        storeActions.posts.deletePostSuccess,
        storeActions.posts.deletePostFailure,
        storeActions.posts.approveOrDeletePost,
      ],
    (actions, target) => {
      if (
        target.payload &&
        target.payload.status &&
        target.payload.status === 'SUCCESS'
      ) {
        if (target.payload.is_approved) {
          return;
        }
        actions.updateItemCardHeight({
          clearAll: true,
        });
        actions.removePostFromFeedState(target.payload.postId);
      }
    }
  ),
  onPostBookmarked: thunkOn(
    (actions, storeActions) =>
      storeActions.posts && [storeActions.posts.bookmarkOnPost],
    (actions, target) => {
      if (
        target.payload &&
        target.payload.postId &&
        target.payload.isMyBookmarksPage &&
        target.payload.isMyBookmarksPage
      ) {
        actions.removeBookmarkPostFromFeedState(target.payload.postId);
      } else {
        if (target.payload.isBookmark) {
          actions.addBookmarkPostFromFeedState(target.payload.postId);
        } else {
          actions.removeBookmarkPostFromFeedState(target.payload.postId);
        }
      }
    }
  ),
  setDashboardData: action((state, payload) => {
    state.dashboard = payload;
  }),
  fetchDashboard: thunk(
    async (
      actions,
      { feedStreamApi, isGetRequest, isMentor },
      { getState, getStoreActions }
    ) => {
      const {
        unAnsweredQC,
        answeredQC,
        questions,
        escalatedQC,
        assignedUsers,
      } = await gWW.getDashboardData(feedStreamApi, isGetRequest, isMentor);
      actions.setDashboardData({
        unAnsweredQC,
        answeredQC,
        questions,
        escalatedQC,
        assignedUsers,
      });
    }
  ),
  setCourseData: action((state, payload) => {
    state.course = payload;
  }),
  fetchCourse: thunk(async (actions, { courseId, language }) => {
    try {
      const data = await gWW.getLearnOrCourseData(courseId, false, language);
      data && actions.setCourseData(data);
      return data;
    } catch {}
  }),
  setLearnData: action((state, payload) => {
    state.learn = payload;
  }),
  fetchLearn: thunk(async (actions, { courseId, language }) => {
    try {
      const data = await gWW.getLearnOrCourseData(courseId, true, language);
      data && actions.setLearnData(data);
      return data;
    } catch {}
  }),
  SetUserObj: action((state, payload) => {
    state.userObj = payload;
  }),

  fetchUserData: thunk(async (actions, { userId }) => {
    try {
      const response = await userService.getUserDetails(userId);
      response && actions.SetUserObj(response);
      return response;
    } catch {
      console.log('error');
    }
  }),
};
