import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useTablet } from '../../hooks/utilHooks';
import sheroesLogo from '../../img/sheroes-white-logo.svg';
import sheroesRedLogo from '../../img/sheroes-red-logo.svg';

const Header = () => {
  const classes = useStyles();
  const isTablet = useTablet();

  return (
    <React.Fragment>
      <Box
        className={classes.header}
        py={4}
        px={4}
        style={{
          position: window.location.pathname === '/' ? 'absolute' : 'fixed',
          top: isTablet ? 0 : '12px',
        }}
      >
        <Box
          className={isTablet ? 'width100' : 'mxAuto homeContainer'}
          display="block"
          textAlign={isTablet ? 'center' : 'left'}
        >
          <img
            src={isTablet ? sheroesRedLogo : sheroesLogo}
            alt="SHEROES"
            width='125px'
            height='16px'
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};
export default Header;

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    left: theme.spacing(0),
    right: theme.spacing(0),
    zIndex: '100',
  },
}));
