import { action } from 'easy-peasy';
import { urlMap } from '../utils/constants';
import {
  getOnboardingStatus,
  getOnboardingPrevUrl,
} from '../services/onboardingService';

const defaultNavBar = {
  title: '',
  showBack: false,
  showBell: true,
  showDialogOnBack: false,
  showWomenWillCourse: false,
  topRightButton: '',
  topLeftButton: '',
  hideNavBar: false,
  showSearchBar: false,
  showCommunityIcon: false,
  showCommunityShareIcon: false,
  toggleDrawerIcon: false,
  showCommunitySideBar: true,
  showBottomNavbar: false,
  showMenuSideBar: true,
  topSpace: '0px',
  rightSideBar: null,
  disableCustomSideBarGrid: false,
  showCustomRightSideBar: true,
  showFeedOnBack: false,
};

const isOnboardingDone = getOnboardingStatus();

export default {
  options: defaultNavBar,
  setTitle: action((state, payload) => {
    state.options.title = payload;
  }),
  setOptions: action((state, payload) => {
    state.options = { ...defaultNavBar, ...payload };
  }),
  notificationsCount: null,
  handleGoBack: action((state, { history, lastLocation, goBack = true }) => {
    if (goBack) {
      if (isOnboardingDone) {
        if (lastLocation && lastLocation.pathname) {
          history.goBack();
        } else {
          history.replace(urlMap.FEED);
        }
      } else {
        getOnboardingPrevUrl(history);
      }
    }
  }),
};
