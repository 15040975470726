import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import ReplyIcon from '@material-ui/icons/Reply';

const CommentReply = ({ comment, openReplies }) => {
  const classes = useStyles();
  return (
    <Typography
      component="p"
      variant="caption"
      className={classes.replySection}
      onClick={(e) => openReplies(e, comment.commentId)}
    >
      <ReplyIcon className={classes.replyIcon} /> View {comment.repliesCount}
      {comment.repliesCount === 1 ? ' reply' : ' replies'}
    </Typography>
  );
};
const useStyles = makeStyles((theme) => ({
  replySection: {
    display: 'flex',
    alignItems: 'center',
    color: '#2993e6',
    cursor: 'pointer',
  },
  replyIcon: {
    transform: 'rotate(180deg)',
    marginRight: theme.spacing(1),
  },
}));
export default CommentReply;
