import { useEffect } from 'react';
import { convertDateToUTC } from '../utils/dateUtil';
import { postToFollowerObj } from '../mappers/communityMapper';
import { strings } from '../utils/constants';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useLoggedInUser } from './userHooks';
import { themePostConfig } from '../utils/constants';
import analytics from '../services/analyticsService';
import { eventNames, eventProps } from '../../src/utils/eventConstants';
import pollService from '../services/pollService';
import { getUserMentionObj } from '../utils/editorUtil';
import { getUrlFromText } from '../utils/textUtil';
import communityService from '../services/communityService';
import { getQueryParam } from '../utils/routeUtils';
import branch from 'branch-sdk';
const _ = require('lodash');

function useCreateOrEditPostRequest(post) {
  const user = useLoggedInUser();
  const setErrorMessage = useStoreActions(
    (actions) => actions.snackBar.setMessage
  );
  const { setDialogBoxOpen } = useStoreActions((actions) => actions.dialogBox);
  const {
    setPostCreatorType,
    setHandleDeleteItem,
    setSelectedCommunity,
    checkThemePostStatus,
    setPostDescription,
    setRichLinkData,
    setUserMentions,
    setIsBranchLink,
    setBranchData,
  } = useStoreActions((state) => state.createPost);
  let {
    postCreatorType,
    selectedTheme,
    isThemeChange,
    isThemePost,
    files,
    postDescription,
    ogTitle,
    ogImageUrl,
    ogRequestedUrl,
    isOgVideoLink,
    ogDescription,
    userMentions,
    isBranchLink,
    branchData,
  } = useStoreState((state) => state.createPost.postObject);
  const branchKey = process.env.REACT_APP_BRANCH_KEY;
  const branchMatchId = getQueryParam('_branch_match_id');
  const isEditablePost = post ? true : false;

  function handleUserAnonymity(event) {
    const checked = event.target.checked;
    setPostCreatorType(checked ? 'Anonymous' : user.name);
  }

  // clicked on post as community toggle button
  function handlePostAsCommunity(event) {
    const checked = event.target.checked;
    setPostCreatorType(checked ? 'Community' : user.name);
  }

  /**
   * Author: Shubham Gupta
   * Date: 1 Nov 2022
   * branch.init method is used to fetch data of the current branch url clicked
   */
  useEffect(() => {
    branch.init(branchKey, function (err, data) {
      const { data_parsed } = data;
      setBranchData(data_parsed);
      setIsBranchLink(branchMatchId === null ? false : true);
    });
  }, [branchKey, branchMatchId, setBranchData, setIsBranchLink]);

  if (
    isEditablePost &&
    post &&
    post.userMentions &&
    userMentions &&
    userMentions.length === 0
  ) {
    userMentions = post.userMentions;
  }
  userMentions = getUserMentionObj(
    userMentions,
    postDescription || (post && post.description),
    isEditablePost
  );
  //}
  let branchThemeId = branchData?.theme_id;
  if (post && post.isThemePost && !isThemeChange) {
    isThemePost = post.isThemePost;
    selectedTheme = {
      themeImageUrl: post.themeImageUrl,
      themeTextColor: post.themePostDescriptionColor,
      themeTextFontSize: post.themePostDescriptionFontSize,
    };
  } else if (isThemeChange && selectedTheme !== null) {
    isThemePost = isThemeChange;
    selectedTheme = {
      themeImageUrl: selectedTheme.themeImageUrl,
      themeTextColor: selectedTheme.themeTextColor,
      themeTextFontSize: themePostConfig.themeTextFontSize,
    };
  } else if (!post && isBranchLink) {
    isThemePost = true;
    selectedTheme = communityService.getThemeById(branchThemeId);
  }
  const buildRequestBody = ({
    scheduleDate,
    post,
    selectedTheme,
    isThemePost,
    selectedCommunity,
    deletedImageIds,
    userMentions,
    isPollPost,
    pollObject,
    deleteVideoIds,
  }) => {
    const postId = (post && post.entityId) || '';
    const userId = user && user.id;
    const userParticipantId = user && user.userParticipantId;
    const schedulePostISODate = scheduleDate && convertDateToUTC(scheduleDate);
    const themePostRequest = {
      theme_image_url: selectedTheme && selectedTheme.themeImageUrl,
      theme_text_color: selectedTheme && selectedTheme.themeTextColor,
      theme_text_font_size: selectedTheme && selectedTheme.themeTextFontSize,
      is_theme_post: isThemePost,
    };
    const postToFollowersRequest = selectedCommunity.id ===
      postToFollowerObj[0].id && {
      recipient_participant_id: userParticipantId,
      recipient_id: userId,
    };

    const linkRenderRequest = ogRequestedUrl && {
      og_title_s: ogTitle,
      og_image_url_s: ogImageUrl,
      og_requested_url_s: ogRequestedUrl,
      is_og_video_link_b: isOgVideoLink,
      og_description_s: ogDescription,
    };
    let postRequestBody = {
      community_id: selectedCommunity.id,
      creator_type:
        postCreatorType === strings.ANONYMOUS
          ? 'ANONYMOUS'
          : postCreatorType === strings.COMMUNITY
          ? 'COMMUNITY_OWNER'
          : post?.author?.name === strings.ADMIN && isEditablePost
          ? 'COMMUNITY_OWNER'
          : 'USER',
      description:
        isEditablePost && !postDescription ? post.description : postDescription,
      id: postId,
      is_active: isEditablePost ? true : false,
      crdt: schedulePostISODate ? schedulePostISODate : '',
      has_mentions: userMentions && userMentions.length > 0 ? true : false,
      user_mentions: userMentions && [...userMentions],
      ...postToFollowersRequest,
      ...linkRenderRequest,
    };
    if (isPollPost && !isEditablePost) {
      const pollEndedDate = new Date();
      if (
        pollObject &&
        pollObject.pollEnded &&
        pollObject.pollEnded === '1 Day'
      ) {
        pollEndedDate.setDate(pollEndedDate.getDate() + 1);
      } else if (
        pollObject &&
        pollObject.pollEnded &&
        pollObject.pollEnded === '2 Days'
      ) {
        pollEndedDate.setDate(pollEndedDate.getDate() + 2);
      } else if (
        pollObject &&
        pollObject.pollEnded &&
        pollObject.pollEnded === '1 Week'
      ) {
        pollEndedDate.setDate(pollEndedDate.getDate() + 7);
      } else if (
        pollObject &&
        pollObject.pollEnded &&
        pollObject.pollEnded === '1 Month'
      ) {
        pollEndedDate.setMonth(pollEndedDate.getMonth() + 1);
      }
      const originalDate = new Date(pollEndedDate);
      const toISOString = originalDate.toISOString().split('.')[0];
      postRequestBody.ends_at =
        pollObject && pollObject.pollEnded && toISOString + 'Z';
      postRequestBody.poll_options = pollObject && pollObject.pollOptions;
      postRequestBody.type = pollObject && pollObject.pollType;
    }

    postRequestBody = themePostRequest.is_theme_post
      ? { ...postRequestBody, ...themePostRequest }
      : postRequestBody;
    if (isEditablePost)
      postRequestBody.delete_image_id = deletedImageIds.map((id) => id + '');
    postRequestBody.delete_video_ids = deleteVideoIds;
    return postRequestBody;
  };

  function validateCreatePost(
    selectedCommunity,
    files,
    postDescription,
    isPollPost,
    pollObject,
    setCreatePostLoading
  ) {
    if (!selectedCommunity.id) {
      setErrorMessage('Please choose the community to post in');
      setDialogBoxOpen(false);
      setCreatePostLoading(false);

      return false;
    }
    if ((postDescription && postDescription.length <= 1) || !postDescription) {
      setErrorMessage(
        `Please Enter Something in ${isPollPost ? 'Question' : 'Description'}`
      );
      setDialogBoxOpen(false);
      setCreatePostLoading(false);
      return false;
    }
    if (isPollPost && pollObject && pollObject.pollOptions) {
      if (
        pollObject &&
        pollObject.pollType &&
        pollObject.pollType === 'IMAGE'
      ) {
        const imgValidOptions =
          pollObject.pollOptions?.length > 0 &&
          _.every(
            pollObject.pollOptions,
            (obj) =>
              _.has(obj, 'image_url') &&
              !_.isEmpty(obj.image_url) &&
              !_.isEmpty(obj.description)
          );
        if (!imgValidOptions) {
          setErrorMessage('Options should not empty.');
          setDialogBoxOpen(false);
          setCreatePostLoading(false);
          return false;
        }
      } else {
        const textValidOptions =
          pollObject.pollOptions &&
          pollObject.pollOptions.length > 0 &&
          _.every(
            pollObject.pollOptions,
            (obj) => obj && obj.description && obj.description !== ''
          );
        if (!textValidOptions) {
          setErrorMessage('Options should not empty.');
          setDialogBoxOpen(false);
          setCreatePostLoading(false);
          return false;
        }
      }
    }
    if (!selectedCommunity.id || (!files.length && !postDescription)) {
      setDialogBoxOpen(false);
      setCreatePostLoading(false);
      return false;
    }

    if (
      postCreatorType === 'Anonymous' &&
      selectedCommunity.id === postToFollowerObj[0].id
    ) {
      setErrorMessage('Cannot post to your followers anonymously.');
      setDialogBoxOpen(false);
      setCreatePostLoading(false);
      return false;
    }
    setDialogBoxOpen(false);
    setCreatePostLoading(true);
    return true;
  }
  function createPostAnalyticsTrack({
    selectedCommunity,
    postDescription,
    postCreatorType,
    createPostRequestBody,
  }) {
    analytics.track(
      isEditablePost ? eventNames.postEdited : eventNames.postCreated,
      {
        [eventProps.communityId]: selectedCommunity.id,
        [eventProps.communityName]: selectedCommunity.name,
        [eventProps.description]: postDescription,
        [eventProps.postCreatorType]: postCreatorType,
        [eventProps.language]: 'en',
        [eventProps.postType]: createPostRequestBody.is_theme_post
          ? 'Theme Post'
          : 'Normal Post',
      }
    );
  }
  const handleDeleteItem = (payload) => {
    setHandleDeleteItem(payload);
  };
  const handleSelectCommunity = (community) => {
    setSelectedCommunity({
      id: community.id,
      name: community.name,
      isCommunityOwner: community.isCommunityOwner,
    });
  };
  const handleSetPostDescription = (value) => {
    setPostDescription(value);
    const renderUrl = getUrlFromText(value);
    if (renderUrl) {
      renderLinkUrl(renderUrl);
    } else {
      setRichLinkData({ ogResponseData: '' });
    }
    checkThemePostStatus({ files, selectedTheme, postDescription });
  };
  /**
   *
   * @param {string} url
   *  Call API for render Link url and set to Rich Link Data
   */
  async function renderLinkUrl(url) {
    try {
      const res = url && (await pollService.getRichLinkData(url));
      const ogResponseData =
        res?.status === 'SUCCESS'
          ? {
              ogTitle: res.og_title_s,
              ogImageUrl: res.og_image_url_s,
              ogRequestedUrl: res.og_requested_url_s,
              isOgVideoLink: res.is_og_video_link_b,
              ogDescription: res.og_description_s,
            }
          : {
              ogTitle: '',
              ogImageUrl: '',
              ogRequestedUrl: '',
              isOgVideoLink: '',
              ogDescription: '',
            };
      setRichLinkData({ ogResponseData });
    } catch (e) {
      return e;
    }
  }
  /**
   *
   * @param {object} payload
   * handle set user mention object in state
   */
  const handleSetUserMention = (payload) => {
    setUserMentions(payload);
  };

  /**
   *
   * @param {state} editorState
   * @param {type} context
   * set Editor State from editor and set user mention and post
   */
  function handleSetEditorState(content, mentionData, context) {
    if (context === 'POST') {
      handleSetUserMention(mentionData); //set user Mention Object
      content && handleSetPostDescription(content); //set post description
    }
  }
  return {
    buildRequestBody,
    handleUserAnonymity,
    handlePostAsCommunity,
    selectedTheme,
    isThemePost,
    validateCreatePost,
    files,
    setErrorMessage,
    createPostAnalyticsTrack,
    handleDeleteItem,
    handleSelectCommunity,
    handleSetPostDescription,
    handleSetUserMention,
    handleSetEditorState,
    renderLinkUrl,
    userMentions,
  };
}
export { useCreateOrEditPostRequest };
