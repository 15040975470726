import GwwWelcomeScreen from '../scenes/gwwWelcomeScreen';
import { lazyPreload } from '../utils/lazyUtil';

const Gender = lazyPreload(() => import('../components/onboarding/gender'));
const MaleRestricted = lazyPreload(() =>
  import('../components/onboarding/maleRestricted')
);
const Interest = lazyPreload(() => import('../components/onboarding/interest'));
const UpdateUserProfile = lazyPreload(() =>
  import('../components/onboarding/updateUserProfile')
);
const GenderVerification = lazyPreload(() =>
  import('../components/onboarding/genderVerification')
);
const OnboardingRoute = lazyPreload(() =>
  import('../components/common/onboardingRoute')
);
const WebcamCapture = lazyPreload(() =>
  import('../components/onboarding/webCamCapture')
);
const YourSelf = lazyPreload(() => import('../scenes/yourSelf'));
const QuizScreen = lazyPreload(() => import('../scenes/quizScreen'));
const SurveyScreen = lazyPreload(() => import('../scenes/surveyScreen'));

const Language = lazyPreload(() => import('../scenes/language'));
const WomenWillStarted = lazyPreload(() =>
  import('../components/onboarding/womenWillStarted')
);
const GlobalLanguage = lazyPreload(() =>
  import('../components/onboarding/global_language')
);

const TNC = lazyPreload(() => import('../components/home/tnc'));
const ImageNotVerified = lazyPreload(() =>
  import('../components/onboarding/imageNotVerified')
);
const SaireeDigitalCard = lazyPreload(() =>
  import('../components/home/saireeDigitalCard')
);
const WelcomeScreen = lazyPreload(() =>
  import('../components/onboarding/welcomeScreen')
);

export default [
  {
    route: OnboardingRoute,
    path: '/onboarding/gender',
    component: Gender,
  },
  {
    path: '/onboarding/interest',
    component: Interest,
  },
  {
    path: '/onboarding/male-restricted',
    component: MaleRestricted,
  },
  {
    path: '/onboarding/gender-verification',
    component: GenderVerification,
  },
  {
    path: '/onboarding/selfie',
    component: WebcamCapture,
  },
  {
    path: '/update-user-profile',
    component: UpdateUserProfile,
  },
  {
    path: '/onboarding/introduce-yourself',
    component: YourSelf,
  },
  {
    path: '/onboarding/language',
    component: Language,
  },
  {
    path: '/language_selection',
    component: GlobalLanguage,
  },
  {
    path: '/onboarding/global-language',
    component: GlobalLanguage,
  },
  {
    path: '/onboarding/womenwill-started',
    component: WomenWillStarted,
  },
  {
    path: '/lecture/:lectureId/quiz/:quizId',
    component: QuizScreen,
  },
  {
    path: '/lecture/:lectureId/survey/:quizId',
    component: SurveyScreen,
  },
  {
    path: '/home/tnc',
    component: TNC,
  },
  {
    path: '/onboarding/image-not-verified',
    component: ImageNotVerified,
  },
  {
    path: '/onboarding/welcome',
    component: WelcomeScreen,
  },
  {
    path: '/onboarding/gww-welcome',
    component: GwwWelcomeScreen,
  },
  {
    path: '/sairee',
    component: SaireeDigitalCard,
  },
];
