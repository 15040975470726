import React from 'react';
import { lazyPreload } from '../utils/lazyUtil';
import ArticleDetail from '../scenes/articleDetail'; // Don't lazy load article detail to improve speed for SEO
import SheroesMagazine from '../scenes/sheroesMagazine';
const PostDetail = lazyPreload(() => import('../scenes/postDetail'));
const CommunityDetail = lazyPreload(() => import('../scenes/communityDetail'));
const Articles = lazyPreload(() => import('../scenes/articles'));
const MenuScene = lazyPreload(() => import('../scenes/menuScene'));
const UsersChampionsList = lazyPreload(() =>
  import('../components/user/usersChampionsList')
);
const PrivacyAndPolicy = lazyPreload(() =>
  import('../components/home/privacyAndPolicy')
);
//Path for logout user page access
export default [
  {
    path: '/articles/:articleTitleSlug/:articleId',
    component: ArticleDetail,
  },
  {
    path: '/communities/:communityNameSlug/:communityId/:postId',
    component: PostDetail,
  },
  {
    path: '/communities/:communityNameSlug/:communityId',
    render: (props) => (
      <CommunityDetail {...props} setOrderName="CommunityFeed" />
    ),
  },
  {
    path: '/articles/category/:categorySlug/:categoryId',
    component: Articles,
  },
  {
    path: '/articles',
    component: Articles,
  },
  {
    path: '/articles/trending',
    component: Articles,
  },
  {
    path: '/menu',
    component: MenuScene,
  },
  {
    path: '/sheroes-magazine/10',
    component: SheroesMagazine,
  },
  {
    path: '/champions',
    component: UsersChampionsList,
  },
  {
    path: '/Home/policy',
    component: PrivacyAndPolicy,
  },
];
