function entityMasterMap(docs) {
  return docs.map((item, index) => {
    return { id: item.id, title: item.title };
  });
}
function entitySkillMasterMap(docs) {
  return docs.map((item, index) => {
    return { value: item.id, label: item.title };
  });
}
export { entityMasterMap, entitySkillMasterMap };
