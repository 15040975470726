import React from 'react';
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  CardMedia,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Loader from '../common/loader';
import AdapterLink from '../common/adapterLink';
import { ellipsize } from '../../utils/textUtil';
import imgParams from '../../utils/imageUtil';
import { useTablet } from '../../hooks/utilHooks';

const SimilarArticles = ({ similarData }) => {
  const classes = useStyles();
  const isTablet = useTablet();

  return (
    <React.Fragment>
      {similarData && similarData.length === 0 ? <Loader key={1} /> : ''}
      {similarData && similarData.length > 0 && (
        <Box>
          <Typography variant="subtitle2">Similar Articles</Typography>

          <List className={classes.root}>
            {similarData.map((item, index) => (
              <ListItem
                key={index}
                alignItems="flex-start"
                to={item.slug}
                component={AdapterLink}
                className={classes.itemMain}
              >
                <Box bgcolor="#dcdcdc" className={classes.mediaMain}>
                  <CardMedia
                    className={classes.media}
                    image={imgParams(item.articleImageUrl, 720)}
                  />
                </Box>

                <ListItemText
                  primary={
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary"
                    >
                      {ellipsize(item.title, isTablet ? 60 : 25)}
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        variant="caption"
                        className={classes.itemDate}
                        color="textPrimary"
                        align={isTablet ? 'right' : 'left'}
                      >
                        {item.createdAt}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </React.Fragment>
  );
};
const useStyles = makeStyles((theme) => ({
  root: {
    height: '275px',
    overflowY: 'auto',
  },
  mediaMain: {
    width: '100px',
    height: '70px',
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(4),
  },
  media: {
    width: '100px',
    height: '100%',
    borderRadius: theme.spacing(1),
  },
  itemMain: {
    borderBottom: '1px solid #dcdcdc',
    paddingLeft: theme.spacing(0),
  },
  itemDate: {
    display: 'block',
    marginTop: theme.spacing(1),
  },
  themeImage: {
    backgroundSize: 'cover!important',
    position: 'relative',
  },
}));
export default SimilarArticles;
