import http from './httpService';
import reverse from 'lodash/reverse';
import { postMap, commentMap } from '../mappers/modelMapper';
import { chapterMap } from '../mappers/lectureMapper';
import apiUrl from '../utils/apiUrl';
import { maxPageSize, validVideoFormats } from '../utils/constants';
import { default as _each } from 'lodash/each';
import { getPostLanguage } from './authService';
import language from '../utils/languages';

const webLanguage = getPostLanguage();
const languageText = language[webLanguage ? webLanguage : 'en'];

async function getBlockedAccounts() {
  const { data } = await http.get(apiUrl.BLOCKED_ACCOUNTS_LIST_URL);
  return data;
}

async function getPost(postId, postSubType, isLecture) {
  let api = '';
  let requestBody = {};
  if (postSubType === 'PL') {
    api = apiUrl.GET_USER_POLL + postId;
    requestBody = {};
  }
  // else if (postSubType === 'SHOP') {
  //   api = apiUrl.GET_SHOP_PRODUCT_DETAIL;
  //   requestBody = [postId];
  // }
  else if (isLecture || postSubType === 'CH') {
    api = apiUrl.LECTURE_DETAILS + postId;
  } else {
    api = apiUrl.GET_POST;
    requestBody = {
      id_of_entity_or_participant: postId,
      sub_type: postSubType,
      source: 'web',
    };
  }
  const { data } =
    isLecture || postSubType === 'CH'
      ? await http.get(api)
      : await http.post(api, requestBody);
  //if (data[0].status === 'SUCCESS') {
  if (
    postSubType === 'SHOP' &&
    data &&
    data[0] &&
    data[0].status === 'SUCCESS'
  ) {
    const productItem = {
      productId: data[0]?.productResponse?.id,
      productPrice: data[0]?.productResponse?.price,
      productTotalAmount: data[0]?.productResponse?.total_amount,
      productDeliveryCost: data[0]?.productResponse?.delivery_cost,
    };

    const productResponse = data[0].doc;
    const allProductMap = { ...productResponse, ...productItem };

    const postData = postMap(allProductMap);
    return postData;
    // ? shopProductMap(shopResponseData)
    // : postMap(data.docs[0]);
  } else if (postSubType === 'CH' && data?.status === 'SUCCESS') {
    return chapterMap(data);
  } else {
    return postMap(data.docs[0]);
  }
  //}
  //return postMap(postSubType === 'SHOP' ? data[0].doc : data.docs[0]);
}

async function deletePost(postId, setToastMessage) {
  try {
    const { data } = await http.post(apiUrl.DELETE_POST, {
      id: postId,
      sub_type: 'P',
    });
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
    } else {
      return data.status;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}

async function getPostComments({
  postEntityId,
  pageNum,
  lectureEntityId,
  isLecture,
  setToastMessage,
}) {
  try {
    let { data } = await http.post(apiUrl.GET_POST_COMMENTS, {
      entity_id: isLecture ? lectureEntityId : postEntityId,
      page_no: pageNum,
      page_size: maxPageSize,
    });
    const docsData = data && data.docs && data.docs.map(commentMap);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return reverse(docsData);
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}

async function saveComment(
  postId,
  comment,
  userAnonymity,
  commentUserMention,
  parentParticipationId,
  lectureEntityId,
  isLecture,
  isReplies,
  setToastMessage
) {
  try {
    const { data } = await http.post(
      isLecture && !isReplies
        ? apiUrl.ADD_LECTURE_COMMENT
        : apiUrl.SAVE_COMMENT,
      {
        entity_id: postId,
        comment: comment,
        is_anonymous: isLecture && !isReplies ? false : userAnonymity,
        has_mentions:
          isLecture && !isReplies
            ? false
            : commentUserMention.length > 0
            ? true
            : false,
        user_mentions:
          isLecture && !isReplies
            ? null
            : commentUserMention && [...commentUserMention],
        parent_participation_id:
          isLecture && !isReplies ? 0 : parentParticipationId,
      }
    );
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return commentMap(data.participation_model);
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}
async function deleteComment(postId, commentId, setToastMessage) {
  try {
    const { data } = await http.post(apiUrl.DELETE_POST_COMMENT, {
      participation_id: commentId,
      entity_id: postId,
    });
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return { status: 'FAILED' };
    } else {
      return commentMap(data.participation_model);
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return { status: 'FAILED' };
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return { status: 'FAILED' };
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return { status: 'FAILED' };
    }
  }
}

async function reportSpam(
  docObj,
  spamInfoObj,
  userId,
  contentType,
  setToastMessage
) {
  try {
    const spamReqObj = {
      community_id: docObj.communityId,
      model_id: docObj.docId,
      model_type: contentType.toUpperCase(),
      reason: spamInfoObj.reason,
      reported_by_user_id: userId,
      reported_on_user_id: docObj.docAuthorId,
      score: spamInfoObj.score,
    };
    const { data } = await http.post(apiUrl.REPORT_SPAM, spamReqObj);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}

async function savePost(savePostObj, files, isVideoPost, setToastMessage) {
  const splitFileFormat =
    files && files.length > 0 && files[0].type && files[0].type.split('/');

  const fileSizeBytes = files && files.length && files[0].size;
  const fileSize = (fileSizeBytes && parseInt(fileSizeBytes / 1024 ** 2)) || 0;

  let postApi = '';
  if (savePostObj.id) {
    if (isVideoPost) {
      postApi = apiUrl.EDIT_VIDEO_POST;
    } else {
      postApi = apiUrl.EDIT_POST;
    }
  } else {
    if (isVideoPost) {
      postApi = apiUrl.VIDEO_POST;
    } else {
      postApi = apiUrl.CREATE_POST;
    }
  }
  const reqData = new FormData();
  _each(files, function (file) {
    reqData.append('file', file);
  });
  reqData.append('postContent', JSON.stringify(savePostObj));

  try {
    const { data } = await http.post(postApi, reqData);
    if (data && data.status === 'FAILED') {
      if (
        splitFileFormat &&
        splitFileFormat.length > 0 &&
        !validVideoFormats.includes(splitFileFormat[1]) &&
        data.error_message === 'Error in uploading user post videos'
      ) {
        const formattedFormats = validVideoFormats
          .map((format) => ` ${format}`)
          .join(',');

        setToastMessage(
          `File format is not supported. It should be ${formattedFormats}.`
        );
      } else if (isVideoPost && fileSize > 45) {
        setToastMessage('Video file size should be less than 45 MB.');
      } else if (fileSize > 10) {
        setToastMessage('Image file size should not be more than 10 MB.');
      } else {
        setToastMessage &&
          setToastMessage(
            data.error_message
              ? data.error_message
              : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
          );
      }
      return null;
    } else {
      return postMap(data.doc);
    }
  } catch (ex) {
    if (isVideoPost) {
      if (
        splitFileFormat &&
        splitFileFormat.length > 0 &&
        !validVideoFormats.includes(splitFileFormat[1]) &&
        ex &&
        ex.error_message === 'Error in uploading user post videos'
      ) {
        const formattedFormats = validVideoFormats
          .map((format) => ` ${format}`)
          .join(',');

        setToastMessage(
          `File format is not supported. It should be ${formattedFormats}.`
        );
      } else if (fileSize > 45) {
        setToastMessage('Video file size should be less than 45 MB.');
      }
    } else if (fileSize > 10) {
      setToastMessage('Image file size should not be more than 10 MB.');
    } else if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}

async function approveOrDeletePost(
  entityId,
  isSpam,
  isActive,
  isApproved,
  setToastMessage
) {
  try {
    const { data } = await http.post(apiUrl.APPROVE_POST, {
      id: entityId,
      is_spam: isSpam,
      is_active: isActive,
      is_approved: isApproved,
    });
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data.status;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}
async function featurePost(isTopPost, entityId, setToastMessage) {
  try {
    const { data } = await http.post(apiUrl.FEATURE_POST, {
      user_post_id: entityId,
      is_top_post_b: isTopPost,
    });
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}
async function getReplyOnComments(postEntityId, commentId, pageNum) {
  let { data } = await http.post(apiUrl.GET_REPLY_COMMENT, {
    entity_id: postEntityId,
    parent_participation_id: commentId,
    page_no: pageNum,
    is_spam_comment: false,
    page_size: maxPageSize,
  });
  data = data.docs.map(commentMap);
  return reverse(data);
}

async function getPostReactions(postId, pageNum, setToastMessage) {
  try {
    let { data } = await http.post(apiUrl.GET_REACTIONS, {
      entity_id: postId,
      page_no: pageNum,
    });
    data = data.docs.map(commentMap);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return reverse(data);
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}

async function getHomePagePost(postId, postSubType) {
  let api = '';
  let requestBody = {};
  if (postSubType === 'PL') {
    api = apiUrl.GET_USER_POLL + postId;
    requestBody = {};
  } else {
    api = apiUrl.GET_POST;
    requestBody = {
      id_of_entity_or_participant: postId,
      sub_type: postSubType,
      source: 'web',
    };
  }
  const { data } = await http.post(api, requestBody);

  if (
    postSubType === 'SHOP' &&
    data &&
    data[0] &&
    data[0].status === 'SUCCESS'
  ) {
    const productItem = {
      productId: data[0]?.productResponse?.id,
      productPrice: data[0]?.productResponse?.price,
      productTotalAmount: data[0]?.productResponse?.total_amount,
      productDeliveryCost: data[0]?.productResponse?.delivery_cost,
    };

    const productResponse = data[0].doc;
    const allProductMap = { ...productResponse, ...productItem };

    const postData = postMap(allProductMap);
    return postData;
  } else {
    return data.status === 'SUCCESS' && postMap(data.docs[0]);
  }
}
async function getPostCommentsById(commentId) {
  const commentApiUrl = apiUrl.GET_COMMENT_BY_ID + commentId;
  let { data } = await http.post(commentApiUrl, {
    is_reply: false,
    page_no: 1,
    page_size: 20,
  });
  data.docs = data && commentMap(data.docs[0]);
  return reverse(data);
}
async function editComment(
  isAnonymous,
  isCommentActive,
  postEntityId,
  description,
  participantId,
  commentId,
  isLecture,
  isReplies,
  commentUserMention,
  setToastMessage
) {
  const req = {
    entity_id: postEntityId,
    is_active: isCommentActive,
    is_anonymous: isAnonymous,
    comment: description,
    participation_id: commentId,
    participantId: participantId,
    has_mentions:
      isLecture && !isReplies
        ? false
        : commentUserMention.length > 0
        ? true
        : false,
    user_mentions:
      isLecture && !isReplies
        ? null
        : commentUserMention && [...commentUserMention],
  };
  try {
    const { data } = await http.post(apiUrl?.EDIT_COMMENT_POST, req);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return commentMap(data.participation_model);
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}
async function savePollPost(savePostObj, setToastMessage) {
  try {
    const { data } = await http.post(apiUrl.POLL_POST, savePostObj);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data && data.doc && postMap(data.doc);
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}
async function convertBlobIntoSheroesImageUrl(
  imageUrl,
  setToastMessage,
  imgHeight,
  fileSizeInMB
) {
  try {
    const { data } = await http.post(apiUrl.GLOBAL_IMAGE_ADD, {
      images: [imageUrl],
      page_no: 1,
      page_size: 10,
    });

    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(
          fileSizeInMB > 5.1
            ? 'Image size should be less than 5 MB.'
            : imgHeight >= 6000
            ? 'Image height is too large.'
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    }
  }
}
export default {
  getPost,
  saveComment,
  getPostComments,
  deleteComment,
  deletePost,
  reportSpam,
  savePost,
  approveOrDeletePost,
  featurePost,
  getReplyOnComments,
  getPostReactions,
  getHomePagePost,
  getPostCommentsById,
  getBlockedAccounts,
  editComment,
  savePollPost,
  convertBlobIntoSheroesImageUrl,
};
