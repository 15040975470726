import { action } from 'easy-peasy';

export default {
  value: 0,
  courseTab: 0,
  setTabValue: action((state, payload) => {
    state.value = payload;
  }),
  setCourseTab: action((state, payload) => {
    state.courseTab = payload;
  }),
};
