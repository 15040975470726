import http from './httpService';
import APIURL from '../utils/apiUrl';
import { lectureDetailMap } from '../mappers/lectureMapper';
import apiUrl from '../utils/apiUrl';
import { getQueryParam } from '../utils/routeUtils';
import { getPostLanguage } from './authService';
import language from '../utils/languages';

const webLanguage = getPostLanguage();
const languageText = language[webLanguage ? webLanguage : 'en'];

async function getLectureDetail(id) {
  const { data } = await http.get(APIURL.LECTURE_DETAILS + id);
  return data.chapter && lectureDetailMap(data.chapter);
}

async function getLearnOrCourseData(courseId, isBonusModule, language) {
  const tab = getQueryParam('tab');
  const splitData = window.location.pathname.split('/');
  const isFeed = splitData[1] === 'feed';
  let api;
  if (tab === 'learn') {
    api = apiUrl.COURSE_PROGRESS + courseId + '&isBonusModule=' + isBonusModule;
  } else if (tab === 'course' || tab === 'gww' || isFeed) {
    api = apiUrl.COURSE_PROGRESS + courseId;
  }
  const { data } = await http.get(api, {
    headers: {
      'X-Language': language,
    },
  });
  return data;
}

async function updateQuizStatus(id, option, actionType, isSurvey) {
  try {
    const { data } = await http.post(APIURL.UPDATE_QUIZ_STATUS, {
      quizId: id,
      selectedOption: actionType === 'skip' ? null : option,
      isSurvey: !isSurvey ? false : true,
    });
    if (data.status === 'SUCCESS') {
      return data;
    }
  } catch (error) {
    return error.message;
  }
}

async function updateVideoStatus(
  lectureId,
  watchTime,
  language,
  setToastMessage
) {
  try {
    const { data } = await http.post(
      APIURL.UPDATE_VIDEO_STATUS,
      {
        lectureId: lectureId,
        videoWatchTime: watchTime,
      },
      {
        headers: {
          'X-Language': language,
        },
      }
    );
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}

// view certificate
async function viewCertificate(userId, courseId, setToastMessage) {
  const req = {
    courseId: courseId,
    userId: userId,
  };
  try {
    const { data } = await http.post(APIURL.VIEW_CERTIFICATE, req);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}

function getRequestData(feedStreamApi, isGetRequest) {
  let requestBody, streamApi, apiRequest;

  if (isGetRequest) {
    streamApi = feedStreamApi;
    apiRequest = http.get(streamApi, requestBody);
  } else {
    streamApi = feedStreamApi;
    apiRequest = http.post(streamApi, requestBody);
  }
  return { streamApi, requestBody, apiRequest };
}
async function getDashboardData(feedStreamApi, isGetRequest) {
  const { apiRequest } = getRequestData(feedStreamApi, isGetRequest);
  const { data } = await apiRequest;
  return data;
}
async function getQuestions(Qtype) {
  const { data } = await http.get(
    APIURL.FACILITATOR_DASHBOARD_DETAILS + Qtype + '&pageNo=0'
  );
  return data;
}

export default {
  getLectureDetail,
  updateQuizStatus,
  getDashboardData,
  updateVideoStatus,
  getQuestions,
  viewCertificate,
  getLearnOrCourseData,
};
