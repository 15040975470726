import { onboardingSteps, urlMap } from '../utils/constants';
import { getThirdPartyUser } from './authService';

const userGenderKey = 'userGender';
const onboardingStatusKey = 'onboardingStatus';
const completedStepKey = 'onboardingCompletedTillStep';
const onboardingBlurEffectKey = 'onboardingBlurEffect';
const onboardingSlideIndexKey = 'onboardingSlideIndex';

export function setOnboardingSlideIndex(response) {
  localStorage.setItem(onboardingSlideIndexKey, response);
}
export function getOnboardingSlideIndex() {
  const slideIndex = localStorage.getItem(onboardingSlideIndexKey);
  return slideIndex ? Number(slideIndex) : 0;
}
export function setOnboardingBlurEffect(response) {
  localStorage.setItem(onboardingBlurEffectKey, response);
}
export function getOnboardingBlurEffect() {
  const isBlurEffect = localStorage.getItem(onboardingBlurEffectKey);
  return isBlurEffect === 'true' || isBlurEffect === true ? true : false;
}
export function setOnboardingStatus(isOnboardingDone, responseData) {
  localStorage.setItem(onboardingStatusKey, isOnboardingDone);
  if (!isOnboardingDone) {
    if (
      responseData?.nextScreen ===
      'intro-gender_verification-community_onboarding'
    ) {
      localStorage.setItem(completedStepKey, -1);
    } else if (responseData?.nextScreen === 'intro-gender_verification') {
      localStorage.setItem(completedStepKey, 0);
    } else if (responseData?.nextScreen === 'gender_verification') {
      localStorage.setItem(completedStepKey, 1);
    } else if (responseData?.nextScreen === 'feed') {
      localStorage.setItem(completedStepKey, 2);
    } else {
      localStorage.setItem(completedStepKey, -1);
    }
  }
}

export function getOnboardingStatus() {
  try {
    const onboardingStr = localStorage.getItem(onboardingStatusKey);
    return JSON.parse(onboardingStr);
  } catch (ex) {
    return null;
  }
}
export function setUserGender(response) {
  localStorage.setItem(userGenderKey, response);
}
export function getUserGender() {
  const userGender = localStorage.getItem(userGenderKey);
  return userGender;
}

export function getCompletedStepIndex() {
  const completedStep = localStorage.getItem(completedStepKey) || -1;
  return parseInt(completedStep);
}

export function moveToNextStep(history, state) {
  const userFromMM = getThirdPartyUser();

  const completedStep = state.isUserLandingFirstTime
    ? getCompletedStepIndex() + 1
    : getCompletedStepIndex();
  localStorage.setItem(completedStepKey, completedStep);
  const completedStatus = getOnboardingStatus();
  const nextStep = completedStep + 1;
  if (nextStep === onboardingSteps.length - 1) {
    setOnboardingStatus(true);
  }
  const currentLocation = window.location.pathname;
  const currentScreenIndex = onboardingSteps.indexOf(currentLocation) + 1;
  if (userFromMM) {
    history.push(urlMap.ONBOARDING_INTO_YOURSELF, { isThirdPartyUrl: true });
  } else {
    history.push(
      state.previousScreen && state.previousScreen === 'selfieVerification'
        ? urlMap.ONBOARDING_WELCOME
        : completedStatus
        ? urlMap.FEED
        : onboardingSteps[currentScreenIndex],
      {
        state,
      }
    );
  }

  const fromLocation = history.location.pathname;
  completedStatus && fromLocation === urlMap.FEED && window.location.reload();
  return null;
}

export function getOnboardingPrevUrl(history) {
  const currentPath = window.location.pathname;
  const isSelfiePath = currentPath === urlMap.ONBOARDING_SELFIE;
  const isMaleRestricted = currentPath === urlMap.MALE_RESTRICTED;
  const currentPathIndex = onboardingSteps.indexOf(currentPath) - 1;

  if (isSelfiePath) {
    history.replace(urlMap.ONBOARDING_GENDER_VERIFICATION);
  } else if (isMaleRestricted) {
    history.replace(urlMap.LOGOUT);
  } else if (currentPathIndex < 0) {
    // if onboarding is not done it'll redirect to pending onboarding step.
    history.goBack();
  } else if (onboardingSteps.includes(currentPath)) {
    history.push(onboardingSteps[currentPathIndex]);
  } else {
    history.goBack();
  }
}

export default {
  setOnboardingStatus,
  getOnboardingStatus,
  setUserGender,
  getUserGender,
  moveToNextStep,
  getCompletedStepIndex,
  getOnboardingPrevUrl,
};
