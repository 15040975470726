import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import CustomButton from './customButton';
import useReactRouter from 'use-react-router';
import { getPostLanguage } from '../../services/authService';
import languages from '../../utils/languages';

const EmailMobileLogin = () => {
  const classes = useStyles();
  const { history } = useReactRouter();
  const webLanguage = getPostLanguage();
  const languageText = languages[webLanguage ? webLanguage : 'en'];

  const handleOpenMobileEmail = () => {
    setTimeout(() => {
      history.push({ pathname: '/mobile-email-form' });
    }, 1000);
  };

  return (
    <React.Fragment>
      <Box>
        <CustomButton
          variant="primary"
          color="primary"
          size="large"
          onClick={handleOpenMobileEmail}
          className={`${classes.btn} loginBtn`}
          text={languageText.CONTINUE_WITH_EMAIL_MOBILE_NO}
        />
      </Box>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  btn: {
    padding: '0.688rem 0 !important',
    '&:hover': {
      background: '#dc4541 !important',
      color: '#fff !important',
    },
  },
}));
export default EmailMobileLogin;
