import React from 'react';
import { Box, makeStyles } from '@material-ui/core';

const NotFound = ({ icon, title, description }) => {
  const classes = useStyles();

  return (
    <Box bgcolor="#fff" align="center" width="100%" py={10}>
      <Box>
        <img className={classes.imageSize} src={icon} alt="not found" />
      </Box>
      <Box>{title}</Box>
      <Box>{description}</Box>
    </Box>
  );
};

const useStyles = makeStyles({
  imageSize: {
    width: '80px',
    height: '80px',
  },
});
export default NotFound;
