import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Box,
} from '@material-ui/core';
import CustomButton from '../common/customButton';
import AdapterLink from '../common/adapterLink';
import defaultCommunityIcon from '../../img/default-community-icon.svg';
import imgParams from '../../utils/imageUtil';
import { useTablet } from '../../hooks/utilHooks';
import Loader from '../../components/common/loader';
import { ellipsize } from '../../utils/textUtil';
import { getPostLanguage } from '../../services/authService';
import languages from '../../utils/languages';
import { useGetMyCommunities } from '../../hooks/communityHooks';
import NotFound from '../common/notFound';

const MyCommunitiesDrawer = () => {
  const listOfCommunities = useGetMyCommunities();
  const classes = useStyles();
  const isTablet = useTablet();
  const webLanguage = getPostLanguage();
  const languageText = languages[webLanguage ? webLanguage : 'en'];

  return (
    <List className={classes.scrollSet}>
      <Typography
        variant="subtitle2"
        display="block"
        align="center"
        className={classes.communityHeading}
      >
        {languageText?.ID_MY_COMMUNITIES}
      </Typography>
      <Box className={!isTablet && classes.listMain}>
        <Box width={isTablet ? 1 : 0.84} ml={!isTablet ? 4 : ''}>
          {!listOfCommunities ? (
            <Loader />
          ) : listOfCommunities && listOfCommunities.length > 0 ? (
            listOfCommunities.map((myCommunity, index) => (
              <ListItem
                key={index}
                className={classes.listItem}
                to={myCommunity.communitySlug}
                component={AdapterLink}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={myCommunity.logoAltText}
                    src={imgParams(myCommunity.thumbnailImageUrl, 60, 60)}
                    className={classes.setCommunityLogo}
                  />
                </ListItemAvatar>
                <ListItemText
                  color="primary"
                  primary={
                    <Typography
                      variant="overline"
                      display="block"
                      color="textPrimary"
                    >
                      {ellipsize(myCommunity.name, 17)}
                    </Typography>
                  }
                />
                {myCommunity &&
                myCommunity.totalNewPostNumber &&
                myCommunity.totalNewPostNumber > 0 ? (
                  <Typography
                    style={{
                      padding:
                        myCommunity.totalNewPostNumber > 0 &&
                        myCommunity.totalNewPostNumber < 10
                          ? '2px 7px'
                          : '3px 4px',
                    }}
                    variant="overline"
                    className={classes.newPostNo}
                  >
                    {myCommunity.totalNewPostNumber > 98
                      ? '99+'
                      : myCommunity.totalNewPostNumber}
                  </Typography>
                ) : (
                  ''
                )}
              </ListItem>
            ))
          ) : (
            <NotFound
              icon={defaultCommunityIcon}
              title={'No communities found'}
            />
          )}
          <Box className="clearfix"></Box>
        </Box>
      </Box>
      <CustomButton
        to="/communities"
        component={AdapterLink}
        variant={isTablet ? 'primary' : ''}
        color="primary"
        text={languageText?.EXPLORE_ALL}
        size="regular"
        className={
          isTablet
            ? `${classes.allCommunityBtn} stickToBottom`
            : classes.allCommunityBtn
        }
      ></CustomButton>
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  listMain: {
    width: '110%',
    height: '200px',
    overflowY: 'auto',
  },
  listItem: {
    width: '50%',
    height: theme.spacing(27),
    textAlign: 'center',
    display: 'inherit',
    float: 'left',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  setCommunityLogo: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0px auto',
    width: theme.spacing(13),
    height: theme.spacing(13),
    border: ' 1px solid #f1f1f1',
    borderRadius: '50%',
  },
  communityHeading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(7),
  },
  allCommunityBtn: {
    width: '272px',
    left: 'inherit!important',
    right: theme.spacing(0),
    borderRadius: 'inherit',
  },
  newPostNo: {
    position: 'absolute',
    top: theme.spacing(2),
    border: '1px solid #fff',
    background: theme.palette.primary.main,
    color: 'white',
    right: '30px',
    borderRadius: '50%',
  },
  scrollSet: {
    width: theme.spacing(68),
  },
}));
export default MyCommunitiesDrawer;
