import merge from 'lodash/merge';
import { useStoreState } from 'easy-peasy';
import {
  getCurrentUser,
  setCurrentUser,
  setIsShowWWFeed,
} from '../services/authService';
import { useSnackBar } from './snackBarHooks';
import { useEffect, useState } from 'react';
import { getFirstAndLastName } from '../utils/userUtil';
import { setOnboardingStatus } from '../services/onboardingService';
import { urlMap } from '../utils/constants';
import { useHistory } from 'react-router-dom';
import profileService from '../services/profileService';
import { makeStyles } from '@material-ui/core';

function useLoggedInUser() {
  return useStoreState((state) => state.user.user);
}

function useYourSelf() {
  const history = useHistory();
  const user = getCurrentUser();
  const isThirdPartyUrl = history?.location?.state?.isThirdPartyUrl;
  const setToastMessage = useSnackBar();
  const [radioValue, setRadioValue] = useState(
    user && user.questionnaireAns ? user.questionnaireAns : ''
  );
  const [inputValue, setInputValue] = useState(
    user && user.name ? user.name : ''
  );
  const [showLoader, setShowLoader] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);
  const [isFocused, setIsFocused] = useState(user && user.name ? true : false);
  function handleOnBlur(type) {
    setIsFocused(inputValue ? true : false);
  }

  function handleOnFocus(type) {
    setIsFocused(true);
  }
  const handleSaveYourSelf = async () => {
    setCurrentStep(2);
    setShowLoader(true);
    const getName = inputValue && getFirstAndLastName(inputValue);
    const firstName = getName.firstName;
    const lastName = getName.lastName;

    const yourselfReq = {
      questionnaireAns: radioValue,
      firstName: firstName,
      lastName: lastName,
    };

    const data = await profileService.editProfile(yourselfReq, setToastMessage);
    const updateObj = {
      firstName: firstName,
      lastName: lastName,
      name: firstName + ' ' + lastName,
      questionnaireAns: radioValue,
      id: user.id,
    };
    if (data && data.status) {
      const updateUserObj = merge(user, updateObj);
      setCurrentUser(updateUserObj);
      setShowLoader(false);

      if (isThirdPartyUrl) {
        setIsShowWWFeed('true');
        setOnboardingStatus(true);
        document.location = urlMap.ONBOARDING_GWW_WELCOME;
      } else {
        data.nextScreen === 'feed_blt' &&
          history.push(urlMap.ONBOARDING_GWW_WELCOME, { user });
      }
    }
  };
  const handleChange = (e) => {
    setRadioValue(e.target.value);
  };

  const handleChangeInput = (e, type) => {
    const value = e.target.value.trimStart();
    if (type === 'name') setInputValue(value);
  };
  useEffect(() => {
    if (!radioValue || !inputValue) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [radioValue, inputValue]);

  const useStyles = makeStyles((theme) => ({
    textFiled: {
      color: '#dc4541',
      fontSize: theme.spacing(3),
      fontWeight: 'normal',
      margin: theme.spacing(0, 0, 3, 0),
    },
    submitBtn: {
      backgroundColor: '#019d53',
      borderRadius: theme.spacing(6),
      '&:hover': {
        backgroundColor: '#019d53',
      },
    },

    checkIcon: {
      color: '#019d53',
      border: '1px solid #878787',
      borderRadius: '50%',
    },
    loaderStyle: {
      position: 'absolute',
      background: 'rgba(0,0,0,0.1)',
      left: '0',
      right: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      top: '0',
      width: '100%',
    },
    cssFocused: {
      fontWeight: '600 !important',
    },
    cssRoot: {
      fontWeight: 500,
    },
    underline: {
      '&::after': {
        borderBottom: '1px solid #747474 !important',
      },
      '&::before': {
        borderBottom: '0.5px solid #C3C1C1 !important',
      },
    },
  }));
  const classes = useStyles();

  return {
    disabled,
    showLoader,
    inputValue,
    handleOnBlur,
    isFocused,
    handleOnFocus,
    handleChangeInput,
    currentStep,
    handleSaveYourSelf,
    handleChange,
    radioValue,
    classes,
  };
}
export { useLoggedInUser, useYourSelf };
