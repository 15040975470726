import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import welcomeHindi from '../img/onboarding/hindi.svg';
import women_will from '../img/onboarding/women-will.png';
import google_sheroes from '../img/onboarding/google-sheroes.png';
import LoaderBlue from '../img/onboarding/loader-blue.png';
import india_map from '../img/onboarding/india-map.png';
import { useMobile } from '../hooks/utilHooks';
import { getPostLanguage } from '../services/authService';
import { urlMap } from '../utils/constants';
import moEngage from '../services/moEngageService';
import { useLastLocation } from 'react-router-last-location';
import { getPreviousLocation } from '../utils/screenUtils';
import { getSpentTime } from '../services/authService';
import { screenNamesSet } from '../utils/screenUtils';

function GwwWelcomeScreen() {
  const history = useHistory();
  const isMobile = useMobile();
  const language = getPostLanguage();
  const lastLocation = useLastLocation();
  const sourceName = getPreviousLocation(lastLocation);
  const spentTime = getSpentTime();
  moEngage.trackScreenOpen(
    sourceName,
    screenNamesSet.gwwWelcomeScreen,
    spentTime
  );
  setTimeout(() => {
    history.push(urlMap.FEED);
  }, 3000);

  return (
    <React.Fragment>
      <Box
        style={{ position: 'absolute', left: '0%', right: '0%' }}
        bgcolor="white"
        height="100vh"
      >
        <Box width={isMobile ? '100%' : '50%'} m="0px auto" px={4}>
          <Box textAlign="center" mb={4}>
            {language === 'hi' && (
              <img src={welcomeHindi} alt="welcome in hindi" width="60%" />
            )}
            <Typography variant="h6">
              Your Free Business Course Starts Now!
            </Typography>

            <Box mt={4}>
              <Typography variant="h6">Learn & Earn,</Typography>
              <Typography variant="h6">Get Google Certificate</Typography>
            </Box>

            <Box mt={2.5}>
              <img
                src={LoaderBlue}
                alt="women will"
                width={isMobile ? '18%' : '6%'}
              />{' '}
            </Box>
            <Box>
              <img
                src={women_will}
                alt="women will"
                width={isMobile ? '90%' : '40%'}
              />
            </Box>
            <Box my={4}>
              <img
                src={google_sheroes}
                alt="google sheroes"
                width={isMobile ? '90%' : '40%'}
              />
            </Box>
            <img
              src={india_map}
              alt="india map"
              width={isMobile ? '80%' : '40%'}
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
export default GwwWelcomeScreen;
