import React, { useEffect, useState } from 'react';
import logo from '../img/article_detailed.gif';
import {
  Box,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import Loader from '../components/common/loader';
import { useGetPost } from '../hooks/postHooks';
import PostEngagement from '../components/post/postEngagement';
import { makeStyles } from '@material-ui/styles';
import { useNavBar } from '../hooks/navBarHooks';
import { useScreenOpenTracker } from '../hooks/analyticsHook';
import imgParams from '../utils/imageUtil';
import BorderLine from '../components/common/borderLine';
import articleService from '../services/articleService';
import SimilarArticles from '../components/article/similarArticles';
import { ReactComponent as CommentIcon } from '../img/comment.svg';
import { scrollToPosition } from '../utils/postUtil';
import { useTablet, useDimensions } from '../hooks/utilHooks';
import MetaData from '../components/common/metaData';
import ArticleDetailHeader from '../components/article/articleDetailHeader';
import ArticleDetailCardContent from '../components/article/articleDetailCardContent';
import ArticleSchema from '../components/post/articleSchema';
import { Redirect } from 'react-router-dom';
import {
  getLoginSource,
  getPostLanguage,
  getSpentTime,
} from '../services/authService';
import languages from '../utils/languages';
import MoengageService from '../services/moEngageService';
import { useLastLocation } from 'react-router-last-location';
import { getPreviousLocation, screenNamesSet } from '../utils/screenUtils';
import authService from '../services/authService';
import ArticleDetailImage from '../components/common/articleDetailImage';

const ArticleDetail = ({ match, location }) => {
  const user = authService.getCurrentUser();
  const [similarData, setSimilarData] = useState([]);
  const { width, ref } = useDimensions();
  const webLanguage = getPostLanguage();
  const languageText = languages[webLanguage ? webLanguage : 'en'];
  const spentTime = getSpentTime();
  const postEntityId = atob(decodeURIComponent(match.params.articleId));
  const lastLocation = useLastLocation();
  const sourceName = getPreviousLocation(lastLocation);

  useNavBar({
    showBack: true,
    rightSideBar: (
      <>
        <Box style={{ backgroundColor: '#f5f5f5' }}>
          <Box
            p={3}
            style={{
              backgroundColor: 'white',
              marginBottom: '13px',
              borderRadius: '15px',
            }}
          >
            <SimilarArticles similarData={similarData} />
          </Box>
          <Box>
            <ArticleDetailImage
              imageArticleDetail={logo}
              linkOfImage={
                'https://sheroes.com/register?psid=MQ==&source=SHEROESArticles'
              }
            />
          </Box>
        </Box>
      </>
    ),
  });

  const postId = location.state && location.state.postId;

  const postSubType = 'A';
  const focusComment = location.focusComment;
  const classes = useStyles();
  const { post } = useGetPost(postId, postEntityId, postSubType);
  MoengageService.trackArticle(
    sourceName,
    screenNamesSet.articleDetail,
    spentTime,
    user,
    post && post.articleCategoryName,
    postEntityId
  );
  const articleEntityId = post && post.id;
  const articleRef = React.useRef();

  useScreenOpenTracker(
    {
      screenName: 'Article Details Screen',
      id: postEntityId,
      title: post && post.title,
    },
    post ? true : false
  );
  const handleClickToScroll = (event) => {
    scrollToPosition(articleRef);
  };

  useEffect(() => {
    async function getSimilarArticles() {
      try {
        const similarArticles = await articleService.getSimilarContent(
          'A',
          articleEntityId
        );
        setSimilarData(similarArticles);
      } catch (error) {
        setSimilarData(null);
      }
    }
    articleEntityId && getSimilarArticles();
  }, [articleEntityId]);

  const isTablet = useTablet();
  const loginSource = getLoginSource();

  if (post && !post.isActive) {
    return <Redirect to="/not-found" />;
  }

  return (
    <React.Fragment>
      {post && (
        <MetaData
          title={post.metaTitle || post.title}
          description={post.metaDescription || post.title}
          ogUrl={post.deepLinkUrl}
          ogImageUrl={post.articleImageUrl}
          // ampUrl={post.ampUrl}
        />
      )}

      {isTablet && !loginSource === 'paytm' && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={'https://shrs.me/Kt29cXYDY5'}
        >
          <Box color="black" bgcolor="yellow" py={1.5} align="center">
            Download the app.
          </Box>
        </a>
      )}

      <Box width={1} maxWidth="sm" mb={20} mt={2} bgcolor="white">
        {post && !post.id ? <Loader key={1} /> : ''}
        {post && post.id && (
          <React.Fragment>
            <ArticleDetailHeader post={post} languageText={languageText} />
            <Box mt={3} px={4}>
              <img
                ref={ref}
                className={classes.media}
                src={imgParams(post.articleImageUrl, width)}
                alt={post.title}
              />
            </Box>
            <ArticleDetailCardContent post={post} />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  src={imgParams(
                    post.author && post.author.imageUrl,
                    40,
                    40,
                    'auto'
                  )}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="body2" color="textPrimary">
                    {post.author && post.author.name}
                  </Typography>
                }
                secondary={
                  <Typography variant="subtitle1" color="textSecondary">
                    {post.author && post.author.description}
                  </Typography>
                }
              />
            </ListItem>
            <Box
              px={4}
              py={4}
              display="flex"
              alignItems="center"
              onClick={handleClickToScroll}
              ref={articleRef}
            >
              <CommentIcon className={classes.setCommentIcon} />
              <Typography variant="body2">
                {isTablet ? 'See comments' : 'Comments'}
              </Typography>
            </Box>
            {isTablet && (
              <Box px={4}>
                <SimilarArticles similarData={similarData} />
              </Box>
            )}
            <BorderLine color="grey.600" />
            <Box ref={articleRef} />
            <PostEngagement
              isPostDetail={false}
              focusComment={focusComment}
              post={post}
              isArticleDetail={true}
            />
            {post && <ArticleSchema post={post} />}
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  media: {
    width: '100%',
    height: 'auto',
    borderRadius: theme.spacing(3),
  },
  setCommentIcon: {
    marginRight: theme.spacing(2),
  },
}));

export default ArticleDetail;
