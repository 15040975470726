import { lazyPreload } from '../utils/lazyUtil';
const CreatePost = lazyPreload(() => import('../scenes/createpost'));
const CreatePoll = lazyPreload(() =>
  import('../components/createPost/createPoll')
);
const PostDetail = lazyPreload(() => import('../scenes/postDetail'));
const ArticleDetail = lazyPreload(() => import('../scenes/articleDetail'));
const Replies = lazyPreload(() => import('../scenes/replies'));

const routes = [
  {
    path: '/create-post',
    component: CreatePost,
  },
  {
    path: '/create-poll',
    component: CreatePoll,
  },
  {
    path: '/edit-post',
    component: CreatePost,
  },
  {
    path: '/communities/:communityNameSlug/:communityId/polls/:postId',
    component: PostDetail,
  },
  {
    path: '/stories/:articleTitleSlug/:articleId',
    component: ArticleDetail,
  },
  {
    path: '/communities/:communityNameSlug/:communityId/:postId/replies/:commentId',
    component: Replies,
  },
  {
    path: '/communities/:communityNameSlug/:communityId/polls/:postId/replies/:commentId',
    component: Replies,
  },
  {
    path: '/articles/:articleTitleSlug/:articleId/replies/:commentId',
    component: Replies,
  },
  {
    path: '/lecture/:postId/replies/:commentId',
    component: Replies,
  },
];
export default routes;
