import React from 'react';
import { useNavBar } from '../hooks/navBarHooks';
import { ReactComponent as SheroesLogo } from '../img/logo.svg';
import { useTablet } from '../hooks/utilHooks';
import { Box, makeStyles, Typography } from '@material-ui/core';
import NotFoundImage from '../img/404.svg';
import AnyLink from './common/anyLink';
import { useLastLocation } from 'react-router-last-location';
import { getPreviousLocation, screenNamesSet } from '../utils/screenUtils';
import { getSpentTime } from '../services/authService';
import MoengageService from '../services/moEngageService';

const NotFound = () => {
  const isTablet = useTablet();
  const classes = useStyles();
  const lastLocation = useLastLocation();
  const sourceName = getPreviousLocation(lastLocation);
  const spentTime = getSpentTime();
  MoengageService.trackScreenOpen(
    sourceName,
    screenNamesSet.notFound,
    spentTime
  );
  useNavBar({
    title: isTablet && <SheroesLogo />,
    showBottomNavbar: true,
  });

  return (
    <React.Fragment>
      <Box
        bgcolor="white"
        borderRadius="16px"
        padding={4}
        mb={20}
        height="752px"
      >
        <div align="center" width="100%">
          <Typography variant="h6">
            The page you're looking for is unavailable
          </Typography>
          <Box>
            <img
              src={NotFoundImage}
              alt="not found"
              height={isTablet ? '200px' : '250px'}
              width={isTablet ? '300px' : '400px'}
            />
          </Box>
        </div>
        <ul style={{ padding: '16px' }}>
          <Typography variant="body2">In the meantime you can </Typography>
          <Box mt={3}>
            <li>
              <Typography display="inline" align="left" variant="body1">
                Go Home{' '}
                <span role="img" aria-label="house">
                  🏠
                </span>
              </Typography>
              <Typography display="inline" variant="body2">
                <AnyLink className={classes.linkColor} to="/">
                  &nbsp;&nbsp;www.sheroes.com
                </AnyLink>
              </Typography>
            </li>
            <li>
              <Box mt={3}></Box>
              <Typography display="inline" align="left" variant="body1">
                See what's happening in
              </Typography>
              <Typography display="inline" variant="body2">
                <AnyLink className={classes.linkColor} to="/communities">
                  &nbsp;SHEROES communities
                </AnyLink>
              </Typography>
            </li>
            <li>
              <Box mt={3}></Box>
              <Typography display="inline" align="left" variant="body1">
                Read
              </Typography>
              <Typography display="inline" variant="body2">
                <AnyLink className={classes.linkColor} to="/articles">
                  &nbsp;inspiring articles&nbsp;
                </AnyLink>
              </Typography>
              <Typography display="inline">about women.</Typography>
            </li>
            <li>
              <Box mt={3}></Box>
              <Typography display="inline" align="left" variant="body1">
                Read what women in
              </Typography>
              <Typography display="inline" variant="body2">
                <AnyLink className={classes.linkColor} to="/">
                  &nbsp;SHEROES&nbsp;
                </AnyLink>
              </Typography>
              <Typography display="inline" align="left" variant="body1">
                are talking about.
              </Typography>
            </li>
            <li>
              <Box mt={3}></Box>
              <Typography display="inline" align="left" variant="body1">
                Talk to someone privately, ping us on
              </Typography>
              <Typography display="inline" variant="body2">
                <AnyLink className={classes.linkColor} to="/helpline">
                  &nbsp;SHEROES helpline&nbsp;
                </AnyLink>
              </Typography>
            </li>
            <li>
              <Box mt={3}></Box>
              <Typography display="inline" align="left" variant="body1">
                Know more about
              </Typography>
              <Typography display="inline" variant="body2">
                <AnyLink
                  className={classes.linkColor}
                  to="https://www.mahila.money/"
                >
                  &nbsp;mahila money&nbsp;
                </AnyLink>
              </Typography>
              <Typography display="inline" align="left" variant="body1">
                to attain financial freedom.
              </Typography>
            </li>
            <li>
              <Box mt={3}>
                <Typography display="inline" variant="body1" align="left">
                  Become a MARS partner and get access to work from home online
                  jobs. Click here
                </Typography>
                <Typography display="inline" variant="body2">
                  <AnyLink
                    className={classes.linkColor}
                    to="https://marsbysheroes.com/"
                  >
                    &nbsp;MARS
                  </AnyLink>
                </Typography>
              </Box>
            </li>
            <li>
              <Box mt={3}></Box>
              <Typography display="inline" align="left" variant="body1">
                SHOPonSHEROES has been discontinued until further notice.
              </Typography>
            </li>
          </Box>
        </ul>
      </Box>
    </React.Fragment>
  );
};

const useStyles = makeStyles({
  listDot: {
    width: '8px',
    height: '8px',
    margin: '23px 12px 29px 0',
    backgroundColor: '#353535',
  },
  linkColor: {
    color: '#dc4541!important',
    textDecoration: 'none',
  },
});

export default NotFound;
