function followedMentorMapper(data) {
  const docs = data && data.docs;
  const userObj =
    docs &&
    docs.map((doc) => ({
      isRequestSent: doc && doc.solr_ignore_is_friend_request_sent_to,
      receivedFriendRequestId:
        doc && doc.solr_ignore_received_friend_request_id,
      isFriend: doc && doc.solr_ignore_is_friend,
      isFollowed: doc && doc.solr_ignore_is_user_followed,
      participantTypeId: doc && doc.entity_or_participant_type_id_i,
      isAuthorMentor:
        doc &&
        (doc.solr_ignore_is_author_mentor ||
          doc.entity_or_participant_type_id_i === 7),
      isRequestReceived: doc && doc.solr_ignore_is_friend_request_received_from,
      participantId: doc && doc.id_of_entity_or_participant,
      userDeeplinkUrl: doc && doc.solr_ignore_user_deep_link_url,
      sheroesDeeplink: doc && doc.sheroes_deep_link_s,
      mentorFollowersCount: doc && doc.solr_ignore_no_of_mentor_followers,
      location: doc && doc.location_s,
      cityName: doc && doc.city_name_s,
      mentorDeeplinkUrl: doc && doc.solr_ignore_mentor_deep_link_url,
      profileImageUrl: doc && doc.image_url,
      userName: doc && doc.name,
      showProfileBadge: doc && doc.solr_ignore_show_profile_badge,
      profileBadgeUrl: doc && doc.solr_ignore_profile_badge_url,
      profileString: doc && doc.solr_ignore_custom_profile_string,
    }));
  return data && data.status && data.status === 'FAILED' ? data : userObj;
}
function friendListDataMap(data) {
  const docs = data && data.docs && data.docs;
  const nextToken = data && data.next_token && data.next_token;
  let obj = [];
  const response =
    docs &&
    docs.map((doc) => ({
      isFollowed: doc && doc.solr_ignore_is_user_followed,
      userDeepLinkUrl: doc && doc.solr_ignore_deep_link_url,
      sheroesDeeplink: doc && doc.sheroes_deep_link_s,
      mentorDeeplinkUrl: doc && doc.solr_ignore_mentor_deep_link_url,
      location: doc && doc.location_s,
      cityName: doc && doc.city_name_s,
      entityId: doc && doc.id_of_entity_or_participant,
      name: doc && doc.name,
      type: doc && doc.type,
      imageUrl: doc && doc.image_url,
      profileString: doc && doc.solr_ignore_custom_profile_string,
    }));
  obj.push(response);
  obj.push(nextToken);
  return docs === null ? null : obj;
}
//This mapper is for mapiing the data for champions from champions response docs.
function championsDataMap(data) {
  const docs = data && data.docs;
  const championsObj = docs.map((doc) => ({
    isRequestSent: doc && doc.solr_ignore_is_friend_request_sent_to,
    receivedFriendRequestId: doc && doc.solr_ignore_received_friend_request_id,
    isFriend: doc && doc.solr_ignore_is_friend,
    isFollowed: doc && doc.solr_ignore_is_user_followed,
    participantTypeId: doc && doc.entity_or_participant_type_id_i,
    isAuthorMentor:
      doc &&
      (doc.solr_ignore_is_author_mentor ||
        doc.entity_or_participant_type_id_i === 7),
    isRequestReceived: doc && doc.solr_ignore_is_friend_request_received_from,
    participantId: doc && doc.id_of_entity_or_participant,
    sheroesDeeplink: doc && doc.sheroes_deep_link_s,
    mentorFollowersCount: doc && doc.solr_ignore_no_of_mentor_followers,
    location: doc && doc.location_s,
    cityName: doc && doc.city_name_s,
    mentorDeeplinkUrl: doc && doc.solr_ignore_mentor_deep_link_url,
    profileImageUrl: doc && doc.image_url,
    userName: doc && doc.name,
    showProfileBadge: doc && doc.solr_ignore_show_profile_badge,
    profileBadgeUrl: doc && doc.solr_ignore_profile_badge_url,
    profileString: doc && doc.solr_ignore_custom_profile_string,
  }));
  return docs === null ? null : championsObj;
}
export { followedMentorMapper, friendListDataMap, championsDataMap };
