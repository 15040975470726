import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { testimonialObj } from '../../utils/homeUtils';
import TestimonialCarouselBuilt from './testimonialCarouselBuilt';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const TestimonialCarousel = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  function handleStepChange(step) {
    setActiveStep(step);
  }

  return (
    <React.Fragment>
      <Box>
        <AutoPlaySwipeableViews
          index={activeStep}
          onChangeIndex={handleStepChange}
        >
          {testimonialObj &&
            testimonialObj.map((item, index) => (
              <Box key={index}>
                <Box display="flex" alignItems="center" my={2}>
                  <Typography variant="body2">Ratings: </Typography>
                  <Typography className={classes.starIcon}>
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                  </Typography>
                </Box>
                <Typography variant="body1" className={classes.description}>
                  {item.description}
                </Typography>

                <Box>
                  <List>
                    <ListItem className={classes.listItem}>
                      <ListItemAvatar>
                        <Avatar>
                          <img
                            src={item.authorUrl}
                            alt={item.authorName}
                            width="40px"
                            height="40px"
                          />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography variant="body2">
                            {item.authorName}
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Box>
              </Box>
            ))}
        </AutoPlaySwipeableViews>
      </Box>
      <TestimonialCarouselBuilt
        testimonialObj={testimonialObj}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    </React.Fragment>
  );
};
export default TestimonialCarousel;

const useStyles = makeStyles((theme) => ({
  starIcon: {
    color: '#ffbf12',
    margin: theme.spacing(1, 0, 0, 2),
  },
  description: {
    borderBottom: '2px solid #dc4541',
    paddingBottom: theme.spacing(2),
  },
  listItem: {
    padding: theme.spacing(0),
  },
}));
