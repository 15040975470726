import apiUrl from '../utils/apiUrl';
import thirdPartyService from './thirdPartyService';
const branchKey = process.env.REACT_APP_BRANCH_KEY;

async function parseBranchLink(link) {
  const branchGetApi =
    apiUrl.GET_BRANCH_LINK_DATA + link + '&branch_key=' + branchKey;
  const { data } = await thirdPartyService.get(branchGetApi);
  return data;
}

function setBranchData(data) {
  localStorage.setItem('branchData', JSON.stringify(data));
}
function getBranchData() {
  const branchData = JSON.parse(localStorage.getItem('branchData'));
  return branchData;
}

export default { parseBranchLink, setBranchData, getBranchData };
