import { basePostMap } from './modelMapper';
import { getPostLanguage } from '../services/authService';
import languages from '../utils/languages';
import { stripDomain } from '../utils/postUtil';

const webLanguage = getPostLanguage();
const languageText = languages[webLanguage ? webLanguage : 'en'];
function chapterMap(docs) {
  const chapterObj = {
    subType: docs.subType,
    chapterTitle: docs.title,
    showActionsPostCompletion: docs?.showActionsPostCompletion,
    courseId: docs.courseId,
    description: docs.description,
    id: docs.id,
    imageUrl: docs.imageUrl,
    isActive: docs.isActive,
    lecture: lectureMap(docs),
    status: docs.status,
  };
  return basePostMap(docs, chapterObj);
}
function lectureMap(docs) {
  const lectureOptions = docs.lecture;
  return (
    lectureOptions &&
    lectureOptions.map((option) => ({
      lectureId: option.id,
      quizUrl: option?.quiz
        ? '/lecture/' + btoa(option.id) + '/quiz/' + btoa(option?.quiz[0]?.id)
        : '',
      title: option.title,
      description: option.description,
      isBonus: docs?.isBonus,
      video: option.video,
      lectureEntityId: option.entityId,
      status: option.status,
      minCompleteDuration: option.minCompleteDuration,
      deepLinkUrl: '/lecture/' + btoa(option?.id),
      quiz: option.quiz,
      skipQuiz: option?.skipQuiz,
      nextLectureId: option.nextLectureId,
      nextLectureUrl:
        option?.nextLectureId !== null && option?.nextLectureId !== undefined
          ? '/lecture/' + btoa(option?.nextLectureId)
          : null,
      nextChapterId: option.nextChapterId,
      videoWatchTime: option.videoWatchTime,
    }))
  );
}

function lectureDetailMap(docs) {
  const chapterObj = {
    subType: docs.subType,
    chapterTitle: docs.title,
    courseId: docs.courseId,
    description: docs.description,
    id: docs.id,
    imageUrl: docs.imageUrl,
    isActive: docs.isActive,
    lecture: lectureMap(docs),
    status: docs.status,
  };
  return basePostMap(docs, chapterObj);
}
function filterSectionName(data, isCourse, isBonus) {
  const firstChapter = {}; //this key is for handling this case if lastWatched key is not available in response
  const getSections = [];
  if (data?.chapters !== undefined) {
    let sectionName = [];
    // filterize the sectionName
    data &&
      data.chapters &&
      data.chapters.map((chapter) => {
        if (isCourse) {
          if (
            sectionName.length === 0 ||
            !sectionName.includes(chapter?.sectionName)
          ) {
            sectionName.push(chapter?.sectionName);
          }
        } else {
          sectionName.push(chapter?.title);
        }
        return {};
      });

    // filterize the all sections
    sectionName.map((section, i) => {
      let wholeChapters = [];
      data.chapters.map((chapter) => {
        if (isCourse && chapter?.sectionName === section) {
          chapter.lecture.map((lecture) => {
            firstChapter.chapterId = chapter?.id;
            firstChapter.lectureId = lecture?.id;
            return {};
          });
          wholeChapters.push(chapter);
        } else if (isBonus) {
          wholeChapters.push(chapter);
        }
        return {};
      });
      if (isCourse) {
        getSections.push({
          sectionName: section,
          index: i,
          videoWatchCount: data?.videoWatchCount,
          minVideoWatchCount: data?.minVideoWatchCount,
          chapter: wholeChapters,
          lastWatched:
            data?.lastWatched === null || data?.lastWatched === undefined
              ? firstChapter
              : data?.lastWatched,
        });
      } else {
        getSections.push({
          title: section,
          chapter: wholeChapters,
        });
      }
      return {};
    });
  }
  return getSections;
}
function videoMapper(doc) {
  const videoObj = {
    sourceVideoUrl: doc.source_video_url_s,
    videoUrl: doc.video_url_s,
    videoThumbnailUrl: doc.video_thumbnail_url_s,
    isVideoStreamingEnabled: doc.is_video_streaming_enabled_b,
  };
  return videoObj;
}
function carouselMapper(doc) {
  let carouselObj = {};
  if (doc.solr_ignore_list_of_base_or_participant_model.length) {
    const carouselCardSubType =
      doc.solr_ignore_list_of_base_or_participant_model[0].sub_type;
    const carouselId = doc.sub_type.toLowerCase() + '-' + carouselCardSubType;

    carouselObj = {
      cards:
        doc.solr_ignore_list_of_base_or_participant_model.map(carouselCardMap),
      id: carouselId,
      cardType: carouselCardSubType,
    };
  }
  return carouselObj;
}

function carouselCardMap(card) {
  return {
    imageUrl: card.image_url || card.thumbnail_image_url,
    displayTextImageUrl:
      card && card.display_text_image_url && card.display_text_image_url[0],
    id: card.id,
    communityName: card && card.solr_ignore_post_community_name,
    authorName: card && card.author_name,
    authorId: card && card.author_id,
    description: card && card.description,
    authorImageUrl: card && card.author_image_url,
    deepLinkUrl: stripDomain(card && card.solr_ignore_deep_link_url),
    videoUrl: card && card.video_url_s,
    videoThumbnailUrl: card && card.video_thumbnail_url_s,
    subType: card && card.sub_type,
    isShop: card && card.is_shop_b,
    name: card && card.name,
    leaderboardCommunityName:
      card && card.solr_ignore_leaderboard_community_name,
    profileBadgeUrl: card && card.solr_ignore_profile_badge_url,
    customProfileString: card && card.solr_ignore_custom_profile_string,
    showProfileBadge: card.solr_ignore_show_profile_badge,
    cardId: card.id_of_entity_or_participant,
    isVideoLocked: card.isVideoLocked,
    lockedMessage: card.lockedMessage,
    userTypeId: card.entity_or_participant_type_id_i,
    btnText:
      card.entity_or_participant_type_id_i === 7
        ? languageText.FOLLOW
        : languageText.ADD_FRIEND,
    isAuthorMentor:
      card &&
      (card.solr_ignore_is_author_mentor ||
        card.entity_or_participant_type_id_i === 7),
    isFollowed: card && card.solr_ignore_is_user_followed,
    isAuthorFriend: card && card.solr_ignore_is_author_friend,
    isRequestReceived: card && card.solr_ignore_is_friend_request_received_from,
    isFriend: card && card.solr_ignore_is_friend,
    isRequestSent: card && card.solr_ignore_is_friend_request_sent_to,
    userId: card && card.entity_or_participant_id,
    participantId: card && card.id_of_entity_or_participant,
  };
}
export {
  chapterMap,
  filterSectionName,
  lectureDetailMap,
  videoMapper,
  carouselMapper,
};
