import {
  dateFromTimeStamp,
  DATE_FIRST_FORMAT_WITHOUT_TIME,
  getDateFormatByValue,
} from '../utils/dateUtil';

const moEngage = {
  initialize: function () {
    (function () {
      const appID =
        window.location.hostname === 'sheroes.com'
          ? 'HXHWI3FD69RY5YOAA69IIYS3'
          : 'HXHWI3FD69RY5YOAA69IIYS3_DEBUG';
      const debugMode = window.location.hostname === 'sheroes.com' ? 0 : 1;
      // const debugMode = 1;
      // console.log("appID :-", appID);
      // console.log("MOE Debug Mode:-", debugMode);
      window.Moengage = window.moe({
        app_id: appID,
        debug_logs: debugMode,
        cluster: 'DC_3',
      });
      //const Moengage = window.Moengage;
    })();
  },
  registerUser: function (userData) {
    let loginMethod;
    if (userData && userData.loginMethod === 'facebook') {
      loginMethod = 'Facebook';
    } else if (userData && userData.loginMethod === 'google') {
      loginMethod = 'Google';
    } else {
      loginMethod = 'Sheroes';
    }
    let crdt = userData?.createdDate;
    userData?.firstName !== null &&
      window.Moengage.add_first_name(userData?.firstName);
    userData?.lastName !== null &&
      window.Moengage.add_last_name(userData?.lastName);
    userData?.emailId !== null && window.Moengage.add_email(userData?.emailId);
    userData?.mobileNumber !== null &&
      window.Moengage.add_mobile(userData?.mobileNumber);
    userData?.name !== null && window.Moengage.add_user_name(userData?.name); // Full name for user
    userData?.gender !== null && window.Moengage.add_gender(userData?.gender);
    userData?.dateOfBirth !== null &&
      window.Moengage.add_birthday(
        getDateFormatByValue(
          userData?.dateOfBirth,
          DATE_FIRST_FORMAT_WITHOUT_TIME
        )
      );
    userData?.id !== null && window.Moengage.add_unique_user_id(userData?.id); //

    window.Moengage.add_user_attribute('CRDT_DATE', dateFromTimeStamp(crdt));
    userData?.emailId !== null &&
      window.Moengage.add_user_attribute('EMAIL', userData?.emailId);
    userData?.dateOfBirth !== null &&
      window.Moengage.add_user_attribute(
        'DOB',
        getDateFormatByValue(
          userData?.dateOfBirth,
          DATE_FIRST_FORMAT_WITHOUT_TIME
        )
      );
    userData?.currentLanguage !== null &&
      window.Moengage.add_user_attribute('LANGUAGE', userData?.currentLanguage);
    userData?.name !== null &&
      window.Moengage.add_user_attribute('NAME', userData?.name);
    userData?.id !== null &&
      window.Moengage.add_user_attribute('USER_ID', userData?.id);
    window.Moengage.add_user_attribute(
      'USER_TYPE',
      userData?.userTypeId === 7 ? 'Champion' : 'Normal'
    );
    userData?.mobileNumber !== null &&
      window.Moengage.add_user_attribute('MOBILE', userData?.mobileNumber);
    userData?.location !== null &&
      window.Moengage.add_user_attribute('LOCATION', userData?.location);
    window.Moengage.add_user_attribute('LOGIN_METHOD', loginMethod);
  },
  trackGenderErrorContacted: function (spentTime, userId) {
    window.Moengage.track_event('GENDER_ERROR_CONTACTED', {
      time_spent: spentTime,
      user_id: userId,
    });
  },
  trackShareClicked: function (spentTime, post) {
    window.Moengage.track_event('SHARE_CLICKED', {
      time_spent: spentTime,
      community_id: post?.communityId,
    });
  },
  trackArticle: function (
    source,
    screenName,
    spentTime,
    user,
    articleType,
    articleId
  ) {
    window &&
      window.Moengage &&
      window.Moengage.track_event('SCREEN_OPENED', {
        source: source,
        screen_name: screenName,
        time_spent: spentTime,
        article_type: articleType,
        is_logged_in: user ? true : false,
        article_id: articleId,
      });
  },
  trackScreenOpen: function (source, screenName, spentTime) {
    window.Moengage.track_event('SCREEN_OPENED', {
      source: source,
      screen_name: screenName,
      time_spent: spentTime,
    });
  },
  trackLogin: function (source, screenName, spentTime, referrerUrl) {
    window &&
      window.Moengage &&
      window.Moengage.track_event('SCREEN_OPENED', {
        source: source,
        screen_name: screenName,
        time_spent: spentTime,
        source_link: referrerUrl,
      });
  },
  trackMentorDashboard: function (questionsUnanswered, questionsAnswered) {
    window.Moengage.track_event('MENTOR_DASHBOARD', {
      questions_unanswered: questionsUnanswered,
      questions_answered: questionsAnswered,
    });
  },
  trackFCDashboard: function (questionsUnanswered, questionsAnswered) {
    window.Moengage.track_event('FACILITATOR_DASHBOARD', {
      questions_unanswered: questionsUnanswered,
      questions_answered: questionsAnswered,
    });
  },
  trackCommunityJoined: function (
    joinOnMilliseconds,
    communityId,
    sourceName,
    type
  ) {
    window.Moengage.track_event('COMMUNITY_JOINED', {
      joined_on: joinOnMilliseconds,
      community_id: communityId,
      source_campaign: sourceName,
      type: type,
    });
  },
  trackCommunityLeft: function (joinOnMilliseconds, communityId) {
    window.Moengage.track_event('COMMUNITY_LEFT', {
      // joined_on: joinOnMilliseconds,
      left_on: joinOnMilliseconds,
      community_id: communityId,
    });
  },
  trackLectureDetail: function (
    source,
    screenName,
    spentTime,
    moduleName,
    moduleId,
    videoId,
    videoName
  ) {
    window.Moengage.track_event('SCREEN_OPENED', {
      source: source,
      screen_name: screenName,
      time_spent: spentTime,
      module_name: moduleName,
      module_id: moduleId,
      video_id: videoId,
      video_name: videoName,
    });
  },
  trackReplyOnComment: function (
    source,
    screenName,
    spentTime,
    commentId,
    communityId
  ) {
    window.Moengage.track_event('SCREEN_OPENED', {
      source: source,
      screen_name: screenName,
      time_spent: spentTime,
      comment_id: commentId,
      community_id: communityId,
    });
  },
  trackPostDetail: function (source, screenName, spentTime, post, user) {
    window.Moengage.track_event('SCREEN_OPENED', {
      source: source,
      is_logged_in: user ? true : false,
      screen_name: screenName,
      time_spent: spentTime,
      post_type:
        post?.images > 0
          ? 'Image'
          : post?.isThemePost
          ? 'Theme Post'
          : post?.subType === 'A'
          ? 'Article'
          : 'Text',
    });
  },
  trackPostCreated: function (
    files,
    screenName,
    isThemePost,
    selectedCommunityId,
    communityName,
    isSchedulePost,
    sourceName
  ) {
    window.Moengage.track_event('POST_CREATED', {
      post_type:
        files.length > 0 ? 'Image' : isThemePost ? 'Theme Post' : 'Text',
      community_id: selectedCommunityId,
      'Post Category': !communityName ? 'Followers' : 'Community',
      scheduled: isSchedulePost,
      source_campaign: sourceName,
      screen_name: screenName,
    });
  },
  trackCommunityTabs: function (
    source,
    screenName,
    spentTime,
    communityId,
    communitySource,
    communityScreenName
  ) {
    window.Moengage.track_event('SCREEN_OPENED', {
      screen_name: communityScreenName,
      community_id: communityId,
      time_spent: spentTime,
      source: communitySource,
      previous_tab_name: source,
      current_tab_name: screenName,
    });
  },
  trackLanguagePrefered: function (
    spentTime,
    userId,
    languageChosen,
    eventName
  ) {
    window.Moengage.track_event(
      eventName === 'lang_preferred'
        ? 'LANGUAGE_PREFERED'
        : 'LANGUAGE_SWITCHED',
      {
        time_stamp: spentTime,
        user_id: userId,
        language_chosen: languageChosen,
      }
    );
  },
  trackVideoCommented: function (
    webLanguage,
    lectureTitle,
    chapterTitle,
    mentorId,
    videoId,
    moduleId
  ) {
    window.Moengage.track_event('VIDEO_COMMENTED', {
      language_preffered: webLanguage,
      video_name: lectureTitle,
      module_name: chapterTitle,
      mentor_id: mentorId,
      video_id: videoId,
      module_id: moduleId,
    });
  },
  trackPostEngaged: function (post, engagemenType) {
    console.log('post', post);
    window.Moengage.track_event('POST_ENGAGED', {
      post_type:
        post?.images > 0
          ? 'Image'
          : post?.isThemePost
          ? 'Theme Post'
          : post?.subType === 'A'
          ? 'Article'
          : post?.subType === 'PL'
          ? 'Poll'
          : post?.subType === 'V'
          ? 'Video'
          : 'Text',
      post_category:
        post?.communityId === '-1'
          ? 'Followers'
          : post?.isWallPost || post?.isPostOwnWall
          ? 'Wall'
          : 'Community',
      engagement_type: engagemenType,
      community_id: post?.communityId,
      post_id: post?.entityId,
    });
  },
  trackDoneClicked: function (firstName, formSubmitted) {
    window.Moengage.track_event('DONE_CLICKED', {
      first_name: firstName,
      form_submitted: formSubmitted,
    });
  },
  trackPostDeleted: function (post) {
    window.Moengage.track_event('POST_DELETED', {
      post_type:
        post?.images > 0
          ? 'Image'
          : post?.isThemePost
          ? 'Theme Post'
          : post?.subType === 'A'
          ? 'Article'
          : post?.subType === 'PL'
          ? 'Poll'
          : post?.subType === 'V'
          ? 'Video'
          : 'Text',
      post_category:
        post?.communityId === '-1'
          ? 'Followers'
          : post?.isWallPost || post?.isPostOwnWall
          ? 'Wall'
          : 'Community',
      post_id: post?.id,
    });
  },
  trackPostEdited: function (post) {
    window.Moengage.track_event('POST_EDITED', {
      post_type:
        post?.images > 0
          ? 'Image'
          : post?.isThemePost
          ? 'Theme Post'
          : post?.subType === 'A'
          ? 'Article'
          : post?.subType === 'PL'
          ? 'Poll'
          : post?.subType === 'V'
          ? 'Video'
          : 'Text',
      post_category:
        post?.communityId === '-1'
          ? 'Followers'
          : post?.isWallPost || post?.isPostOwnWall
          ? 'Wall'
          : 'Community',
      post_id: post.id,
    });
  },
  trackPostFeatured: function (post) {
    console.log('post', post);
    window.Moengage.track_event('POST_FEATURED', {
      post_type:
        post?.images > 0
          ? 'Image'
          : post?.isThemePost
          ? 'Theme Post'
          : post?.subType === 'A'
          ? 'Article'
          : post?.subType === 'PL'
          ? 'Poll'
          : post?.subType === 'V'
          ? 'Video'
          : 'Text',
      post_category:
        post?.communityId === '-1'
          ? 'Followers'
          : post?.isWallPost || post?.isPostOwnWall
          ? 'Wall'
          : 'Community',
      post_id: post.id,
    });
  },
  trackCommentReported: function (post, isArticleDetail) {
    window.Moengage.track_event('COMMENT_REPORTED', {
      post_type:
        post?.images > 0
          ? 'Image'
          : post?.isThemePost
          ? 'Theme Post'
          : post?.subType === 'A'
          ? 'Article'
          : post?.subType === 'PL'
          ? 'Poll'
          : post?.subType === 'V'
          ? 'Video'
          : 'Text',
      post_category:
        post?.communityId === '-1'
          ? 'Followers'
          : post?.isWallPost || post?.isPostOwnWall
          ? 'Wall'
          : 'Community',
    });
  },
  trackReplyOnCommentReported: function (post, isArticleDetail) {
    window.Moengage.track_event('REPLY_ON_COMMENT_REPORTED', {
      post_type:
        post?.images > 0
          ? 'Image'
          : post?.isThemePost
          ? 'Theme Post'
          : post?.subType === 'A'
          ? 'Article'
          : post?.subType === 'PL'
          ? 'Poll'
          : post?.subType === 'V'
          ? 'Video'
          : 'Text',
      post_category:
        post?.communityId === '-1'
          ? 'Followers'
          : post?.isWallPost || post?.isPostOwnWall
          ? 'Wall'
          : 'Community',
    });
  },
  trackProfileReported: function (user) {
    window.Moengage.track_event('PROFILE_REPORTED', {
      user_type: user?.userTypeId === 7 ? 'Champion' : 'Normal',
    });
  },
  trackBadgeRewarded: function (badgeName, moduleName) {
    window.Moengage.track_event('BADGE_REWARDED', {
      badge_name: badgeName,
      module_name: moduleName,
    });
  },
  trackBadgeShared: function (screenName, badgeName) {
    window.Moengage.track_event('BADGE_SHARED', {
      screen_name: screenName,
      badge_name: badgeName,
      // shared_on: sharedOn,
    });
  },
  trackCertificateDownload: function (lang, certificateLink) {
    window.Moengage.track_event('CERTIFICATE_DOWNLOADED', {
      language: lang,
      certificate_link: certificateLink,
    });
  },
  trackLetsStartClicked: function (source, screenName, spentTime) {
    window.Moengage.track_event('LETS_START_CLICKED', {
      source: source,
      screen_name: screenName,
      time_spent: spentTime,
    });
  },
  trackCourseCompleted: function (lang, videosWatched, status) {
    window.Moengage.track_event('COURSE_COMPLETED', {
      language: lang,
      videos_watched: videosWatched,
      status: status,
    });
  },
  trackPushNotificationReceived: function (
    platform,
    screenName,
    notificationTitle,
    timeStamp,
    deeplink
  ) {
    window.Moengage.track_event('PUSH_RECEIVED', {
      platform: platform,
      screen_name: screenName,
      notification_title: notificationTitle,
      time_stamp: timeStamp,
      deeplink: deeplink,
    });
  },
  trackPushNotificationClicked: function (
    platform,
    screenName,
    notificationTitle,
    timeStamp,
    deeplink
  ) {
    window.Moengage.track_event('PUSH_CLICKED', {
      platform: platform,
      screen_name: screenName,
      notification_title: notificationTitle,
      time_stamp: timeStamp,
      deeplink: deeplink,
    });
  },
  trackAssignment: function (
    source,
    screenName,
    spentTime,
    isQuizAnswered,
    quizStatus,
    quizId,
    moduleId,
    videoId
  ) {
    window.Moengage.track_event('SCREEN_OPENED', {
      source: source,
      screen_name: screenName,
      time_spent: spentTime,
      is_answered: isQuizAnswered,
      status: quizStatus,
      quiz_id: Number(quizId),
      module_id: moduleId,
      video_id: videoId,
    });
  },
  trackSurvey: function (source, screenName, spentTime, isSurveyAnswered) {
    window.Moengage.track_event('SCREEN_OPENED', {
      source: source,
      screen_name: screenName,
      time_spent: spentTime,
      status: isSurveyAnswered,
      is_answered: isSurveyAnswered,
    });
  },
  trackViewCertificate: function (
    source,
    screenName,
    spentTime,
    courseId,
    language,
    userType
  ) {
    window.Moengage.track_event('SCREEN_OPENED', {
      source: source,
      screen_name: screenName,
      time_spent: spentTime,
      course_id: courseId,
      language: language,
      user_type: userType,
    });
  },
};

export default moEngage;
