import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { initializeServices } from './utils/appUtil';
import { registerFirebaseServiceWorker } from './register-sw';
import { GoogleOAuthProvider } from '@react-oauth/google';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

initializeServices();
ReactDOM.render(
    <GoogleOAuthProvider clientId={clientId}>
         <App />
    </GoogleOAuthProvider> , document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
registerFirebaseServiceWorker();
