import React from 'react';
import { lazyPreload } from '../utils/lazyUtil';

const MyBookmarks = lazyPreload(() => import('../scenes/myBookmarks'));
const BlockedAccounts = lazyPreload(() => import('../scenes/blockedAccounts'));
const Notifications = lazyPreload(() => import('../scenes/notifications'));
const FeedScene = lazyPreload(() => import('../scenes/feedScene'));
const Communities = lazyPreload(() => import('../scenes/communities'));
const CommunitiesByCategory = lazyPreload(() =>
  import('../scenes/communitiesByCategory')
);
const Certificate = lazyPreload(() => import('../scenes/certificate'));
const MyCommunities = lazyPreload(() => import('../scenes/myCommunities'));
const UserProfile = lazyPreload(() => import('../scenes/userProfile'));
const EditProfile = lazyPreload(() => import('../scenes/editProfile'));
const Search = lazyPreload(() => import('../scenes/search'));
const AppCollections = lazyPreload(() => import('../scenes/appCollections'));
const UserBadges = lazyPreload(() => import('../components/user/userBadges'));
const PostDetail = lazyPreload(() => import('../scenes/postDetail'));
const MessageDetail = lazyPreload(() =>
  import('../components/user/MessageDetail')
);
const UserFriendList = lazyPreload(() => import('../scenes/userFriendList'));
const UserFollowersList = lazyPreload(() =>
  import('../scenes/userFollowerList')
);
const UserFollowingList = lazyPreload(() =>
  import('../scenes/userFollowingList')
);
const UsersChampionsList = lazyPreload(() =>
  import('../components/user/usersChampionsList')
);
const MutualCommunityList = lazyPreload(() =>
  import('../components/user/mutualCommunityList')
);

const routes = [
  {
    path: '/feed',
    render: (props) => <FeedScene {...props} setOrderName="Feed" />,
  },
  {
    path: '/communities/interests/:categoryId',
    component: CommunitiesByCategory,
  },
  {
    path: '/notifications',
    component: Notifications,
  },
  {
    path: '/communities',
    component: Communities,
  },
  {
    path: '/my-communities',
    component: MyCommunities,
  },
  {
    path: '/users/:userName/:userId',
    component: UserProfile,
  },
  {
    path: '/champions/:userName/:userId',
    component: UserProfile,
  },
  {
    path: '/users/edit-profile',
    component: EditProfile,
  },
  {
    path: '/search',
    component: Search,
  },
  {
    path: '/app-collections',
    component: AppCollections,
  },
  {
    path: '/badges/users/:userName/:userId',
    component: UserBadges,
  },
  {
    path: '/lecture/:postId',
    component: PostDetail,
  },
  {
    path: '/users/:userName/:userId/message',
    component: MessageDetail,
  },

  {
    path: '/certificate',
    component: Certificate,
  },
  {
    path: '/course/certificate',
    component: Certificate,
  },
  {
    path: '/my-bookmarks',
    component: MyBookmarks,
  },
  {
    path: '/blocked-accounts',
    component: BlockedAccounts,
  },
  {
    path: '/blocked-contacts',
    component: BlockedAccounts,
  },
  {
    path: '/friend-list/:userId',
    component: UserFriendList,
  },
  {
    path: '/users/:userName/:userId/followers-list',
    component: UserFollowersList,
  },
  {
    path: '/users/:userName/:userId/following-list',
    component: UserFollowingList,
  },
  {
    path: '/users/:userName/:userId/champions-list',
    component: UsersChampionsList,
  },
  {
    path: '/mutual-communities-joined',
    component: MutualCommunityList,
  },
];
export default routes;
