import React from 'react';
import { icons } from './marsConstants';
import { ReactComponent as QuizCrossIcon } from '../img/quiz-wrong-icon.svg';
import { ReactComponent as QuizTickIcon } from '../img/quiz-tick-icon.svg';
import { getCurrentUser } from '../services/httpService';
import { getThirdPartyUser } from '../services/authService';
import { galChampionEmailId } from './constants';

const user = getCurrentUser();
const userFromMM = getThirdPartyUser();
const currentPath = window.location.pathname;
function handleWatchVideo(quizData, history) {
  history.push(quizData?.deepLinkUrl);
}

function handleNextVideo(
  updateQuizData,
  surveyUrl,
  quizId,
  quizData,
  nextLectureUrl,
  history,
  isBonus
) {
  if (updateQuizData?.survey?.length === 0) {
    history.push(nextLectureUrl);
  } else if (updateQuizData?.survey === null) {
    history.push(nextLectureUrl);
  } else if (
    quizData?.nextLectureId === null ||
    quizData?.nextLectureId === undefined
  ) {
    if (updateQuizData?.isCourseComplete) {
      history.push('/certificate');
    } else if (isBonus) {
      history.push('/feed?tab=learn');
    } else {
      history.push('/feed?tab=course');
    }
  } else if (updateQuizData?.isCourseComplete) {
    history.push('/certificate');
  } else {
    history.push(nextLectureUrl);
  }
}

function selectedOptionClass(
  status,
  indexOfCorrectOption,
  index,
  selectedIndex,
  correct,
  wrong,
  correct1,
  btnOutlined
) {
  if (status === true && indexOfCorrectOption === index) {
    return correct;
  } else if (status === false && index === selectedIndex) {
    return wrong;
  } else if (status === false && index === indexOfCorrectOption) {
    return correct1;
  } else {
    return btnOutlined;
  }
}

function showPreviousSelection(
  correctQuizOption,
  index,
  selectedQuizOption,
  correct,
  wrong,
  correct1,
  btnOutlined
) {
  if (
    selectedQuizOption === correctQuizOption &&
    index === selectedQuizOption
  ) {
    return correct;
  } else if (
    selectedQuizOption !== correctQuizOption &&
    index === selectedQuizOption
  ) {
    return wrong;
  } else if (
    selectedQuizOption !== correctQuizOption &&
    index === correctQuizOption
  ) {
    return correct1;
  } else {
    return btnOutlined;
  }
}

function showCurrentSelection(
  currentCorrectOption,
  index,
  currentSelectedOption,
  correct,
  wrong,
  correct1,
  btnOutlined
) {
  if (
    currentSelectedOption === currentCorrectOption &&
    index === currentSelectedOption
  ) {
    return correct;
  } else if (
    currentSelectedOption !== currentCorrectOption &&
    index === currentSelectedOption
  ) {
    return wrong;
  } else if (
    currentSelectedOption !== currentCorrectOption &&
    index === currentCorrectOption
  ) {
    return correct1;
  } else {
    return btnOutlined;
  }
}

function showCurrentOptionIcon(
  currentCorrectOption,
  index,
  currentSelectedOption,
  imageSize,
  tickIcon
) {
  if (
    currentSelectedOption === currentCorrectOption &&
    index === currentSelectedOption
  ) {
    return <img className={imageSize} src={icons.ClappingHands} alt="clap" />;
  } else if (
    currentSelectedOption !== currentCorrectOption &&
    index === currentCorrectOption
  ) {
    return <QuizTickIcon alt="TickIcon" className={tickIcon} />;
  } else if (
    currentSelectedOption !== currentCorrectOption &&
    index === currentSelectedOption
  ) {
    return <QuizCrossIcon alt="CrossIcon" className={tickIcon} />;
  }
}

function showPreviousSelectionIcon(
  correctQuizOption,
  index,
  selectedQuizOption,
  imageSize,
  tickIcon
) {
  if (
    selectedQuizOption === correctQuizOption &&
    index === selectedQuizOption
  ) {
    return <img className={imageSize} src={icons.ClappingHands} alt="clap" />;
  } else if (
    selectedQuizOption !== correctQuizOption &&
    index === correctQuizOption
  ) {
    return <QuizTickIcon alt="TickIcon" className={tickIcon} />;
  } else if (
    selectedQuizOption !== correctQuizOption &&
    index === selectedQuizOption
  ) {
    return <QuizCrossIcon alt="CrossIcon" className={tickIcon} />;
  }
}

function selectedOptionIcon(
  status,
  indexOfCorrectOption,
  index,
  selectedIndex,
  imageSize,
  tickIcon
) {
  if (status === true && index === selectedIndex) {
    return <img className={imageSize} src={icons.ClappingHands} alt="clap" />;
  } else if (status === false && indexOfCorrectOption === index) {
    return <QuizTickIcon alt="TickIcon" className={tickIcon} />;
  } else if (status === false && selectedIndex === index) {
    return <QuizCrossIcon alt="CrossIcon" className={tickIcon} />;
  }
}

export function handleNextVideoText(
  quiz,
  quizIsAttempted,
  selectedOption,
  languageText,
  updateQuizData
) {
  if (selectedOption === null && quiz?.isAttempted === false) {
    return languageText?.WATCH_VIDEO;
  } else if (updateQuizData?.survey?.length > 0) {
    return languageText?.TAKE_SURVEY;
  } else if (quiz?.isAttempted === true || quizIsAttempted === true) {
    return languageText?.NEXT_VIDEO;
  } else if (selectedOption !== null || updateQuizData?.survey?.length === 0) {
    return languageText?.NEXT_VIDEO;
  }
}

export function handleOnClick(
  updateQuizData,
  surveyUrl,
  quizId,
  selectedOption,
  quizIsAttempted,
  nextLectureUrl,
  quizData,
  history,
  isBonus,
  surveyObj
) {
  if (nextLectureUrl === null) {
    if (updateQuizData?.isCourseComplete) {
      history.push('/certificate');
    } else if (isBonus) {
      history.push('/feed?tab=learn');
    } else {
      history.push('/feed?tab=course');
    }
  } else if (
    updateQuizData &&
    updateQuizData.isCourseComplete === true &&
    updateQuizData?.survey?.length !== 0
  ) {
    history.push(surveyUrl, { updateQuizData, quizId, isBonus });
  } else if (updateQuizData?.survey && updateQuizData?.survey?.length !== 0) {
    history.push(surveyUrl, { updateQuizData, quizId, isBonus });
  } else if (selectedOption !== null || updateQuizData?.survey?.length === 0) {
    history.push(nextLectureUrl);
  } else if (quizIsAttempted === true) {
    handleNextVideo(
      updateQuizData,
      surveyUrl,
      quizId,
      quizData,
      nextLectureUrl,
      history,
      isBonus
    );
  } else if (selectedOption === null) {
    handleWatchVideo(quizData, history);
  } else if (quizIsAttempted === false) {
    history.push(nextLectureUrl);
  }
}

export function handleDisabled(
  selectedOption,
  quiz,
  videoStatus,
  quizIsAttempted
) {
  if (
    selectedOption !== null ||
    quiz?.isAttempted === true ||
    videoStatus === null ||
    quizIsAttempted === true
  ) {
    return true;
  } else {
    return false;
  }
}

export function handleClassName(
  quiz,
  quizIsAttempted,
  correctQuizOption,
  index,
  selectedQuizOption,
  correct,
  wrong,
  correct1,
  btnOutlined,
  currentCorrectOption,
  currentSelectedOption,
  status,
  indexOfCorrectOption,
  selectedIndex
) {
  if (quiz?.isAttempted === true) {
    //when user comes again on the quiz after completing the course
    return showPreviousSelection(
      correctQuizOption,
      index,
      selectedQuizOption,
      correct,
      wrong,
      correct1,
      btnOutlined
    );
  } else if (quizIsAttempted === true) {
    //when user refreshes the quiz when first came
    return showCurrentSelection(
      currentCorrectOption,
      index,
      currentSelectedOption,
      correct,
      wrong,
      correct1,
      btnOutlined
    );
  } else {
    //when user came on the quiz for first time
    return selectedOptionClass(
      status,
      indexOfCorrectOption,
      index,
      selectedIndex,
      correct,
      wrong,
      correct1,
      btnOutlined
    );
  }
}

export function handleOptionIcon(
  quiz,
  quizIsAttempted,
  correctQuizOption,
  index,
  selectedQuizOption,
  imageSize,
  tickIcon,
  currentCorrectOption,
  currentSelectedOption,
  status,
  indexOfCorrectOption,
  selectedIndex
) {
  if (quiz?.isAttempted === true) {
    //when user comes again on the quiz after completing the course
    return showPreviousSelectionIcon(
      correctQuizOption,
      index,
      selectedQuizOption,
      imageSize,
      tickIcon
    );
  } else if (quizIsAttempted === true) {
    //when user refreshes the quiz when first came
    return showCurrentOptionIcon(
      currentCorrectOption,
      index,
      currentSelectedOption,
      imageSize,
      tickIcon
    );
  } else {
    //when user came on the quiz for first time
    return selectedOptionIcon(
      status,
      indexOfCorrectOption,
      index,
      selectedIndex,
      imageSize,
      tickIcon
    );
  }
}

export function showLanguageMenuIcon() {
  if (userFromMM) {
    return false;
  } else if (currentPath === '/language_selection') {
    return false;
  } else if (
    user &&
    user.emailId &&
    galChampionEmailId.indexOf(user.emailId) !== -1
  ) {
    return true;
  } else if (user && user.userTypeId === 2) {
    return true;
  } else {
    return false;
  }
}
