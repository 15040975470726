import React from 'react';
import { Box } from '@material-ui/core';

const ArticleDetailImage = ({ imageArticleDetail, linkOfImage }) => {
  return (
    <Box>
      <a href={linkOfImage} target="_blank" rel="noopener noreferrer">
        <img
          src={imageArticleDetail}
          alt="WomenWill"
          style={{
            overFlow: 'hidden',
            borderRadius: '15px',
            width: '100%',
          }}
        />
      </a>
    </Box>
  );
};
export default ArticleDetailImage;
