import React, { useEffect, useState } from 'react';
import {
  getSpentTime,
  getThirdPartyUser,
  getUserDetailsNew,
  getUserDeviceData,
  setPostLanguage,
} from '../services/authService';
import { useMobile, useTablet } from './utilHooks';
import { useHistory } from 'react-router-dom';
import FeedIcon from '../img/menu/menu-feed.svg';
import { SHEROES_ADMIN_URL, urlMap } from '../utils/constants';
import {
  getCurrentUser,
  getPostLanguage,
  setIsShowWWFeed,
} from '../services/authService';
import PropTypes from 'prop-types';
import MoengageService from '../services/moEngageService';
import AddHomeScreenIcon from '../img/menu/add-to-home.png';
import AdminIcon from '../img/menu/menu-admin.png';
import imgParams from '../utils/imageUtil';
import communityService from '../services/communityService';
import { addToHomeScreen, isIosDevice } from '../utils/appUtil';
import { isAdminOrModerator } from '../utils/userUtil';
import { womenWillCommunityId } from '../utils/textUtil';
import language from '../utils/languages';
import { useSnackBar } from './snackBarHooks';
import { ReactComponent as SheroesLogo } from '../img/logo.svg';
import { Box, Typography } from '@material-ui/core';
import queryString from 'query-string';
import { useStoreActions, useStoreState } from 'easy-peasy';
import {
  currentScreenOpen,
  getPreviousLocation,
  screenNamesSet,
} from '../utils/screenUtils';
import { useLastLocation } from 'react-router-last-location';
import { showCreatePost } from '../utils/feedUtil';
import { useScreenOpenTracker } from './analyticsHook';
import { useNavBar } from './navBarHooks';
import userService from '../services/userService';

/**
 * Author: Shubh Kirti
 * Date: 8 Sep, 2023
 * @param {boolean} isTablet
 * @param {string} userFromMM
 * @param {object} history
 * @returns
 * (i) removedMenuKeys: that keys which needs to remove from menu api (eg:  *     app-lock, rate-us),
 * (ii) insertMenuItems: some menu items which are not present in api so *      statically adding them
 */
function useMenuItems() {
  const userFromMM = getThirdPartyUser();
  const history = useHistory();
  const isTablet = useTablet();
  const isMobile = useMobile();
  const user = getCurrentUser();
  const setToastMessage = useSnackBar();
  const isWWCommMember = user && user.isWWCommMember && user.isWWCommMember;
  const webLanguage = getPostLanguage();
  const enHiLang = webLanguage === 'en' || webLanguage === 'hi';
  const languageText = language[webLanguage ? webLanguage : 'en'];
  const removedMenuKeys = [
    'app_lock',
    'exclusives',
    'step_by_step_guide',
    'write_a_story',
    'quiz',
    'invite_friends',
    'my_orders',
    'faqs',
    'communities',
    'rate_us',
    'my_challenges',
    'articles',
  ];

  async function handleJoinWWCommunity() {
    const wwCid = womenWillCommunityId;
    const response = await await communityService.joinCommunity(
      wwCid,
      user.id,
      true,
      history,
      setToastMessage
    );
    if (response.status === 'SUCCESS') {
      setIsShowWWFeed(true);
    }
  }
  const showSocialFeed = () => {
    setIsShowWWFeed(false);
  };
  const showFeedScreen = () => {
    setIsShowWWFeed(true);
  };
  const handleAddToHome = () => {
    addToHomeScreen(true);
  };
  const insertMenuItems = [
    {
      isActive: user && userFromMM === null && enHiLang,
      menuUrl: urlMap.SOCIAL_TAB,
      menuName: languageText.SOCIAL_FEED,
      onClick: showSocialFeed,
      iconUrl: FeedIcon,
      menuKey: 'social-feed',
      selectedIconUrl: FeedIcon,
      isStatic: true, // set true if menu-item is inserted statically
    },
    {
      isActive: userFromMM === 'true' || (user && isWWCommMember && !enHiLang),
      menuUrl: urlMap.FEED,
      menuName: languageText.WOMEN_WILL_FEED,
      onClick: showFeedScreen,
      iconUrl: FeedIcon,
      menuKey: 'womenWill-feed',
      selectedIconUrl: FeedIcon,
      isStatic: true,
    },
    {
      isActive: user && !isWWCommMember && !enHiLang,
      menuUrl: urlMap.FEED,
      menuName: languageText.WOMEN_WILL_FEED,
      onClick: handleJoinWWCommunity,
      iconUrl: FeedIcon,
      menuKey: 'womenWill-feed',
      selectedIconUrl: FeedIcon,
      isStatic: true,
    },

    {
      isActive: user && enHiLang && userFromMM !== 'true',
      menuUrl: '/articles',
      menuName: languageText.ARTICLE,
      iconUrl:
        'https://img.sheroes.in/img/uploads/sheroespage/logoes/1581938758icarticles.png',
      menuKey: 'articles',
      selectedIconUrl:
        'https://img.sheroes.in/img/uploads/sheroespage/logoes/1581938758icarticles.png',
      isStatic: true,
    },
    {
      isActive: isAdminOrModerator() ? true : false,
      menuUrl: SHEROES_ADMIN_URL + '/landing/landingadmin',
      menuName: languageText.ADMIN,
      iconUrl: AdminIcon,
      menuKey: 'admin',
      selectedIconUrl: AdminIcon,
      isStatic: true,
    },
    {
      isActive: (!isTablet || !isMobile) && user ? true : false,
      menuUrl: user && user.userProfileLink && user.userProfileLink,
      menuName: languageText.PROFILE,
      iconUrl: user && user.image && imgParams(user.image, 30, 30, 'auto'),
      menuKey: 'profile',
      selectedIconUrl:
        user && user.image && imgParams(user.image, 30, 30, 'auto'),
      isStatic: true,
    },
    {
      isActive: !isIosDevice && (isMobile || isTablet),
      menuUrl: urlMap.FEED,
      menuName: 'Add to Home screen',
      onClick: handleAddToHome,
      iconUrl: AddHomeScreenIcon,
      menuKey: 'add-to-home-screen',
      selectedIconUrl: AddHomeScreenIcon,
      isStatic: true,
    },
  ];
  return { removedMenuKeys, insertMenuItems };
}

/**
 * Author: Shubh Kirti
 * Date: 8 Sep, 2023
 * @param {string} deepLinkUrl (eg: https://sheroes.com/my-bookmarks)
 * @returns stripped domain url (eg: /my-bookmarks)
 */
function stripMenuDomain(deepLinkUrl) {
  let postSlug = '';
  if (deepLinkUrl && deepLinkUrl.includes('sheroes.com')) {
    postSlug = deepLinkUrl.replace('https://sheroes.com', '');
  } else if (deepLinkUrl && deepLinkUrl.includes('sheroes.in')) {
    postSlug = deepLinkUrl.replace('https://sheroes.in', '');
  } else if (deepLinkUrl && deepLinkUrl.includes('shrs.me')) {
    postSlug = deepLinkUrl.replace('https://shrs.me', '');
  } else {
    postSlug = deepLinkUrl;
  }
  return postSlug;
}

function useFeedScene({ history, isSocialTab, feedStreamApi, feedConfig }) {
  const screenName = 'Feed Screen';
  const webLanguage = getPostLanguage();
  const languageText = language[webLanguage ? webLanguage : 'en'];
  const enHiLang = webLanguage === 'en' || webLanguage === 'hi';
  const spentTime = getSpentTime();
  const [streamUrl, setStreamUrl] = useState(null);
  const isTablet = useTablet();
  const isMobile = useMobile();
  const user = getCurrentUser();
  const lastLocation = useLastLocation();
  const { setTabValue } = useStoreActions((actions) => actions.tab);
  const moeSource = getPreviousLocation(lastLocation);
  const qs = queryString.parse(history.location.search);
  let courseTab = useStoreState((state) => state.tab.courseTab);
  const isUserFc_Gm =
    user && (user.userCategory === 'fc' || user.userCategory === 'gm');
  const currentScreen =
    currentScreenOpen(qs, courseTab, user) || screenNamesSet.socialFeed;

  setTimeout(() => {
    MoengageService.trackScreenOpen(moeSource, currentScreen, spentTime);
  }, 2000);

  useScreenOpenTracker({
    screenName: screenName,
  });
  useNavBar({
    title: isTablet && <SheroesLogo />,
    showCommunityIcon: true,
    showBack: false,
    showBottomNavbar: true,
    activeTab: 'feed',
  });

  useEffect(() => {
    async function renderedTab() {
      if (qs.tab === 'gww' || qs.tab === 'course' || qs.tab === 'dashboard') {
        setTabValue(1);
      } else if (isSocialTab) {
        setTabValue(0);
      } else {
        setTabValue(1);
      }
    }
    renderedTab();

    if (qs.tab === 'social' && !enHiLang) {
      history.push('/');
    }
    async function getUserDetails() {
      await getUserDetailsNew();
      history.push(urlMap.ONBOARDING_INTEREST);
    }
    user && !user?.hasInterests && getUserDetails();
  }, [history, setTabValue, qs.tab, enHiLang, isSocialTab, user]);
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const handleChange = async (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 1) {
      if (courseTab === 0) {
        if (isUserFc_Gm) {
          history.push(makePath('dashboard'));
        } else {
          history.push(makePath('course'));
        }
      } else if (courseTab === 1) {
        if (isUserFc_Gm) {
          history.push(makePath('course'));
        } else {
          history.push(makePath('learn'));
        }
      } else if (courseTab === 2) {
        history.push(makePath('feed'));
      }
      setStreamUrl(feedConfig.gwwStreamApi);
    } else if (newValue === 0) {
      if (enHiLang) {
        history.push(makePath('social'));
      }
    }
  };

  const isShowCreatePost = showCreatePost(
    user,
    feedConfig,
    languageText,
    isMobile
  );
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    if (isSocialTab) {
      setStreamUrl(feedStreamApi);
      MoengageService.trackScreenOpen(moeSource, 'Social Feed');
    }
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        <Box mt={isMobile ? 10 : 11}></Box>
        <Box p={1}>{children}</Box>
      </Typography>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function makePath(value) {
    const qs = {
      tab: value,
    };
    return {
      search: queryString.stringify(qs),
    };
  }

  return { streamUrl, TabPanel, isShowCreatePost, a11yProps, handleChange };
}

function useLanguageMenu({ history, match }) {
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const spentTime = getSpentTime();
  const user = getCurrentUser();
  const userId = user && user.id;

  MoengageService.trackScreenOpen(
    'OTP Validation',
    'Language Selection',
    spentTime
  );

  const handleChange = (e) => {
    setSelectedLanguage(e.target.value);
  };

  useScreenOpenTracker({
    screenName: 'Language',
  });

  async function handleSaveLanguage() {
    const getUserDevice = getUserDeviceData();
    const request = {
      ...getUserDevice,
      user_id: user && user.id,
    };
    const data = await userService.updateUserLanguage(
      selectedLanguage,
      request
    );
    await userService.addPreferences(selectedLanguage, userId);
    if (data && data.status === 'SUCCESS') {
      await getUserDetailsNew();
      setPostLanguage(selectedLanguage);
      MoengageService.trackLanguagePrefered(
        spentTime,
        user?.id,
        selectedLanguage,
        'lan_switched'
      );

      history.push(
        match?.path === '/language_selection'
          ? '/feed'
          : urlMap.ONBOARDING_INTO_YOURSELF
      );
    }
  }
  return { selectedLanguage, handleSaveLanguage, handleChange };
}
export { useMenuItems, stripMenuDomain, useFeedScene, useLanguageMenu };
