import React from 'react';
import { Box } from '@material-ui/core/';
import { getSpentTime } from '../../services/authService';
import MoengageService from '../../services/moEngageService';
import { useLastLocation } from 'react-router-last-location';
import { getPreviousLocation, screenNamesSet } from '../../utils/screenUtils';

const RenderIframe = ({ url }) => {
  const splitData = url.split('/');
  let urlName = splitData[3];
  const lastLocation = useLastLocation();
  const sourceName = getPreviousLocation(lastLocation);
  const spentTime = getSpentTime();

  if (urlName === 'helpline') {
    MoengageService.trackScreenOpen(
      sourceName,
      screenNamesSet.helpline,
      spentTime
    );
  } else if (urlName === 'community-guidelines') {
    MoengageService.trackScreenOpen(
      sourceName,
      screenNamesSet.communityGuidelines,
      spentTime
    );
  } else if (splitData[4] === 'editorial-policy') {
    MoengageService.trackScreenOpen(
      sourceName,
      screenNamesSet.advertorialPolicy,
      spentTime
    );
  } else if (splitData[4] === 'policy') {
    MoengageService.trackScreenOpen(
      sourceName,
      screenNamesSet.privacyPolicy,
      spentTime
    );
  }

  const renderIframe = (url) => {
    return (
      '<iframe frameborder=0 src="' +
      url +
      '" width="100%" height="800"></iframe>'
    );
  };
  return (
    <Box
      dangerouslySetInnerHTML={{
        __html: renderIframe(url),
      }}
    ></Box>
  );
};

export default RenderIframe;
