import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Box } from '@material-ui/core';

const Loader = ({ position, isWebOnboarding }) => {
  const classes = useStyles();
  return (
    <Box
      align="center"
      width={1}
      height={1}
      py={2}
      borderRadius={isWebOnboarding ? '10px' : '0px'}
      className={position === 'fixed' ? classes.fixedLoader : ' '}
    >
      <CircularProgress color="primary" />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  fixedLoader: {
    background: 'rgba(0,0,0,0.2)',
    position: 'absolute',
    zIndex: '100000',
    display: 'flex',

    top: 0,
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
  },
}));
export default Loader;
