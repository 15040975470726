import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import Navbar from './components/header/navbar';
import { StoreProvider, createStore } from 'easy-peasy';
import globalState from './stores/globalStore';
import { ArrowForward } from '@material-ui/icons';
import theme from './theme';
import globalStyles from './components/common/globalStyles';
import CustomSnackbar from './components/common/customSnackbar';
import CustomDialogBox from './components/common/customDialogBox';
import CustomErrorHandler from './components/common/customErrorHandler';
import CustomSaathiDialog from './components/common/customSaathiDialog';
import ReportSpam from './components/common/reportSpam';
import { getRemoteConfigFromServer } from './services/remoteConfigService';
import communityService from './services/communityService';
import { renderRoutes } from './utils/routeUtils';
import routes from './routes/mainRoutes';
import { requestNotificationPermission } from './utils/pushNotification';
import { LastLocationProvider } from 'react-router-last-location';
import auth from './services/authService';
import {
  getLoginEventFired,
  setLoginEventFired,
  setPostLanguage,
  getPostLanguage,
  getThirdPartyUser,
} from './services/authService';
import { eventNames, eventProps } from './utils/eventConstants';
import analytics from './services/analyticsService';
import LoginModal from './components/home/loginModal';
import CustomSideBarGrid from './components/common/customSideBarGrid';
import MetaData from './components/common/metaData';
import MoengageService from './services/moEngageService';
import Tour from 'reactour';
import { useTablet } from './hooks/utilHooks';
import {
  webViewSteps,
  mobileViewSteps,
  isUserComesFromLoginScreen,
} from './utils/coachMarkUtils';
import { useHistory } from 'react-router-dom';
import { getCurrentUser } from './services/httpService';
import { setCurrentUser } from './services/authService';
import { isSaireeDigitalCardUrl } from './utils/textUtil';
import CommonModal from './components/common/customModal';

const globalStore = createStore(globalState);

const Main = () => {
  const [openTour, setOpenTour] = useState(false);
  const postLang = getPostLanguage();
  const user = getCurrentUser();
  const history = useHistory();
  const isTablet = useTablet();
  const steps = isTablet ? mobileViewSteps(postLang) : webViewSteps(postLang);
  const onScreenSteps = steps.filter(({ selector }) => selector !== '');
  const isOpen = isUserComesFromLoginScreen({ user, history });

  useEffect(() => {
    isOpen && setOpenTour(true);
  }, [isOpen]);

  window.onpopstate = function () {
    // On clicking the back button the tour should be stop
    setOpenTour(false);
  };
  const handleCloseTour = () => {
    const updatedData = { isTourComplete: true };
    setCurrentUser({ ...user, ...updatedData });
    setOpenTour(false);
  };
  const nextArrowIcon = () => {
    return <ArrowForward style={{ fontSize: '22px' }}></ArrowForward>;
  };

  return (
    <React.Fragment>
      <LastLocationProvider>
        {!isSaireeDigitalCardUrl && <Navbar />}
        <CustomSideBarGrid>{renderRoutes(routes)}</CustomSideBarGrid>
        <MetaData />
        <CustomSnackbar />
        <CustomDialogBox />
        <CustomErrorHandler />
        <CustomSaathiDialog />
        <CommonModal />
        <ReportSpam />
        <LoginModal />
      </LastLocationProvider>
      <Tour
        steps={onScreenSteps}
        isOpen={user?.isTourComplete ? false : openTour}
        showNavigation={false}
        showNumber={false}
        showCloseButton={false}
        maskSpace={1}
        disableInteraction={true}
        prevButton={<div></div>}
        nextButton={nextArrowIcon()}
        lastStepNextButton={nextArrowIcon()}
        onRequestClose={handleCloseTour}
      ></Tour>
    </React.Fragment>
  );
};

const App = ({ children }) => {
  globalStyles(); //calling global css classes here so that they can be used across app.
  globalStore.getActions().user.updateUser();
  const { user } = globalStore.getState().user;
  const setToastMessage = globalStore.getActions().snackBar.setMessage;

  //Check the token and login anywhere in site than only for protected routes.
  if (!user) auth.loginUserByToken(window.location.href, setToastMessage);
  const loginEventFired = getLoginEventFired();
  const postLang = getPostLanguage();
  const userFromMM = getThirdPartyUser();
  const referrerUrl = document.referrer;
  const splitUrl = referrerUrl.split('/');
  const logoutUrl = splitUrl[3] === 'logout';
  const themeList = localStorage.getItem('themeList');

  !logoutUrl && auth.setReferrerUrl(referrerUrl);
  const currentLanguage =
    user && user.currentLanguage ? user.currentLanguage : 'en';
  user && postLang == null && setPostLanguage(currentLanguage);
  user && analytics.registerUser(user);
  user &&
    !loginEventFired &&
    analytics.track(eventNames.webLogin, {
      [eventProps.isNewUser]: user.isNewUser,
    });
  !loginEventFired && setLoginEventFired();
  useEffect(() => {
    user && getRemoteConfigFromServer(user?.currentLanguage, setToastMessage);
    user && requestNotificationPermission();
    user &&
      ((themeList && themeList.length === 0) || themeList === null) &&
      communityService.getThemesFromServices();
    user && MoengageService.registerUser(user);
    // eslint-disable-next-line
  }, [user, userFromMM, themeList]);

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <BrowserRouter>
        <StoreProvider store={globalStore}>
          {children || <Main />}
        </StoreProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
