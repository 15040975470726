import PersonIcon from '../img/mars/person.svg';
import CallIcon from '../img/mars/call.svg';
import EmailIcon from '../img/mars/email.svg';
import StateIcon from '../img/mars/state.svg';
import JobIcon from '../img/mars/jobs.svg';
import SkillIcon from '../img/mars/skills.svg';
import HoursIcon from '../img/mars/hours.svg';
import ThumbDown from '../img/mars/thumb-down.svg';
import ThumbUp from '../img/mars/thumb-up.svg';
import ThumbUpSolid from '../img/mars/thumbs-up-solid.svg';
import Glass1 from '../img/mars/glass1.svg';
import Glass2 from '../img/mars/glass2.svg';
import Glass3 from '../img/mars/glass3.svg';
import Language from '../img/mars/language.svg';
import Concrete from '../img/mars/personality/concrete.svg';
import Theoretical from '../img/mars/personality/theoretical.svg';
import Clap from '../img/mars/clap.svg';
import UploadIcon from '../img/mars/upload.svg';
import ClappingHands from '../img/mars/clapping-hands.gif';
import WhatsappIcon from '../img/mars/whatsapp.png';
import EducationIcon from '../img/mars/mortarboard.png';

const marsUrlMap = {
  ABOUT: '/mars/about',
  PERSONAL_DETAILS: '/mars/personal-details',
  EDUCATIONAL_QUALIFICATION: '/mars/education-qualification',
  INSTRUCTIONS: '/mars/instructions',
  QUESTIONS: '/mars/questions',
  MARS_SUCCESS: '/mars/success',
};
const marsRegisterUrlMap = {
  ABOUT: '/mars/about',
  PERSONAL_DETAILS_REGISTER: '/mars/register/personal-details',
  EDUCATIONAL_QUALIFICATION: '/mars/register/education-qualification',
  QUESTIONS: '/mars/questions',
  MARS_SUCCESS: '/mars/register/success',
};
const languages = [
  {
    id: 1,
    key: 'en',
    value: 'English',
  },
  {
    id: 2,
    key: 'hi',
    value: 'Hindi',
  },
  {
    id: 3,
    key: 'mr',
    value: 'Marathi',
  },
  {
    id: 4,
    key: 'bn',
    value: 'Bengali',
  },
  // {
  //   id: 6,
  //   key: 'or',
  //   value: 'Oriya',
  // },
  {
    id: 5,
    key: 'ta',
    value: 'Tamil',
  },
  // {
  //   id: 8,
  //   key: 'pa',
  //   value: 'Punjabi',
  // },
  // {
  //   id: 9,
  //   key: 'as',
  //   value: 'Assamese',
  // },
  {
    id: 6,
    key: 'kn',
    value: 'Kannada',
  },
  {
    id: 7,
    key: 'te',
    value: 'Telugu',
  },
  {
    id: 8,
    key: 'ml',
    value: 'Malayalam',
  },
  {
    id: 12,
    key: 'oth',
    value: 'Other',
  },
];

const icons = {
  PersonIcon,
  CallIcon,
  EmailIcon,
  StateIcon,
  JobIcon,
  SkillIcon,
  HoursIcon,
  ThumbDown,
  ThumbUp,
  ThumbUpSolid,
  Glass1,
  Glass2,
  Glass3,
  Language,
  Concrete,
  Theoretical,
  Clap,
  UploadIcon,
  ClappingHands,
  WhatsappIcon,
  EducationIcon,
};

const qualifications = [
  { id: 1, value: 'Less than Class 10th' },
  { id: 2, value: 'Class 10' },
  { id: 3, value: 'Class 12 or equivalent' },
  { id: 4, value: 'Post Graduation' },
  { id: 5, value: 'Diploma' },
  { id: 6, value: 'Graduation' },
  { id: 7, value: 'Other' },
];
const yesNo = [
  { id: 1, value: 'yes', label: 'Yes' },
  { id: 2, value: 'no', label: 'No' },
];
const devoteForWork = [
  {
    id: 1,
    value: '1',
    label: 'Less than 2 hours/ day',
    icon: icons.Glass1,
    checkedIcon: icons.Glass2,
  },
  {
    id: 2,
    value: '2',
    label: '2 - 4 hours/ day',
    icon: icons.Glass1,
    checkedIcon: icons.Glass2,
  },
  {
    id: 3,
    value: '3',
    label: 'More than 4 hours/ day',
    icon: icons.Glass3,
    checkedIcon: icons.Glass2,
  },
  {
    id: 4,
    value: '4',
    label: 'Upto 8 hours a day',
    icon: icons.Glass3,
    checkedIcon: icons.Glass2,
  },
];

const questionSection = {
  APTITUDE: 'aptitude',
  COMMUNICATION: 'communication',
  ADAPTABILITY: 'adaptability',
  READINESS: 'readiness',
  EMPATHY: 'empathy',
  PERSONALITY: 'personality',
};
const marsSteps = [
  marsUrlMap.PERSONAL_DETAILS,
  marsUrlMap.EDUCATIONAL_QUALIFICATION,
  marsUrlMap.APTITUDE,
  marsUrlMap.COMMUNICATION,
  marsUrlMap.ADAPTABILITY,
  marsUrlMap.READINESS,
  marsUrlMap.EMPATHY,
  marsUrlMap.MARS_SUCCESS,
];
const marsNextScreen = [
  {
    personal: marsUrlMap.PERSONAL_DETAILS,
    education: marsUrlMap.EDUCATIONAL_QUALIFICATION,
    aptitude: marsUrlMap.APTITUDE,
    communication: marsUrlMap.COMMUNICATION,
    adaptability: marsUrlMap.ADAPTABILITY,
    readiness: marsUrlMap.READINESS,
    empathy: marsUrlMap.EMPATHY,
    success: marsUrlMap.MARS_SUCCESS,
  },
];

const exprinceInYr = [
  { id: 0, value: 'No experience', label: 'No experience' },
  { id: 2, value: 'less than 2 years', label: 'less than 2 years' },
  { id: 3, value: '2 - 5 years', label: '2 - 5 years' },
  { id: 5, value: '5 years +', label: '5 years +' },
];

const remoteWorkExprince = [
  {
    id: 1,
    value: 'no_remote_work_experience',
    label: 'No remote work experience',
  },
  { id: 2, value: 'less_than_2_years', label: 'less than 2 years' },
  { id: 3, value: '2_5_years', label: '2 - 5 years' },
  { id: 4, value: '5_years', label: '5 years +' },
];

const availableForNightShift = [
  { id: 1, value: 'yes', label: 'Yes' },
  { id: 2, value: 'no', label: 'No' },
];

const openToHybridWork = [
  { id: 1, value: 'yes', label: 'Yes' },
  { id: 2, value: 'no', label: 'No' },
];

const openToOnsiteWork = [
  { id: 1, value: 'yes', label: 'Yes' },
  { id: 2, value: 'no', label: 'No' },
];

const acceptedFormat = '.doc,.docx,.pdf';

// eslint-disable-next-line
const emailIdRegex = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
const mobileRegex = /^[0-9]{10}$/;
const ifscCodeRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/; //^[A-Z]{4}0[A-Z0-9]{6}$
// eslint-disable-next-line
const urlRegex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
const MAX_FILE_SIZE = '1048576';
const marsUploadImage = '.jpg,.png,.jpeg';

export {
  languages,
  icons,
  qualifications,
  marsUrlMap,
  yesNo,
  devoteForWork,
  questionSection,
  marsSteps,
  marsNextScreen,
  acceptedFormat,
  emailIdRegex,
  mobileRegex,
  marsRegisterUrlMap,
  ifscCodeRegex,
  urlRegex,
  MAX_FILE_SIZE,
  exprinceInYr,
  availableForNightShift,
  marsUploadImage,
  remoteWorkExprince,
  openToHybridWork,
  openToOnsiteWork
};
