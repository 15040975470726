import React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useStoreActions, useStoreState } from 'easy-peasy';
import AdapterLink from './adapterLink';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomSaathiDialog = () => {
  const classes = useStyles();
  const { open, errorMessage, btnPrimaryText, dialogIcon, dialogTitle } =
    useStoreState((state) => state.saathiHandler);

  const { setSaathiDialogBoxOpen } = useStoreActions(
    (actions) => actions.saathiHandler
  );

  const handleDialogBox = (action) => {
    if (action) {
      setSaathiDialogBoxOpen(false);
    } else {
      setSaathiDialogBoxOpen(false);
    }
  };

  return (
    <React.Fragment>
      {open && (
        <Dialog
          TransitionComponent={Transition}
          open={open}
          classes={{ paper: classes.paperDialog }}
          onClose={() => setSaathiDialogBoxOpen(false)}
        >
          <DialogTitle>
            <Box className={classes.dialogIconSet}>
              {dialogIcon}
              <Typography display="initial" className={classes.dialogTitleSet}>
                {dialogTitle}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogContentSet}>
            <DialogContentText color="textPrimary">
              {errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogActionsSet}>
            <Box
              to="/create-post"
              component={AdapterLink}
              onClick={() => handleDialogBox(true)}
            >
              {btnPrimaryText}
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  paperDialog: {
    margin: theme.spacing(4),
    padding: theme.spacing(4),
  },
  dialogTitleSet: {
    padding: theme.spacing(0),
    fontWeight: 'bold',
    display: 'inherit',
    marginLeft: theme.spacing(2.5),
    fontSize: '16px',
  },
  dialogContentSet: {
    padding: theme.spacing(0),
  },
  dialogActionsSet: {
    padding: theme.spacing(0),
  },
  dialogButton: {
    padding: theme.spacing(0),
  },
  dialogIconSet: {
    verticalAlign: 'text-top',
    display: 'inline-flex',
  },
}));
export default CustomSaathiDialog;
