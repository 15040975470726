import feedStore from './feedStore';
import userStore from './userStore';
import navBarStore from './navBarStore';
import snackBarStore from './snackBarStore';
import dialogStore from './dialogBoxStore';
import spamStore from './spamStore';
import postStore from './postStore';
import communityStore from './communityStore';
import createPostStore from './createPostStore';
import errorHandlerStore from './errorHandlerStore';
import saathiHandlerStore from './saathiHandlerStore';
import shopStore from './shopStore';
import tabStore from './tabStore';
import languages from '../utils/languages';
import lectureStore from './lectureStore';
import aboutStore from './aboutStore';
import modalStore from './modalStore';

const globalState = {
  user: userStore,
  navBar: navBarStore,
  posts: postStore,
  snackBar: snackBarStore,
  dialogBox: dialogStore,
  reportSpam: spamStore,
  feed: feedStore,
  community: communityStore,
  createPost: createPostStore,
  errorHandler: errorHandlerStore,
  saathiHandler: saathiHandlerStore,
  shop: shopStore,
  tab: tabStore,
  languages: languages,
  lecture: lectureStore,
  about: aboutStore,
  commonModal: modalStore,
};

export default globalState;
