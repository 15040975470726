import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import BorderLine from '../common/borderLine';
import AnyLink from '../common/anyLink';
import { getSpentTime } from '../../services/authService';
import MoengageService from '../../services/moEngageService';
import PostEngagementOptions from './postEngagementOptions';

const PostEngagementSection = ({
  post,
  handleLike,
  isLiked,
  handleCommentClick,
  handleRepost,
  isReposted,
  isArticleDetail,
  focusComment,
}) => {
  const classes = useStyles();
  const showBorder =
    ((post.likesCount || post.commentsCount) && post.isImagePost) ||
    !post.isImagePost;

  const spentTime = getSpentTime();

  const handleShareClick = () => {
    console.log('shared successfully!');
    MoengageService.trackShareClicked(spentTime, post);
  };

  return (
    <React.Fragment>
      {isArticleDetail && !focusComment && <BorderLine color="grey.600" />}
      {showBorder && (
        <Box px={4}>
          <BorderLine color="grey.100" />
        </Box>
      )}
      <Box px={4} pt={2}>
        <Box>
          <Grid justify="space-between" container>
            <PostEngagementOptions
              isLiked={isLiked}
              handleLike={handleLike}
              post={post}
              classes={classes}
              handleShareClick={handleShareClick}
              isReposted={isReposted}
              handleCommentClick={handleCommentClick}
              handleRepost={handleRepost}
            />
            {!isArticleDetail && (
              <Grid item xs={4} lg={3}>
                <Box align="right">
                  <AnyLink to={post.deepLinkUrl}>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      component="span"
                    >
                      {post.author && post.createdAt}
                    </Typography>
                  </AnyLink>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  share: {
    cursor: 'pointer',
  },
  countSet: {
    padding: theme.spacing(1.5, 0),
    '& span': {
      position: 'relative',
      cursor: 'pointer',
      '&::after': {
        content: '"."',
        display: 'inline-block',
        padding: theme.spacing(0, 1.5),
        fontSize: theme.spacing(5),
        position: 'relative',
        top: '-3px',
      },
      '&:last-child': {
        '&::after': {
          display: 'none',
        },
      },
    },
  },
  likeText: {
    display: 'inline-block',
  },
}));
export default PostEngagementSection;
