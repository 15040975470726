import { getUrlFromText, replaceBetween } from '../utils/textUtil';
import { default as _each } from 'lodash/each';
import { default as _isEmpty } from 'lodash/isEmpty';
import { default as _map } from 'lodash/map';
import { getUserLinkUrl } from '../utils/userUtil';
import linkify from 'linkifyjs/html';

/**
 *
 * Draft js plugins: https://github.com/draft-js-plugins/draft-js-plugins/tree/master/docs/client/components/pages/Mention
 *
 *  Official Docs and Ref for Draft js : https://github.com/facebook/draft-js
 *
 * @param {*} state
 * @param {Object} userMentionList
 *
 * function for create editor state with user mention list.
 * It take state and user mention list in args and return
 * block , entityMaps and links map
 * for Entity Related : https://draftjs.org/docs/api-reference-editor
 */
function createEditorState(state, userMentionList) {
  const blocks = createBlocks(state, userMentionList);
  const entityMaps = createEntityMaps(userMentionList);
  const linkMaps = createLinkMaps(state);
  return { blocks, entityMaps, linkMaps };
}

/**
 *
 * @param {*} block
 * @param {*} userList
 *  Function for Set Entity Ranges for user mention list position in text.
 *  Return object with offset,length and key
 */

function setEntityRanges(block, userList) {
  const entityRanges = [];
  if (!userList) return;
  for (let i = 0; i < userList.length; i++) {
    let entity = {};
    if (block.indexOf(userList[i].mention.name) !== -1) {
      entity = {
        offset: block.indexOf(userList[i].mention.name),
        length: userList[i].mention.name.length,
        key: i,
      };
      entityRanges.push(entity);
    }
  }
  return entityRanges;
}

/**
 *
 * @param {object} userList
 *  Function for create Map for User tags
 *  Return object with mention data
 */
function createEntityMaps(userList) {
  if (!userList) return;
  const entityMapObj = [];

  userList &&
    _each(userList, function (user) {
      let entity = {
        type: 'mention',
        mutability: 'SEGMENTED',
        data: {
          mention: {
            name: user.mention.name,
            link: user.mention.user_profile_url,
            avatar: user.mention.user_profile_image_url,
            user_id: user.mention.user_id,
            user_type_id: user.mention.user_type_id,
          },
        },
      };
      entityMapObj.push(entity);
    });

  return entityMapObj;
}
/**
 *
 * @param {*} state
 * @param {*} userList
 * Function for create block on the basis of ranges for multiple lines
 * No of lines in post create separate blocks for Edit Post
 */
function createBlocks(state, userList) {
  const lines = state.split(/\r\n|\r|\n/);
  let blocks = [];
  lines &&
    _each(lines, function (line, index) {
      let blocksMap = {
        key: generateRandomKey(),
        text: line,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: userList ? setEntityRanges(line, userList) : [],
        data: {},
      };
      blocks.push(blocksMap);
    });
  return blocks;
}

/**
 *
 * @param {State} state
 * Function for create link object for render link in post when edit
 *
 */
function createLinkMaps(state) {
  if (!state) return;
  const url = getUrlFromText(state);
  return url
    ? {
        type: 'LINK',
        mutability: 'MUTABLE',
        data: { href: url, url: url },
      }
    : '';
}
/**
 *
 * Function for generate random unique key for set in entity
 */
const seenKeys = {};
const MULTIPLIER = Math.pow(2, 24);
function generateRandomKey() {
  let key;
  while (key === undefined || seenKeys.hasOwnProperty(key) || !isNaN(+key)) {
    key = Math.floor(Math.random() * MULTIPLIER).toString(32);
  }
  seenKeys[key] = true;
  return key;
}
/**
 *
 * @param {*} object
 * Function for get ranges of user map in description
 * return the ranges
 */
function getEntityRanges(object) {
  if (!object) return;
  let ranges = [];
  for (let i = 0; i < object.length; i++) {
    if (object[i].entityRanges.length > 0) {
      for (let j = 0; j < object[i].entityRanges.length; j++) {
        ranges.push(object[i].entityRanges[j]);
      }
    }
  }
  return ranges;
}
/**
 *
 * @param {Object} userMentionList
 * @param {String} description
 * @param {Boolean} isEdit
 *
 * Function get user mention form post description  for editing and render in the Editor
 * Return mention object
 */
function getUserMentionObj(userMentionList, description) {
  description = stripHtmlWithHashTag(description);
  description = description.replace(/@/g, '');
  description = description.replace(/&nbsp;/g, ' ');
  let postMentionsObj = [];
  userMentionList &&
    _map(userMentionList, function (mention) {
      let start = description.indexOf(mention.name);
      let len = mention.name && mention.name.length;
      let map = {};
      map.mention = {
        name: mention.name,
        start: start,
        end: start + len,
        user_profile_url: mention.link || mention.user_profile_url,
        user_id: mention.id || mention.userId || mention.user_id,
        user_type_id: mention.userTypeId || mention.user_type_id,
        user_profile_image_url:
          mention.avatar || mention.user_profile_image_url,
      };
      postMentionsObj.push(map);
    });
  return postMentionsObj;
}
/**
 *
 * @param {String} text
 * @param {Object} mentions
 * @param {Boolean} isEdit
 * @param {String} themeTextColor
 * @param {Boolean} isThemePost
 *  Function for render mention list in view based on params
 *  This is used to render user tagging for theme post and normal post .
 */
function renderMention(
  text,
  mentions,
  isEdit,
  themeTextColor,
  isThemePost,
  subType
) {
  const themeColor = isThemePost ? themeTextColor : '';

  let strippedFinalText = stripHtmlWithHashTag(text);
  let replacedTextValue;
  _each(mentions, function (mention) {
    if (!mention) return;
    strippedFinalText = replaceBetween(
      strippedFinalText,
      mention.start,
      mention.end + 1,
      mention.name + '}'
    );
  });
  if (!isEdit && subType) {
    strippedFinalText = linkify(strippedFinalText, {
      className: 'linkColor',
    });
  }

  _each(mentions, function (mention, index) {
    if (!mention) return;
    if (isEdit) {
      replacedTextValue = getPeopleText(mention, index, false, themeColor);
    } else {
      replacedTextValue = getPeopleText(mention, index, true, themeColor);
    }
    strippedFinalText = strippedFinalText.replace(
      mention.name + '}',
      replacedTextValue + '&nbsp;'
    );
  });
  return strippedFinalText;
}

/**
 *  Function to strip html form text
 *
 */

function stripHtmlWithHashTag(html) {
  var doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

function stripHtml(html) {
  var doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

/**
 *
 * @param {*} mention
 * @param {*} index
 * @param {*} isUrl
 * @param {*} themeColor
 * Function for get People text to render in view with link
 *  if user has profile link then render with link otherwise render without link
 *  Return HTML set of Link to render in view
 */
function getPeopleText(mention, index, isUrl, themeColor) {
  const linkColor = themeColor || '#2793e7';
  if (isUrl) {
    const style = 'style="color:' + linkColor + '"';
    return (
      '<a ' +
      style +
      ' href=' +
      mention.userLinkUrl +
      '>@' +
      mention.name +
      '</a>'
    );
  } else {
    return (
      '<span class="tagged-user" start=' +
      mention.start +
      ' end=' +
      mention.end +
      ' index=' +
      index +
      '>' +
      mention.name +
      '</span>'
    );
  }
}

/**
 *
 * @param {object} object
 *  Function for find object type(mention ,link) and map with their objects to generate List
 *  return mention , link object to generate User Link
 */
function getEntityTypeObj(object) {
  let mentionData = [];
  let linkData = [];
  if (_isEmpty(object)) return '';
  const len = Object.keys(object).length;
  for (let i = 0; i < len; i++) {
    if (object[i].type === 'mention') {
      mentionData.push(object[i].data.mention);
    }
    if (object[i].type === 'LINK') {
      linkData = object[i].data;
    }
  }
  return {
    mentionData,
    linkData,
  };
}
/**
 *
 * @param {*} user
 * Function for map user Mention Data before render user suggestion list
 * Return user object
 */
function mapMentionData(user) {
  if (!user) return;
  return {
    name: user.name ? user.name.trim() : '',
    user_id: user.user_id ? user.user_id : '',
    link: user.user_profile_url ? user.user_profile_url : '',
    user_profile_url: getUserLinkUrl(user),
    avatar: user.user_profile_image_url ? user.user_profile_image_url : '',
    user_type_id: user.user_type_id ? user.user_type_id : '',
  };
}
export {
  createEditorState,
  createBlocks,
  getEntityRanges,
  getUserMentionObj,
  renderMention,
  getEntityTypeObj,
  stripHtml,
  mapMentionData,
  stripHtmlWithHashTag,
};
