import auth from '../services/authService';
import userService from '../services/userService';

async function getPaytmToken(setToastMessage) {
  const clientId = '5d588853b13b480bbfefafba4ca23b4a'; //process.env.REACT_APP_PAYTM_CLIENT_ID;
  if (window.JSBridge) {
    window.JSBridge.call(
      'paytmFetchAuthCode',
      {
        clientId: clientId,
      },
      async function (result) {
        const res = result;
        if (res) {
          await callPayTmAccessToken(res, setToastMessage);
        }
        return res;
      }
    );
  } else {
    document.addEventListener(
      'JSBridgeReady',
      () => {
        window.JSBridge.call(
          'paytmFetchAuthCode',
          {
            clientId: clientId,
          },
          async function (result) {
            const res = result;
            if (res) {
              await callPayTmAccessToken(res, setToastMessage);
            }
            return res;
          }
        );
      },
      false
    );
  }
}
async function callPayTmAccessToken(res, setToastMessage) {
  let apiCallStatus = false;
  auth.setLoginSource('paytm');
  if (res.data.authId) {
    const authId = res.data.authId;
    if (apiCallStatus === false) {
      const response = await userService.getPayTmAccessToken(authId);
      if (response.status === 'SUCCESS') {
        apiCallStatus = true;
        await getPayTmUserInfo(response.access_token, setToastMessage);
      }
    }
  }
}
async function getPayTmUserInfo(accessToken, setToastMessage) {
  const userData = await userService.getUserInfo(accessToken);
  if (userData.status === 'SUCCESS') {
    const accessToken = userData.access_token;
    const clientSource = 'PayTm';
    await auth.payTmLoginByToken(accessToken, clientSource, setToastMessage);
  }
}

export const callBridge = (
  { bridgeName, payload = {}, callBack = () => {} },
  resolve = () => {},
  promise
) => {
  if (window.JSBridge) {
    window.JSBridge.call(bridgeName, payload, (e) => {
      callBack(e);
      if (promise) resolve(e);
    });
  } else {
    document.addEventListener(
      'JSBridgeReady',
      () => {
        window.JSBridge.call(bridgeName, payload, (e) => {
          callBack(e);
          if (promise) resolve(e);
        });
      },
      false
    );
  }
};
export { getPaytmToken };
