import { default as _each } from 'lodash/each';
import { stripDomain } from '../utils/postUtil';
import { hiddenCommunityTabs } from '../utils/constants';
import PostToFollower from '../img/post-to-followers.svg';
import { womenWillLogo, womenWillCommunityId } from '../utils/textUtil';
import { deliveryStatus, getCount } from '../utils/shopUtil';
import languages from '../utils/languages';
import { getPostLanguage } from '../services/authService';

const webLanguage = getPostLanguage();
const languageText = languages[webLanguage ? webLanguage : 'en'];
function communityCategoryListMap(doc) {
  const lists = doc.solr_ignore_list_of_base_or_participant_model;

  return lists && lists.map((list) => communityItemMap(list));
}
function communityItemMap(item) {
  const obj = {
    id: item.id_of_entity_or_participant,
    thumbnailImageUrl: item.thumbnail_image_url,
    numberOfMembers: item.solr_ignore_no_of_members,
    name: item.name,
    logoAltText: item.logo_alt_text_s,
    description: item.list_short_description,
    isJoined: item.solr_ignore_is_member || item.solr_ignore_is_owner,
    totalNewPostNumber:
      item.solr_ignore_total_no_of_new_posts_after_last_access,
    communitySlug: stripDomain(
      item.solr_ignore_deep_link_url || item.sheroes_deep_link_s
    ),
    isCommunityOwner: item.solr_ignore_is_owner,
  };
  return item?.status === 'FAILED' ? item : obj;
}
function shopItemMap(item) {
  return {
    id: item.id_of_entity_or_participant,
    thumbnailImageUrl: item.thumbnail_image_url,
    numberOfMembers: item.solr_ignore_no_of_members,
    name: item.name,
    logoAltText: item.logo_alt_text_s,
    description: item.list_short_description,
    isJoined: item.solr_ignore_is_member || item.solr_ignore_is_owner,
    totalNewPostNumber:
      item.solr_ignore_total_no_of_new_posts_after_last_access,
    communitySlug:
      '/shop-on-sheroes/' +
      item.slug_s +
      '/' +
      btoa(item.id_of_entity_or_participant),
    shopCategory: item.community_type_s,
    // stripDomain(
    //   item.solr_ignore_deep_link_url || item.sheroes_deep_link_s
    // ),
  };
}

function communityCategoryMap(doc) {
  const endUrl = doc.endPointUrl;
  return {
    title: doc.title,
    endPointUrl: doc.endPointUrl,
    communities: communityCategoryListMap(doc),
    categorySlug: getCategorySlug(endUrl),
  };
}

function addressMap(doc) {
  return {
    name: doc.name,
    mobile: doc.mobile,
    fullAddress: doc.full_address,
    addressLine1: doc.address_line1,
    addressLine2: doc.address_line2,
    pinCode: doc.pincode,
    isDefaultAddress: doc.is_default_address,
    isRecentAddress: doc.is_recent_address,
    addressId: doc.address_id,
    email: doc.email,
    showDeliveryBtn: doc.is_default_address ? true : false,
  };
}

function getCategorySlug(url) {
  if (!url) return;
  let splitUrl = url.split('=');
  let categoryId = splitUrl[2];
  let categorySlug = '/communities/interests/' + categoryId;
  return categorySlug;
}

function filterTabs(communityTabs) {
  communityTabs = communityTabs.filter((tab) => {
    return !(hiddenCommunityTabs.indexOf(tab.key) > -1);
  });
  return communityTabs;
}

function setCommunityDefaultInfo(communityTabs, defaultTabKey) {
  let defaultActiveTab,
    defaultStreamUrl,
    feedStreamUrl,
    defaultTabIndex,
    feedTabIndex,
    defaultTabType,
    feedTabType,
    communityDescription,
    defaultActiveTabTitle;
  _each(communityTabs, function (tab, index) {
    // IF default tab key exists.
    if (defaultTabKey === tab.key) {
      defaultActiveTab = tab.key;
      defaultActiveTabTitle = tab.title;
      defaultTabIndex = index;
      defaultStreamUrl = tab.dataUrl;
      defaultTabType = tab.type;
    }

    if (tab.key === 'feed') {
      feedStreamUrl = tab.dataUrl;
      feedTabIndex = index;
      feedTabType = tab.type;
    }
    // To set the community description
    if (tab.key === 'about') {
      communityDescription = tab.htmlData;
    }
  });
  // IF default key is undefined, making feed as default tab.
  if (!defaultTabKey) {
    defaultStreamUrl = feedStreamUrl;
    defaultActiveTab = 'feed';
    defaultActiveTabTitle = 'Feed';
    defaultTabIndex = feedTabIndex;
    defaultTabType = feedTabType;
  }
  return {
    defaultStreamUrl,
    defaultTabIndex,
    defaultTabType,
    defaultActiveTab,
    communityDescription,
    defaultActiveTabTitle,
  };
}

function communityDetailMap(community) {
  let communityTabs =
    community.solr_ignore_community_tabs &&
    community.solr_ignore_community_tabs.map(communityTabMap);
  communityTabs = filterTabs(communityTabs);
  const defaultTabKey = community.solr_ignore_default_tab_key;

  return {
    ...setCommunityDefaultInfo(communityTabs, defaultTabKey),
    communityTabs: communityTabs,
    name: community.name,
    isMember: community.solr_ignore_is_member,
    isOwner: community.solr_ignore_is_owner,
    id: community.id_of_entity_or_participant,
    deepLinkUrl: community.solr_ignore_deep_link_url,
    description: community.description,
    subType: community.sub_type,
    isIndexed: community.is_index_b,
    isMemberOrOwner:
      community.solr_ignore_is_member || community.solr_ignore_is_owner,
    imageUrl: community.image_url,
    location: community.community_location_s,
    type: community.community_type_s,
    thumbUrl: community.thumbnail_image_url,
    createdById: community.created_by_l,
    isShop: community.is_shop_b,
    locationMasterId: community.location_master_id_l,
    communityTypeId: community.community_type_l,
    communityTypeName: community.community_type_s,
    mobile: community.mobile_s,
    email: community?.email_s,
  };
}

function communityTabMap(tab) {
  return {
    type: tab.type_s,
    key: tab.key_s,
    title: tab.title_s,
    data: tab.data_html_s,
    dataUrl: tab.data_url_s,
    showFab: tab.show_fab_b,
    htmlData: tab.data_html_s,
  };
}
function themeItemMap(item) {
  return {
    id: item.id,
    isDefault: item.is_default,
    themeImageUrl: item.theme_image_url,
    themeTextCharLength: item.theme_text_char_length,
    themeTextColor: item.theme_text_color,
    themeTextLineCount: item.theme_text_line_count,
  };
}
const postToFollowerObj = [
  {
    id: -1,
    name: languageText?.POST_TO_FOLLOWERS,
    thumbnailImageUrl: PostToFollower,
  },
];
const postToWomenWillObj = [
  {
    id: womenWillCommunityId,
    name: 'Women Will - An Entrepreneurship Program',
    thumbnailImageUrl: womenWillLogo,
  },
];
function catalogueMap(doc) {
  let response = [];
  const nextToken = doc && doc.next_token;
  const numFound = doc && doc.numFound;
  response.docs =
    doc && doc.docs.map((list) => catalougeItemMap(list, nextToken));
  response.nextToken = nextToken;
  response.numFound = numFound;
  return response;
}

function catalougeItemMap(doc, token) {
  var cData = [];
  cData = {
    displayTextImageUrl:
      doc &&
      doc.display_text_image_url !== null &&
      doc.display_text_image_url[0],
    subType: doc?.sub_type,
    name: doc?.name,
    sourceVideoUrl: doc?.source_video_url_s,
    videoUrl: doc?.video_url_s,
    videoThumbnailUrl: doc?.video_thumbnail_url_s,
    shopDeepLink: stripDomain(doc?.sheroes_deep_link_s),
    //nextToken: nextToken,
  };
  //cData.nextToken = token;
  return cData;
}

function getDeliveryStatus(currentStatus) {
  return deliveryStatus.filter((ds) => ds.name === currentStatus);
}

function dashboardDataMap(docs) {
  return {
    totalPurchasedAmount: docs && docs.total_purchased_amount,
  };
}

function saleSummaryMap(docs) {
  return {
    lastMonthEarnings: docs && docs.lastMonthEarnings,
    currMonthEarnings: docs && docs.currMonthEarnings,
    totalEarnings: docs && docs.totalEarnings,
  };
}

function generatePaymentLinkMap(docs) {
  return {
    paymentUrl: docs && docs.payment_url,
    paymentRedirectUrl: docs && docs.payment_redirect_url,
    orderId: docs && docs.order_id,
    paymentReqId: docs && docs.payment_req_id,
    status: docs && docs.status,
  };
}

function getCatalogueMap(docs) {
  let response = [];
  const nextToken = docs && docs.next_token;
  response.docs =
    docs &&
    docs.items &&
    docs.items.map((list) => getCatalougeItemMap(list, nextToken));
  response.nextToken = nextToken;
  response.more = docs.more;
  return response;
}

function getCatalougeItemMap(docs) {
  var itemData = [];
  itemData = {
    id: docs.id,
    deepLinkUrl: createCatalogueDeepLink(docs),
    description: docs?.description,
    isActive: docs?.isActive,
    name: docs?.name,
    shopId: docs?.shopId,
    sku: docs?.sku[0],
    isService: docs?.type === 'S' ? true : false,
    //mediaList: docs?.sku[0]?.mediaList,
  };
  return itemData;
}
function createCatalogueDeepLink(doc) {
  //let linkUrl = '/shop-on-sheroes/<shop_name>/<product_name>/{{itemId}}';
  const shopId = btoa(doc.shopId);
  const itemId = btoa(doc.id);
  const shopName = convertToSlug(doc.shopName);
  let linkUrl =
    '/shop-on-sheroes/catalogue-details/' +
    shopName +
    '/' +
    shopId +
    '/' +
    itemId;
  return linkUrl;
}
function convertToSlug(Text) {
  return Text.toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
}

function mapShopMedia(doc) {
  var mediaObj = [];
  let docObj = [];
  for (let i = 0; i < doc.docs.length; i++) {
    if (doc.docs[i].display_text_image_url !== null) {
      docObj[i] = {
        src: doc.docs[i].display_text_image_url[0],
        thumbnail: doc.docs[i].display_text_image_url[0],
        thumbnailWidth: doc.docs[i].post_image_width_is[0],
        thumbnailHeight: doc.docs[i].post_image_height_is[0],
        url: stripDomain(doc.docs[i].solr_ignore_deep_link_url),
      };
      mediaObj.push(docObj[i]);
    } else {
      docObj[i] = {
        src: doc.docs[i].video_thumbnail_url_s,
        thumbnail: doc.docs[i].video_thumbnail_url_s,
        thumbnailWidth: doc.docs[i].width_i,
        thumbnailHeight: doc.docs[i].height_i,
        url: stripDomain(doc.docs[i].solr_ignore_deep_link_url),
      };
      mediaObj.push(docObj[i]);
    }
  }
  mediaObj.nextToken = doc.next_token;
  mediaObj.numFound = doc.numFound;
  return mediaObj;
}

function dashboardCarouselMap(doc) {
  const lists = doc && doc[0]?.solr_ignore_list_of_base_or_participant_model;
  return lists && lists.map((list) => dashboardCarouselItemMap(list));
}

function dashboardCarouselItemMap(item) {
  return {
    imageUrl: item.image_url,
    deepLinkUrl: item.solr_ignore_deep_link_url,
    name: item.name,
  };
}
function mapShopSocialData(data) {
  const aboutTabDetails = data?.community_details?.community_about_tab_details;
  const ageOn = aboutTabDetails.ageOnPlatform;
  const splitNumber = ageOn.substr(0, ageOn.indexOf(' '));
  const splitText = ageOn.substr(ageOn.indexOf(' ') + 1);
  return {
    name: aboutTabDetails.community_name,
    description: aboutTabDetails.community_description,
    location: aboutTabDetails.community_location_s,
    type: aboutTabDetails.community_type_s,
    itemsCount: getCount(aboutTabDetails.itemsCount),
    lastAccessTime: aboutTabDetails.lastAccessTime,
    is_owner: aboutTabDetails.solr_ignore_is_owner,
    noOfMembers: aboutTabDetails.solr_ignore_no_of_members,
    imageUrl: aboutTabDetails.thumbnail_image_url,
    totalSalesCount: aboutTabDetails.totalSalesCount,
    //ageOnPlatform: '', //aboutTabDetails.ageOnPlatform.split(/(?<=^\S+)\s/),
    //ageOnPlatform: aboutTabDetails.ageOnPlatform.split(/(?:\/)([^#]+)(?=#*)/),
    ageOnPlatformNumber: splitNumber,
    ageOnPlatformText: splitText,
  };
}
function mapInterestData(data) {
  const lastItem = data[data.length - 1];
  for (var i = 0; i < data.length; i++) {
    if (data[i].id === lastItem.id) {
      data[i].isLastItem = true;
    } else {
      data[i].isLastItem = false;
    }
  }
  return data;
}

//This Mapper is for mapping the data for mutual community list and community joined for nonself profile.
function mutualCommunityItemMap(item) {
  const docs = item?.docs;
  const featuredDocs = item?.solr_ignore_featured_docs;
  let obj = [];
  const response =
    docs &&
    docs?.map((item) => ({
      id: item.id_of_entity_or_participant,
      thumbnailImageUrl: item.thumbnail_image_url,
      numberOfMembers: item.solr_ignore_no_of_members,
      name: item.name,
      logoAltText: item.logo_alt_text_s,
      description: item.list_short_description,
      isJoined: item.solr_ignore_is_member || item.solr_ignore_is_owner,
      totalNewPostNumber:
        item.solr_ignore_total_no_of_new_posts_after_last_access,
      communitySlug: stripDomain(
        item.solr_ignore_deep_link_url || item.sheroes_deep_link_s
      ),
      isCommunityOwner: item.solr_ignore_is_owner,
    }));
  const mutualCommunityRes =
    featuredDocs &&
    featuredDocs?.map((item) => ({
      id: item.id_of_entity_or_participant,
      thumbnailImageUrl: item.thumbnail_image_url,
      numberOfMembers: item.solr_ignore_no_of_members,
      name: item.name,
      logoAltText: item.logo_alt_text_s,
      description: item.list_short_description,
      isJoined: item.solr_ignore_is_member || item.solr_ignore_is_owner,
      totalNewPostNumber:
        item.solr_ignore_total_no_of_new_posts_after_last_access,
      communitySlug: stripDomain(
        item.solr_ignore_deep_link_url || item.sheroes_deep_link_s
      ),
      isCommunityOwner: item.solr_ignore_is_owner,
    }));
  obj.push(response);
  obj.push(mutualCommunityRes);
  return item?.status === 'FAILED' ? item : obj;
}

export {
  communityDetailMap,
  communityCategoryMap,
  communityItemMap,
  themeItemMap,
  postToFollowerObj,
  shopItemMap,
  addressMap,
  catalogueMap,
  getDeliveryStatus,
  dashboardDataMap,
  saleSummaryMap,
  generatePaymentLinkMap,
  getCatalogueMap,
  mapShopMedia,
  dashboardCarouselMap,
  dashboardCarouselItemMap,
  mapShopSocialData,
  postToWomenWillObj,
  mapInterestData,
  mutualCommunityItemMap,
};
