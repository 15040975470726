/**
 * Author: Shubham Gupta
 * Date: 28 Mar 2023
 */
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState } from 'react';
import AnyLink from '../components/common/anyLink';
import CustomButton from '../components/common/customButton';
import Footer from '../components/home/footer';
import { useNavBar } from '../hooks/navBarHooks';
import { useTablet } from '../hooks/utilHooks';
import { socialLinkObjSheroesMagazine } from '../utils/homeUtils';
import { sheroesMagazineItems } from '../utils/constants';
import Fade from '@material-ui/core/Fade';
import { getCurrentUser } from '../services/authService';

const SheroesMagazine = () => {
  const isTablet = useTablet();
  const classes = useStyles();
  const user = getCurrentUser();
  const handleDownload = () => {
    window.open('https://shrs.me/arIpKoS8UQ', '_blank');
  };
  useNavBar({
    title: 'Magazine',
    showMenuSideBar: false,
    showCustomRightSideBar: false,
    showBack: true,
  });
  const [showDownload, setShowDownload] = useState(false);
  let prevScrollpos = window.pageYOffset;

  window.onscroll = function () {
    let currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      setShowDownload(false);
    } else {
      setShowDownload(true);
    }
    prevScrollpos = currentScrollPos;
  };

  return (
    <React.Fragment>
      <Box
        className={isTablet ? classes.mobileImg : classes.desktopImg}
        mt={isTablet && !user ? 1 : 0}
      >
        {sheroesMagazineItems &&
          sheroesMagazineItems.map((item, index) => (
            <img key={index} src={item.img} alt={item.name}></img>
          ))}
      </Box>
      <Fade
        in={showDownload}
        style={{ transitionDelay: '200ms' }}
        timeout={800}
      >
        <Box
          className={classes.contentBox}
          justifyContent={isTablet ? 'center' : 'left'}
        >
          <Box
            className={classes.innerContent}
            width={isTablet ? '100%' : '75%'}
          >
            <Box mx={isTablet ? 0 : -3.75} display="flex">
              <Box
                width={isTablet ? '100%' : '75%'}
                position="relative"
                paddingLeft={isTablet ? '3.5%' : '8%'}
                display="flex"
                alignItems="center"
              >
                {!isTablet && (
                  <Typography className={classes.shareTxt}>
                    Share on:
                  </Typography>
                )}
                {socialLinkObjSheroesMagazine &&
                  socialLinkObjSheroesMagazine.map((item, index) => (
                    <AnyLink key={index} to={item.url}>
                      <Box className={classes.icon}>
                        <img key={index} src={item.imgUrl} alt={item.altText} />
                      </Box>
                    </AnyLink>
                  ))}
              </Box>

              <Box
                width={isTablet ? '100%' : '25%'}
                paddingLeft={isTablet ? '13%' : '3%'}
                position={'relative'}
                float="left"
              >
                <CustomButton
                  variant="primary"
                  color="primary"
                  size="large"
                  onClick={handleDownload}
                  className={classes.downloadBtn}
                  text={'DOWNLOAD PDF'}
                  style={{
                    padding: isTablet ? '8px 12px' : '8px 20px',
                    fontSize: isTablet ? '12px' : '14px',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
      <Box
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
        }}
      >
        <Footer />
      </Box>
    </React.Fragment>
  );
};

export default SheroesMagazine;
const useStyles = makeStyles((theme) => ({
  mobileImg: {
    paddingLeft: '4px',
    paddingRight: '4px',
    '& img': {
      width: '100%!important',
    },
  },
  desktopImg: {
    width: '87.7%',
    boxShadow: '0px 0px 8px #9f9f9f',
    margin: '0px auto',
    border: '1px #dcdcdc solid',
  },
  contentBox: {
    borderRadius: '6px',
    boxShadow: '0px 0px 4px #dcdcdc',
    display: 'flex',
    alignItems: 'center',
    left: 0,
    right: 0,
    zIndex: 50,
    padding: '15px 0px',
    position: 'fixed',
    margin: '0px auto',
    fontSize: '16px',
    width: '100%',
    background: '#fff',
    bottom: '0px',
    fontFamily: 'Open Sans !important !important',
    '& img': {
      marginRight: '5px',
    },
  },
  innerContent: {
    margin: '0px auto',
  },
  icon: {
    margin: '0px 5px 0px 5px',
    width: '32px',
    height: '32px',
  },
  downloadBtn: {
    borderRadius: '4px',
    background: '#dc4541',
    margin: '0px 0px',
    fontWeight: 600,
    color: ' #fff',
  },
  shareTxt: {
    color: '#3c3c3c',
    fontSize: '14px',
    letterSpacing: '0.9px',
    margin: '0px 0px',
    padding: '0px 0px',
  },
}));
