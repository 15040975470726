export default {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
  },
  '&multiLine': {
    control: {
      minHeight: 38,
    },
    input: {
      padding: 9,
      border: 'none',
    },
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: 'none',
      boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.15)',
      paddingBottom: '16px',
      maxHeight: 200,
      overflowY: 'auto',
    },
    item: {
      padding: '10px 25px',
      fontWeight: 'normal',
      fontSize: '12px',
      '&focused': {
        backgroundColor: '#DAEFFD',
      },
    },
  },
};
