import uuid from 'uuid';
import http from './httpService';
import { notificationMap } from '../mappers/modelMapper';
import APIURL from '../utils/apiUrl';
import language from '../utils/languages';
import { getPostLanguage } from './authService';

const webLanguage = getPostLanguage();
const languageText = language[webLanguage ? webLanguage : 'en'];

async function getNotificationCount(setToastMessage) {
  const requestBodyCount = {};
  try {
    const { data } = await http.post(
      APIURL.BELL_NOTIFICATION_COUNT,
      requestBodyCount
    );
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data.unreadNotificationCount;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      (ex.response.data.error_message || ex.response.data._message)
    ) {
      setToastMessage &&
        setToastMessage(
          ex.response.data.error_message || ex.response.data._message
        );
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}

async function getNotifications(pageNumber, setToastMessage) {
  const requestBody = { page_size: 20 };
  let notificationDocs = {};
  requestBody.page_no = pageNumber;
  try {
    const { data } = await http.post(APIURL.BELL_NOTIFICATION, requestBody);
    notificationDocs.docs = data.solr_ignore_notification_responses.map((doc) =>
      notificationMap(doc.notification)
    );
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return notificationDocs.docs;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      (ex.response.data.error_message || ex.response.data._message)
    ) {
      setToastMessage &&
        setToastMessage(
          ex.response.data.error_message || ex.response.data._message
        );
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}
function getUuid() {
  return localStorage.getItem('device_guid');
}
function setUuid(uuid) {
  localStorage.setItem('device_guid', uuid);
}

async function savePushNotificationToken(token) {
  const deviceGuid = getUuid() ? getUuid() : uuid();

  const request = {
    device_guid: deviceGuid,
    device_id: 'web-pwa',
    device_type: 'android',
    push_token: token,
    device_name: 'web',
    is_logged_out: false,
    locale: 'eng/en',
    time_zone: 'usa',
    app_version: '8.2.3',
  };
  try {
    const { data } = await http.post(APIURL.SAVE_PUSH_TOKEN, request);
    setUuid(deviceGuid);
    return data;
  } catch (ex) {
    return null;
  }
}

async function markAsRead(id, setToastMessage) {
  try {
    const { data } = await http.post(APIURL.NOTIFICATION_MARK_AS_READ, {
      notification_id: id,
    });

    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}
export default {
  getNotificationCount,
  getNotifications,
  savePushNotificationToken,
  markAsRead,
};
