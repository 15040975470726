import React from 'react';
import CustomButton from '../common/customButton';
import { Typography } from '@material-ui/core';
const PostActionButton = ({ text, icon, handleAction }) => {
  return (
    <CustomButton
      text={
        <Typography
          variant="body1"
          component="span"
          className="displayInlineBlock"
        >
          {text}
        </Typography>
      }
      onClick={handleAction}
      icon={icon}
      variant="iconButton"
    />
  );
};

export default PostActionButton;
