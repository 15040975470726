import { makeStyles } from '@material-ui/core/styles';

const globalStyles = makeStyles((theme) => ({
  //listing only classes which are very generic and used in more than 3-4 components.
  '@global': {
    '.container': {
      width: '1200px',
      maxWidth: '100%',
      margin: '0px auto',
    },
    '.genderDate input[type="date"]::-webkit-calendar-picker-indicator': {
      background:
        'url(https://img.sheroes.in/img/uploads/sheroespage/logoes/1676892883group-626522.svg) no-repeat',
      cursor: 'pointer',
    },
    '.linkColor': {
      color: '#2793e7',
      textDecoration: 'none',
      cursor: 'pointer',
    },
    '.cursorPointer': {
      cursor: 'pointer',
      '& a': {
        color: '#2793e7!important',
        textDecoration: 'none',
      },
    },
    '.stickToBottom': {
      position: 'fixed',
      bottom: 0,
      zIndex: 10,
      left: 0,
    },
    '.userMenuIcon': {
      width: '20px',
      fontSize: '20px',
      color: '#878787',
    },
    '.feedMenuIcon': {
      width: '20px',
      fontSize: '20px',
      color: '#3c3c3c',
    },
    '.stickToTop': {
      position: 'fixed',
      top: 0,
      zIndex: 10,
    },
    '.stepCounter': {
      position: 'fixed',
      bottom: '10px',
      padding: '0px 10px 0px 10px',
    },
    '.displayFlexColumn': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: '20px',
    },
    '.onboardingScrollablePart': {
      width: '100%',
      overflowY: 'scroll',
      height: '450px',
    },
    '.selfieSection': {
      background: '#3c3c3c',
      borderRadius: '10px',
      margin: '10px 0px',
      zIndex: 1,
      height: '280px',
      width: '100%',
      '& img': {
        borderRadius: '10px',
        height: '100%',
        objectFit: 'cover',
      },
    },
    '.boxShadowBottom': {
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
    },
    '.wordBreak': {
      wordBreak: 'break-word',
    },
    '.overflowHidden': {
      overflow: 'hidden',
    },
    '.mxAuto': {
      marginLeft: 'auto!important',
      marginRight: 'auto!important',
    },
    '.positionRelative': {
      position: 'relative',
    },
    '.minHeight100': {
      minHeight: '90vh',
      height: '100%',
    },
    '.clearfix': {
      clear: 'both',
    },
    '.communityTabActive': {
      padding: '0 4px!important',
      color: '#dc4541!important',
      height: '24px',
      marginTop: '7px',
    },
    '.removeBorderRadius': {
      borderRadius: 'inherit',
    },
    '.defaultBorder': {
      border: '1px solid #dcdcdc',
    },
    '.removeUnderline': {
      textDecoration: 'none',
    },
    '.floatLeft': {
      float: 'left',
    },
    '.floatRight': {
      float: 'right',
    },
    '.textDecorationHidden': {
      textDecoration: 'none',
    },
    '.blackLink': {
      textDecoration: 'none',
      color: '#3c3c3c',
    },
    '.championIconStyle': {
      position: 'absolute',
      bottom: '0px',
      right: '-4px',
      width: '16px',
      height: '16px',
    },
    '.postShareIcon': {
      position: 'absolute',
      bottom: '-3px',
      right: '0',
      left: '0',
      margin: '0px auto',
      width: '16px',
      height: '16px',
    },
    '.width100': {
      width: '100%',
    },
    '.homeContainer': {
      width: '1200px',
      maxWidth: '100%',
      paddingRight: '16px',
      paddingLeft: '16px',
    },
    '.navProfileImg': {
      width: '24px',
      borderRadius: '50%',
    },
    '.dashboardNav': {
      '& .navLink': {
        maxHeight: 'inherit',
        width: '100%',
      },
    },
    '.navLink': {
      maxHeight: '540px',
      overflow: 'hidden',
      overflowY: 'auto',
      width: '105%',
    },
    '.navLink a': {
      color: '#3c3c3c',
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        color: '#dc4541',
        textDecoration: 'none',
      },
    },
    '.navLink li': {
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    '.navLink a.active': {
      color: '#dc4541',
    },
    '.removeOutline': {
      '&:focus': {
        outline: 'none',
      },
    },
    '.displayInlineBlock': {
      display: 'inline-block',
      color: '#3c3c3c',
    },
    '.postSingleImg': {
      display: 'block',
      '& img': {
        borderRadius: '12px',
        width: '100%',
        height: '100%',
      },
    },
    '.editor :global(.public-DraftEditor-content)': {
      minHeight: '140px',
    },
    '.bottomNavigationAction': {
      minWidth: '70px',
    },
    '.replyContainer': {
      overflowY: 'auto',
    },
    '.blurImg': {
      opacity: '0.6',
      border: '1px solid #ff0000',
      borderRadius: '4px',
      padding: '1px',
    },
    //Mars style
    '.marsContainer': {
      background: 'white',
      padding: theme.spacing(6, 4),
      borderRadius: '15px',
    },
    '.inputGroup': {
      margin: '0px 0px 15px 0',
    },
    '.marsActiveBtn': {
      width: '100%',
      marginTop: '12px',
      padding: '12px 10px',
      fontSize: '16px',
    },
    '.inputName': {
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(2),
      '& img': {
        marginRight: theme.spacing(2),
      },
    },
    // '.diwaliDiya': {
    //   width: '12%',
    //   verticalAlign: 'middle',
    //   margin: '-8px 0px 0px 20px',
    // },
    '.top-header': {
      background: '#fff',
      color: '#3c3c3c',
      textAlign: 'center',
      '& .header-item': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 8px',
        '& .header-item p': {
          marginRight: '16px',
        },
        '& a': {
          textDecoration: 'none',
        },
      },
    },
    '.sumoTitle': {
      marginRight: '16px',
      fontWeight: '600',
      fontSize: '14px',
    },

    '.sumoButton': {
      lineHeight: '30px',
      height: '34px',
      color: 'rgba(220, 69, 65, 1)',
      WebkitTransition: 'all 0.8s',
      MozTransition: 'all 0.8s',
      OTransition: 'all 0.8s',
      transition: ' all 0.8s',
      border: '2px solid rgba(220, 69, 65, 1)',
      position: 'relative',
      width: '120px',
      fontSize: '14px',
      textAlign: 'center',
      cursor: 'pointer',
      animation: 'glowing 1500ms infinite',
      '& span': {
        zIndex: '2',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
      },
      '&::before': {
        content: `''`,
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: ' 100%',
        zIndex: '1',
        opacity: '0',
        backgroundColor: 'rgba(220, 69, 65, 1)',
        color: '#fff',
        transition: 'all 0.7s',
        transform: ' scale(0.2, 1)',
      },
      '&:hover': {
        '&:before': {
          opacity: '1',
          transform: ' scale(1, 1)',
        },
      },
    },
    '.sumoButton:hover span': {
      color: ' #fff',
    },
    '.catalogueSet .ReactVirtualized__Grid__innerScrollContainer': {
      background: '#fff',
      padding: '0px 16px',
    },
    '.catalogueSet .ReactVirtualized__Grid__innerScrollContainer > div': {
      color: '#fff',
      position: 'inherit!important',
      top: 'inherit!important',
      float: 'left',
      width: '31.5% !important',
      margin: '0px 6px 0px 0px',
    },
    '.dashboardAddIcon': {
      position: 'fixed',
      right: '0',
      bottom: '0',
      zIndex: '1000',
      cursor: 'pointer',
    },
    '.addProductList': {
      display: 'grid',
      gridTemplateColumns: 'repeat(4,1fr)',
      gap: '4px',
    },
    '.addProductList .image-item': {
      position: 'relative',
      width: '80px',
    },
    '.snackbarMain .MuiSnackbarContent-root': {
      background: '#dc4541',
      color: 'white',
    },
    '.videoContent a': {
      color: '#2793e7!important',
    },
    '@keyframes glowing': {
      '0%': {
        backgroundColor: ' #dc4541',
        boxShadow: '0 0 3px #dc4541',
        color: '#fff',
      },
      '50%': {
        backgroundColor: ' #be231f',
        boxShadow: '0 0 20px #be231f',
        color: '#fff',
      },
      '100%': {
        backgroundColor: ' #dc4541',
        boxShadow: '0 0 3px #dc4541',
        color: '#fff',
      },
    },
    '.transparentHeader': {
      backgroundColor: 'inherit',
      minHeight: '0px',
      boxShadow: 'none',
    },
    '.navbarBorder': {
      boxShadow: 'inherit',
    },
    '.videoInner': {
      backgroundColor: '#000',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    '.videoPlayIcon': {
      position: 'absolute',
      top: '45%',
      margin: '0px auto',
      left: '0',
      right: '0',
      zIndex: '2',
      '& g': {
        fill: '#dc4541',
      },
    },
    '.lockGreyIcon': {
      position: 'absolute',
      top: '45%',
      margin: '0px auto',
      left: '0',
      right: '0',
      zIndex: '2',
      '& g': {
        fill: '#4a4a4c',
      },
    },
    '.lockGreyIcon2': {
      position: 'absolute',
      top: '25%',
      margin: '0px auto',
      left: '0',
      right: '0',
      zIndex: '2',
      width: '60px',
      height: '60px',
      '& g': {
        fill: '#4a4a4c',
      },
    },
    '.videoGreyIcon': {
      position: 'absolute',
      top: '45%',
      margin: '0px auto',
      left: '0',
      right: '0',
      zIndex: '2',
      '& g': {
        fill: '#4a4a4c',
      },
    },
    '.videoGreyIcon2': {
      position: 'absolute',
      top: '35%',
      width: '43px',
      height: '43px',
      margin: '0px auto',
      left: '0',
      right: '0',
      zIndex: '2',
      '& g': {
        fill: '#4a4a4c',
      },
    },
    '.dashboardCarouselStyle': {
      '& .react-multi-carousel-track': {
        padding: '0 0 18px 0',
      },
      '& .react-multi-carousel-dot button': {
        borderWidth: 'inherit',
        background: '#878787',
      },
      '& .react-multi-carousel-dot--active button': {
        background: '#dc4541',
      },
    },
    '.snackbar': {
      display: 'inline-block',
      position: 'fixed',
      background: 'rgba(220, 69, 65, 1)',
      color: '#fff',
      minHeight: '50px',
      minWidth: '290px',
      padding: '16px 24px 12px',
      boxSizing: 'border-box',
      boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.26)',
      borderRadius: '2px',
      bottom: '1rem',
      left: '1rem',
      fontSize: '90%',
      transition: 'visibility 0.3s, transform 0.3s',
      transform: 'translateY(100px)',
      visibility: 'hidden',
      willChange: 'transform',
      textTransform: 'capitalize',
    },
    '.snackbar--visible': {
      visibility: 'visible',
      transform: 'none',
    },
    '.feedChapterCarousel .react-multi-carousel-dot-list': {
      bottom: '-22px',
    },
    '.feedChapterCarousel button': {
      zIndex: 99,
    },
    '.feedChapterCarousel .react-multi-carousel-dot button': {
      background: '#878787',
      border: 'none',
    },
    '.feedChapterCarousel .react-multi-carousel-dot--active button': {
      background: '#dc4541',
      border: 'none',
    },
    '.react-multi-carousel-dot button': {
      width: '8px',
      height: '8px',
      marginRight: '4px',
    },
    '.read-more-read-less': {
      background: 'white',
      boxShadow: '0 0 1rem rgba(0,0,0,.1)',
      padding: '1rem',
      borderRadius: '.5rem',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    '.interestLastItem': {
      '&:last-child': {
        // position: 'absolute',
        // top: '25%',
        width: '100%!important',
        '& .MuiCardMedia-root': {
          width: '365px!important',
          backgroundSize: 'contain',
        },
      },
    },
    '.interestLastItemDesktop': {
      '&:last-child': {
        width: '100%!important',
        '& .MuiCardMedia-root': {
          width: '568px!important',
          backgroundSize: 'contain',
        },
      },
    },
    '.menuVertIconStyle': {
      transform: 'rotate(90deg)',
    },
    '.fontSize_26': {
      fontSize: '26px',
    },
    '.fontSize_22': {
      fontSize: '22px',
    },
    '.assignmentBtn': {
      borderColor: '#639cc4',
      color: '#3c3c3c',
      fontSize: '12px',
      padding: '10px 0',
      borderRadius: '24px',
      '&:hover': {
        borderColor: '#639cc4',
        backgroundColor: '#639cc4',
        color: '#fff',
      },
    },
    '.greyBtn': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      border: 'none',
      color: 'rgba(0, 0, 0, 0.55)',
      fontSize: '12px',
      padding: '10px 0',
      borderRadius: '24px',
      '&:hover': {
        border: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.55)',
      },
    },
    '.askBtn': {
      borderColor: '#019d53',
      color: '#019d53',
      fontSize: '12px',
      padding: '10px 0',
      borderRadius: '24px',
      '&:hover': {
        borderColor: '#019d53',
        backgroundColor: '#019d53',
        color: '#fff',
      },
    },
    '.btnWidth2': {
      width: '49%',
    },
    '.btnWidth33': {
      width: '32%',
    },
    '.btnWidth100': {
      width: '100%',
    },
    '.playerWrapper': {
      width: '100%',
      position: 'relative',
      '&:hover': {
        '& .makeStyles-playIcon-111': {
          visibility: 'visible',
        },
      },
      '& div': {
        height: 'auto!important',
      },
    },
    '.courseProgressBar': {
      position: 'relative',
    },

    '.courseProgressBar .MuiSlider-root.MuiSlider-colorPrimary': {
      color: '#019d53',
    },
    '.courseProgressBar .MuiSlider-root': {
      padding: '0px!important',
    },
    '.courseProgressBar .MuiSlider-track': {
      height: '7px',
      borderRadius: '20px',
    },
    '.courseProgressBar .MuiSlider-rail': {
      opacity: '0.45',
      borderRadius: '20px',
      height: '5px',
      border: '1px solid #3c3c3c',
      backgroundColor: 'transparent',
    },
    '.courseProgressBar .MuiSlider-thumb': {
      marginTop: '-3px',
    },
    '.courseProgressBar .sliderLeftThumb': {
      width: '12px',
      height: '12px',
      position: 'absolute',
      borderRadius: '50%',
      backgroundColor: '#019d53',
      top: '10px',
    },
    '.courseTabContainer': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: 'none',
      position: 'fixed',
      top: '96px',
      left: '0px',
      right: '0px',
      margin: '0px auto',
      width: '544px',
    },
    '.courseTabContainerForEnHiUser': {
      top: '98px',
      boxShadow: 'none',
    },
    '.courseTabForNonEnHiLangMobile': {
      top: '50px',
      boxShadow: 'none',
    },
    '.courseTabForNonEnHiLang': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: 'none',
      position: 'fixed',
      top: '50px',
      left: '0px',
      right: '0px',
      margin: '0px auto',
      width: '544px',
    },
    '.feedTabContainer': {
      width: '544px',
    },
    '.mentionsInput': {
      width: '100%',
    },
    '.mentionsInput__input': {
      outline: 'inherit!important',
    },
    '.otpTextField': {
      width: '100%',
      margin: '24px 0px',
    },
    '.otpTextField .MuiInput-underline:before': {
      border: '1px solid',
      borderRadius: '3px',
      height: '50px',
    },
    '.otpTextField .MuiInputBase-input': {
      height: '35px',
    },
    '.rightSection': {
      position: 'absolute',
      zIndex: 1,
      width: '1200px',
      maxWidth: '100%',
      left: 0,
      right: 0,
      top: '60px',
      margin: '0px auto',
    },
    '.rightBox': {
      position: 'absolute',
      zIndex: 1,
      top: '10%',
      right: '0',
      width: '346px',
      height: '630px',
      background: '#FFFFFF',
      border: '1.5px solid #FFFFFF',
      borderRadius: '10px',
    },
    '.verify-otp-input': {
      '& input[value=""]': {
        border: '1px solid #747474',
        boxShadow: 'none',
      },
    },
    '.mobileEmailForm-owl-carousel .owl-stage-outer .owl-stage': {
      height: '100%',
      display: 'flex',
      marginTop: '25px',
      alignItems: 'center',
      padding: '10px',
      '& .owl-item': {
        padding: '12px',
        transform: 'scale(0.8)',
        top: '-12px',
        '& p': {
          padding: '0px 10px',
        },
      },
      '& .owl-item.active': {
        transition: 'all 0.5s',
      },
      '& .owl-item.active.center': {
        top: '0px !important',
        '& div img': {
          transform: 'scale(2.6)',
          marginBottom: '40px',
        },
        '& p': {
          transform: 'scale(1.5)',
          lineHeight: '12px',
          marginTop: '10px',
          padding: '3px 0px',
        },
      },
    },
    '@keyframes carousel': {
      '0%': {
        transform: 'scaleX(0)',
      },
      '100%': {
        transform: 'scale(2.3)',
      },
    },
    '.onboardingDisabledBtn': {
      borderRadius: '22px',
      height: '42px',
      fontSize: '16px',
      fontWeight: '600',
      color: '#fff !important',
      backgroundColor: '#C3ACA5 !important',
      marginBottom: '0.8rem',
    },
    '.onboardingActiveBtn': {
      borderRadius: '22px',
      fontWeight: '600',
      height: '42px',
      fontSize: '16px',
      boxShadow: 'none',
      marginBottom: '0.8rem',
    },
    '.blurContent': {
      filter: 'blur(4px)',
      background: 'rgba(0, 0, 0, 0.05)',
      position: 'fixed',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    '.blurFalse': {
      position: 'fixed',
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    '.webcamMain': {
      position: 'absolute',
      width: '100%',
      left: '0%',
      height: '100%',
      top: '0%',
      '& video': {
        borderRadius: '10px',
      },
    },
    '.selfieMask': {
      position: 'absolute',
      left: '0px',
      width: '100%',
      top: 0,
      borderRadius: '9px',
    },

    '.saireeProfileImg': {
      borderRadius: '50%',
      border: '10px solid #fff',
      margin: theme.spacing('-260px', 0, 0, 0),
      boxShadow:
        'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
    },
    '.loginBtn': {
      fontSize: '1rem',
      fontWeight: '500',
      minWidth: '17.813rem',
      textDecoration: 'none',
      padding: '0.688rem 1.5rem',
      boxShadow: '2px 2px 20px 2px rgba(0, 0, 0, 0.06)',
      borderRadius: '35px',
      '&:hover': {
        background: '#fff',
        color: '#484747',
      },
    },
    '.onboardingTitle': {
      margin: '12px',
      fontSize: 18,
      fontWeight: 600,
    },
    '.loginBtnContainer': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: '1.20rem',
      marginTop: '5%',
    },
    '.otpEnterSection': {
      margin: '-30px 0px 0px 0px',
    },

    // Media-queries
    '@media only screen and (min-width:1921px) and (max-width:4320px)': {
      '.slider1_animated_bubble2': {
        left: '50%',
      },
      '.bottomSideAnimatedBubble': {
        left: '38%',
      },
      '.slider-content': {
        height: 'auto',
      },
    },
    '@media (min-width:1025px) and (max-width:1280px)': {
      '.courseTabContainer': {
        width: '42%',
      },
      '.feedTabContainer': {
        width: '42%',
      },
      '.loginBtn': {
        padding: '0.25rem 0px!important',
      },
      '.loginBtnContainer': {
        rowGap: '0.7rem',
        marginTop: '2%',
      },
      '.mobileEmailForm-owl-carousel .owl-stage-outer .owl-stage': {
        '& .owl-item': {
          padding: '10px',
          transform: 'scale(0.5)',
          top: '-40px',
          '& p': {
            padding: '0px 10px',
          },
        },
        '& .owl-item.active': {
          transition: 'all 0.5s',
        },
        '& .owl-item.active.center': {
          top: '-30px !important',
          '& div img': {
            transform: 'scale(2.3)',
            marginBottom: '30px',
          },
          '& p': {
            transform: 'scale(1.4)',
            lineHeight: '12px',
            padding: '3px 0px',
          },
        },
      },
      '.otpEnterSection': {
        bottom: '20%',
      },
      '.onboardingTitle': {
        fontSize: '17px',
      },
      '.onboardingScrollablePart': {
        height: '300px',
      },
      '.selfieSection': {
        height: '200px',
      },
      '.rightBox': {
        height: '480px',
      },
    },

    '@media (max-width:1024px)': {
      '.courseTabContainer': {
        width: '100%',
        top: '122px',
      },
      '.feedTabContainer': {
        width: '100%',
      },
    },
    '@media (max-width:767px)': {
      '.loginBtn': {
        fontSize: '0.9rem',
        fontWeight: '600',
        minWidth: '17.813rem',
        textDecoration: 'none',
        padding: '0.35rem 0px!important',
        borderRadius: '25px',
        '&:hover': {
          background: '#fff',
          color: '#484747',
        },
      },
      '.saireeProfileImg': {
        border: '6px solid #fff',
        marginTop: '-130px',
      },
      '.rightSection': {
        position: 'inherit',
        width: '100%',
        margin: '0px auto 0 auto',
      },
      '.rightBox': {
        top: '0%',
        width: '100%',
        height: 'auto',
        bottom: '0',
        overflowY: 'hidden',
        borderRadius: 0,
      },
      '.home-middle-section': {
        margin: '70px 0px 0px 0px',
      },
      '.selfieMask': {
        position: 'inherit',
        borderRadius: '0px',
      },
      '.webcamMain': {
        position: 'absolute',
        width: '100%',
        left: '0',
        height: '100%',
        top: '0',
        '& video': {
          borderRadius: '0px',
        },
      },
      '.loginBtnContainer': {
        rowGap: '0.8rem',
        marginTop: '6%',
      },
    },
  },
}));

export default globalStyles;
