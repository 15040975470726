import React, { useState } from 'react';
import {
  Dialog,
  Box,
  RadioGroup,
  Radio,
  FormControl,
  List,
  ListItem,
  DialogTitle,
  Typography,
  FormControlLabel,
} from '@material-ui/core';
import CustomButton from './customButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { useStoreState, useStoreActions } from 'easy-peasy';
import postService from '../../services/postService';
import { useSnackBar } from '../../hooks/snackBarHooks';
import { getPostObjForEventTracking } from '../../utils/postUtil';
import analytics from '../../services/analyticsService';
import { eventNames } from '../../utils/eventConstants';
import { getPostLanguage } from '../../services/authService';
import languages from '../../utils/languages';

const ReportSpam = () => {
  const [spamInfo, setSpamInfo] = useState({
    score: 0,
    reason: '',
  });
  const classes = useStyles();
  const setToastMessage = useSnackBar('');
  const webLanguage = getPostLanguage();
  const languageText = languages[webLanguage ? webLanguage : 'en'];
  const { user } = useStoreState((state) => state.user);
  const { showSpamModal, spamReasons, contentType, docInfo } = useStoreState(
    (state) => state.reportSpam
  );
  const postObj = useStoreState(
    (state) => state.posts.postsObj[docInfo && docInfo.docId]
  );
  const { updateSpamState, fetchSpamReasons } = useStoreActions(
    (actions) => actions.reportSpam
  );

  spamReasons || fetchSpamReasons();

  const handleChange = (reason) => {
    setSpamInfo({ reason: reason.reason, score: reason.score });
  };

  const handleSubmit = async () => {
    const eventPropsObj = postObj && getPostObjForEventTracking(postObj);
    setSpamInfo({ score: 0, reason: '' });
    try {
      await postService.reportSpam(
        docInfo,
        spamInfo,
        user.id,
        contentType,
        setToastMessage
      );
      analytics.track(eventNames.postReported, eventPropsObj);
      updateSpamState({ showSpamModal: false });
      setToastMessage('Your report has been submitted successfully.');
    } catch (error) {
      updateSpamState({ showSpamModal: false });
    }
  };
  return (
    <Dialog
      className={classes.dialogBox}
      disableBackdropClick={false}
      open={showSpamModal}
      onClose={() => {
        updateSpamState({ showSpamModal: false });
      }}
    >
      <Box className={classes.closeDialogBox} justifyContent="flex-end" p={6}>
        <CustomButton
          onClick={() => {
            updateSpamState({ showSpamModal: false });
          }}
          variant="iconButton"
          icon={<CloseIcon style={{ color: 'white', fontSize: '26px' }} />}
        />
      </Box>
      <FormControl component="fieldset">
        <DialogTitle
          className={classes.dialogTitleBox}
          id="simple-dialog-title"
        >
          <Typography variant="body2">
            {languageText?.HELP_US_UNDERSTAND}
          </Typography>
          <Typography variant="body1">
            {languageText?.WHY_IS_IT_INAPPROPRIATE}
          </Typography>
        </DialogTitle>
        <Box className={classes.reasonsList}>
          <List>
            {spamReasons &&
              contentType &&
              spamReasons[contentType].map((reason, index) => (
                <ListItem key={index}>
                  <RadioGroup
                    className={classes.group}
                    value={spamInfo.reason}
                    onChange={() => handleChange(reason)}
                  >
                    <FormControlLabel
                      value={reason.reason}
                      control={<Radio color="primary" />}
                      label={reason.label}
                    />
                  </RadioGroup>
                </ListItem>
              ))}
          </List>
        </Box>
        <CustomButton
          fullWidth={true}
          variant="primary"
          onClick={handleSubmit}
          text={languageText?.REPORT}
          size="regular"
          disabled={spamInfo && spamInfo.reason ? false : true}
        ></CustomButton>
      </FormControl>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogTitleBox: {
    backgroundColor: theme.palette.grey['700'],
  },
  closeDialogBox: {
    top: '2%',
    right: 0,
    position: 'fixed',
  },
}));

export default ReportSpam;
