import { action, thunk } from 'easy-peasy';
import postService from '../services/postService';
import pollService from '../services/pollService';
import communityService from '../services/communityService';
import analytics from '../services/analyticsService';
import { eventNames, eventProps } from '../utils/eventConstants';
import { getPostObjForEventTracking } from '../utils/postUtil';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import gWW from '../services/gWW';
import { getPostLanguage } from '../services/authService';
import { urlMap } from '../utils/constants';
dayjs.extend(utc);

export default {
  postsObj: {},
  replyOnCommentsObj: {},
  deletePostResult: null,
  editCommentObj: { isCommentEditable: false },
  setPostState: action((state, docs) => {
    const posts = {};
    if (typeof docs !== 'undefined') {
      const newDocs =
        docs &&
        docs.map((doc, i) => {
          //using this if block, to make carousel id unique
          if (doc && doc.id === 'w-U') {
            doc.id += i;
            return doc;
          } else {
            return doc;
          }
        });
      newDocs.map((doc) => (posts[doc.id] = doc));
      state.postsObj = { ...state.postsObj, ...posts };
    }
  }),
  updatePostState: action((state, postObj) => {
    const currentTime = dayjs.utc().format();
    if (postObj && dayjs(postObj.postCreatedAt).isAfter(currentTime)) {
      postObj.isSchedulePost = true;
    }
    state.postsObj[postObj.id] = postObj;
  }),
  setEditCommentObj: action((state, payload) => {
    state.editCommentObj = payload;
  }),
  updateReplyOnComment: action((state, post) => {
    state.replyOnCommentsObj = post;
  }),
  fetchPost: thunk(
    async (
      actions,
      { postEntityId, postSubType, isPostDetail, history, isLecture }
    ) => {
      try {
        let post = {};
        post = isLecture
          ? await gWW.getLectureDetail(postEntityId)
          : await postService.getPost(postEntityId, postSubType, isLecture);
        actions.updatePostState(post);
        return post.id; //need to return the postId in order to get corresponding postObj directly from store.
      } catch (error) {
        if (isPostDetail) history.replace('/');
      }
    }
  ),
  likePost: thunk(
    async (
      actions,
      { postId, postEntityId, likeStatus, setToastMessage },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      const prevPostLikesCount = postObj.likesCount;
      const prevPostLikeStatus = postObj.isLiked;

      try {
        const data = await pollService.toggleLike(
          postEntityId,
          likeStatus,
          setToastMessage
        );
        if (data && data.status === 'SUCCESS') {
          postObj.isLiked = likeStatus;
          postObj.shouldRecompute = true;
          postObj.likesCount = likeStatus
            ? prevPostLikesCount + 1
            : prevPostLikesCount - 1;
          actions.updatePostState(postObj);
          const eventName = postObj.isLiked
            ? eventNames.postLiked
            : eventNames.postUnLiked;
          analytics.track(eventName, getPostObjForEventTracking(postObj));
        }
      } catch (error) {
        postObj.isLiked = prevPostLikeStatus;
        postObj.likesCount = prevPostLikesCount;
      }
    }
  ),

  likeComment: thunk(
    async (
      actions,
      {
        postId,
        postEntityId,
        commentIndex,
        likeStatus,
        commentId,
        isPostDetail,
        commentReplyIndex,
        parentCommentIndex,
        isCommentDetail,
        isArticleDetail,
        setToastMessage,
      },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      let commentObj =
        isPostDetail || isArticleDetail
          ? isCommentDetail
            ? postObj.comments[parentCommentIndex].replyOnComment[
                commentReplyIndex
              ]
            : postObj.comments[commentIndex]
          : postObj.lastComment[0];
      const prevPostLikesCount = commentObj.likesCount;
      commentObj.isLiked = likeStatus;
      commentObj.likesCount = likeStatus
        ? prevPostLikesCount + 1
        : prevPostLikesCount - 1;
      const commentsCount = postObj.comments.length;
      const lastComment = commentIndex === commentsCount - 1;
      if ((isPostDetail || isArticleDetail) && !isCommentDetail) {
        postObj.comments[commentIndex] = commentObj;
        if (lastComment) {
          postObj.lastComment[0] = commentObj;
        }
      } else if (isPostDetail && isCommentDetail) {
        postObj.comments[parentCommentIndex].replyOnComment[commentReplyIndex] =
          commentObj;
      } else {
        postObj.lastComment[0] = commentObj;
        postObj.comments[commentsCount - 1] = commentObj;
      }

      const data = await pollService.toggleCommentLike(
        postEntityId,
        commentId,
        likeStatus,
        setToastMessage
      );
      if (data && data.status === 'SUCCESS') {
        actions.updatePostState(postObj);

        const eventName = commentObj.isLiked
          ? eventNames.postLiked
          : eventNames.postUnLiked;
        analytics.track(eventName, getPostObjForEventTracking(commentObj));
        commentObj.isLiked = likeStatus;
        commentObj.likesCount = likeStatus
          ? prevPostLikesCount + 1
          : prevPostLikesCount - 1;
      }
    }
  ),

  repost: thunk(
    async (
      actions,
      { postId, postEntityId, repostStatus, setToastMessage },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      const prevRepostsCount = postObj.repostsCount;
      const prevPostrepostStatus = postObj.isReposted;
      postObj.isReposted = repostStatus;
      postObj.shouldRecompute = true;
      postObj.repostsCount = repostStatus
        ? prevRepostsCount + 1
        : prevRepostsCount - 1;
      try {
        actions.updatePostState(postObj);
        await pollService.toggleRepost(
          postEntityId,
          repostStatus,
          setToastMessage
        );
        const eventName = postObj.isReposted
          ? eventNames.postReposted
          : eventNames.postUnReposted;
        analytics.track(eventName, getPostObjForEventTracking(postObj));
      } catch (exception) {
        postObj.isReposted = prevPostrepostStatus;
        postObj.repostsCount = prevRepostsCount;
      }
    }
  ),

  fetchPostComments: thunk(
    async (
      actions,
      {
        postId,
        postEntityId,
        pageNum,
        shouldPrepend,
        lectureEntityId,
        isLecture,
        setToastMessage,
      },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      try {
        const comments = await postService.getPostComments({
          postEntityId,
          pageNum,
          lectureEntityId,
          isLecture,
          setToastMessage,
        });
        // console.log('postEntityId from store:', postEntityId);
        // console.log('postId from store:', postId);
        postObj.fromReplies = false;
        postObj.comments = shouldPrepend
          ? [...comments, ...postObj.comments]
          : [...postObj.comments, ...comments];
        actions.updatePostState(postObj);
      } catch (error) {}
    }
  ),

  saveCommentPost: thunk(
    async (
      actions,
      {
        postId,
        postEntityId,
        commentObj,
        isAnonymous,
        commentUserMention,
        commentId,
        setHandleErrorMessage,
        lectureEntityId,
        isLecture,
        isReplies,
        isCommentActive,
        participantId,
        setToastMessage,
      },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      const prevComments = [...postObj.comments];
      const prevCommentsCount = postObj.commentsCount;
      const editCommentObj = getState().editCommentObj;
      try {
        const comment = editCommentObj.isCommentEditable
          ? await postService.editComment(
              isAnonymous,
              isCommentActive,
              postEntityId,
              commentObj[0].description,
              participantId,
              editCommentObj?.comment.id,
              isLecture,
              isReplies,
              commentUserMention,
              setToastMessage
            )
          : await postService.saveComment(
              postEntityId,
              commentObj[0].description,
              isAnonymous,
              commentUserMention,
              commentId,
              lectureEntityId,
              isLecture,
              isReplies,
              setToastMessage
            );

        if (commentId) {
          //  this part is for replies page
          if (editCommentObj.isCommentEditable) {
            if (editCommentObj?.isParentComment) {
              const replyComment = [
                ...postObj.comments[editCommentObj?.replyParentCommentIndex]
                  .replyOnComment,
              ];
              const prevReplyCommentCount =
                postObj.comments[editCommentObj?.replyParentCommentIndex]
                  .repliesCount;
              postObj.comments[editCommentObj?.replyParentCommentIndex] =
                comment;
              postObj.comments[
                editCommentObj?.replyParentCommentIndex
              ].replyOnComment = [...replyComment];

              postObj.comments[
                editCommentObj?.replyParentCommentIndex
              ].repliesCount = prevReplyCommentCount;
              postObj.lastComment = [comment];
              postObj.comments = [...postObj.comments];
            } else {
              const commentIndex = postObj.comments.findIndex(
                (comment) => comment.commentId === commentId
              );
              postObj.comments[commentIndex].replyOnComment[
                editCommentObj?.commentIndex
              ] = comment;
              postObj.comments = [...postObj.comments];
            }
            actions.setEditCommentObj({ isCommentEditable: false });
          } else {
            const commentIndex = postObj.comments.findIndex(
              (comment) => comment.commentId === commentId
            );
            const replyComment = [
              ...postObj.comments[commentIndex].replyOnComment,
            ];
            postObj.comments[commentIndex].replyOnComment = [
              ...replyComment,
              comment,
            ];
            const prevReplyCommentCount =
              postObj.comments[commentIndex].repliesCount;
            postObj.comments[commentIndex].repliesCount =
              prevReplyCommentCount + 1;
          }
        } else {
          //  this part is for detail page
          if (editCommentObj.isCommentEditable) {
            if (editCommentObj && editCommentObj.isLastComment) {
              const replyComment = [
                ...postObj.comments[postObj.comments.length - 1].replyOnComment,
              ];
              const prevReplyCommentCount =
                postObj.comments[postObj.comments.length - 1].repliesCount;
              postObj.comments[postObj.comments.length - 1] = comment;
              postObj.comments[postObj.comments.length - 1].replyOnComment = [
                ...replyComment,
              ];

              postObj.comments[postObj.comments.length - 1].repliesCount =
                prevReplyCommentCount;
              postObj.lastComment = [comment];

              postObj.comments = [...postObj.comments];
            } else {
              const editedCommentIndex =
                editCommentObj &&
                editCommentObj.commentIndex !== undefined &&
                editCommentObj.commentIndex !== null &&
                editCommentObj.commentIndex;
              const replyComment = [
                ...postObj.comments[editedCommentIndex].replyOnComment,
              ];
              const prevReplyCommentCount =
                postObj.comments[editedCommentIndex].repliesCount;
              postObj.comments[editedCommentIndex] = comment;
              postObj.comments[editedCommentIndex].replyOnComment = [
                ...replyComment,
              ];
              postObj.comments[editedCommentIndex].repliesCount =
                prevReplyCommentCount;
              postObj.lastComment = [comment];

              postObj.comments = [...postObj.comments];
            }

            actions.setEditCommentObj({ isCommentEditable: false });
          } else {
            postObj.comments = [...postObj.comments, ...commentObj];
            postObj.commentsCount = prevCommentsCount + 1;
            postObj.lastComment = [comment];
            postObj.comments = [...prevComments, comment];
          }
        }
        postObj.shouldRecompute = true;
        actions.updatePostState(postObj);
        analytics.track(eventNames.replyCreated, {
          [eventProps.body]: commentObj[0].description,
          [eventProps.postId]: postObj.entityId,
          [eventProps.subType]: postObj.subType,
          [eventProps.streamType]: postObj.streamType,
        });
        return '';
      } catch (error) {
        error && setHandleErrorMessage(error?.response?.data);
        postObj.comments = prevComments;
        postObj.commentsCount = prevCommentsCount;
        return commentObj[0].description; //need to return the comment which was written to show it in the box if any error is thrown.
      }
    }
  ),
  saveMentorCommentPost: thunk(
    async (
      actions,
      {
        postId,
        postEntityId,
        commentObj,
        isAnonymous,
        commentUserMention,
        commentId,
        setHandleErrorMessage,
        lectureEntityId,
        isLecture,
        isReplies,
        setToastMessage,
      },
      { getState }
    ) => {
      const post = getState().replyOnCommentsObj.replyOnComment;
      const prevComments = [...post];
      try {
        const comment = await postService.saveComment(
          postEntityId,
          commentObj[0].description,
          isAnonymous,
          commentUserMention,
          commentId,
          lectureEntityId,
          isLecture,
          isReplies,
          setToastMessage
        );
        post.replyOnComment = [...prevComments, comment];
        actions.updateReplyOnComment(post);
        // analytics.track(eventNames.replyCreated, {
        //   [eventProps.body]: commentObj[0].description,
        //   [eventProps.postId]: postObj.entityId,
        //   [eventProps.subType]: postObj.subType,
        //   [eventProps.streamType]: postObj.streamType,
        // });
        return '';
      } catch (error) {
        //   error && setHandleErrorMessage(error?.response?.data);
        //   postObj.comments = prevComments;
        //   postObj.commentsCount = prevCommentsCount;
        //   return commentObj[0].description; //need to return the comment which was written to show it in the box if any error is thrown.
      }
    }
  ),
  deleteCommentPost: thunk(
    async (
      actions,
      {
        postId,
        postEntityId,
        commentId,
        isCommentDetail,
        parentCommentIndex,
        history,
        setToastMessage,
      },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      let prevComments = '';
      let prevCommentsCount = '';
      let prevReplyComments = '';
      let prevReplyCommentsCount = '';

      try {
        const data = await postService.deleteComment(
          postEntityId,
          commentId,
          setToastMessage
        );
        if (data && data.status && data.status === 'FAILED') {
          return null;
        } else {
          if (parentCommentIndex !== undefined && isCommentDetail) {
            prevReplyComments = [
              ...postObj.comments[parentCommentIndex].replyOnComment,
            ];
            prevReplyCommentsCount =
              postObj.comments[parentCommentIndex].repliesCount;
            postObj.comments[parentCommentIndex].replyOnComment =
              postObj.comments[parentCommentIndex].replyOnComment.filter(
                (commentReply) => {
                  return commentReply.id !== commentId;
                }
              );
            postObj.comments[parentCommentIndex].repliesCount =
              prevCommentsCount - 1;
          } else {
            prevComments = [...postObj.comments];
            prevCommentsCount = postObj.commentsCount;
            postObj.comments = postObj.comments.filter((comment) => {
              return comment.id !== commentId;
            });
            postObj.commentsCount = prevCommentsCount - 1;
            postObj.lastComment = postObj.comments.length
              ? [postObj.comments[postObj.commentsCount - 1]]
              : [];
          }
          postObj.shouldRecompute = true;
          console.log(
            prevComments,
            prevCommentsCount,
            prevReplyComments,
            prevReplyCommentsCount
          );
          actions.updatePostState(postObj);
          actions.updatePostState(postObj);
          analytics.track(eventNames.replyDeleted, {
            [eventProps.postId]: postObj.entityId,
            [eventProps.subType]: postObj.subType,
            [eventProps.streamType]: postObj.streamType,
          });
          if (postObj.fromReplies && !isCommentDetail) {
            history.replace(postObj.slug);
          }
        }
      } catch (error) {}
    }
  ),
  savePost: thunk(
    async (
      actions,
      {
        createPostRequestBody,
        files,
        history,
        isEditablePost,
        setErrorMessage,
        lastLocation,
        fromScreen,
        setHandleErrorMessage,
        isPollPost,
        isVideoPost,
        setCreatePostLoading,
      }
    ) => {
      setCreatePostLoading(true);
      const api = isPollPost
        ? postService.savePollPost(createPostRequestBody, setHandleErrorMessage)
        : postService.savePost(
            createPostRequestBody,
            files,
            isVideoPost,
            setErrorMessage
          );

      const postObj = await api;
      setCreatePostLoading(false);
      if (postObj) {
        if (!isEditablePost) postObj.isNewPost = true;
        postObj.shouldRecompute = true;
        actions.updatePostState(postObj);
        const eventName = isEditablePost
          ? eventNames.postEdited
          : eventNames.postCreated;
        analytics.track(eventName, getPostObjForEventTracking(postObj));
        if (
          (lastLocation && lastLocation.pathname !== '/create-post') ||
          (fromScreen && fromScreen !== '/create-post')
        ) {
          history.goBack();
        } else {
          const webLanguage = getPostLanguage();
          const enHiLang = webLanguage === 'en' || webLanguage === 'hi';
          if (enHiLang) {
            history.replace(urlMap.SOCIAL_TAB);
          } else if (webLanguage === 'mr') {
            history.replace(urlMap.COURSE_TAB);
          } else {
            history.replace(urlMap.FEED_TAB);
          }
        }
      }
    }
  ),
  deletePost: thunk(
    async (
      actions,
      { postId, postEntityId, isPostDetail, history, setToastMessage },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      try {
        const status = await postService.deletePost(
          postEntityId,
          setToastMessage
        );
        if (status === 'SUCCESS') {
          analytics.track(
            eventNames.postDeleted,
            getPostObjForEventTracking(postObj)
          );
          if (isPostDetail) history.replace('/');
          actions.deletePostSuccess({
            status: 'SUCCESS',
            postId,
            postEntityId,
            isPostDetail,
            history,
            setToastMessage,
          });
        } else {
          actions.deletePostFailure({
            status: 'FAILED',
            postId,
            postEntityId,
            isPostDetail,
            history,
            setToastMessage,
          });
        }
      } catch (error) {
        actions.deletePostFailure({
          status: 'FAILED',
          postId,
          postEntityId,
          isPostDetail,
          history,
          setToastMessage,
        });
      }
    }
  ),
  deletePostSuccess: action((state, payload) => {
    state.deletePostResult = payload;
  }),

  deletePostFailure: action((state, payload) => {
    state.deletePostResult = payload;
  }),
  submitPoll: thunk(
    async (
      actions,
      { pollId, pollOptionId, setToastMessage },
      { getState }
    ) => {
      try {
        const postObj = await pollService.submitPoll(
          pollId,
          pollOptionId,
          setToastMessage
        );
        const pollObj = getState().postsObj[postObj.id];
        analytics.track(
          eventNames.pollVoted,
          getPostObjForEventTracking(postObj)
        );
        postObj.comments = pollObj.comments;
        postObj.lastComment = pollObj.lastComment;
        actions.updatePostState(postObj);
      } catch (error) {
        return error;
      }
    }
  ),

  joinCommunity: thunk(
    async (
      actions,
      { communityId, userId, toJoin, history, setToastMessage },
      { getStoreActions, getStoreState }
    ) => {
      try {
        const communityData = await communityService.joinCommunity(
          communityId,
          userId,
          toJoin,
          history,
          setToastMessage
        );
        let communityJoinedStatus = false;
        const communityDetail = {
          ...getStoreState().community.communities[communityId]
            .communityDetails,
        };
        if (communityData.status === 'SUCCESS') {
          communityJoinedStatus = toJoin;
          analytics.track(eventNames.communityJoined, {
            [eventProps.id]: communityId,
            [eventProps.communityName]: communityDetail.name,
          });
        }
        communityDetail.isMember = communityJoinedStatus;

        getStoreActions().community.setCommunityDetails(communityDetail);
      } catch (e) {
        //setErrorMessage('Please try Again');
        return e;
      }
    }
  ),
  approveOrDeletePost: thunk(
    async (
      actions,
      { postId, entityId, is_spam, is_active, is_approved, setToastMessage },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      try {
        postObj.isSpamPost = false;
        postObj.shouldRecompute = true;
        actions.updatePostState(postObj);
        await postService.approveOrDeletePost(
          entityId,
          is_spam,
          is_active,
          is_approved,
          setToastMessage
        );
      } catch (error) {}
    }
  ),
  featurePost: thunk(
    async (
      actions,
      { isTopPost, postId, entityId, setToastMessage },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      const changePostStatus = isTopPost ? false : true;
      const data = await postService.featurePost(
        changePostStatus,
        entityId,
        setToastMessage
      );

      if (data && data.status === 'SUCCESS') {
        postObj.isTopPost = !isTopPost;
        postObj.shouldRecompute = true;
        actions.updatePostState(postObj);

        if (postObj && postObj.optionsMenu.length > 0) {
          postObj.optionsMenu.forEach((option) => {
            if (option.id === 'featurePost') {
              option.name = postObj.isTopPost
                ? 'UnFeature This Post'
                : 'Feature This Post';
            }
          });
        }
      }
    }
  ),
  fetchReplyOnComments: thunk(
    async (
      actions,
      {
        postId,
        postEntityId,
        commentId,
        pageNum,
        commentIndex,
        postSubType,
        selectedPostId,
        setToastMessage,
      },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      if (!postObj) {
        let post = [];
        post[0] = await postService.getPost(selectedPostId, postSubType);
        const parentComments =
          postEntityId &&
          (await postService.getPostComments({
            postEntityId,
            pageNum,
            setToastMessage,
          }));

        post[0].comments = [...parentComments];
        actions.setPostState(post);
      }
      try {
        const postObj = getState().postsObj[postId];
        const comments =
          postEntityId &&
          (await postService.getReplyOnComments(
            postEntityId,
            commentId,
            pageNum
          ));
        postObj.shouldRecompute = true;
        postObj.fromReplies = true;
        postObj.comments[commentIndex].replyOnComment = [...comments];
        actions.updatePostState(postObj);
      } catch (error) {}
    }
  ),
  fetchCommentOnComments: thunk(
    async (actions, { postEntityId, commentId, pageNum }, { getState }) => {
      let post = [];
      // const parentComments = await postService.getPostCommentsById(commentId);
      // console.log('parentComments:', parentComments);
      // post.comments = [parentComments.docs];
      // console.log('post P:', post);

      try {
        const replyComment = await postService.getReplyOnComments(
          postEntityId,
          commentId,
          pageNum
        );
        post.replyOnComment = [...replyComment];
        actions.updateReplyOnComment(post);
      } catch (error) {}
    }
  ),

  bookmarkOnPost: thunk(
    async (
      actions,
      { postId, entityId, isBookmark, setToastMessage, languageText },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      try {
        const changePostStatus = postObj.isBookmarked ? false : true;
        postObj.isBookmarked = changePostStatus;
        postObj.shouldRecompute = true;
        if (postObj && postObj.optionsMenu.length > 0) {
          postObj.optionsMenu.forEach((option) => {
            if (option.id === 'bookmark') {
              option.name = postObj.isBookmarked
                ? languageText.REMOVE_BOOKMARK
                : languageText.BOOKMARK_POST;
            }
          });
        }
        const data = await pollService.toggleBookmark(
          entityId,
          isBookmark,
          setToastMessage
        );
        data?.status === 'SUCCESS' &&
          setToastMessage(
            isBookmark
              ? languageText?.POST_BOOKMARKED
              : languageText?.POST_UNBOOKMARKED
          );
      } catch (error) {}
    }
  ),

  // fetchLectureDetail: thunk(
  //   async (actions, { id, history }) => {
  //     console.log('id from store:', id);
  //     try {
  //       const post = await gWW.getLectureDetailData(id);
  //       console.log('post from store:', post);
  //       actions.updatePostState(post);
  //       return post.id; //need to return the postId in order to get corresponding postObj directly from store.
  //     } catch (error) {
  //       //if (isPostDetail) history.replace('/');
  //     }
  //   }
  // ),
};
