import { action, thunk } from 'easy-peasy';
import shopOnSheroesService from '../services/shopOnSheroesService';
import { getCurrentUser } from '../services/authService';
import { shopRedirectUrl, urlMap } from '../utils/constants';
import communityService from '../services/communityService';
import { getPostLanguage } from '../services/authService';
import languages from '../utils/languages';

const user = getCurrentUser();
const webLanguage = getPostLanguage();
const languageText = languages[webLanguage ? webLanguage : 'en'];
const shopRequestObj = {
  id: '',
  name: null,
  mobile: user ? user.mobileNumber : null,
  email: user ? user.emailId : null,
  description: '',
  location_master_id: null,
  community_type_id: null,
  logo_image: null,
  is_closed: false,
  is_shop: true,
  purpose: 'Created from web',
  is_current_location_selected: false,
  current_location_city: null,
  isEdit: false,
  shopLocation: {},
  setCategory: {},
};

const bankDetailsObj = {
  account_number: '',
  ifsc_code: '',
  account_holder_name: '',
  tnc: '',
  confirm_account_number: '',
};
const addProductObj = {
  name: '',
  id: '',
  description: '',
  type: '',
  isReturnable: false,
  isActive: true,
  shareToFeed: true,
  isExchangable: false,
  shopId: '',
  sku: {
    qty: 1,
    sellPrice: '',
    shipping: '',
    mediaList: [],
  },
};

export default {
  shops: shopRequestObj,
  bankData: bankDetailsObj,
  products: addProductObj,
  businessObj: {},
  bankObj: {},
  shopObj: {},
  currentApi: '',
  isFeedLoading: false,
  setLoading: action((state, isLoading) => {
    state.isFeedLoading = isLoading;
  }),

  updateShopState: action((state, shopObj) => {
    state.shopObj = shopObj;
  }),
  updateProductState: action((state, products) => {
    state.products = products;
  }),
  updateCreateShopData: action((state, shopObj) => {
    state.shopObj = shopObj;
  }),
  updateProductData: action((state, productObj) => {
    state.products = productObj;
  }),
  updateBusinessData: action((state, businessObj) => {
    state.businessObj = businessObj;
  }),

  updateBankData: action((state, bankObj) => {
    state.bankObj = bankObj;
  }),
  updateAboutData: action((state, shopObj) => {
    state.shopObj = shopObj;
  }),

  createShop: thunk(async (actions, { request, setHandleErrorMessage }) => {
    try {
      const doc = await shopOnSheroesService.createShop(request);
      actions.updateShopState(doc);
      return doc;
    } catch (exception) {
      setHandleErrorMessage(exception);
    }
  }),
  saveBankDetails: thunk(
    async (actions, { request, setHandleErrorMessage }) => {
      try {
        delete request.tnc;
        delete request.confirm_account_number;
        const doc = await shopOnSheroesService.addBankDetails(request);
        actions.updateBankData(doc);
        return doc;
      } catch (error) {
        setHandleErrorMessage(error);
      }
    }
  ),

  getBusinessData: thunk(async (actions) => {
    let redirectUrl = '';
    let labelText = '';
    try {
      const data = await shopOnSheroesService.getBusinessDetails();
      if (data.verificationLevel === 'selfie') {
        redirectUrl = urlMap.ONBOARDING_GENDER_VERIFICATION;
        labelText = languageText?.LUNCH_YOUR_ONLINE_STORE_NOW;
      } else if (data.isShop && data.isBankDetailExist) {
        redirectUrl = shopRedirectUrl.SHOP_DASHBOARD;
        labelText = languageText?.MY_SHOP;
      } else if (data.isShop && !data.isBankDetailExist) {
        //redirectUrl = shopRedirectUrl.BANK_DETAILS;
        redirectUrl = shopRedirectUrl.CREATE_SHOP;
        labelText = languageText?.LUNCH_YOUR_ONLINE_STORE_NOW;
      } else if (!data.isShop && !data.isBankDetailExist) {
        redirectUrl = shopRedirectUrl.CREATE_SHOP;
        labelText = languageText?.LUNCH_YOUR_ONLINE_STORE_NOW;
      }
      data.labelText = labelText;
      data.redirectUrl = redirectUrl;
      actions.updateBusinessData(data);
      return data;
    } catch (error) {
      console.log('error');
    }
  }),
  getBankAccountDetails: thunk(async (actions) => {
    try {
      const data = await shopOnSheroesService.getBankAccount();
      actions.updateBankData(data);
      return data;
    } catch (error) {
      console.log('error');
    }
  }),
  getShopDetails: thunk(async (actions, { shopId }) => {
    try {
      const communityDetails = await communityService.getCommunityDetail(
        shopId
      );
      //actions.updateCreateShopData(communityDetails);
      return communityDetails;
    } catch (error) {
      console.log('error');
    }
  }),

  /**
   *  for Add product
   */
  addProduct: thunk(
    async (actions, { request, setSuccessMessage, setHandleErrorMessage }) => {
      try {
        const doc = await shopOnSheroesService.addProduct(request);
        actions.updateProductState(doc);
        setSuccessMessage(doc.message);
        return doc;
      } catch (exception) {
        setHandleErrorMessage(exception);
      }
    }
  ),
  /**
   * get product
   */
  getItemDetails: thunk(async (actions, { shopId, itemId }) => {
    try {
      const res = await shopOnSheroesService.getCatalogue(shopId, null, itemId);
      actions.updateProductData(res.docs[0]);
      return res;
    } catch (error) {
      console.log('error');
    }
  }),
  shareOnFeed: thunk(
    async (actions, { request, setSuccessMessage, setHandleErrorMessage }) => {
      try {
        const doc = await shopOnSheroesService.shareToFeed(request);
        setSuccessMessage(doc.message);
        return doc;
      } catch (exception) {
        setHandleErrorMessage(exception);
      }
    }
  ),
  shopAbout: thunk(async (actions, { communityId }) => {
    try {
      const doc = await shopOnSheroesService.getCommunityAbout(communityId);
      actions.updateAboutData(doc);
      return doc;
    } catch {
      console.log('error');
    }
  }),
};
