import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { Typography } from '@material-ui/core';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useHistory } from 'react-router';

const CustomSnackbar = () => {
  const history = useHistory();
  const { open, message, duration } = useStoreState((state) => state.snackBar);
  const setOpen = useStoreActions((actions) => actions.snackBar.setOpen);
  const createPostPathnames = ['/create-post', '/create-poll', '/edit-post'];
  const isCreatePost =
    history &&
    history.location &&
    createPostPathnames.includes(history.location.pathname);
  return (
    <div className="snackbarMain">
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={isCreatePost ? 6000 : duration}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<Typography id="message-id">{message}</Typography>}
      />
    </div>
  );
};

export default CustomSnackbar;
