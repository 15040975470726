import http from './httpService';
import APIURL from '../utils/apiUrl';
import {
  communityDetailMap,
  shopItemMap,
  addressMap,
  catalogueMap,
  dashboardDataMap,
  saleSummaryMap,
  generatePaymentLinkMap,
  getCatalogueMap,
  mapShopMedia,
  dashboardCarouselMap,
  mapShopSocialData,
} from '../mappers/communityMapper';
import {
  shopCategoryMap,
  orderDetailMap,
  orderItemMapper,
  businessDetailMap,
} from '../mappers/shopMapper';
import { createCatalogueApiUrl, setNextStage } from '../utils/shopUtil';
import { getPostLanguage, getUserToken } from './authService';
import language from '../utils/languages';

const webLanguage = getPostLanguage();
const languageText = language[webLanguage ? webLanguage : 'en'];

/**
 * Function for get Shop list From API
 * @param {*} pageNumber
 * @returns
 */
async function getShopListFromServices(pageNumber) {
  const requestBody = { page_size: 10 };
  requestBody.page_no = pageNumber;
  try {
    const { data } = await http.post(APIURL.GET_ALL_SHOPS_LIST, requestBody);
    return data.docs.map(shopItemMap);
  } catch (ex) {
    return null;
  }
}

async function getCommunityDetail(communityId) {
  const requestBody = {
    id_of_entity_or_participant: communityId,
    sub_type: 'C',
  };
  const { data } = await http.post(APIURL.GET_POST, requestBody);
  return data.docs && data.docs.length > 0 && communityDetailMap(data.docs[0]);
}

async function addAddress(reqObj) {
  const { data } = await http.post(APIURL.ADD_UPDATE_USER_ADDRESS, reqObj);
  return data.user_address_details.map(addressMap);
}

async function initPayment(paymentReqObj) {
  const { data } = await http.post(APIURL.SHOP_INIT_PAYMENT, paymentReqObj);
  return data;
}

async function catalogueItem(apiUrl, nextToken) {
  const { data } = await http.post(apiUrl, { next_token: nextToken });
  return catalogueMap(data);
}

async function getOrderDetails() {
  const { data } = await http.get(APIURL.GET_ORDER_HISTORY, {});
  return data && orderItemMapper(data);
}

async function orderSummary(orderId) {
  try {
    const { data } = await http.get(APIURL.GET_ORDER_SUMMARY + orderId, {});
    return data && orderDetailMap(data.orders[0]);
  } catch (ex) {
    return ex;
  }
}

async function sellerOrderSummary(orderId) {
  try {
    const { data } = await http.get(
      APIURL.GET_SELLER_ORDER_SUMMARY + orderId,
      {}
    );
    return data && orderDetailMap(data.orders[0]);
  } catch (ex) {
    return ex;
  }
}

async function getBusinessType() {
  const { data } = await http.get(APIURL.GET_BUSINESS_TYPE);
  return data && shopCategoryMap(data.community_types);
}
async function getBusinessDetails() {
  const { data } = await http.get(APIURL.GET_BUSINESS_DETAIL);
  return data && businessDetailMap(data);
}
async function createShop(shopReqObj) {
  const shopApi = shopReqObj.id ? APIURL.EDIT_BUSINESS : APIURL.CREATE_BUSINESS;
  const reqData = new FormData();
  if (
    shopReqObj.isEdit &&
    shopReqObj.logo_image &&
    shopReqObj.logo_image.indexOf('https://') !== -1
  ) {
    delete shopReqObj.logo_image;
  }
  reqData.append('communityContent', JSON.stringify(shopReqObj));
  //const { data } = await http.post(shopApi, reqData);

  const token = getUserToken();
  const data = await fetch(process.env.REACT_APP_API_URL + shopApi, {
    method: 'POST',
    body: reqData,
    headers: { Authorization: token },
  })
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
  return data;
}

async function getCatalogue(shopId = null, token = null, itemId = null) {
  const catalogueApiUrl = createCatalogueApiUrl(shopId, token, itemId);
  const { data } = await http.get(`${catalogueApiUrl}`);
  return data && getCatalogueMap(data);
}
async function addBankDetails(request) {
  const { data } = await http.post(APIURL.ADD_BANK_DETAILS, request);
  return data;
}
async function dashboardData(apiUrl) {
  const req = {
    page_no: 1,
    page_size: 20,
  };
  const { data } = await http.post(apiUrl, req);
  return dashboardDataMap(data);
}
async function getSaleSummaryDetails() {
  const { data } = await http.get(APIURL.SALES_SUMMARY, {});
  return saleSummaryMap(data);
}

async function generatePaymentLink(req) {
  const { data } = await http.post(APIURL.GENERATE_PAYMENT_LINK, req);
  return generatePaymentLinkMap(data);
}
async function getBankAccount() {
  const { data } = await http.get(APIURL.GET_BANK_ACCOUNT);
  return data;
}
async function shareToFeed(req) {
  const { data } = await http.post(APIURL.SHARE_TO_FEED, req);
  return data;
}

async function updateOrderState(req) {
  const { data } = await http.post(APIURL.UPDATE_ORDER_STATE, req);
  const responseData = {
    nextStage: setNextStage(req.orderState),
  };
  if (data && data.status === 'SUCCESS') {
    return { ...responseData, ...data };
  }
}

async function addProduct(request) {
  const { data } = await http.post(APIURL.ADD_PRODUCT_ITEM, request);
  return data;
}

async function uploadImage(file, type, setToastMessage) {
  const uploadUrl = type === 'V' ? APIURL.UPLOAD_VIDEO : APIURL.UPLOAD_IMAGE;
  try {
    const { data } = await http.post(uploadUrl, file);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}
async function getShopMedia(url, token, qs) {
  if (qs && qs.q) {
    const tokenUrl = '&next_token=' + token;
    const newUrl = token ? url + tokenUrl : url;
    const { data } = await http.get(newUrl);
    return data && mapShopMedia(data);
  } else {
    const { data } = await http.post(url, {
      next_token: token,
    });
    return data && mapShopMedia(data);
  }
}

async function getShopMediaData(url, token, query) {
  return await getShopMedia(url, token, query);
}
function getPostData(url) {
  const res = http.get(url);
  console.log('res:', res);
}

async function getDashboardCarousel() {
  const requestBody = {};
  const { data } = await http.post(APIURL.DASHBOARD_CAROUSEL, requestBody);
  return data && dashboardCarouselMap(data.docs);
}
async function getCommunityAbout(id) {
  const { data } = await http.get(APIURL.COMMUNITY_SHOP_ABOUT + id);
  return data && mapShopSocialData(data);
}

export default {
  getShopListFromServices,
  getCommunityDetail,
  addAddress,
  initPayment,
  catalogueItem,
  getOrderDetails,
  orderSummary,
  getBusinessType,
  getBusinessDetails,
  createShop,
  getCatalogue,
  addBankDetails,
  dashboardData,
  getSaleSummaryDetails,
  generatePaymentLink,
  getBankAccount,
  shareToFeed,
  updateOrderState,
  addProduct,
  uploadImage,
  getShopMedia,
  getShopMediaData,
  getPostData,
  sellerOrderSummary,
  getDashboardCarousel,
  getCommunityAbout,
};
