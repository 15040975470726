import { default as _map } from 'lodash/map';
import React from 'react';
import { stripDomain, findHashtags, getSubType } from '../utils/postUtil';
import { renderMention } from '../utils/editorUtil';
import authService from '../services/authService';
import { dayjsTimeFormat, getSellerDeeplinkUrl } from '../utils/textUtil';
import { articleMap } from '../mappers/articleMapper';
import { SHEROES_ADMIN_URL, userType } from '../utils/constants';
import { getRemoteConfig } from '../services/remoteConfigService';
import { communityItemMap } from '../mappers/communityMapper';
import {
  checkUserType,
  isAdminOrModerator,
  hideNotificationByCategory,
} from '../utils/userUtil';
import languages from '../utils/languages';
import { getPostLanguage } from '../services/authService';
import { carouselMapper, videoMapper } from './lectureMapper';
import { orderItemMapper } from './shopMapper';
import { mapUserMention, userTypeMap, isCurrentUserAuthor } from './userMapper';
import {
  Block,
  BookmarkBorderOutlined,
  EditOutlined,
  ShareOutlined,
} from '@material-ui/icons';
import BlockUserIcon from '../img/black-block-user.png';
import FeaturePostIcon from '../img/feature-post.png';
import DeleteIcon from '../img/delete.png';

const appConfig = getRemoteConfig();
const webLanguage = getPostLanguage();
const languageText = languages[webLanguage ? webLanguage : 'en'];

function postMap(doc) {
  const subType = doc.sub_type;
  const postTypeMap = {
    PL: pollMap,
    P: basePostMap,
    C: communityMap,
    H: challengeMap,
    A: articleMap,
    I: promocardMap,
    W: carouselMap,
    V: videoMap,
    LB: basePostMap,
    U: userSearchMap,
    OH: orderItemMap,
    Q: bookmarkMap,
  };
  return postTypeMap[subType](doc);
}

function userSearchMap(doc) {
  const userObj = {
    id: doc.id,
    name: doc.name,
    entityId: doc.id_of_entity_or_participant,
    imageUrl: doc.image_url,
    userSlug: stripDomain(doc.solr_ignore_deep_link_url),
    location: doc.city_name_s || doc.location_s,
    isFollowed:
      doc.solr_ignore_is_user_followed || doc.solr_ignore_is_mentor_followed,
    userTypeId: userTypeMap[doc.entity_or_participant_type_id_i],
  };
  return basePostMap(doc, userObj);
}

function communityMap(doc) {
  const communityObj = communityItemMap(doc);
  return basePostMap(doc, communityObj);
}
function orderItemMap(doc) {
  const itemObj = orderItemMapper(doc);
  return basePostMap(doc, itemObj);
}
function carouselMap(doc) {
  const itemObj = carouselMapper(doc);
  return basePostMap(doc, itemObj);
}
function videoMap(doc) {
  const videoObj = videoMapper(doc);
  return basePostMap(doc, videoObj);
}

function bookmarkMap(doc) {
  const bookmarkObj = {
    isBookmarked: doc && doc.solr_ignore_is_bookmarked,
    bookmarksCount: doc && doc.solr_ignore_no_of_bookmarks,
  };
  return basePostMap(doc, bookmarkObj);
}

function pollOptionsMap(doc) {
  const options = doc.solr_ignore_poll_options;
  return (
    options &&
    options.map((option) => ({
      id: option.id,
      description: option.description,
      voteResults:
        option.solr_ignore_total_no_of_votes_percent === null
          ? '0%'
          : option.solr_ignore_total_no_of_votes_percent + '%',
      isVoted: option.solr_ignore_is_voted,
      imageUrl: option.image_url,
    }))
  );
}

function pollMap(doc) {
  const postSlug = stripDomain(doc.solr_ignore_deep_link_url);
  const pollEndSet = doc.is_expired
    ? 'Poll Ended'
    : 'Ends in: ' + dayjsTimeFormat(doc.ends_at_dt);
  const pollObj = {
    slug: postSlug,
    pollType: doc.poll_type_s,
    pollOptions: pollOptionsMap(doc),
    totalVotes: doc.solr_ignore_total_no_of_responses,
    isResponseOnPoll: doc.solr_ignore_is_responed_on_poll,
    showResult: doc.show_results_b,
    isExpired: doc.is_expired,
    endDate: pollEndSet,
  };
  return basePostMap(doc, pollObj);
}

function promocardMap(doc) {
  const promocardObj = {
    imageUrl: doc.image_url,
  };
  return basePostMap(doc, promocardObj);
}

function challengeMap(doc) {
  const challengeObj = {};
  return basePostMap(doc, challengeObj);
}

function imagesMap(doc) {
  const sources = doc.display_text_image_url;
  const ids = doc.search_id_post_image;
  return (
    sources &&
    sources.map((src, i) => ({
      src: sources[i],
      id: ids[i],
    }))
  );
}

function mentionAndHashTag(
  description,
  userMentions,
  isEdit,
  theme_text_color_s,
  is_theme_post_b,
  sub_type
) {
  let mentionData =
    sub_type !== 'A'
      ? renderMention(
          description,
          userMentions,
          false,
          theme_text_color_s,
          is_theme_post_b,
          sub_type
        )
      : description;
  const isHashTag = mentionData && checkIfValidHashtag(mentionData);
  return isHashTag;
}
function checkIfValidHashtag(str) {
  // Regular expression to check if string is a hashtag
  const regexExp = /#\w\w+\s?/gi;
  const isTrue = regexExp.test(str);
  return isTrue ? findHashtags(str) : str;
}

function basePostMap(doc, otherFields) {
  const shopStream = doc?.endPointUrl;
  const user = authService.getCurrentUser();
  const commentData = commentMap(
    doc.solr_ignore_last_comments && doc.solr_ignore_last_comments[0]
  );
  const postSlug = stripDomain(doc.solr_ignore_deep_link_url);
  const branchLinkUrl = doc.short_branch_url_s;
  const authorData = postAuthorMap(doc);
  const postOptions = optionsMap(doc, 'post');
  const themePostDescriptionFontSize =
    setThemePostDescriptionFont(doc.description) || 22;
  const userDeepLinkUrl =
    authorData.name === 'Anonymous' || !user
      ? ''
      : stripDomain(doc.solr_ignore_user_deep_link_url);
  const mentorDeepLinkUrl =
    authorData.name === 'Anonymous' || !user
      ? ''
      : stripDomain(doc.solr_ignore_mentor_deep_link_url);
  const isAuthorMentor = doc.solr_ignore_is_author_mentor;

  const showProfileBadge =
    authorData.name !== 'Anonymous' &&
    user &&
    doc.solr_ignore_show_profile_badge;
  const getOnlyMention =
    doc.user_mentions && _map(doc.user_mentions, 'mention');
  let userMentions = getOnlyMention && mapUserMention(getOnlyMention);
  const deepLinkUrl = isAuthorMentor ? mentorDeepLinkUrl : userDeepLinkUrl;

  const baseObj = {
    endPointUrl: shopStream,
    id: doc.id,
    entityId: doc.id_of_entity_or_participant,
    subType: doc.sub_type,
    description: mentionAndHashTag(
      doc.description || '',
      userMentions,
      false,
      doc.theme_text_color_s,
      doc.is_theme_post_b,
      doc.sub_type
    ),
    createdAt: doc.solr_ignore_posting_date_dt,
    likesCount: doc.likes_count_l || doc.solr_ignore_no_of_likes,
    repostsCount: doc.repost_count_l,
    commentsCount: doc.comments_count_l || doc.solr_ignore_no_of_comments,
    images: imagesMap(doc), // [ {src: '', id: 1}, {src: '', id: 2} ]
    isLiked: !!doc.solr_ignore_reacted_value,
    isReposted: !!doc.solr_ignore_is_own_repost,
    nextScreen: doc?.next_screen,
    isTopPost: doc.is_top_post_b,
    parentId: doc.entity_or_participant_id,
    communityName:
      doc.solr_ignore_post_community_name ||
      doc.solr_ignore_poll_community_name,
    communityId: doc.community_i,
    communitySlug: doc.is_shop_b
      ? stripDomain(doc.sheroes_deep_link_s)
      : stripDomain(doc.solr_ignore_participant_deep_link_url),
    slug: postSlug,
    shopLink: stripDomain(doc?.solr_ignore_participant_deep_link_url),
    author: authorData,
    lastComment: [commentData],
    deepLinkUrl: doc.solr_ignore_deep_link_url,
    isAuthorMentor:
      doc &&
      (doc.solr_ignore_is_author_mentor ||
        doc.entity_or_participant_type_id_i === 7),
    userDeepLinkUrl: deepLinkUrl && deepLinkUrl.replace('#', ''),
    branchLinkUrl: branchLinkUrl,
    comments: [],
    optionsMenu: postOptions,
    isThemePost: doc.is_theme_post_b,
    themePostDescriptionColor: doc.theme_text_color_s,
    themePostDescriptionFontSize: themePostDescriptionFontSize,
    sharePostInfo: {
      text: doc.description,
      url: doc.solr_ignore_deep_link_url,
      title: 'SHEROES',
    },
    metaInfo: doc.solr_ignore_meta_details,
    metaDescription: doc.solr_ignore_meta_description || doc.meta_description_s,
    metaTitle: doc.solr_ignore_meta_title || doc.meta_title,
    isImagePost:
      doc.theme_image_url_s || doc.display_text_image_url ? true : false,
    themeImageUrl: doc.theme_image_url_s,
    typeId: doc.entity_or_participant_type_id_i,
    showProfileBadge: showProfileBadge,
    profileBadgeUrl: doc.solr_ignore_profile_badge_url,
    streamType: doc.solr_ignore_stream_name,
    isIndexed: doc.is_index_b || doc.is_shop_b,
    isCommunityPost: doc.is_commumity_post_b,
    postLanguage: doc.entity_language_s,
    isSpamPost: doc.is_spam_post_b,
    isCommunityOwner: doc.solr_ignore_is_community_owner,
    authorId: doc.author_id,
    isActive: doc.p_is_active,
    isSchedulePost: false,
    postCreatedAt: doc.p_crdt,
    userRecipientId: doc.recipient_id_l,
    userRecipientParticipantId: doc.recipient_participant_id_l,
    isWallPost: doc.solr_ignore_wall_post,
    isPostOwnWall: doc.solr_ignore_post_on_own_wall,
    userDeepLinkUrlForAdmin: SHEROES_ADMIN_URL + '/users/edit/' + doc.author_id,
    isAuthorProfileSpam: doc.solr_ignore_is_author_profile_spam,
    isVideoLink: doc.is_og_video_link_b,
    requestedUrl: doc.og_requested_url_s,
    ogImageUrl: doc.og_image_url_s,
    ogTitle: doc.og_title_s,
    ogDescription: doc.og_description_s,
    isFollowed: doc.solr_ignore_is_user_followed,
    challengePostText: doc.challenge_accept_post_text_s,
    createdById: doc.created_by_l,
    isAnonymous: doc.is_anonymous_b,
    userMentions: userMentions && userMentions,
    shopDeepLink: stripDomain(doc.sheroes_deep_link_s),
    shopDeepLinkFullUrl: doc.sheroes_deep_link_s,
    name: doc.name,
    productId: doc.productId,
    productPrice: doc.productPrice
      ? doc.productPrice
      : doc?.solr_ignore_product_detail?.price,
    productDeliveryCost: doc.productDeliveryCost
      ? doc.productDeliveryCost
      : doc?.solr_ignore_product_detail?.deliveryCost,
    productTotalAmount: doc.productTotalAmount,
    isShop: doc.is_shop_b,
    sourceVideoUrl: doc.source_video_url_s,
    videoUrl: doc.video_url_s,
    videoThumbnailUrl: doc.video_thumbnail_url_s,
    communityType: doc.community_type_s,
    postCommunityLogo: doc.solr_ignore_post_community_logo,
    title: doc.title,
    sellerDetailDeeplinkUrl: doc && doc.id && getSellerDeeplinkUrl(doc.id),
    showActionsPostCompletion: doc && doc.showActionsPostCompletion,
    isBookmarked: doc.solr_ignore_is_bookmarked,
    bookmarksCount: doc.solr_ignore_no_of_bookmarks,
    participantTypeId: doc && doc.entity_or_participant_type_id_i,
    isRequestReceived:
      doc && doc.solr_ignore_is_friend_request_received_from_author,
    isFriend: doc && doc.solr_ignore_is_author_friend,
    isRequestSent: doc && doc.solr_ignore_is_friend_request_sent_to_author,
    participantId: doc && doc.author_id,
    isAuthorFriend: doc && doc.solr_ignore_is_author_friend,
    videoId: doc && doc.video_id_l,
  };

  return {
    ...baseObj,
    ...otherFields,
  };
}

function commentMap(doc) {
  const commentOptions = optionsMap(doc, 'comment');
  if (!doc) {
    return;
  }
  const user = authService.getCurrentUser();
  const showProfileBadge =
    doc.solr_ignore_participant_name !== 'Anonymous' &&
    user &&
    doc.solr_ignore_show_author_profile_badge;
  const getOnlyMention =
    doc.comment_user_mentions && _map(doc.comment_user_mentions, 'mention');
  const usersMentions = getOnlyMention && mapUserMention(getOnlyMention);
  const subType = getSubType();
  return {
    id: doc.id_of_entity_or_participant || doc.id,
    entityId: doc.entity_id,
    commentId: doc.comments_id,
    mentorId:
      doc &&
      doc.comment_user_mentions &&
      doc.comment_user_mentions[0]?.mention.user_id,
    description: renderMention(
      doc.comment,
      usersMentions,
      false,
      '',
      false,
      subType
    ),
    authorName: doc.solr_ignore_participant_name,
    authorImageUrl: doc.solr_ignore_participant_image_url,
    authorId: doc?.participant_id,
    createdAt: doc.solr_ignore_created_on,
    isComment: true,
    optionsMenu: commentOptions,
    authorDeepLinkUrl:
      doc.solr_ignore_participant_name === 'Anonymous'
        ? ''
        : stripDomain(doc.solr_ignore_user_deep_link_url),
    isAuthorMentor:
      doc.solr_ignore_participant_name !== 'Anonymous' &&
      user &&
      doc.solr_ignore_is_mentor,
    showProfileBadge: showProfileBadge,
    profileBadgeUrl: doc.solr_ignore_author_profile_badge_url,
    likesCount: doc.solr_ignore_number_of_likes_on_comment,
    repliesCount: doc.solr_ignore_number_of_reply_on_comment,
    replyOnComment: [],
    isLiked: !!doc.solr_ignore_is_my_own_like_on_comment,
    isCommentActive: doc?.is_active,
    commentUserMentions:
      doc?.comment_user_mentions &&
      (doc?.comment_user_mentions !== undefined ||
        doc?.comment_user_mentions !== null) &&
      doc?.comment_user_mentions.map((user) => user.mention),
  };
}

function postAuthorMap(doc) {
  const authorObj = {
    name: doc.author_name,
    imageUrl: doc.author_image_url,
    id: doc.author_id,
    description: doc.author_short_description,
  };
  return authorObj;
}
function optionsMap(doc, postType) {
  //TODO make conditional with post type.
  const isTopPostStatus = doc && doc.is_top_post_b;
  const isBookmarked = doc && doc.solr_ignore_is_bookmarked;
  const shareAccess =
    (postType !== 'comment' && !isCurrentUserAuthor(doc, postType)) ||
    (postType !== 'comment' && isCurrentUserAuthor(doc, postType));
  const bookmarkAccess =
    (postType !== 'comment' && !isCurrentUserAuthor(doc, postType)) ||
    (postType !== 'comment' && isCurrentUserAuthor(doc, postType));

  const blockedUserAccess =
    (postType !== 'comment' && !isCurrentUserAuthor(doc, postType)) ||
    (postType !== 'comment' && isCurrentUserAuthor(doc, postType));

  const deleteAccess =
    isCurrentUserAuthor(doc, postType) || checkUserType(userType.isAdmin);
  const updateAccess = isCurrentUserAuthor(doc, postType);
  const reportAccess = !isCurrentUserAuthor(doc, postType);
  const featurePostAccess =
    postType !== 'comment' && (isCommunityOwner(doc) || isAdminOrModerator());
  const sharePostInfo = {
    text: doc && doc.description,
    url: doc && doc.solr_ignore_deep_link_url,
    title: 'SHEROES',
  };
  const optionsMap = [
    {
      id: 'share',
      name: languageText.SHARE_POST,
      access: shareAccess,
      shareData: sharePostInfo,
      icon: () => {
        return <ShareOutlined className="feedMenuIcon" />;
      },
    },
    {
      id: 'bookmark',
      name: isBookmarked
        ? languageText.REMOVE_BOOKMARK
        : languageText.BOOKMARK_POST,
      access: bookmarkAccess,
      icon: () => {
        return <BookmarkBorderOutlined className="feedMenuIcon" />;
      },
    },
    {
      id: 'blockedUser',
      name: languageText.BLOCK_USER,
      access: blockedUserAccess,
      icon: () => {
        return <img src={BlockUserIcon} alt="" className="feedMenuIcon" />;
      },
    },
    {
      id: 'edit',
      name: languageText.EDIT,
      access: updateAccess,
      icon: () => {
        return <EditOutlined className="feedMenuIcon" />;
      },
    },
    {
      id: 'delete',
      name: languageText.DELETE,
      access: deleteAccess,
      icon: () => {
        return <img src={DeleteIcon} alt="" width="16" />;
      },
    },
    {
      id: 'reportSpam',
      name: languageText.REPORT_SPAM,
      access: reportAccess,
      icon: () => {
        return <Block className="feedMenuIcon" />;
      },
    },
    {
      id: 'featurePost',
      name: isTopPostStatus
        ? languageText.UNFEATURE_POST
        : languageText.FEATURE_POST,
      access: featurePostAccess,
      icon: () => {
        return <img src={FeaturePostIcon} alt="" width="16" />;
      },
    },
  ];
  return optionsMap;
}
function isCommunityOwner(doc) {
  return doc && doc.solr_ignore_is_community_owner;
}

function setThemePostDescriptionFont(description) {
  if (!appConfig || !description) return;
  const { themeMediumFontCharLimit, themeLargeFontSize, themeMediumFontSize } =
    appConfig;
  const themePostDescriptionFontSize =
    description && description.length > themeMediumFontCharLimit
      ? themeLargeFontSize
      : themeMediumFontSize;
  return themePostDescriptionFontSize;
}

function notificationMap(doc) {
  const deepLinkUrl =
    doc.category === 'FOLLOW_SUPER_SHEROES_NEW_BADGE_EARNED'
      ? 'https://sheroes.com/super-sheroes?endPoint=' +
        doc.deep_link_url +
        '&title=SuperSHEROES of the Week'
      : doc.deep_link_url;
  return {
    notification: {
      id: doc.id,
      category: doc.category,
      superSheroesBadge: doc.FOLLOW_SUPER_SHEROES_NEW_BADGE_EARNED,
      title: doc.title,
      icon: doc.icon,
      message: doc.message,
      leftImgIcon: doc.left_image_icon,
      rightImgIcon: doc.right_image_icon,
      createdAt: doc.solr_ignore_posting_date_dt,
      deepLinkUrl: deepLinkUrl,
      isRead: doc.is_read,
      isBlocked: hideNotificationByCategory(doc.category),
    },
  };
}
export {
  commentMap,
  postMap,
  notificationMap,
  optionsMap,
  pollMap,
  basePostMap,
  bookmarkMap,
};
