import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import NavigationMenu from '../sideMenu/navigationMenu';
import MyCommunitiesDrawer from '../community/myCommunitiesDrawer';
import { useTablet } from '../../hooks/utilHooks';
import { useLoggedInUser } from '../../hooks/userHooks';
import { useStoreState } from 'easy-peasy';
import { playStore, appStore } from '../../utils/homeUtils';
import AdapterLink from './adapterLink';
import {
  getLoginSource,
  getPostLanguage,
  getThirdPartyUser,
} from '../../services/authService';
import languages from '../../utils/languages';
import useReactRouter from 'use-react-router';
import { isSaireeDigitalCardUrl } from '../../utils/textUtil';
import { getIsOnboardingUrl } from '../../utils/redirectUtil';

const CustomSideBarGrid = ({ children }) => {
  const classes = useStyles();
  const { history } = useReactRouter();
  const createPostPathnames = ['/create-post', '/create-poll', '/edit-post'];
  const isCreatePost =
    history &&
    history.location &&
    createPostPathnames.includes(history.location.pathname);
  const webLanguage = getPostLanguage();
  const enHiLang = webLanguage === 'en' || webLanguage === 'hi';
  const languageText = languages[webLanguage ? webLanguage : 'en'];
  const userFromMM = getThirdPartyUser();
  const isTablet = useTablet();
  const user = useLoggedInUser();
  const isOnboardingUrl = getIsOnboardingUrl();
  const loginSource = getLoginSource();
  const {
    showMenuSideBar,
    showCustomRightSideBar,
    rightSideBar,
    topSpace,
    disableCustomSideBarGrid,
    showCommunitySideBar,
  } = useStoreState((state) => state.navBar.options);
  if (disableCustomSideBarGrid) {
    return <React.Fragment> {children}</React.Fragment>;
  }

  const sideColum = () => {
    if (showMenuSideBar && showCommunitySideBar) {
      return 6;
    } else if (showMenuSideBar && showCustomRightSideBar) {
      return 6;
    } else if (!showMenuSideBar && showCustomRightSideBar) {
      return 9;
    } else if (!showMenuSideBar && !showCustomRightSideBar) {
      if (isCreatePost) {
        return 6;
      } else {
        return 12;
      }
    }
  };
  return (
    <Box className="container" px={!isTablet ? 4 : ''}>
      <Grid container>
        {!isTablet && showMenuSideBar && isOnboardingUrl && (
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Box
              marginTop={topSpace}
              className={classes.sideBar}
              left="0"
              right="890px"
            >
              <Box className={classes.sideMenu}>
                <NavigationMenu />
              </Box>
              {user &&
                user.partnerId === null &&
                userFromMM === null &&
                enHiLang && (
                  <Box
                    to="/create-post"
                    component={AdapterLink}
                    className={classes.postBtn}
                  >
                    <Typography variant="subtitle2">
                      {languageText?.CREATE_POST}
                    </Typography>
                  </Box>
                )}
            </Box>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          lg={sideColum()}
          className={
            (!showMenuSideBar && !showCommunitySideBar) || isTablet
              ? 'mxAuto'
              : ''
          }
        >
          <Box px={!isTablet ? 4 : ''}>{children}</Box>
        </Grid>

        {!isTablet &&
          showCustomRightSideBar &&
          userFromMM === null &&
          isOnboardingUrl && (
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <Box className={classes.sideBar} left="890px" right="0">
                {showCommunitySideBar && (
                  <Box marginTop={topSpace} className={classes.rightSideBar}>
                    {rightSideBar ||
                      (user && enHiLang && <MyCommunitiesDrawer />)}
                  </Box>
                )}

                {!isSaireeDigitalCardUrl && (
                  <Box p={4} mt={2} className={classes.rightSideBar}>
                    <Typography variant="subtitle2">Get the App</Typography>
                    <Box pt={1} pb={1}>
                      <Typography variant="body1">
                        Experience the Premium Features!
                      </Typography>
                    </Box>

                    {loginSource !== 'paytm' && (
                      <Box mt={1} display="flex" justifyContent="space-around">
                        <a
                          href="https://shrs.me/zELbHsDJNS"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={playStore} alt="Play Store" width="90%" />
                        </a>
                        <a
                          href="https://shrs.me/UY88ifnmNS"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={appStore} alt="App Store" width="90%" />
                        </a>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          )}
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  sideBar: {
    width: '275px',
    position: 'fixed',
    margin: '0px auto',
    zIndex: '1001',
  },
  rightSideBar: {
    background: '#fff',
    overflow: 'hidden',
    borderRadius: '15px',
    width: '100%',
  },
  sideMenu: {
    background: '#fff',
    borderRadius: '15px',
    overflow: 'hidden',
  },
  postBtn: {
    background: theme.palette.primary.main,
    color: 'white',
    display: 'block',
    textDecoration: 'none',
    borderRadius: theme.spacing(6),
    padding: theme.spacing(3, 0),
    margin: '16px auto 0px',
    textAlign: 'center',
    width: '80%',
  },
}));

export default CustomSideBarGrid;
