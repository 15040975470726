import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import {
  getLinkOrPath,
  isRouteSupported,
  isBranchLink,
} from '../../utils/routeUtils';
import branchService from '../../services/branchService';
import useReactRouter from 'use-react-router';
import AdapterLink from './adapterLink';
import { reOnboardingUrl } from '../../utils/constants';

const AnyLink = ({ to, children, className }) => {
  const classes = useStyles();
  const url = getLinkOrPath(to);
  const { history } = useReactRouter();
  const isBranchUrl = isBranchLink(to);

  async function handleBranchLink(url, e) {
    e.stopPropagation();
    const { data } = await branchService.parseBranchLink(url);
    if (data && data.url) {
      isRouteSupported(data.url)
        ? history.push(data.url)
        : window.open(data.url, '_blank');
    } else if (data && data.deep_link_url) {
      branchService.setBranchData(data);
      const path = getLinkOrPath(data.deep_link_url);
      isRouteSupported(path)
        ? history.push(path, { deepLink: data.end_point_url })
        : window.open(data.deep_link_url, '_blank');
    }
  }

  const handleOnboardingState = (event) => {
    event.preventDefault();
    const matchedUrl = reOnboardingUrl.filter(
      (onboarding) => onboarding.value === url
    );
    matchedUrl && matchedUrl[0]
      ? history.push(matchedUrl[0].redirectUrl)
      : window.open(url, '_blank');
  };

  const isInternal = isRouteSupported(url);

  return (
    <React.Fragment>
      {isInternal && !isBranchUrl && (
        <Box
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={`${'blackLink'} ${className}`}
          to={url}
          component={AdapterLink}
        >
          {children}
        </Box>
      )}
      {isBranchUrl && (
        <Box className={className} onClick={(e) => handleBranchLink(url, e)}>
          {children}
        </Box>
      )}
      {!isInternal && !isBranchUrl && (
        <a
          onClick={handleOnboardingState}
          rel="noopener noreferrer"
          target="_blank"
          className={className || classes.linkSet}
          href={url}
        >
          {children}
        </a>
      )}
    </React.Fragment>
  );
};
export default AnyLink;
const useStyles = makeStyles((theme) => ({
  linkSet: {
    color: theme.palette.link.main,
    textDecoration: 'none',
    '&:hover': { textDecoration: 'none' },
  },
}));
