import dayjs from 'dayjs';
import { getRemoteConfig } from '../services/remoteConfigService';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

const appConfigData = getRemoteConfig();
const envUrl = process.env.REACT_APP_API_URL.split('/');
const getEvnUrl = envUrl[2];
const womenWillCommunityId =
  getEvnUrl === 'testservices.sheroes.in'
    ? 5376
    : getEvnUrl === 'testservicesconf.sheroes.in'
    ? 1795
    : 10916;
const fbPixelId = 550491175133920;
const womenWillLogo =
  'https://img.sheroes.in/img/uploads/community/logo/1644317236opening_screen_without_image.png';

const getCurrentLocation = window.location.pathname.split('/');
const isSaireeDigitalCardUrl = getCurrentLocation[1] === 'sairee';

function ellipsize(text, maxLength) {
  return text && text.length > maxLength
    ? text.substr(0, maxLength) + '...'
    : text;
}

function dayjsTimeFormat(dt) {
  const endTime = dayjs(dt);
  return dayjs(new Date()).to(endTime, true);
}

//Format the given input into string in suitable terms of 'k', 'M', etc.
function numberCountFormatter(n) {
  if (n < 999) {
    //return whatever the value it is as minimum value to represent in shorthand is 1000.
    return n;
  }
  const ranges = [
    { divider: 1e18, suffix: 'E' },
    { divider: 1e15, suffix: 'P' },
    { divider: 1e12, suffix: 'T' },
    { divider: 1e9, suffix: 'G' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'k' },
  ];

  for (let i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      const value =
        (n / ranges[i].divider) % 1 !== 0
          ? (n / ranges[i].divider).toFixed(1) // Upto only a single digit of the decimal.
          : n / ranges[i].divider;
      return value + ranges[i].suffix;
    }
  }
  return n.toString();
}
function getSlug(text) {
  if (!text) return;
  let cleanText = text
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, '-');
  cleanText = cleanText.replace(/-+/g, '-');
  return cleanText;
}

function getUrlFromText(text) {
  let match =
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  let firstUrl = match.exec(text);
  const urlToRender = firstUrl && firstUrl.length && firstUrl[0];
  return urlToRender;
}
function replaceBetween(str, start, end, what) {
  return str.substring(0, start) + what + str.substring(end);
}

function getReplySlug(pathname, commentId) {
  if (!commentId && !pathname) return;
  const cid = btoa(commentId);
  const replyUrl = pathname + '/replies/' + cid;
  return replyUrl;
}
function ucFirst(word) {
  if (!word || word === undefined) return;
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
}
function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

function getSellerDeeplinkUrl(itemId) {
  const currentLocation = window.location.pathname.split('/');
  const isMyOrder = currentLocation[2] === 'my-orders';
  if (isMyOrder) return;
  const cid = btoa(itemId);
  const sellerUrl = 'seller/order-details/' + cid;
  return sellerUrl;
}
function hostUrl() {
  return window.location.protocol + '//' + window.location.host;
}

function htmlUnescape(str) {
  return str
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .replace(/&/g, '')
    .replace(/#39/g, '')
    .replace(/;/g, "'")
    .replace(/nbsp'/g, ' ')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&#x2F/g, '/')
    .replace(/&#x3D;/g, '=')
    .replace(/&#x60;/g, '`');
}

let startTime, endTime;
window.onload = function () {
  //time = new Date();
  startTime = performance.now();
};

function end() {
  endTime = performance.now();
  var timeDiff = endTime - startTime; //in ms
  // strip the ms
  timeDiff /= 1000;

  // get seconds
  var seconds = Math.round(timeDiff);
  console.log(seconds + ' seconds');
}

window.onbeforeunload = function () {
  endTime = performance.now();
  var timeDiff = endTime - startTime; //in ms
  // strip the ms
  timeDiff /= 1000;

  // get seconds
  var seconds = Math.round(timeDiff) + 's';
  window.localStorage['spentTime'] = seconds;
  //store it with whatever serverside language.
};

function getPlainTextOfMentionUser(commentDescription) {
  var text = commentDescription
    .replaceAll(/ *\([^)]*\) */g, '')
    .replaceAll('[', '')
    .replaceAll(']', ' ')
    .replaceAll('@', '');
  return text;
}

export {
  ellipsize,
  dayjsTimeFormat,
  numberCountFormatter,
  getSlug,
  getUrlFromText,
  replaceBetween,
  getReplySlug,
  ucFirst,
  shuffle,
  getSellerDeeplinkUrl,
  appConfigData,
  hostUrl,
  fbPixelId,
  htmlUnescape,
  womenWillLogo,
  envUrl,
  getEvnUrl,
  womenWillCommunityId,
  end,
  getPlainTextOfMentionUser,
  getCurrentLocation,
  isSaireeDigitalCardUrl,
};
