import React from 'react';
import { Redirect } from 'react-router-dom';
import { lazyPreload } from '../utils/lazyUtil';

const LoginForm = lazyPreload(() => import('../components/loginForm'));
const Logout = lazyPreload(() => import('../components/logout'));
const DeactivatedUser = lazyPreload(() =>
  import('../components/common/deactivatedUser')
);
const MobileEmailForm = lazyPreload(() =>
  import('../components/onboarding/mobileEmailForm')
);
const VerifyOtp = lazyPreload(() => import('../components/common/verifyOtp'));
const ThirdPartyLogin = lazyPreload(() => import('../scenes/thirdPartyLogin'));

export default [
  {
    path: '/login',
    component: LoginForm,
  },
  {
    path: '/signup',
    render: (props) => <Redirect to="/" />,
  },
  {
    path: '/sign-in',
    render: (props) => <Redirect to="/login" />,
  },
  {
    path: '/user-deactivated',
    component: DeactivatedUser,
  },
  {
    path: '/logout',
    component: Logout,
  },
  {
    path: '/users/logout',
    component: Logout,
  },
  {
    path: '/mobile-email-form',
    component: MobileEmailForm,
  },
  {
    path: '/verify-otp',
    component: VerifyOtp,
  },
  {
    path: '/register',
    component: MobileEmailForm,
  },
  {
    path: '/third-party',
    component: ThirdPartyLogin,
  },
];
