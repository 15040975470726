import React from 'react';
import { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useScrollToTop, useTablet } from './utilHooks';
import { scrollToBottom } from '../utils/postUtil';
import { getUserMentionObj } from '../utils/editorUtil';
import { ReactComponent as SorryIcon } from '../img/sorry.svg';
import authService, { getPostLanguage } from '../services/authService';
import languages from '../utils/languages';
import MoengageService from '../services/moEngageService';
import { getPlainTextOfMentionUser } from '../utils/textUtil';
import { stripHtml } from '../utils/editorUtil';
import { useSnackBar } from './snackBarHooks';
import { useHistory } from 'react-router-dom';

const webLanguage = getPostLanguage();
const languageText = languages[webLanguage ? webLanguage : 'en'];

function usePostTitle(post, isLecture, lectureObj) {
  const isTablet = useTablet();
  const setTitle = useStoreActions((actions) => actions.navBar.setTitle);
  const postTitleText =
    post && post.isShop
      ? post.name
      : isLecture
      ? post?.title
      : post && post.author && `${post.author.name}'s Post`;
  const pollTitleText = post && post.author && `${post.author.name}'s Poll`;
  const communityName = post && post.name;
  const lectureTitle = post && post.lecture && post.lecture[0].title;
  const titleMap = {
    P: postTitleText,
    A: isTablet ? 'S H E R O E S' : 'Article',
    V: postTitleText,
    PL: pollTitleText,
    C: communityName,
    CH: lectureTitle,
  };
  useEffect(() => {
    const title = titleMap[post && post.subType];
    setTitle(title);
  }, [post, setTitle, titleMap]);
}

/**
 * Fetches post TODO
 * @param {String} id is an unique string used in post store
 * @param {Number} postEntityId
 * @param {String} postSubType
 */
function useGetPost(
  id,
  postEntityId,
  postSubType = 'P',
  isLecture,
  lectureObj
) {
  const [postId, setPostId] = useState(id);
  const post = useStoreState((state) => state.posts.postsObj[postId]);
  const fetchPost = useStoreActions((actions) => actions.posts.fetchPost);
  usePostTitle(post, isLecture, lectureObj);
  useScrollToTop();
  useEffect(() => {
    async function fetchPostId() {
      const postIdResponse = await fetchPost({
        postEntityId,
        postSubType,
        isLecture,
      });
      postId || setPostId(postIdResponse);
    }
    post || fetchPostId();
    return () => {
      postId && setPostId();
    };
  }, [fetchPost, post, postEntityId, postId, postSubType, isLecture]);

  return { post };
}

function useBookmarkOnPost(postId) {
  const isBookmarked = useStoreState(
    (state) => state.posts?.postsObj[postId]?.isBookmarked
  );
  return isBookmarked;
}

function useLikeOnPost(postId) {
  const isLiked = useStoreState(
    (state) => state.posts.postsObj[postId].isLiked
  );
  const likesCount = useStoreState(
    (state) => state.posts.postsObj[postId].likesCount
  );
  const likePost = useStoreActions((actions) => actions.posts.likePost);
  return { isLiked, likesCount, likePost };
}

function useLikeOnComment(
  postId,
  commentIndex,
  isDetails,
  isCommentDetail,
  commentReplyIndex,
  parentCommentIndex,
  isMentorReply
) {
  const comment = useStoreState(
    (state) =>
      state.posts.postsObj[postId] &&
      state.posts.postsObj[postId].comments[
        isCommentDetail ? parentCommentIndex : commentIndex
      ]
  );
  const feedComment = useStoreState(
    (state) =>
      state.posts.postsObj[postId] &&
      state.posts.postsObj[postId].lastComment[0]
  );
  let isLiked = false;
  let likesCount = 0;
  if (isMentorReply) {
    isLiked = false;
    likesCount = 0;
  } else if (isDetails && !isMentorReply) {
    if (
      isCommentDetail &&
      commentReplyIndex !== undefined &&
      comment?.replyOnComment
    ) {
      isLiked = comment?.replyOnComment[commentReplyIndex]?.isLiked;
      likesCount = comment?.replyOnComment[commentReplyIndex]?.likesCount;
    } else {
      isLiked = comment && comment?.isLiked;
      likesCount = comment && comment?.likesCount;
    }
  } else {
    isLiked = feedComment && feedComment.isLiked;
    likesCount = feedComment && feedComment.likesCount;
  }
  const likeComment = useStoreActions((actions) => actions.posts.likeComment);
  return { isLiked, likesCount, likeComment };
}

function useRepost(postId) {
  const isReposted = useStoreState(
    (state) => state.posts.postsObj[postId].isReposted
  );
  const repostsCount = useStoreState(
    (state) => state.posts.postsObj[postId].repostsCount
  );
  const repost = useStoreActions((actions) => actions.posts.repost);

  return { isReposted, repostsCount, repost };
}

function useCommentOnPost(
  post,
  setScrollToBottom,
  isLecture,
  lectureEntityId,
  lectureObj,
  chapterTitle,
  isReplies,
  commentIndex
) {
  const { editCommentObj, isCommentActive } = useEditComment();
  const { saveCommentPost, saveMentorCommentPost } = useStoreActions(
    (actions) => actions.posts
  );

  const {
    setErrorDialogBoxOpen,
    setHandleErrorMessage,
    setErrorDialogContent,
  } = useStoreActions((actions) => actions.errorHandler);
  const user = useStoreState((state) => state.user.user);
  const participantId = user?.participantId;
  const { fetchMentionUsers } = useStoreActions((actions) => actions.user);
  const setToastMessage = useSnackBar();
  const { isCommentSubmit } = useStoreState(
    (state) => state.createPost.postObject
  );
  const { setIsCommentSubmit } = useStoreActions((state) => state.createPost);
  //TODO move to constants when merged.
  const anonymousUserImage =
    'https://img.sheroes.in/img/uploads/sheroespage/logoes/1513929058anonymouse_ic.png';
  const [commentDescription, setCommentDescription] = useState('');
  const [commentUserMention, setCommentUserMention] = useState([]);
  const [mentionObj, setMentionObj] = useState([]);
  const communityId = post?.communityId;
  const postAuthorUserId = post?.authorId;
  const postEntityId = post?.entityId;

  function handleCommentChange(e) {
    setCommentDescription(e.target.value);
    const searchText = e.target.value;
    const mentionData =
      mentionObj &&
      mentionObj.length > 0 &&
      mentionObj.filter((user) => searchText.includes(user.name));

    mentionData && mentionData.length > 0
      ? setMentionObj(mentionData)
      : setMentionObj([]);

    fetchMentionUsers({
      searchText,
      communityId,
      postAuthorUserId,
      postEntityId,
      setToastMessage,
      mentionObj,
    });
  }

  useEffect(() => {
    setCommentDescription(
      editCommentObj?.isCommentEditable
        ? stripHtml(editCommentObj?.comment?.description)
        : ''
    );
    if (editCommentObj?.isCommentEditable) {
      if (isReplies) {
        setMentionObj(
          post?.comments[commentIndex]?.replyOnComment[
            editCommentObj?.commentIndex
          ].commentUserMentions
        );
      } else {
        setMentionObj(
          post?.comments[editCommentObj?.commentIndex]?.commentUserMentions
        );
      }
    } else {
      setMentionObj([]);
    }
    !user?.mentionUsers &&
      fetchMentionUsers({
        searchText: '',
        communityId,
        postAuthorUserId,
        postEntityId,
        setToastMessage,
      });
    // eslint-disable-next-line
  }, [editCommentObj, post, isReplies, commentIndex]);
  /**
   *
   * @param {state} editorState
   * Handle to set editor state  and set response to comment user
   */
  function handleSetCommentEditor(content, mentionData) {
    content && setCommentDescription(content);
    const userCommentMention = getUserMentionObj(mentionData, content);
    setCommentUserMention(userCommentMention);
    setIsCommentSubmit(false);
  }

  function handleSetMention(mentionId, getMentionUsers) {
    const mentionData = getMentionUsers.find(
      (user) => user?.user_id === mentionId
    );

    setMentionObj((current) =>
      current === null ? [mentionData] : [...current, mentionData]
    );
  }

  async function handleSaveComment(
    userAnonymity,
    commentDescription,
    commentUserMention,
    commentId,
    lectureEntityId,
    isLecture,
    isReplies
  ) {
    isLecture &&
      MoengageService.trackVideoCommented(
        webLanguage,
        lectureObj?.title,
        chapterTitle,
        post?.comments[0]?.mentorId,
        lectureObj && lectureObj.lectureId,
        post && post.id
      );
    var commentText = getPlainTextOfMentionUser(commentDescription);
    let commentMentionList = '';
    commentMentionList = getUserMentionObj(mentionObj, commentText);
    setCommentUserMention(commentMentionList);

    let newComment = {
      description: commentText,
      authorName: userAnonymity ? 'Anonymous' : user.name ? user.name : '',
      authorImageUrl: !userAnonymity ? user.image : anonymousUserImage,
      createdAt: languageText?.ID_JUST_NOW,
      isComment: true,
      isAuthorMentor: user.userTypeId === 7,
    };

    const reqBody = {
      commentObj: [newComment],
      postId: post.id,
      postEntityId: isLecture ? lectureEntityId : post.parentId,
      isAnonymous: userAnonymity,
      commentUserMention: commentMentionList,
      commentId: commentId,
      fromReplies: post.fromReplies,
      setHandleErrorMessage: setHandleErrorMessage,
      lectureEntityId,
      isLecture,
      isReplies,
      isCommentActive,
      participantId,
      setToastMessage,
    };
    const commentDescript = await saveCommentPost(reqBody);
    setErrorDialogBoxOpen(true);
    setErrorDialogContent({
      btnPrimaryText: 'Not Now',
      btnSecondaryText: 'Verify',
      dialogIcon: <SorryIcon />,
      dialogTitle: 'Sorry!',
    });

    commentDescript && setCommentDescription(commentDescript);

    scrollToBottom();
    setIsCommentSubmit(true);
    setCommentDescription('');
  }
  async function handleMentorSaveComment(
    userAnonymity,
    commentDescription,
    commentUserMention,
    commentId,
    lectureEntityId,
    isLecture,
    isReplies,
    isMentorReply,
    commentEntityId
  ) {
    var commentText = getPlainTextOfMentionUser(commentDescription);
    let commentMentionList = '';
    commentMentionList = getUserMentionObj(mentionObj, commentText);
    setCommentUserMention(commentMentionList);

    let newComment = {
      description: commentText,
      authorName: userAnonymity ? 'Anonymous' : user.name ? user.name : '',
      authorImageUrl: !userAnonymity ? user.image : anonymousUserImage,
      createdAt: 'Just Now',
      isComment: true,
      isAuthorMentor: user.userTypeId === 7,
    };
    const reqBody = {
      commentObj: [newComment],
      postId: post.id,
      postEntityId: lectureEntityId,
      isAnonymous: userAnonymity,
      commentUserMention: commentMentionList,
      commentId: commentId,
      fromReplies: post.fromReplies,
      setHandleErrorMessage: setHandleErrorMessage,
      lectureEntityId,
      isLecture,
      isReplies,
      isMentorReply,
      setToastMessage,
    };

    const commentDescript = await saveMentorCommentPost(reqBody);
    commentDescript && setCommentDescription(commentDescript);
    scrollToBottom();
    setIsCommentSubmit(true);
    setCommentDescription('');
  }
  return {
    handleSaveComment,
    commentDescription,
    handleCommentChange,
    commentUserMention,
    handleSetCommentEditor,
    isCommentSubmit,
    setIsCommentSubmit,
    handleMentorSaveComment,
    handleSetMention,
  };
}

function useEditComment() {
  const editCommentObj = useStoreState((state) => state.posts.editCommentObj);
  const isCommentActive = editCommentObj?.comment?.isCommentActive;
  return { editCommentObj, isCommentActive };
}

function usePostEngagement({
  likePost,
  isLiked,
  post,
  repost,
  isReposted,
  isPostDetail,
  postId,
  isArticleDetail,
  setScrollToBottom,
  setFocusComment,
}) {
  const userLoginModal = useStoreActions(
    (actions) => actions.user.userLoginModal
  );
  const setToastMessage = useSnackBar();
  const history = useHistory();
  const setScrollPostId = useStoreActions(
    (actions) => actions.feed.setScrollPostId
  );
  const { setErrorDialogBoxOpen, setErrorDialogContent } = useStoreActions(
    (actions) => actions.errorHandler
  );

  const user = authService.getCurrentUser();
  const refUrl = window.location.href;
  const loginScreen = authService.getLoginSource();

  async function handleLike(e) {
    if (!user) {
      loginScreen &&
        loginScreen !== 'paytm' &&
        userLoginModal({
          showModal: true,
          referrerUrl: refUrl,
          loginScreen,
          setToastMessage,
        });
    } else {
      e.stopPropagation();
      likePost({
        postId: post.id,
        postEntityId: post.parentId,
        likeStatus: !isLiked,
        setToastMessage: setToastMessage,
      });
      setErrorDialogBoxOpen(
        loginScreen === '' || loginScreen === undefined ? true : false
      );
      setErrorDialogContent({
        btnPrimaryText: 'Not Now',
        btnSecondaryText: 'Verify',
        dialogIcon: <SorryIcon />,
        dialogTitle: 'Sorry!',
      });
      MoengageService.trackPostEngaged(post, 'Like');
    }
  }

  async function handleRepost(e) {
    if (!user) {
      loginScreen &&
        loginScreen !== 'paytm' &&
        userLoginModal({
          showModal: true,
          referrerUrl: refUrl,
          loginScreen,
          setToastMessage,
        });
    } else {
      e.stopPropagation();
      repost({
        postId: post.id,
        postEntityId: post.parentId,
        repostStatus: !isReposted,
        setToastMessage: setToastMessage,
      });
      setErrorDialogBoxOpen(
        loginScreen === '' || loginScreen === undefined ? true : false
      );
      setErrorDialogContent({
        btnPrimaryText: 'Not Now',
        btnSecondaryText: 'Verify',
        dialogIcon: <SorryIcon />,
        dialogTitle: 'Sorry!',
      });
      MoengageService.trackPostEngaged(post, 'Repost');
    }
  }

  function handleCommentClick(e, focusComment = true) {
    if (!user) {
      loginScreen &&
        loginScreen !== 'paytm' &&
        userLoginModal({
          showModal: true,
          referrerUrl: refUrl,
          loginScreen,
          setToastMessage,
        });
    }
    e.stopPropagation(); //to prevent going into detail screen if clicked from feed.
    if (!isPostDetail && !isArticleDetail) {
      setScrollPostId(post.id);
      history.push(
        { pathname: post.slug, focusComment: focusComment },
        { postId }
      );
      MoengageService.trackPostEngaged(post, 'Comment');
    } else if (isArticleDetail) {
      setFocusComment(focusComment);
      setScrollToBottom(true);
    }
  }
  return { handleCommentClick, handleLike, handleRepost };
}
export {
  usePostTitle,
  useGetPost,
  useLikeOnPost,
  useCommentOnPost,
  useRepost,
  useLikeOnComment,
  useBookmarkOnPost,
  useEditComment,
  usePostEngagement,
};
