import APIURL from './apiUrl';

function joinCommunityByFeed(communityId, userId) {
  // user comes from posts of feed screen to join the community
  return {
    apiUrl: APIURL.JOIN_COMMUNITY,
    requestBody: {
      community_id: communityId,
      user_ids: [userId],
      currentScreen: 'Community Detail',
      currentFeature: 'Community',
      previousScreen: 'GWW Feed/Social Feed/Shop Feed',
      previousFeature: 'Post',
    },
  };
}

function joinCommunityByPostDetail(communityId, userId) {
  // user comes from Post detail screen to join the community
  return {
    apiUrl: APIURL.JOIN_COMMUNITY,
    requestBody: {
      community_id: communityId,
      user_ids: [userId],
      currentScreen: 'Community Detail',
      currentFeature: 'Community',
      previousScreen: 'Post Detail',
      previousFeature: 'Post',
    },
  };
}

function joinCommunityByCommunityDetail(communityId, userId) {
  // user trying to join the community by community detail screen
  return {
    apiUrl: APIURL.JOIN_COMMUNITY,
    requestBody: {
      community_id: communityId,
      user_ids: [userId],
      currentScreen: 'Community Detail',
      currentFeature: 'Community',
      previousScreen: 'Community Search',
      previousFeature: 'Search',
    },
  };
}

function joinCommunityByCommunityList(communityId, userId) {
  // user trying to join the community by community List screen
  return {
    apiUrl: APIURL.JOIN_COMMUNITY,
    requestBody: {
      community_id: communityId,
      user_ids: [userId],
      currentScreen: 'Community List',
      currentFeature: 'Search',
      previousScreen: 'Community Search',
      previousFeature: 'Search',
    },
  };
}
export {
  joinCommunityByFeed,
  joinCommunityByPostDetail,
  joinCommunityByCommunityDetail,
  joinCommunityByCommunityList,
};
