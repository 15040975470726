import React, { Suspense } from 'react';
import { Switch, Route, matchPath, Router } from 'react-router-dom';
import mainRoutes from '../routes/mainRoutes';
import DelayedFallback from '../components/common/delayedFallback';
import ErrorBoundary from '../components/common/errorBoundary';

// Forked from:
// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config

/**
 * Source: https://github.com/ReactTraining/react-router/blob/master/packages/react-router-config/modules/renderRoutes.js
 * @param {*} routes
 * @param {*} extraProps
 * @param {*} switchProps
 */
function renderRoutes(routes, extraProps = {}, switchProps = {}) {
  return (
    <ErrorBoundary>
      <Suspense fallback={<DelayedFallback />}>
        <Switch {...switchProps}>
          {routes.map((route, i) => {
            route.route = route.route || Route;
            route.exact = route.exact || true; //Changing default to exact:true
            return (
              <route.route
                key={route.key || i}
                path={route.path}
                exact={route.exact}
                strict={route.strict}
                render={(props) =>
                  route.render ? (
                    route.render({ ...props, ...extraProps, route: route })
                  ) : (
                    <route.component {...props} {...extraProps} route={route} />
                  )
                }
              />
            );
          })}
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
}

/**
 * Source: https://github.com/ReactTraining/react-router/blob/master/packages/react-router-config/modules/matchRoutes.js
 * @param {*} routes
 * @param {*} pathname
 */
function matchRoutes(routes, pathname, /*not public API*/ branch = []) {
  routes.some((route) => {
    const match = route.path
      ? matchPath(pathname, route)
      : branch.length
      ? branch[branch.length - 1].match // use parent match
      : Router.computeRootMatch(pathname); // use default "root" match

    if (match) {
      branch.push({ route, match });

      if (route.routes) {
        matchRoutes(route.routes, pathname, branch);
      }
    }

    return match;
  });

  return branch;
}

const findComponentForRoute = (path) => {
  const matches = matchRoutes(mainRoutes, path);
  if (
    matches &&
    matches.length > 0 &&
    matches[0].route &&
    !matches[0].route.last
  ) {
    return matches[0].route.component;
  }
  return null;
};

function isRouteSupported(path) {
  if (!path) return false;
  const matches = matchRoutes(mainRoutes, path);
  return (
    matches && matches.length > 0 && matches[0].route && !matches[0].route.last
  );
}
function replaceSheroesUrl(link) {
  if (!link) return null;
  return link
    .replace('https://sheroes.com', '')
    .replace('https://m.sheroes.com', '');
}
function getLinkOrPath(link) {
  const path = replaceSheroesUrl(link);
  return isRouteSupported(path) ? path : link;
}

function isBranchLink(url) {
  if (url === '/' || url === null) return;
  return (
    url?.indexOf('shrs.me') > -1 ||
    url?.indexOf('dpjo.test-app.link') > -1 ||
    url?.indexOf('djpo.app.link') > -1
  );
}

function getQueryParam(key) {
  return new URL(document.location).searchParams.get(key);
}
export {
  renderRoutes,
  isRouteSupported,
  getLinkOrPath,
  replaceSheroesUrl,
  isBranchLink,
  getQueryParam,
  findComponentForRoute,
};
