import { action, thunk } from 'easy-peasy';
import authService from '../services/authService';
import { getPaytmToken } from '../utils/paytmUtil';
import { mentionUsersMap } from '../mappers/userMapper';
import profileService from '../services/profileService';
export default {
  showDeactivateModal: false,
  user: null,
  showModal: false,
  championsFollowedData: null,
  updateUser: action((state, payload) => {
    state.user =
      authService.getCurrentUser() && payload
        ? { ...authService.getCurrentUser(), ...payload }
        : authService.getCurrentUser();
  }),
  updateUserDeactivateState: action((state, { showDeactivateModal }) => {
    state.showDeactivateModal = showDeactivateModal;
  }),
  userLoginModal: action(
    (state, { showModal, referrerUrl, loginScreen, setToastMessage }) => {
      console.log('loginScreen from store:', loginScreen);

      if (loginScreen === 'paytm') {
        getPaytmToken(setToastMessage);
        state.showModal = false;
      } else {
        state.showModal = showModal;
        authService.setReferrerUrl(referrerUrl);
      }
    }
  ),
  fetchMentionUsers: thunk(
    // It is fetching the friends list, followers list for tagging the users in comment.
    async (
      actions,
      {
        searchText,
        communityId,
        postAuthorUserId,
        postEntityId,
        setToastMessage,
        mentionObj,
      }
    ) => {
      try {
        const text =
          searchText &&
          searchText.split(/@(\w+)$/).splice(1, searchText.length);
        const reqObj = {
          user_mention_context: 'COMMENT',
          search_text: text[0] !== undefined ? text[0] : '',
          community_id: communityId,
          post_author_user_id: postAuthorUserId,
          post_entity_id: postEntityId || '',
        };
        const { mentionData } = await profileService.getMentionData(
          reqObj,
          setToastMessage
        );
        const suggestionData =
          mentionData.length > 0 ? mentionUsersMap(mentionData) : [];
        const filteredSuggestionData = suggestionData.filter((suggestion) => {
          return !mentionObj.some(
            (mention) => mention.user_id === suggestion.user_id
          );
        });
        actions.updateUser({
          mentionUsers: filteredSuggestionData,
        });

        return mentionData;
      } catch (err) {
        console.log('err:', err);
      }
    }
  ),
  setChampionsFollowedData: action((state, payload) => {
    state.championsFollowedData = payload;
  }),
};
