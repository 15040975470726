import React from 'react';
import { CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { htmlUnescape } from '../../utils/textUtil';

const ArticleDetailCardContent = ({ post }) => {
  const classes = useStyles();

  const articleDescription = htmlUnescape(post.description);

  return (
    <CardContent>
      <Typography
        variant="subtitle1"
        color="textPrimary"
        className={classes.articleContent}
        dangerouslySetInnerHTML={{
          __html: articleDescription,
        }}
      />
    </CardContent>
  );
};

const useStyles = makeStyles((theme) => ({
  articleContent: {
    lineHeight: '30px',
    '& div': {
      fontSize: '16px !important',
      padding: '0 !important',
      fontWeight: '400!important',
      maxWidth: '100%',
    },
    '& img': {
      width: '100%!important', //adding important attr because inline css is added in content.
      height: 'auto!important',
    },
    '& a ': {
      textDecoration: 'none',
    },
    '& iframe': {
      width: '100%',
    },
  },
}));

export default ArticleDetailCardContent;
