import apiUrl from './apiUrl';
import { getIsShowWWFeed, getPostLanguage } from '../services/authService';
import languages from '../utils/languages';
const feedStreamApi = apiUrl.FEED_STREAM_URL;
const searchStreamApi = apiUrl.SEARCH_FEED_STREAM_URL;
const GWWApi = apiUrl.GWW_STREAM_URL;

export function getFeedStreamApiUrl(setOrderName, communityId) {
  const setOrderKey = '?setOrderKey=' + setOrderName;
  const feedStreamApiMap = {
    Feed: feedStreamApi,
    CommunityFeed: feedStreamApi + setOrderKey + '&community_id=' + communityId,
    UserStoryStream: feedStreamApi + setOrderKey,
    Search: searchStreamApi,
    GWW: GWWApi,
  };
  return feedStreamApiMap[setOrderName];
}

export function getSearchStreamApiUrl(searchValue, categoryType) {
  const commonStreamUrl =
    searchValue &&
    '/participant/search/?search_text=' +
      searchValue +
      '&search_category=' +
      categoryType;

  const streamUrlMap = {
    posts:
      commonStreamUrl || '/participant/feed/stream?setOrderKey=TrendingPosts',
    users: searchValue ? commonStreamUrl : apiUrl.PEOPLE_STREAM_URL,
    communities: commonStreamUrl,
    hashtags: commonStreamUrl,
    images: commonStreamUrl || apiUrl.EXPLORE_MEDIA_STREAM,
  };
  return streamUrlMap[categoryType];
}

export function getFeedConfig(user, setOrderName) {
  const partnerId = user?.partnerId;
  const courseId = user?.courseId;
  const userCategory = user?.userCategory;
  const isShowWWFeed = getIsShowWWFeed();
  const webLanguage = getPostLanguage();
  const languageText = languages[webLanguage ? webLanguage : 'en'];

  const feedConfig = {
    isCourse: false,
    isMentor: false,
  };

  if (partnerId === 8 && user.isDashboard === false) {
    feedConfig.streamKey = setOrderName;
    feedConfig.tabFirstLabel = languageText?.SOCIAL;
    feedConfig.tabThirdLabel = languageText?.GWW;
    feedConfig.tabFourthLabel = languageText.COURSE_PROGRESS;
    feedConfig.tabFiveLabel = languageText?.LEARN;

    feedConfig.firstTabIsGetRequestType = false;
    feedConfig.secondTabIsGetRequestType = false;
    feedConfig.thirdTabIsGetRequestType = false;
    feedConfig.gwwStreamApi = GWWApi;
    feedConfig.courseProgressApi = apiUrl.COURSE_PROGRESS + courseId;
    feedConfig.isCourse = false;
  } else if (userCategory === 'gm') {
    feedConfig.streamKey = setOrderName;
    feedConfig.tabFirstLabel = languageText?.SOCIAL;
    feedConfig.tabThirdLabel = languageText?.GWW;
    feedConfig.tabFourthLabel = languageText?.DASHBOARD;
    feedConfig.tabFiveLabel = languageText?.COURSE_PROGRESS;

    feedConfig.isCourse = false;
    feedConfig.isMentor = true;
    feedConfig.fc = false;

    feedConfig.firstTabIsGetRequestType = false;
    feedConfig.secondTabIsGetRequestType = false;
    feedConfig.thirdTabIsGetRequestType = false;
    feedConfig.facilitatorIsGetRequestType = true;

    feedConfig.gwwStreamApi = GWWApi;
    feedConfig.facilitatorStreamApi =
      apiUrl.MENTOR_DASHBOARD_DETAILS + 'lc&pageNo=0';
    feedConfig.courseProgressApi = apiUrl.COURSE_PROGRESS + courseId;
  } else if (userCategory === 'fc') {
    feedConfig.streamKey = setOrderName;

    feedConfig.tabFirstLabel = languageText?.SOCIAL;
    feedConfig.tabThirdLabel = languageText?.GWW;
    feedConfig.tabFourthLabel = languageText?.DASHBOARD;
    feedConfig.tabFiveLabel = languageText?.COURSE_PROGRESS;

    feedConfig.isCourse = false;
    feedConfig.isMentor = false;
    feedConfig.fc = true;

    feedConfig.firstTabIsGetRequestType = false;
    feedConfig.secondTabIsGetRequestType = false;
    feedConfig.thirdTabIsGetRequestType = false;
    feedConfig.facilitatorIsGetRequestType = true;

    feedConfig.gwwStreamApi = GWWApi;
    feedConfig.facilitatorStreamApi =
      apiUrl.FACILITATOR_DASHBOARD_DETAILS + 'count&pageNo=0';
    feedConfig.courseProgressApi = apiUrl.COURSE_PROGRESS + courseId;
  } else if (isShowWWFeed === 'true') {
    feedConfig.streamKey = setOrderName;
    feedConfig.tabFirstLabel = languageText?.SOCIAL;
    feedConfig.tabThirdLabel = languageText?.GWW;
    feedConfig.tabFourthLabel = languageText.COURSE_PROGRESS;
    feedConfig.tabFiveLabel = languageText?.LEARN;

    feedConfig.firstTabIsGetRequestType = false;
    feedConfig.secondTabIsGetRequestType = false;
    feedConfig.thirdTabIsGetRequestType = false;
    feedConfig.gwwStreamApi = GWWApi;
    feedConfig.courseProgressApi = apiUrl.COURSE_PROGRESS + courseId;
    feedConfig.isCourse = false;
  } else {
    feedConfig.streamKey = setOrderName;
    feedConfig.tabFirstLabel = languageText?.SOCIAL;
    feedConfig.tabThirdLabel = languageText?.GWW;
    feedConfig.tabFourthLabel = languageText.COURSE_PROGRESS;
    feedConfig.tabFiveLabel = languageText?.LEARN;

    feedConfig.firstTabIsGetRequestType = false;
    feedConfig.secondTabIsGetRequestType = false;
    feedConfig.thirdTabIsGetRequestType = false;
    feedConfig.gwwStreamApi = GWWApi;
    feedConfig.courseProgressApi = apiUrl.COURSE_PROGRESS + courseId;
    feedConfig.isCourse = false;
  }
  return feedConfig;
}

export function showCreatePost(user, feedConfig, languageText, isMobile) {
  let isShowCreatePost = false;

  if (
    (user.userCategory === null ||
      user.userCategory === 'u' ||
      user.userCategory === 'gm' ||
      user.userCategory === 'fc' ||
      user.partnerId === 8) &&
    ((feedConfig && feedConfig.tabFirstLabel === languageText.COURSE_FEED) ||
      isMobile === true)
  ) {
    isShowCreatePost = false;
  } else {
    isShowCreatePost = true;
  }
  return isShowCreatePost;
}

export function setFeedTopMargin({ userFromMM, isTablet, enHiLang }) {
  const pathname = window.document.location.pathname;
  const tab = window.document.location.search;
  if (pathname === '/feed') {
    if (tab === '?tab=social' || tab === '?tab=shop') {
      return 0;
    } else {
      if (userFromMM === 'true') {
        return 0;
      } else if (userFromMM !== 'true') {
        if (enHiLang) {
          return 0;
        } else if (!isTablet && !enHiLang) {
          return 6;
        } else if (isTablet && !enHiLang) {
          return 8;
        }
      }
    }
  }
}

export function setCourseTopMargin({ userFromMM, isTablet, enHiLang }) {
  const pathname = window.location.pathname;
  if (pathname === '/feed') {
    if (userFromMM === 'true') {
      if (isTablet) {
        return 1;
      } else {
        return 1;
      }
    } else if (userFromMM !== 'true') {
      if (!isTablet && enHiLang) {
        return 11;
      } else if (isTablet && enHiLang) {
        return 12;
      } else if (!isTablet && !enHiLang) {
        return 8;
      } else if (isTablet && !enHiLang) {
        return 10;
      }
    }
  }
}

export function classToApply({ classes, userFromMM, isTablet, enHiLang }) {
  if (userFromMM === 'true') {
    if (!isTablet) {
      return classes.webViewForMMUser;
    } else if (isTablet) {
      return classes.mobileViewForMMUser;
    }
  } else if (userFromMM !== 'true') {
    if (isTablet && enHiLang) {
      return 'courseTabContainerForEnHiUser';
    } else if (!isTablet && enHiLang) {
      return 'courseTabContainer';
    } else if (isTablet && !enHiLang) {
      return 'courseTabForNonEnHiLangMobile';
    } else if (!enHiLang) {
      return 'courseTabForNonEnHiLang';
    }
  }
}
/**
 * Author: Shubham Gupta
 * Date: 12 Dec 2022
 * @param {*} enHiLang
 * @returns returns block only when user is eng/hindi.
 */
export function showTabs(enHiLang, qs) {
  if (enHiLang && qs.tab !== 'shop') {
    return 'block';
  } else {
    return 'none';
  }
}
/**
 * Author: Shubham Gupta
 * Date: 12 Dec 2022
 * Learn Tab will only be visible to telugu/hindi/english users.
 * @param {*} webLanguage
 * @param {*} enHiLang
 */
export function showLearnTab(webLanguage, enHiLang, isUserFc_Gm) {
  if (webLanguage === 'te' || enHiLang || isUserFc_Gm) {
    return 'block';
  } else {
    return 'none';
  }
}
/**
 * Author: Shubham Gupta
 * Date: 12 Dec 2022
 * @param {*} webLanguage
 * @param {*} userFromMM
 * @param {*} isUserFc_Gm
 * @returns margin on the top of the course content in course tabs.
 */
export function courseContentTopMargin(
  enHiLang,
  webLanguage,
  userFromMM,
  isUserFc_Gm,
  isTablet
) {
  if (userFromMM) {
    // for thirdParty user
    if (isTablet) {
      return 1;
    } else {
      return 0;
    }
  } else if (isUserFc_Gm) {
    //for Facilitator or Mentor
    if (!enHiLang) {
      return -9;
    } else {
      return 11;
    }
  } else {
    // for noraml user
    if (webLanguage === 'mr') {
      return -19;
    } else {
      if (!enHiLang) {
        return -7;
      } else {
        return 11;
      }
    }
  }
}
