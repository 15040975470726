const language = {
  //hindi
  hi: {
    SELECT_YOUR_PREFERRED_LANGUAGE: 'अपनी भाषा चुनिए',
    SUPER_SHEROES: 'सुपरशीरोज',
    MILESTONE: 'माइल्स्टोन',
    ID_MY_COMMUNITIES: 'मेरे समुदाय',
    PLEASE_TRY_AGAIN_AFTER_SOMETIME: 'कृपया कुछ समय बाद पुन: प्रयास करें।',
    POST_BOOKMARKED: 'बुकमार्क किया गया पोस्ट',
    POST_UNBOOKMARKED: 'पोस्ट अनबुकमार्क किया गया',
    REPORT: 'रिपोर्ट',
    HELP_US_UNDERSTAND: 'समझने में हमारी मदद करें',
    WHY_IS_IT_INAPPROPRIATE: 'यह अनुचित क्यों है?',
    SOCIAL: 'सोशल',
    SHOP: 'शॉप',
    GWW: 'WomenWill',
    DASHBOARD: 'डैशबोर्ड',
    EXPLORE_ALL: 'समुदाय खोजें',
    COURSE_PROGRESS: 'कोर्स',
    SURVEY: 'सर्वेक्षण',
    COURSE_FEED: 'फ़ीड',
    LEARN: 'सीखना',
    SKIP: 'छोड़ें',
    TAKE_SURVEY: 'टेक सर्वे',
    BONUS_MODULE: 'बोनस मॉड्यूल',
    ASK_QUESTION: 'प्रश्न पूछें',
    TAKE_ASSIGNMENT: 'असाइनमेंट',
    NEXT_VIDEO: 'अगला वीडियो',
    REPORT_SPAM: 'स्पैम की रिपोर्ट करें',
    EDIT: 'एडिट',
    DELETE: 'डिलीट',
    LIKE: 'लाइक',
    LIKED: 'पसंद किया',
    LIKES: 'लाइक्स',
    COMMENT: 'कमेंट',
    VIEW_REPLY: 'उत्तर देखें',
    REPOST: 'पुनः पोस्ट करें',
    TYPE_YOUR_QUESTION_HERE: 'अपने प्रश्न यहाँ लिखें ',
    QUIZ: 'क्विज़',
    WATCH_VIDEO: 'वीडियो देखें',
    ANSWERED: 'उत्तर दिए गए',
    UN_ANSWERED: 'अनुत्तरित',
    NO_QUESTION: 'उत्तम! आपके लिए नए प्रश्न नहीं हैं।',
    UN_ANSWERED_QUESTION: 'अनुत्तरित प्रश्न',
    ASSIGNED_USERS: 'नियुक्त उपभोक्ता',
    QUESTION_FOR_ME: 'मेरे लिए प्रश्न',
    ESCALATED_QUESTIONS: 'तीव्र प्रश्न',
    POST_ON_WOMENWILL: 'WomenWill पर पोस्ट करें',
    ASK_YOUR_FACILITATOR: 'फैसिलिटेटर से पूछें',
    WOMENWILL_BADGE_WINNERS: 'WomenWill बैज विजेता',
    MENTORS: 'मेंटर्स',
    COMPLETED_BLT_COURSE:
      'बढ़िया! आपने Business Literacy कोर्स पूरा कर लिया हैं!',
    PART_OF_THE_COMMUNITY: 'कम्युनिटी का अहम हिस्सा बनने के लिए आपका धन्यवाद',
    ACCESS_COURSE_VIDEO:
      'शिरोस के मेन्यू बार से कोर्स की विडियो व सर्टिफिकेट का एक्सेस पाएं',
    COMMUNITIES_MY_INTEREST: 'शिरोस पर यह कम्युनिटीज शायद आपको रूचिकर लगें',
    EXPLORE_SHEROES: 'शिरोस को और जाने',
    WOMENWILL_COURSE_PROGRESS: 'WomenWill कोर्स प्रोग्रेस',
    VIEW_CERTIFICATE: 'सर्टिफिकेट देखें',
    ONBOARDING_INTRODUCE_YOURSELF: 'अपना परिचय दें',
    ONBOARDING_WOMEN_KNOW: 'महिलाओं को बताएं कि आप कौन हैं!',
    ONBOARDING_TYPE_NAME: 'अपना पूरा नाम लिखें',
    ONBOARDING_CHOOSE_GENDER: 'क्या आप एक महिला के रूप में पहचान करते हैं ?',
    ONBOARDING_MALE: 'नहीं',
    ONBOARDING_FEMALE: 'हां',
    ONBOARDING_MALE_RESTRICTED_LINE1:
      'क्षमा करें!! हमारा सिस्टम आपको एक महिला के रूप में पहचानने में असमर्थ हैं ',
    ONBOARDING_MALE_RESTRICTED_LINE2:
      'हम केवल महिलाओं और महिलाओं के रूप में पहचान रखने वालों के लिए एक विशेष समुदाय हैं',
    ONBOARDING_MALE_SORRY:
      'कृपया हमें लिखें यदि आपको लगता है कि यह एक त्रुटि है।',
    ONBOARDING_GENDER_VERIFICATION: 'लिंग सत्यापन',
    ONBOARDING_GENDER_VERIFICATION_SUBTEXT:
      'SHEROES को आपके लिए एक सुरक्षित स्थान बनाने के लिए हमें आपकी सहायता की आवश्यकता है।',
    ONBOARDING_SELFIE_GUIDELINES: 'सेल्फी गाइडलाइंस',
    ONBOARDING_CLICK_SELFIE:
      'अगली स्क्रीन पर एक सेल्फी क्लिक करें जैसे ऊपर दिखाया गया है',
    ONBOARDING_USE_YOUR_SELFIE:
      'कृपया सत्यापन के लिए दूसरे व्यक्ति या ग्रुप इमेज का इस्तेमाल न करें क्योंकि इससे हम आपके लिंग का सत्यापन करने में असमर्थ हो सकते हैं।',
    ONBOARDING_SELFIE_PRIVACY:
      'हम आपकी सेल्फी का इस्तेमाल सिर्फ सत्यापन के लिए करेंगे, इसका इस्तेमाल कहीं और नहीं किया जाएगा',
    ONBOARDING_TAKE_SELFIE: 'सेल्फी लें',
    ONBOARDING_CAPTURE_PHOTO: 'फोटो कैप्चर करें',
    ONBOARDING_SELFIE_TEXT:
      'अपनी फ़ोटो को भुजाओं तक ले जाएं\nसुनिश्चित करें कि आपका पूरा चेहरा दिखाई दे रहा है',
    ONBOARDING_AFTER_SELFIE_VERIFICATION:
      'जैसे ही आपकी सेल्फी सत्यापित कर ली जाएगी, हम आपके पास दोबारा आएंगे',
    ONBOARDING_RETAKE: 'दोबारा लें',
    ONBOARDING_CLOSE: 'बंद करें',
    SUBMIT: 'जमा करें',
    ONBOARDING_WHAT_YOUR_INTEREST: 'आपकी क्या दिलचस्पियॉँ हैं?',
    ONBOARDING_INTEREST_SUBTITLE:
      'अपनी फीड को मन मुताबिक बनाने के लिए दिलचस्पियॉँ चुनें',
    CONTINUE: 'जारी रखें',
    GO_TO_WOMWNWILL_COMMUNITY: 'WomenWill प्रोग्राम में शामिल हों',
    BUY_NOW: 'अभी खरीदें',
    FREE: 'नि: शुल्क',
    DELIVERY: 'वितरण',
    NOTIFICATION_TITLE: 'सूचनाएं',
    ARTICLE: 'लेख',
    LOGOUT: 'लॉग आउट',
    HELPLINE: 'हेल्पलाइन',
    FOLLOWER: 'फॉलोवर',
    FOLLOWERS: 'फॉलोवर्स',
    FOLLOWING: 'फॉलोइंग',
    FRIENDS: 'फ्रेंड्स',
    MY_SHOP: 'मेरी दुकान',
    SOCIAL_FEED: 'सामाजिक फ़ीड',
    WOMEN_WILL_FEED: 'WomenWill फ़ीड',
    PROFILE: 'प्रोफ़ाइल',
    FOLLOW: 'फॉलो',
    ADD_FRIEND: 'फ्रेंड बनाएं',
    SEARCH: 'खोजें',
    CREATE_POST: 'पोस्ट बनायें',
    CHOOSE_COMMUNTIY: 'अपना समुदाय चुनें',
    POST_TO_FOLLOWERS: 'फॉलोवर्स को पोस्ट कीजिये',
    POST_AS_ANONYMOUS: 'बेनामी के रूप में पोस्ट करें',
    POST_AS_COMMUNITY: 'समुदाय के रूप में पोस्ट करें',
    CREATE: 'बनायें',
    POST: 'पोस्ट',
    PHOTO: 'फोटो',
    CAMERA: 'कैमरा',
    DISCARD_CHANGES: 'क्या आप वाकई अपने परिवर्तनों को छोड़ना चाहती हैं?',
    DISCARD_POST: 'पोस्ट छोड़ दें?',
    YES: 'हाँ',
    NO: 'नहीं',
    ID_POST: 'कमेंट इस रूप में करें:',
    ANONYMOUS: 'गुमनाम',
    HERE_TO_ASK: 'बेझिझक पूछने/साझा करने के लिए यहां टैप करें',
    ADD_YOUR_COMMENT: 'अपनी टिप्पणी यहाँ जोड़ें',
    ID_MIN_READ: 'मिनट का लेख',
    PUBLISHED_ON: 'पर प्रकाशित',
    ID_JUST_NOW: 'अभी',
    COMPLETED_BLT:
      'बधाई हो! आपने बिज़नेस लिटरेसी ट्रेनिंग मोड्यूल को सफलतापूर्वक पूरा कर लिया है।',
    PART_OF_SHEROES: 'अपने विकास के सफर को SHEROES के साथ आगे बढ़ाएं',
    NEXT: 'अगला',
    UNLOCK_MY_CERTIFICATE:
      'मैंने अभी-अभी WomenWill का वह प्रमाणपत्र अनलॉक किया है! आप भी इस प्रमाणपत्र को Sheroes पर जीत सकते हैं: ',
    DOWNLOAD: 'डाउनलोड',
    ENTER_NAME: 'नाम दर्ज',
    CONGRATULATIONS: 'बधाई हो',
    BLT_COURSE: 'आपने बीएलटी कोर्स पूरा कर लिया है।',
    WOMENWILL_CERTIFICATE: 'WomenWill सर्टिफिकेट',
    USER_HAS_NOT_COMPLETED_THE_COURSE:
      'उपयोगकर्ता ने पाठ्यक्रम पूरा नहीं किया है। कृपया पहले पाठ्यक्रम पूरा करें।',
    YOUR_COURSE_PROGRESS_WILL_APPEAR_HERE:
      'आपके पाठ्यक्रम की प्रगति यहां दिखाई देगी।',
    SHARE_POST: 'साझा करें',
    BOOKMARK_POST: 'बुकमार्क',
    BLOCK_USER: 'उपयोगकर्ता को अवरोधित करें',
    MY_BOOKMARKS: 'मेरे बुकमार्क',
    LOAD_MORE_COMMENTS: 'अधिक टिप्पणियाँ लोड करें',
    VIEW_MORE_COMMENTS: 'अधिक टिप्पणियाँ देखें...',
    RECOMMENDED_POST: 'अनुशंसित पोस्ट',
    REMOVE_BOOKMARK: 'बुकमार्क हटाएं',
    DAY_AGO: 'एक दिन पहले',
    HRS_AGO: 'एक घंटे पहले',
    HEY_YOU_ARE_NOW_A_PART_OF_SHEROES: 'अब आप SHEROES का हिस्सा हैं!',
    TELL_YOUR_दोस्त: 'अपने दोस्तों को बताएं',
    RATE_US: 'हमें रेट करें',
    COMMUNITY_GUIDELINES: 'समुदाय दिशानिर्देश',
    ADVERTORIAL_POLICY: 'विज्ञापन नीति',
    PRIVACY_POLICY: 'गोपनीयता नीति',
    TERMS_CONDITIONS: 'नियम और शर्तें',
    SETTINGS: 'समायोजन',
    APP_LOCK: 'ऐप्लिकेशन लॉक',
    SECURE_ACCESS:
      'उसी पासकोड या बायोमेट्रिक्स का उपयोग करके सुरक्षित पहुंच जिसे आप फोन को अनलॉक करने के लिए उपयोग करते हैं',
    WELCOME_TO_THE: 'आपका स्वागत है',
    WHY_IT_IS_APPROPRIATE: 'यह अनुपयुक्त क्यों है?',
    THIS_POST_IS_SPAM: 'यह पोस्ट स्पैम है',
    THIS_POST_CONTAINS_PERSONAL_DETAILS:
      'इस पोस्ट में दूसरों के व्यक्तिगत विवरण शामिल हैं',
    THIS_POST_HAS_BEEN_DONE_BY_MAN: 'यह पोस्ट एक आदमी ने किया है',
    THIS_POST_CONTAINS_FAKE_CONTENT:
      'इस पोस्ट में भ्रामक/ स्कैम/ मार्केटिंग कंटेंट है',
    THIS_POST_CONTAINS_ABUSIVE_CONTENT:
      'इस पोस्ट में अपमानजनक/ हिंसा/ यौन संबंधी कंटेंट है',
    OTHERS: 'अन्य',
    SHARE_PROFILE: 'प्रोफाइल शेयर करें',
    VIEW: 'देखें',
    POSTS: 'पोस्ट्स',
    NO_POSTS_YET: 'अभी तक कोई पोस्ट नही',
    SOMETHING_ON_YOUR_MIND:
      'क्या आप कुछ सोच रही हैं? हमारे साथ शेयर करें और अपना पहला मजेदार बैज अनलॉक करें।',
    NAME: 'नाम',
    LOCATION: 'स्थान',
    ABOUT_ME: 'मेरे बारे में',
    MAX_WORDS: '(अधिकतम 250 अक्षर)',
    PERSONAL_INFORMATION: 'निजी जानकारी',
    ONLY_VISIBLE_TO_YOU: '(केवल आपको दिखेगी)',
    DATE_OF_BIRTH: 'जन्म तिथि',
    RELATIONSHIP_STATUS: 'संबंध स्थिति',
    SELECT_STATUS: 'स्थिति चुने',
    NUMBER_OF_CHILDREN: 'बच्चों की संख्या',
    MOBILE_NUMBER: 'मोबाइल नंबर',
    EMAIL_ID: 'ईमेल आईडी',
    SAVE: 'सेव',
    NO_BADGE_AVAILABLE: 'अभी यहाँ बैज उप्लब्ध नहीं है।',
    FEATURE_POST: 'इस पोस्ट को फ़ीचर करें',
    UNFEATURE_POST: 'इस पोस्ट को अनफ़ीचर करें',
    EDIT_PROFILE: 'प्रोफ़ाइल संपादित करें',
    BLOCKED_ACCOUNTS: 'ब्लॉक किए गए खाते',
    UNBLOCK: 'अनब्लॉक करें',
    YOU_HAVE_NOT_BLOCKED_ANY_USER:
      'आपने किसी उपयोगकर्ता को अवरोधित नहीं किया है।',
    BADGE_OF_HONOUR: 'बैज ऑफ़ ऑनर',
    TELL_YOUR_FRIENDS: 'अपने मित्रों को बताएँ',
    SAY_HI: 'हाय कहो',
    CONFIRM: 'कन्फर्म करें',
    PENDING: 'पेंडिंग रिक्वेस्ट',
    ABOUT: 'परिचय',
    COMMUNITY_JOINED: 'समुदाय जिसमें आप शामिल हैं',
    CHAMPIONS_FOLLOWED: 'चैंपियंस फॉलोएड',
    VIEW_MORE: 'और देखें',
    PEOPLE_WHO_FOLLOW_ME: 'पीपल हु फॉलो मी',
    NO_FOLLOWERS: 'नो फॉलोअर्स टू शो',
    NO_FOLLOWINGS: 'नो फॉलोइंग टू शो',
    PEOPLE_WHO_FOLLOW_HER: 'जो लोग उसका अनुसरण करते हैं',
    PEOPLE_I_FOLLOW: 'जिन लोगों का मैं अनुसरण करता हूं',
    PEOPLE_SHE_FOLLOWS: 'वे लोग जिनका वह अनुसरण करती है',
    COMMUNITIES: 'समुदाय',
    NOT_JOINED: 'अभी तक किसी समुदाय में शामिल नहीं हुई है',
    NOT_FOLLOWING: 'अभी तक किसी चैंपियन को फॉलो नहीं कर रही है',
    NO_BOOKMARKS_TO_SHOW: 'कोई बुकमार्क उपलब्ध नहीं है',
    ADMIN: 'व्यवस्थापक',
    ASSIGNMENT: 'असाइनमेंट',
    SHEROES_IS_A_WOMEN_ONLY_PLATFORM: 'SHEROES केवल महिलाओं का प्लैटफॉर्म है।',
    CHECK_SELFIE: 'सेल्फी चेक करें',
    MAKE_SURE_YOUR_FACE_IS_IN_THE_OVAL:
      'सुनिश्चित करें कि आपका चेहरा अंडाकार के भीतर है।',
    MAKE_SURE_YOU_ARE_THE_ONLY_ONE_IN_THE_PICTURE:
      'सुनिश्चित करें कि तस्वीर में केवल आप ही हैं।',
    MAKE_SURE_YOU_ARE_NOT_WEARING_A_MASK_IN_THE_PICTURE:
      'सुनिश्चित करें कि आपने तस्वीर में मास्क नहीं पहना है।',
    CLICK_PHOTO_IN_GOOD_LIGHTING: 'अच्छी रोशनी में फ़ोटो क्लिक करें।',
    SORRY_IMAGE_NOT_VERIFIED: 'हमें खेद है ! इमेज कैप्चर नहीं हुई।',
    HERE_ARE_SOME_SELFIE_POINTERS: 'यहां कुछ सेल्फी पॉइंटर्स दिए गए हैं',
    UNABLE_TO_IDENTIFY_AS_FEMALE:
      'हमारा सिस्टम आपको एक महिला के रूप में पहचानने में असमर्थ था।',
    WRITE_TO_US_IF_YOU_THINK_THIS_IS_AN_ERROR:
      'अगर आपको लगता है कि यह एक त्रुटि है तो हमें लिखें',
    WE_WOULD_LOVE_FOR_YOU_TO_SPREAD:
      'हम चाहते हैं कि आप इस बात को अपनी महिला मित्रों के बीच फैलाएं।',
    SHARE_WITH_FRIENDS: 'अपने दोस्तों के साथ शेयर करें',
    SORRY: 'हमें खेद है। ',
    MAKE_SURE_YOUR_SELFIE_CLEARLY_SHOWS_YOUR_FACE:
      'सुनिश्चित करें कि आपकी सेल्फी आपके चेहरे को स्पष्ट रूप से दिखाती है।',
    ACHIEVEMENTS: 'उपलब्धियों',
    SIGN_UP: 'साइन अप करें',
    PERSONAL_DETAILS: 'पर्सनल डिटेल्स',
    OTP_VERIFICATION: 'ओटीपी वेरिफिकेशन',
    PLEASE_ENTER_CODE_SENT_TO: 'कृपया भेजा गया कोड दर्ज करें',
    RESEND_OTP: 'ओटीपी पुनः भेजें',
    IN: 'in',
    SOME_SELFIE_POINTERS: 'कुछ सेल्फी पॉइंटर्स',
    MAKE_SURE_YOUR_FACE_IS_IN_THE_OVAL_AND_CLEARLY_VISIBLE:
      'सुनिश्चित करें कि आपका चेहरा अंडाकार के भीतर है और स्पष्ट रूप से दिखाई दे रहा है।',
    HI_I_FOUND_THIS_WOMEN_ONLY_APP_FOR_YOU:
      'नमस्ते, मुझे यह महिला ऐप आपके लिए मिला है। इसका नाम है शीरोज़।आप बिना किसी हिचकिचाहट के कुछ भी (गुमनाम रूप से भी) बात कर सकती हैं, सवाल पूछ सकती हैं और अन्य महिलाओं को अच्छी सलाह देकर उनकी मदद कर सकती हैं।',
    TO_CORRECTLY_IDENTIFY_YOU:
      'आपको सही ढंग से पहचानने के लिए, हमें आपकी सेल्फी लेने की आवश्यकता है और ऐसा करने के लिए हमें आपकी अनुमति की आवश्यकता है ताकि हम डिवाइस कैमरा तक पहुंच सकें।',
    GET_STARTED_WITH_EMAIL_ADDRESS: 'ईमेल आईडी के साथ आरंभ करें',
    GET_STARTED_WITH_MOBILE_NUMBER: 'मोबाइल नंबर से शुरुआत करें',
    USE_MOBILE_NUMBER: 'मोबाइल नंबर का प्रयोग करें',
    USE_EMAIL_ADDRESS: 'ईमेल आईडी का प्रयोग करें',
    SEND_OTP: 'ओटीपी भेजें',
    CONTINUE_WITH_EMAIL_MOBILE_NO: 'ईमेल/मोबाइल नंबर के साथ कंटिन्यू करें',
    CONTINUE_WITH_FACEBOOK: 'फेसबुक के साथ कंटिन्यू करें',
    CONTINUE_WITH_GOOGLE: 'Google के साथ कंटिन्यू करें',
    WELCOME_TO_SHEROES: 'Welcome to SHEROES',
    HI: 'Hi,',
    CHAMPIONS: 'चैंपियंस',
    REPORT_USER: 'रिपोर्ट उपयोगकर्ता',
    WARNING: 'चेतावनी!!!',
    ARE_YOU_WANT_TO_BLOCK_USER:
      'क्या आप वाकई उपयोगकर्ता को ब्लॉक करना चाहते हैं?',
    CANCEL: 'रद्द करें',
    BLOCK: 'ब्लॉक करें',
    USER: 'उपयोगकर्ता',
    THIS_CONTENT_IS_UNAVAILABLE:
      'यह सामग्री जिसे आप खोज रहे हैं वह अनुपलब्ध है।',
    ARE_YOU_WANT_TO_UNBLOCK_USER:
      'क्या आप वाकई उपयोगकर्ता को अनब्लॉक करना चाहते हैं?',
    CANCEL_REQUEST: 'अनुरोध रद्द करें',
    UNFRIEND: 'अनफ्रेंड',
    UNFOLLOW: 'अनफ़ॉलो',
    POLL: 'पोल',
    VIDEO: 'वीडियो',
    CHOOSE_YOUR_TYPE_OF_POLL: 'अपने प्रकार का मतदान चुनें',
    TEXT_POLL: 'टेक्स्ट पोल',
    IMAGE_POLL: 'इमेज पोल',
    CREATE_POLL: 'पोल बनायें',
    ADD_MORE_OPTIONS: 'और विकल्प जोड़ें',
    OTHER_COMMUNITIES: 'अन्य समुदाय',
  },

  //english
  en: {
    SELECT_YOUR_PREFERRED_LANGUAGE: 'Choose your language',
    SUPER_SHEROES: 'SuperSheroes',
    MILESTONE: 'Milestone',
    ID_MY_COMMUNITIES: 'My Communities',
    PLEASE_TRY_AGAIN_AFTER_SOMETIME: 'Please try again after sometime.',
    POST_BOOKMARKED: 'Post Bookmarked',
    POST_UNBOOKMARKED: 'Post Unbookmarked',
    REPORT: 'Report',
    HELP_US_UNDERSTAND: 'Help us Understand',
    WHY_IS_IT_INAPPROPRIATE: 'Why is it inappropriate?',
    SOCIAL: 'Social',
    SHOP: 'Shop',
    GWW: 'WomenWill',
    DASHBOARD: 'Dashboard',
    EXPLORE_ALL: 'Explore all',
    COURSE_PROGRESS: 'Course',
    COURSE_FEED: 'Feed',
    LEARN: 'Learn',
    SKIP: 'Skip',
    TAKE_SURVEY: 'Take Survey',
    SURVEY: 'Survey',
    BONUS_MODULE: 'Bonus Module',
    gwwFeed: 'Gww Feed',
    ASK_QUESTION: 'Ask question',
    TAKE_ASSIGNMENT: 'Take assignment',
    NEXT_VIDEO: 'Next video',
    REPORT_SPAM: 'Report spam',
    EDIT: 'Edit',
    DELETE: 'Delete',
    LIKE: 'Like',
    LIKED: 'Liked',
    LIKES: 'Likes',
    COMMENT: 'Comment',
    VIEW_REPLY: 'View reply',
    REPOST: 'Reposts',
    TYPE_YOUR_QUESTION_HERE: 'Type your question here',
    QUIZ: 'Quiz',
    WATCH_VIDEO: 'Watch video',
    ANSWERED: 'Answered',
    UN_ANSWERED: 'Unanswered',
    NO_QUESTION: 'Wohoo! You have no new questions',
    UN_ANSWERED_QUESTION: 'Unanswered questions',
    ASSIGNED_USERS: 'Assigned Users',
    QUESTION_FOR_ME: 'Questions for me',
    ESCALATED_QUESTIONS: 'Escalated Questions',
    POST_ON_WOMENWILL: 'Post on WomenWill',
    ASK_YOUR_FACILITATOR: 'Ask your Facilitator',
    WOMENWILL_BADGE_WINNERS: 'WomenWill Badge Winners',
    MENTORS: 'Mentors',
    COMPLETED_BLT_COURSE:
      'YAY! You have completed the Business Literacy Course!',
    PART_OF_THE_COMMUNITY:
      'Thank you for being an important part of the community.',
    ACCESS_COURSE_VIDEO:
      'Access course videos and certificate through menu bar on SHEROES platform',
    COMMUNITIES_MY_INTEREST: 'These communities on SHEROES might interest you',
    EXPLORE_SHEROES: 'Explore SHEROES',
    WOMENWILL_COURSE_PROGRESS: 'WomenWill Course Progress',
    VIEW_CERTIFICATE: 'View Certificate',
    ONBOARDING_INTRODUCE_YOURSELF: 'Introduce Yourself',
    ONBOARDING_WOMEN_KNOW: 'Let women know who are you!',
    ONBOARDING_TYPE_NAME: 'Type your Full Name',
    ONBOARDING_CHOOSE_GENDER: 'Do you identify as a female?',
    ONBOARDING_MALE: 'No',
    ONBOARDING_FEMALE: 'Yes',
    ONBOARDING_MALE_RESTRICTED_LINE1:
      'Sorry! Our system was unable to identify you as female ',
    ONBOARDING_MALE_RESTRICTED_LINE2:
      'We are an exclusive community only for women and those who identify as female',
    ONBOARDING_MALE_SORRY: 'Please write to us if you think this is an error.',
    ONBOARDING_GENDER_VERIFICATION: 'Gender Verification',
    ONBOARDING_GENDER_VERIFICATION_SUBTEXT:
      'We need your help to make SHEROES a safe space for you.',
    ONBOARDING_SELFIE_GUIDELINES: 'Selfie Guidelines',
    ONBOARDING_CLICK_SELFIE:
      'Click a selfie on the next screen like the one shown above',
    ONBOARDING_USE_YOUR_SELFIE:
      "Please don't use others or group images for verification as we cannot verify your gender",
    ONBOARDING_SELFIE_PRIVACY:
      'We will use your selfie for verification purpose and will never post anywhere',
    ONBOARDING_TAKE_SELFIE: 'Take Selfie',
    ONBOARDING_CAPTURE_PHOTO: 'Capture Photo',
    ONBOARDING_SELFIE_TEXT:
      'Take your photo at arms length\nMake sure your whole face is visible',
    ONBOARDING_AFTER_SELFIE_VERIFICATION:
      'We will get back to you as soon as we verify your photo',
    ONBOARDING_RETAKE: 'Retake',
    ONBOARDING_CLOSE: 'Close',
    SUBMIT: 'Submit',
    ONBOARDING_WHAT_YOUR_INTEREST: "What's your interest?",
    ONBOARDING_INTEREST_SUBTITLE:
      'Choose your interests to personalise your feed',
    CONTINUE: 'Continue',
    GO_TO_WOMWNWILL_COMMUNITY: 'Go to WomenWill Community',
    BUY_NOW: 'Buy Now',
    FREE: 'FREE',
    DELIVERY: 'Delivery',
    NOTIFICATION_TITLE: 'Notifications',
    ARTICLE: 'Articles',
    LOGOUT: 'Logout',
    helpline: 'Helpline',
    FOLLOWER: 'Follower',
    FOLLOWERS: 'Followers',
    FOLLOWING: 'Following',
    FRIENDS: 'Friends',
    MY_SHOP: 'My Shop',
    SOCIAL_FEED: 'Social Feed',
    WOMEN_WILL_FEED: 'WomenWill Feed',
    PROFILE: 'Profile',
    FOLLOW: 'Follow',
    ADD_FRIEND: 'Add Friend',
    SEARCH: 'Search',
    CREATE_POST: 'Create Post',
    CHOOSE_COMMUNTIY: 'Choose your community',
    POST_TO_FOLLOWERS: 'Post to Followers',
    POST_AS_ANONYMOUS: 'Post as Anonymous',
    POST_AS_COMMUNITY: 'Post as Community',
    CREATE: 'Create',
    POST: 'Post',
    PHOTO: 'Photo',
    CAMERA: 'Camera',
    DISCARD_CHANGES: 'Are you sure you want to discard your changes?',
    DISCARD_POST: 'Discard Post?',
    YES: 'Yes',
    NO: 'No',
    ID_POST: 'Post as',
    ANONYMOUS: 'Anonymous',
    HERE_TO_ASK: 'Tap here to Ask/Share without hesitation',
    ADD_YOUR_COMMENT: 'Add your comment here',
    ID_MIN_READ: 'min read',
    PUBLISHED_ON: 'Published on',
    ID_JUST_NOW: 'Just Now',
    COMPLETED_BLT:
      'Yay! You have completed the Business Literacy Training Module. Share your certificate with your friends & family.',
    PART_OF_SHEROES: 'Continue your growth journey with SHEROES',
    UNLOCK_MY_CERTIFICATE:
      'I just unlocked a Certificate of WomenWill! You can also win this certificate on SHEROES:  ',
    DOWNLOAD: 'Download',
    ENTER_NAME: 'Enter name',
    CONGRATULATIONS: 'Congratulations!',
    BLT_COURSE: 'You have completed BLT course.',
    WOMENWILL_CERTIFICATE: 'WomenWill Certificate',
    USER_HAS_NOT_COMPLETED_THE_COURSE:
      'User has not completed the course. Please complete the course first.',
    YOUR_COURSE_PROGRESS_WILL_APPEAR_HERE:
      'Your course progress will appear here.',
    SHARE_POST: 'Share',
    BOOKMARK_POST: 'Bookmark',
    REMOVE_BOOKMARK: 'Remove Bookmark',
    BLOCK_USER: 'Block User',
    MY_BOOKMARKS: 'My Bookmarks',
    RECOMMENDED_POST: 'Recommended Post',
    VIEW_MORE_COMMENTS: 'View More Comments...',
    LOAD_MORE_COMMENTS: 'Load More Comments',
    DAY_AGO: 'day ago',
    HRS_AGO: 'hrs ago',
    HEY_YOU_ARE_NOW_A_PART_OF_SHEROES: 'Hey, you are now a part of SHEROES!',
    NEXT: 'Next',
    TELL_YOUR_FRIENDS: 'TELL YOUR FRIENDS',
    RATE_US: 'Rate Us',
    COMMUNITY_GUIDELINES: 'Community Guidelines',
    ADVERTORIAL_POLICY: 'Advertorial Policy',
    PRIVACY_POLICY: 'Privacy Policy',
    TERMS_CONDITIONS: 'Terms & Conditions',
    SETTINGS: 'Settings',
    APP_LOCK: 'App Lock',
    SECURE_ACCESS:
      'Secure access by using same passcode or biometrics you use to unlock the phone',
    WELCOME_TO_THE: 'Welcome to the',
    WHY_IT_IS_APPROPRIATE: 'Why it is inappropriate?',
    THIS_POST_IS_SPAM: 'This post is spam',
    THIS_POST_CONTAINS_PERSONAL_DETAILS:
      'This post contains personal details of others',
    THIS_POST_HAS_BEEN_DONE_BY_MAN: 'This post has been done by a man',
    THIS_POST_CONTAINS_FAKE_CONTENT:
      'This post contains fake/scammy/marketing content',
    THIS_POST_CONTAINS_ABUSIVE_CONTENT:
      'This post contains abusive/violent/pornographic content',
    OTHERS: 'Others',
    SHARE_PROFILE: 'Share Profile',
    VIEW: 'View',
    POSTS: 'Posts',
    NO_POSTS_YET: 'No posts yet',
    SOMETHING_ON_YOUR_MIND:
      'Something on your mind? Share with us and unlock your first exciting badge.',
    NAME: 'Name',
    LOCATION: 'Location',
    ABOUT_ME: 'About Me',
    MAX_WORDS: '(max 250 words)',
    PERSONAL_INFORMATION: 'Personal Information',
    ONLY_VISIBLE_TO_YOU: '(Only visible to you)',
    DATE_OF_BIRTH: 'Date of Birth',
    RELATIONSHIP_STATUS: 'Relationship Status',
    SELECT_STATUS: 'Select Status',
    NUMBER_OF_CHILDREN: 'Number of children',
    MOBILE_NUMBER: 'Mobile Number',
    EMAIL_ID: 'Email ID',
    SAVE: 'Save',
    NO_BADGE_AVAILABLE: 'No Badge Available',
    FEATURE_POST: 'Feature This Post',
    UNFEATURE_POST: 'UnFeature This Post',
    EDIT_PROFILE: 'Edit Profile',
    BLOCKED_ACCOUNTS: 'Blocked Accounts',
    UNBLOCK: 'Unblock',
    YOU_HAVE_NOT_BLOCKED_ANY_USER: "You haven't blocked any user.",
    BADGE_OF_HONOUR: 'BADGE OF HONOUR',
    SAY_HI: 'Say 👋',
    CONFIRM: 'Confirm',
    PENDING: 'Pending',
    ABOUT: 'About',
    COMMUNITY_JOINED: 'Communities Joined',
    CHAMPIONS_FOLLOWED: 'Champions Followed',
    VIEW_MORE: 'View More',
    PEOPLE_WHO_FOLLOW_ME: 'People who follow me',
    NO_FOLLOWERS: 'No Followers To Show',
    NO_FOLLOWINGS: 'No Followings To Show',
    PEOPLE_WHO_FOLLOW_HER: 'People who follow her',
    PEOPLE_I_FOLLOW: 'People I follow',
    PEOPLE_SHE_FOLLOWS: 'People she follows',
    COMMUNITIES: 'Communities',
    NOT_JOINED: 'has not joined any community yet',
    NOT_FOLLOWING: 'is not following any champion yet',
    NO_BOOKMARKS_TO_SHOW: 'No Bookmarks to show',
    ADMIN: 'Admin',
    ASSIGNMENT: 'Assignment',
    SHEROES_IS_A_WOMEN_ONLY_PLATFORM: 'SHEROES is a women only platform',
    CHECK_SELFIE: 'Check Selfie',
    MAKE_SURE_YOUR_FACE_IS_IN_THE_OVAL: 'Make sure your face is in the oval.',
    MAKE_SURE_YOU_ARE_THE_ONLY_ONE_IN_THE_PICTURE:
      'Make sure its you are the only one in the picture.',
    MAKE_SURE_YOU_ARE_NOT_WEARING_A_MASK_IN_THE_PICTURE:
      'Make sure you are not wearing a mask in the picture.',
    CLICK_PHOTO_IN_GOOD_LIGHTING: 'Click photo in good lighting.',
    SORRY_IMAGE_NOT_VERIFIED: 'Sorry! Image not verified',
    HERE_ARE_SOME_SELFIE_POINTERS: 'Here are some selfie pointers',
    UNABLE_TO_IDENTIFY_AS_FEMALE:
      'Our system was unable to identify you as a female.',
    WRITE_TO_US_IF_YOU_THINK_THIS_IS_AN_ERROR:
      'Write to us if you think this is an error',
    WE_WOULD_LOVE_FOR_YOU_TO_SPREAD:
      'We would love for you to spread this word among your female friends.',
    SHARE_WITH_FRIENDS: 'Share with friends',
    SORRY: 'Sorry !',
    MAKE_SURE_YOUR_SELFIE_CLEARLY_SHOWS_YOUR_FACE:
      'Make sure your selfie clearly shows your face.',
    ACHIEVEMENTS: 'Achievements',
    SIGN_UP: 'Sign up',
    PERSONAL_DETAILS: 'Personal Details',
    OTP_VERIFICATION: 'OTP Verification',
    PLEASE_ENTER_CODE_SENT_TO: 'Please enter the code sent to',
    RESEND_OTP: 'Resend OTP',
    IN: 'in',
    SOME_SELFIE_POINTERS: 'Some Selfie Pointers',
    MAKE_SURE_YOUR_FACE_IS_IN_THE_OVAL_AND_CLEARLY_VISIBLE:
      'Make sure  your face is within the oval and clearly visible.',
    HI_I_FOUND_THIS_WOMEN_ONLY_APP_FOR_YOU:
      'Hi, I found this women only app for you. Its name is SHEROES (Women Heroes). You can talk about and ask anything (even anonymously) without hesitation and help other women by giving them good advice.',
    TO_CORRECTLY_IDENTIFY_YOU:
      ' To correctly identify you, we need to capture your selfie and to do that we need your permission to allow us to access the device Camera.',
    GET_STARTED_WITH_EMAIL_ADDRESS: 'Get started with Email Address',
    GET_STARTED_WITH_MOBILE_NUMBER: 'Get started with Mobile Number',
    USE_MOBILE_NUMBER: 'Use Mobile Number',
    USE_EMAIL_ADDRESS: 'Use Email Address',
    SEND_OTP: 'Send OTP',
    CONTINUE_WITH_EMAIL_MOBILE_NO: 'Continue with Email / Mobile No.',
    CONTINUE_WITH_FACEBOOK: 'Continue with Facebook',
    CONTINUE_WITH_GOOGLE: 'Continue with Google',
    WELCOME_TO_SHEROES: 'Welcome to SHEROES',
    HI: 'Hi,',
    CHAMPIONS: 'Champions',
    REPORT_USER: 'Report User',
    WARNING: 'WARNING!!!',
    ARE_YOU_WANT_TO_BLOCK_USER: 'Are you sure you want to block the user?',
    CANCEL: 'Cancel',
    BLOCK: 'Block',
    USER: 'User',
    THIS_CONTENT_IS_UNAVAILABLE:
      "This content you're looking for is unavailable.",
    ARE_YOU_WANT_TO_UNBLOCK_USER: 'Are you sure you want to unblock the user?',
    CANCEL_REQUEST: 'Cancel Request',
    UNFRIEND: 'UnFriend',
    UNFOLLOW: 'Unfollow',
    POLL: 'Poll',
    VIDEO: 'Video',
    CHOOSE_YOUR_TYPE_OF_POLL: 'Choose your type of poll',
    TEXT_POLL: 'Text Poll',
    IMAGE_POLL: 'Image poll',
    CREATE_POLL: 'Create Poll',
    ADD_MORE_OPTIONS: 'Add more Options',
    OTHER_COMMUNITIES: 'Other Communities',
  },

  //telugu
  te: {
    SELECT_YOUR_PREFERRED_LANGUAGE: 'మీకు నచ్చిన భాష ఎంచుకోండి',
    ID_MY_COMMUNITIES: 'నా కమ్యూనిటీస్',
    PLEASE_TRY_AGAIN_AFTER_SOMETIME:
      'దయచేసి కొంత సమయం తర్వాత మళ్లీ ప్రయత్నించండి.',
    POST_BOOKMARKED: 'పోస్ట్ బుక్‌మార్క్ చేయబడింది',
    POST_UNBOOKMARKED: 'పోస్ట్ బుక్‌మార్క్ చేయబడలేదు',
    SOCIAL: 'సామాజిక',
    SHOP: 'అంగడి',
    GWW: 'WomenWill',
    REPORT: 'నివేదిక',
    HELP_US_UNDERSTAND: 'అర్థం చేసుకోవడానికి మాకు సహాయం చేయండి',
    WHY_IS_IT_INAPPROPRIATE: 'ఎందుకు సరికాదు?',
    DASHBOARD: 'డాష్బోర్డ్',
    EXPLORE_ALL: 'అన్నీ అన్వేషించండి',
    COURSE_PROGRESS: 'కోర్సు',
    COURSE_FEED: 'ఫీడ్',
    LEARN: 'నేర్చుకోండి',
    SKIP: 'దాటవేయండి',
    TAKE_SURVEY: 'టేక్ సర్వే',
    BONUS_MODULE: 'బోనస్ మాడ్యూల్',
    ASK_QUESTION: 'ప్రశ్నఅడగండి',
    TAKE_ASSIGNMENT: 'క్విజ్ తీసుకోండి',
    NEXT_VIDEO: 'నెక్స్ట్ వీడియో',
    REPORT_SPAM: 'అనుచిత సందేశాలను రిపోర్ట్ చేయండి',
    SURVEY: 'సర్వే',
    EDIT: 'ఎడిట్',
    DELETE: 'డిలీట్',
    LIKE: 'లైక్',
    LIKED: 'ఇష్టపడ్డారు',
    LIKES: 'లికెస్',
    COMMENT: 'కామెంట్',
    VIEW_REPLY: 'ప్రత్యుత్తరాన్ని వీక్షించండి',
    REPOST: 'రీపోస్ట్‌',
    TYPE_YOUR_QUESTION_HERE: 'మీ ప్రశ్నను ఇక్కడ టైప్ చేయండి',
    QUIZ: 'క్విజ్',
    WATCH_VIDEO: 'వీడియో చూడండి',
    ANSWERED: 'సమాధానం ఇచ్చారు',
    UN_ANSWERED: 'సమాధానం ఇవ్వలేదు',
    NO_QUESTION: 'వుహు! మీకు కొత్త ప్రశ్నలు లేవు',
    UN_ANSWERED_QUESTION: 'సమాధానం లేని ప్రశ్నలు',
    ASSIGNED_USERS: 'కేటాయించిన వినియోగదారులు',
    QUESTION_FOR_ME: 'నాకు ప్రశ్నలు',
    ESCALATED_QUESTIONS: 'ఎస్కేల్టెడ్ ప్రశ్నలు',
    POST_ON_WOMENWILL: 'WomenWill పై పోస్ట్ చేయండి',
    ASK_YOUR_FACILITATOR: 'మీ ఫెసిలిటేటర్‌ని అడగండి',
    WOMENWILL_BADGE_WINNERS: 'WomenWill బ్యాడ్జ్ విజేతలు',
    MENTORS: 'మార్గదర్శకులు',
    COMPLETED_BLT_COURSE:
      'అవును! మీరు Business Literacy Course కోర్సును పూర్తి చేసారు!',
    PART_OF_THE_COMMUNITY: 'సంఘంలో ముఖ్యమైన భాగంగా ఉన్నందుకు ధన్యవాదాలు.',
    ACCESS_COURSE_VIDEO:
      'SHEROES ప్లాట్‌ఫారమ్‌లోని మెను బార్ ద్వారా కోర్సు వీడియోలు మరియు సర్టిఫికేట్‌లను యాక్సెస్ చేయండి',
    COMMUNITIES_MY_INTEREST: 'SHEROESలోని ఈ సంఘాలు మీకు ఆసక్తి కలిగి ఉండవచ్చు',
    EXPLORE_SHEROES: 'SHEROESని అన్వేషించండి',
    WOMENWILL_COURSE_PROGRESS: 'WomenWill కోర్సు ప్రోగ్రెస్',
    VIEW_CERTIFICATE: 'సర్టిఫికేట్ చూడండి',
    ONBOARDING_INTRODUCE_YOURSELF: 'మిమ్మల్ని మీరు పరిచయం చేసుకోండి',
    ONBOARDING_WOMEN_KNOW: 'మీరు ఎవరో మహిళలకు తెలియజేయండి!',
    ONBOARDING_TYPE_NAME: 'మీ పూర్తి పేరును టైప్ చేయండి',
    ONBOARDING_CHOOSE_GENDER: 'మీరు స్త్రీగా గుర్తించారా ?',
    ONBOARDING_MALE: 'సంఖ్య',
    ONBOARDING_FEMALE: 'అవును',
    ONBOARDING_MALE_RESTRICTED_LINE1:
      'క్షమించండి! మా సిస్టమ్ మిమ్మల్ని స్త్రీగా గుర్తించలేకపోయింది',
    ONBOARDING_MALE_RESTRICTED_LINE2:
      'మాది మహిళలు మరియు స్త్రీలుగా గుర్తింపబడే వారికి మాత్రమే ప్రత్యేకమైన సంఘం.',
    ONBOARDING_MALE_SORRY:
      'ఇది పొరపాటు అని మీరు భావిస్తే దయచేసి మాకు వ్రాయండి.',
    ONBOARDING_GENDER_VERIFICATION: 'లింగ ధృవీకరణ',
    ONBOARDING_GENDER_VERIFICATION_SUBTEXT:
      'SHEROESని మీ కోసం సురక్షితమైన స్థలంగా మార్చడానికి మాకు మీ సహాయం కావాలి',
    ONBOARDING_SELFIE_GUIDELINES: 'సెల్ఫీ మార్గదర్శకాలు',
    ONBOARDING_CLICK_SELFIE:
      'పైన చూపిన విధంగా తదుపరి స్క్రీన్‌లో సెల్ఫీ క్లిక్ చేయండి',
    ONBOARDING_USE_YOUR_SELFIE:
      'మేము మీ లింగాన్ని ధృవీకరించలేనందున దయచేసి ధృవీకరణ కోసం ఇతరుల లేదా సమూహల చిత్రాలను ఉపయోగించవద్దు',
    ONBOARDING_SELFIE_PRIVACY:
      'మేము మీ సెల్ఫీని ధృవీకరణ ప్రయోజనం కోసం ఉపయోగిస్తాము మరియు ఎక్కడా పోస్ట్ చేయము',
    ONBOARDING_TAKE_SELFIE: 'సెల్ఫీ తీసుకోండి',
    ONBOARDING_CAPTURE_PHOTO: 'ఫోటో పట్టుకోండి',
    ONBOARDING_SELFIE_TEXT:
      'చేతులు పొడవుగా మీ ఫోటో తీయండి\nమీ ముఖం మొత్తం కనిపించేలా చూసుకోండి',
    ONBOARDING_AFTER_SELFIE_VERIFICATION:
      'మేము మీ ఫోటోను ధృవీకరించిన వెంటనే మేము మిమ్మల్ని సంప్రదిస్తాము',
    ONBOARDING_RETAKE: 'మళ్లీ తీసుకోండి',
    ONBOARDING_CLOSE: 'మూసివేయండి',
    SUBMIT: 'సమర్పించండి',
    ONBOARDING_WHAT_YOUR_INTEREST: 'మీయొక్క ఆసక్తి ఏమిటి?',
    ONBOARDING_INTEREST_SUBTITLE:
      'మీ ఫీడ్ను వ్యక్తిగతీకరించడానికి మీ ఆసక్తులను ఎంచుకోండి',
    CONTINUE: 'కొనసాగుతుంది',
    GO_TO_WOMWNWILL_COMMUNITY: 'WomenWill కమ్యూనిటీకి వెళ్లండి',
    BUY_NOW: 'ఇప్పుడే కొనండి',
    FREE: 'ఉచిత',
    DELIVERY: 'డెలివరీ',
    NOTIFICATION_TITLE: 'నోటిఫికేషన్స్',
    ARTICLE: 'ఆర్టికల్స్',
    LOGOUT: 'లాగ్అవుట్',
    helpline: 'హెల్ప్ లైన్',
    FOLLOWER: 'అనుచరుడు',
    FOLLOWERS: 'ఫాల్లోవెర్స్',
    FOLLOWING: 'ఫాలోయింగ్',
    FRIENDS: 'స్నేహితులు',
    MY_SHOP: 'నా దుకాణం',
    SOCIAL_FEED: 'సామాజిక ఫీడ్',
    WOMEN_WILL_FEED: 'WomenWill ఫీడ్',
    PROFILE: 'ప్రొఫైల్',
    FOLLOW: 'ఫాలో అవ్వండి',
    ADD_FRIEND: 'ఆడ్ ఫ్రెండ్',
    SEARCH: 'శోధన లేదా అన్వేషించు',
    CREATE_POST: 'పోస్ట్ ని సృష్టించండి',
    CHOOSE_COMMUNTIY: 'మీ కమ్యూనిటీ ని ఎంచుకోండి',
    POST_TO_FOLLOWERS: 'అనుచరులకు పోస్ట్ చేయండి',
    POST_AS_ANONYMOUS: 'అనామకంగా పోస్ట్ చేయండి',
    POST_AS_COMMUNITY: 'కమ్యూనిటీగా పోస్ట్',
    CREATE: 'సృష్టించు',
    POST: 'పోస్ట్',
    PHOTO: 'ఫోటో',
    CAMERA: 'కెమెరా',
    DISCARD_CHANGES: 'మీరు ఖచ్చితంగా మీ మార్పులను విస్మరించాలనుకుంటున్నారా?',
    DISCARD_POST: 'పోస్ట్ ని తీసేయండి?',
    YES: 'అవును',
    NO: 'కాదు',
    shopName: 'షాప్ పేరు*',
    ID_POST: 'పోస్ట్ చెయ్యండి:',
    ANONYMOUS: 'అనానిమస్',
    HERE_TO_ASK: 'సంకోచం లేకుండా అడగడానికి/షేర్ చేయడానికి ఇక్కడ నొక్కండి',
    ADD_YOUR_COMMENT: 'మీ వ్యాఖ్యను ఇక్కడ జోడించండి',
    ID_MIN_READ: 'నిమిషంలో చదవండి',
    PUBLISHED_ON: 'ప్రచురించబడింది',
    ID_JUST_NOW: 'ఇప్పుడే',
    COMPLETED_BLT:
      'యాహూ! మీరు వ్యాపార అక్షరాస్యత శిక్షణ మాడ్యూల్‌ని పూర్తి చేసారు. మీ ప్రమాణపత్రాన్ని మీ స్నేహితులు & కుటుంబ సభ్యులతో పంచుకోండి.',
    PART_OF_SHEROES: 'SHEROESతో మీ అభివృద్ధి ప్రయాణాన్ని కొనసాగించండి',
    NEXT: 'నెక్స్ట్',
    UNLOCK_MY_CERTIFICATE:
      'నేను WomenWill నుండి ఆ ప్రమాణపత్రాన్ని ఇప్పుడే అన్‌లాక్ చేసాను! మీరు కూడా Sheroesలో ఈ ప్రమాణపత్రాన్ని గెలుచుకోవచ్చు: ',
    DOWNLOAD: 'డౌన్‌లోడ్ చేయండి',
    ENTER_NAME: 'పేరు నమోదు చేయండి',
    CONGRATULATIONS: 'అభినందనలు!',
    BLT_COURSE: 'మీరు BLT కోర్సును పూర్తి చేసారు.',
    WOMENWILL_CERTIFICATE: 'WomenWill సర్టిఫికేట్',
    USER_HAS_NOT_COMPLETED_THE_COURSE:
      'వినియోగదారు కోర్సును పూర్తి చేయలేదు. దయచేసి ముందుగా కోర్సును పూర్తి చేయండి.',
    YOUR_COURSE_PROGRESS_WILL_APPEAR_HERE:
      'మీ కోర్సు పురోగతి ఇక్కడ కనిపిస్తుంది.',
    SHARE_POST: 'భాగస్వామ్యం',
    BOOKMARK_POST: 'బుక్‌మార్క్',
    REMOVE_BOOKMARK: 'బుక్‌మార్క్‌ని తీసివేయండి',
    BLOCK_USER: 'వినియోగదారుని నిరోధించు',
    MY_BOOKMARKS: 'నా బుక్‌మార్క్‌లు',
    LOAD_MORE_COMMENTS: 'మరిన్ని వ్యాఖ్యలను లోడ్ చేయి',
    VIEW_MORE_COMMENTS: 'మరిన్ని వ్యాఖ్యలను వీక్షించండి...',
    RECOMMENDED_POST: 'సిఫార్సు చేయబడిన పోస్ట్',
    DAY_AGO: 'రోజు క్రితం',
    HRS_AGO: 'గంటల క్రితం',
    HEY_YOU_ARE_NOW_A_PART_OF_SHEROES: 'హే, మీరు ఇప్పుడు SHEROESలో భాగమయ్యారు!',
    TELL_YOUR_FRIENDS: 'మీ స్నేహితులకు చెప్పండి',
    RATE_US: 'మాకు రేట్ చేయండి',
    COMMUNITY_GUIDELINES: 'కమ్యూనిటీ మార్గదర్శకాలు',
    ADVERTORIAL_POLICY: 'అడ్వర్టోరియల్ పాలసీ',
    PRIVACY_POLICY: 'గోప్యతా విధానం',
    TERMS_CONDITIONS: 'నిబంధనలు & షరతులు',
    SETTINGS: 'సెట్టింగ్‌లు',
    APP_LOCK: 'యాప్ లాక్',
    SECURE_ACCESS:
      'ఫోన్‌ను అన్‌లాక్ చేయడానికి మీరు ఉపయోగించే అదే పాస్‌కోడ్ లేదా బయోమెట్రిక్‌లను ఉపయోగించడం ద్వారా సురక్షిత ప్రాప్యత',
    BLOCKED_ACCOUNTS: 'బ్లాక్ చేయబడిన ఖాతాలు',
    YOU_HAVE_NOT_BLOCKED_ANY_USER: 'మీరు ఏ వినియోగదారుని బ్లాక్ చేయలేదు.',
    BADGE_OF_HONOUR: 'బ్యాడ్జ్ ఆఫ్ హానర్',
    SAY_HI: 'హాయ్ చెప్పండి',
    CONFIRM: 'నిర్ధారించు',
    PENDING: 'పెండింగ్‌లో ఉంది',
    ABOUT: 'గురించి',
    COMMUNITY_JOINED: 'మీరు పాల్గొన్న సంఘం',
    CHAMPIONS_FOLLOWED: 'ఛాంపియన్‌లు అనుసరించారు',
    VIEW_MORE: 'మరిన్ని వీక్షించండి',
    PEOPLE_WHO_FOLLOW_ME: 'నన్ను అనుసరించే వ్యక్తులు',
    NO_FOLLOWERS: 'చూపడానికి అనుచరులు లేరు',
    NO_FOLLOWINGS: 'చూపడానికి ఫాలోయింగ్‌లు లేవు',
    PEOPLE_WHO_FOLLOW_HER: 'ఆమెను అనుసరించే వ్యక్తులు',
    PEOPLE_I_FOLLOW: 'నేను అనుసరించే వ్యక్తులు',
    PEOPLE_SHE_FOLLOWS: 'ఆమె అనుసరించే వ్యక్తులు',
    COMMUNITIES: 'కమ్యూనిటీలు',
    NOT_JOINED: 'ఇంకా ఏ సంఘంలో చేరలేదు',
    NOT_FOLLOWING: 'ఇంకా ఏ ఛాంపియన్‌ని అనుసరించడం లేదు',
    NO_BOOKMARKS_TO_SHOW: 'చూపించడానికి బుక్‌మార్క్‌లు లేవు',
    POSTS: 'పోస్ట్‌లు',
    ADMIN: 'అడ్మిన్',
    ASSIGNMENT: 'అసైన్‌మెంట్',
    SHARE_PROFILE: 'ప్రొఫైల్‌ను భాగస్వామ్యం చేయండి',
    VIEW: 'చూడండి',
    NO_BADGE_AVAILABLE: 'బ్యాడ్జ్ అందుబాటులో లేదు',
    SUPER_SHEROES: 'SuperSheroes',
    MILESTONE: 'మైలురాయి',
    SHEROES_IS_A_WOMEN_ONLY_PLATFORM: 'SHEROES అనేది మహిళా వేదిక.',
    CHECK_SELFIE: 'సెల్ఫీని చెక్ చేయండి',
    MAKE_SURE_YOUR_FACE_IS_IN_THE_OVAL:
      'నిర్ధారించుకోండి మీ ముఖం ఇచ్చిన ఓవల్ స్పేస్‌లో ఉందని',
    MAKE_SURE_YOU_ARE_THE_ONLY_ONE_IN_THE_PICTURE:
      'చిత్రంలో మీరు మాత్రమే ఉన్నారని చెక్ చేయండి',
    MAKE_SURE_YOU_ARE_NOT_WEARING_A_MASK_IN_THE_PICTURE:
      'దయచేసి ఫోటో తీస్తున్నప్పుడు మాస్క్ ధరించకండి',
    CLICK_PHOTO_IN_GOOD_LIGHTING: 'మంచి లైటింగ్‌లో ఫోటోను క్లిక్ చేయండి.',
    SORRY_IMAGE_NOT_VERIFIED: 'క్షమించండి! చిత్రం ధృవీకరించబడలేదు',
    HERE_ARE_SOME_SELFIE_POINTERS: 'కొన్ని సెల్ఫీ పాయింటర్లు',
    UNABLE_TO_IDENTIFY_AS_FEMALE:
      'మా సిస్టమ్ మిమ్మల్ని స్త్రీగా గుర్తించలేకపోయింది.',
    WRITE_TO_US_IF_YOU_THINK_THIS_IS_AN_ERROR:
      'ఇది పొరపాటు అని మీరు అనుకుంటే మాకు వ్రాయండి',
    WE_WOULD_LOVE_FOR_YOU_TO_SPREAD:
      'మీరు మీ స్నేహితురాళ్లలో ఈ విషయాన్ని వ్యాప్తి చేయడాన్ని మేము ఇష్టపడతాము.',
    SHARE_WITH_FRIENDS: 'స్నేహితులతో షేర్ చేసుకోండి',
    SORRY: 'క్షమించండి !',
    MAKE_SURE_YOUR_SELFIE_CLEARLY_SHOWS_YOUR_FACE:
      'మీ సెల్ఫీ మీ ముఖాన్ని స్పష్టంగా చూపేలా చూసుకోండి.',
    WHY_IT_IS_APPROPRIATE: 'అది ఎందుకు తగనిది?',
    SAVE: 'సేవ్ చేయండి',
    NAME: 'పేరు',
    EMAIL_ID: 'ఇమెయిల్ ఐడి',
    NUMBER_OF_CHILDREN: 'పిల్లల సంఖ్య',
    SELECT_STATUS: 'స్థితిని ఎంచుకోండి',
    RELATIONSHIP_STATUS: 'సంబంధాల స్థాయి',
    DATE_OF_BIRTH: 'పుట్టిన తేది',
    PERSONAL_INFORMATION: 'వ్యక్తిగత సమాచారం',
    ABOUT_ME: 'నా గురించి',
    LOCATION: 'స్థానం',
    SOMETHING_ON_YOUR_MIND:
      'మీ మనసులో ఏదో ఉందా? మాతో భాగస్వామ్యం చేయండి మరియు మీ మొదటి ఉత్తేజకరమైన బ్యాడ్జ్‌ని అన్‌లాక్ చేయండి.',
    NO_POSTS_YET: 'ఇంకా పోస్ట్‌లు లేవు',
    OTHERS: 'ఇతరులు',
    THIS_POST_CONTAINS_ABUSIVE_CONTENT:
      'ఈ పోస్ట్ దుర్వినియోగ/హింసాత్మక/అశ్లీల కంటెంట్‌ని కలిగి ఉంది',
    THIS_POST_CONTAINS_FAKE_CONTENT:
      'ఈ పోస్ట్ నకిలీ/స్కామ్మీ/మార్కెటింగ్ కంటెంట్‌ని కలిగి ఉంది',
    THIS_POST_HAS_BEEN_DONE_BY_MAN: 'ఈ పోస్ట్ ఒక వ్యక్తి ద్వారా చేయబడింది',
    THIS_POST_CONTAINS_PERSONAL_DETAILS:
      'ఈ పోస్ట్‌లో ఇతరుల వ్యక్తిగత వివరాలు ఉన్నాయి',
    THIS_POST_IS_SPAM: 'ఈ పోస్ట్ స్పామ్',
    WELCOME_TO_THE: 'కు స్వాగతం',
    ACHIEVEMENTS: 'విజయాలు',
    SIGN_UP: 'చేరడం',
    PERSONAL_DETAILS: 'వ్యక్తిగత వివరాలు',
    OTP_VERIFICATION: 'OTP ధృవీకరణ',
    PLEASE_ENTER_CODE_SENT_TO: 'దయచేసి కి పంపిన కోడ్‌ను ఎంటర్ చేయండి',
    RESEND_OTP: 'OTPని మళ్లీ పంపండి',
    IN: 'in',
    SOME_SELFIE_POINTERS: 'సెల్ఫీ ఎలా తీసుకోవాలో కొన్ని చిట్కాలు',
    MAKE_SURE_YOUR_FACE_IS_IN_THE_OVAL_AND_CLEARLY_VISIBLE:
      'మీ ముఖం ఇచ్చిన ఓవల్ స్పేస్‌లో ఉందని మరియు స్పష్టంగా కనిపించేలా చూసుకోండి.',
    HI_I_FOUND_THIS_WOMEN_ONLY_APP_FOR_YOU:
      'హాయ్, నేను మీ కోసం ఈ మహిళలు మాత్రమే యాప్‌ని కనుగొన్నాను. దాని పేరు SHEROES (Women Heroes). మీరు సంకోచం లేకుండా ఏదైనా (అనామకంగా కూడా) మాట్లాడవచ్చు మరియు అడగవచ్చు మరియు ఇతర మహిళలకు మంచి సలహా ఇవ్వడం ద్వారా వారికి సహాయం చేయవచ్చు.',
    TO_CORRECTLY_IDENTIFY_YOU:
      'మిమ్మల్ని సరిగ్గా గుర్తించడానికి, మేము మీ సెల్ఫీని క్యాప్చర్ చేయాలి మరియు అలా చేయడానికి మీ మొబైల్‌లోని కెమెరాను యాక్సెస్ చేయడానికి మాకు మీ అనుమతి అవసరం.',
    GET_STARTED_WITH_EMAIL_ADDRESS: 'E-mail చిరునామాతో ప్రారంభించండి',
    GET_STARTED_WITH_MOBILE_NUMBER: 'Mobile నంబర్ ప్రారంభించండి',
    USE_MOBILE_NUMBER: 'Mobile నంబర్ఉపయోగించండి',
    USE_EMAIL_ADDRESS: 'E-mail  ఉపయోగించండి',
    SEND_OTP: 'Send OTP',
    CONTINUE_WITH_EMAIL_MOBILE_NO: 'E-mail / Mobile నంబర్‌తో కొనసాగండి.',
    CONTINUE_WITH_FACEBOOK: 'Facebook తో కొనసాగండి',
    CONTINUE_WITH_GOOGLE: 'Google తో కొనసాగండి',
    WELCOME_TO_SHEROES: 'Welcome to SHEROES',
    HI: 'Hi,',
    CHAMPIONS: 'ఛాంపియన్‌లు',
    REPORT_USER: 'వినియోగదారుని నివేదించు',
    WARNING: 'హెచ్చరిక!!!',
    ARE_YOU_WANT_TO_BLOCK_USER:
      'మీరు ఖచ్చితంగా వినియోగదారుని బ్లాక్ చేయాలనుకుంటున్నారా?',
    CANCEL: 'రద్దు చేయి',
    BLOCK: 'బ్లాక్',
    USER: 'యూజర్',
    THIS_CONTENT_IS_UNAVAILABLE: 'మీరు వెతుకుతున్న ఈ కంటెంట్ అందుబాటులో లేదు.',
    ARE_YOU_WANT_TO_UNBLOCK_USER:
      'మీరు ఖచ్చితంగా వినియోగదారుని అన్‌బ్లాక్ చేయాలనుకుంటున్నారా?',
    UNBLOCK: 'అన్‌బ్లాక్ చేయండి',
    CANCEL_REQUEST: 'అభ్యర్థనను రద్దు చేయి',
    UNFRIEND: 'అన్‌ఫ్రెండ్',
    UNFOLLOW: 'అనుసరించవద్దు',
    POLL: 'పోల్',
    VIDEO: 'వీడియో',
    CHOOSE_YOUR_TYPE_OF_POLL: 'మీ పోల్ రకాన్ని ఎంచుకోండి',
    TEXT_POLL: 'టెక్స్ట్ పోల్',
    IMAGE_POLL: 'చిత్ర పోల్',
    CREATE_POLL: 'పోల్ సృష్టించు',
    ADD_MORE_OPTIONS: 'మరిన్ని ఎంపికలను జోడించండి',
    OTHER_COMMUNITIES: 'ఇతర సంఘాలు',
  },

  //marathi
  mr: {
    SELECT_YOUR_PREFERRED_LANGUAGE: 'तुमची भाषा निवडा',
    SUPER_SHEROES: 'SuperSheroes',
    MILESTONE: 'माईलस्टोन',
    ID_MY_COMMUNITIES: 'माझे समुदाय',
    PLEASE_TRY_AGAIN_AFTER_SOMETIME: 'कृपया काही वेळाने पुन्हा प्रयत्न करा.',
    POST_BOOKMARKED: 'पोस्ट बुकमार्क केलेले',
    POST_UNBOOKMARKED: 'अनबुकमार्क केलेले पोस्ट',
    REPORT: 'अहवाल द्या',
    HELP_US_UNDERSTAND: 'आम्हाला समजून घेण्यात मदत करा',
    WHY_IS_IT_INAPPROPRIATE: 'ते अयोग्य का आहे?',
    SOCIAL: 'सामाजिक',
    SHOP: 'दुकान',
    GWW: 'WomenWill',
    DASHBOARD: 'डॅशबोर्ड',
    EXPLORE_ALL: 'सर्व एक्सप्लोर करा',
    COURSE_PROGRESS: 'अभ्यासक्रम',
    COURSE_FEED: 'फीड',
    LEARN: 'शिका',
    SKIP: 'वगळा',
    TAKE_SURVEY: 'सर्वेक्षण करा',
    SURVEY: 'सर्वेक्षण',
    BONUS_MODULE: 'बोनस मॉड्यूल',
    gwwFeed: 'Gww फीड',
    ASK_QUESTION: 'प्रश्न विचारा',
    TAKE_ASSIGNMENT: 'प्रश्नमंजूषा घ्या',
    NEXT_VIDEO: 'पुढील व्हिडिओ',
    REPORT_SPAM: 'स्पॅमचा अहवाल द्या',
    EDIT: 'सुधारणे',
    DELETE: 'हटवा',
    LIKE: 'लाइक',
    LIKED: 'लाइकड',
    LIKES: 'लाईक्स',
    COMMENT: 'कमेंट',
    VIEW_REPLY: 'उत्तर पहा',
    REPOST: 'पुन्हा पोस्ट करा',
    TYPE_YOUR_QUESTION_HERE: 'तुमचा प्रश्न येथे टाइप करा',
    QUIZ: 'प्रश्नमंजुषा',
    WATCH_VIDEO: 'व्हिडिओ पहा',
    ANSWERED: 'उत्तर दिलेले',
    UN_ANSWERED: 'अनुत्तरीत',
    NO_QUESTION: 'व्वा! तुम्हाला कोणतेही नवीन प्रश्न नाहीत',
    UN_ANSWERED_QUESTION: 'अनुत्तरीत प्रश्न',
    ASSIGNED_USERS: 'नियुक्त वापारकर्ते',
    QUESTION_FOR_ME: 'माझ्या करिता प्रश्न',
    ESCALATED_QUESTIONS: 'तीव्र प्रश्न',
    POST_ON_WOMENWILL: 'WomenWill वर पोस्ट करा',
    ASK_YOUR_FACILITATOR: 'तुमच्या फैसिलिटेटर ला विचारा',
    WOMENWILL_BADGE_WINNERS: 'WomenWill बैज विजेते',
    MENTORS: 'सल्लागार',
    COMPLETED_BLT_COURSE:
      'अरे व्वा! तुम्ही Business Literacy कोर्स पुर्ण केला आहे.',
    PART_OF_THE_COMMUNITY: 'समुदायाचा महत्वाचा भाग बनल्याबद्दल तुमचे धन्यवाद',
    ACCESS_COURSE_VIDEO:
      'SHEROES च्या मेन्यु बार मधुन कोर्स व्हिडिओ व सर्टिफिकेट चा एक्सेस मिळवा.',
    COMMUNITIES_MY_INTEREST:
      'SHEROES वरील हे सामुदाय कदाचित तुम्हाला रुचीकर वाटतील.',
    EXPLORE_SHEROES: 'SHEROES बद्दल अधिक जाणुन घ्या.',
    WOMENWILL_COURSE_PROGRESS: 'WomenWill कोर्स प्रगती',
    VIEW_CERTIFICATE: 'सर्टिफिकेट बघा.',
    ONBOARDING_INTRODUCE_YOURSELF: 'आपला परिचय द्या',
    ONBOARDING_WOMEN_KNOW: 'आपण कोण आहात हे स्त्रियांना कळू द्या!',
    ONBOARDING_TYPE_NAME: 'तुमचे पूर्ण नाव टाइप करा',
    ONBOARDING_CHOOSE_GENDER: 'तुम्ही स्त्री म्हणून ओळखता का?',
    ONBOARDING_MALE: 'नाही',
    ONBOARDING_FEMALE: 'होय',
    ONBOARDING_MALE_RESTRICTED_LINE1:
      'क्षमस्व! आमची प्रणाली तुम्हाला महिला म्हणून ओळखण्यात अक्षम आहे',
    ONBOARDING_MALE_RESTRICTED_LINE2:
      'आम्ही केवळ महिलांसाठी आणि महिला म्हणून ओळखल्या जाणार्‍या लोकांसाठी एक अनन्य समुदाय आहोत',
    ONBOARDING_MALE_SORRY:
      'जर तुम्हाला ही चूक वाटत असेल तर कृपया आम्हाला लिहा.',
    ONBOARDING_GENDER_VERIFICATION: 'लिंग पडताळणी',
    ONBOARDING_GENDER_VERIFICATION_SUBTEXT:
      'SHEROES तुमच्यासाठी सुरक्षित जागा बनवण्यासाठी आम्हाला तुमच्या मदतीची गरज आहे.',
    ONBOARDING_SELFIE_GUIDELINES: 'सेल्फी मार्गदर्शक तत्त्वे',
    ONBOARDING_CLICK_SELFIE:
      'वर दाखवल्याप्रमाणे पुढील स्क्रीनवर सेल्फीवर क्लिक करा',
    ONBOARDING_USE_YOUR_SELFIE:
      'कृपया पडताळणीसाठी इतर किंवा गट प्रतिमा वापरू नका कारण आम्ही तुमचे लिंग सत्यापित करू शकत नाही',
    ONBOARDING_SELFIE_PRIVACY:
      'आम्ही तुमचा सेल्फी पडताळणीसाठी वापरू आणि कधीही कुठेही पोस्ट करणार नाही',
    ONBOARDING_TAKE_SELFIE: 'सेल्फी घ्या',
    ONBOARDING_CAPTURE_PHOTO: 'फोटो कॅप्चर करा',
    ONBOARDING_SELFIE_TEXT:
      'तुमचा फोटो हाताच्या लांबीवर घ्या\nतुमचा संपूर्ण चेहरा दिसत असल्याची खात्री करा',
    ONBOARDING_AFTER_SELFIE_VERIFICATION:
      'आम्ही तुमच्या फोटोची पडताळणी करताच तुमच्याशी संपर्क साधू',
    ONBOARDING_RETAKE: 'पुन्हा घ्या',
    ONBOARDING_CLOSE: 'बंद करा',
    SUBMIT: 'प्रस्तुत करणे',
    ONBOARDING_WHAT_YOUR_INTEREST: 'तुमची स्वारस्य काय आहे?',
    ONBOARDING_INTEREST_SUBTITLE:
      'तुमची फीड वैयक्तिकृत करण्यासाठी तुमची स्वारस्ये निवडा',
    CONTINUE: 'सुरू',
    GO_TO_WOMWNWILL_COMMUNITY: 'WomenWill समुदाय वर जा',
    BUY_NOW: 'आता खरेदी करा',
    FREE: 'फुकट',
    DELIVERY: 'डिलिव्हरी',
    NOTIFICATION_TITLE: 'अधिसूचना',
    ARTICLE: 'लेख',
    LOGOUT: 'बाहेर पडा.',
    helpline: 'हेल्पलाइन',
    FOLLOWER: 'अनुयायी',
    FOLLOWERS: 'अनुयायी',
    FOLLOWING: 'अनुसरण करत आहात',
    FRIENDS: 'मित्रांनो',
    MY_SHOP: 'माझे दुकान',
    SOCIAL_FEED: 'सामाजिक फीड',
    WOMEN_WILL_FEED: 'WomenWill फीड',
    PROFILE: 'प्रोफाइल',
    FOLLOW: 'अनुसरण करा',
    ADD_FRIEND: 'दोस्त जोड़ें',
    SEARCH: 'खोज',
    CREATE_POST: 'पोस्ट बनाएं',
    CHOOSE_COMMUNTIY: 'अपना समुदाय चुनें',
    POST_TO_FOLLOWERS: 'अनुयायियों को पोस्ट करें',
    POST_AS_ANONYMOUS: 'गुमनाम के रूप में पोस्ट करें',
    POST_AS_COMMUNITY: 'समुदाय म्हणून पोस्ट करा',
    CREATE: 'तयार करा',
    POST: 'पोस्ट',
    PHOTO: 'छायाचित्र',
    CAMERA: 'कॅमेरा',
    DISCARD_CHANGES: 'तुमची खात्री आहे की तुम्ही तुमचे बदल टाकून देऊ इच्छिता?',
    DISCARD_POST: 'पोस्ट टाकून द्यायची?',
    YES: 'होय',
    NO: 'नाही',
    shopName: 'दुकानाचे नाव*',
    ID_POST: 'म्हणून पोस्ट करा',
    ANONYMOUS: 'अनामिक',
    HERE_TO_ASK: 'संकोच न करता विचारण्यासाठी/शेअर करण्यासाठी येथे टॅप करा',
    ADD_YOUR_COMMENT: 'तुमची टिप्पणी येथे जोडा',
    ID_MIN_READ: 'किमान वाचले',
    PUBLISHED_ON: 'रोजी प्रकाशित',
    ID_JUST_NOW: 'आत्ताच',
    COMPLETED_BLT:
      'याय! तुम्ही व्यवसाय साक्षरता प्रशिक्षण मॉड्यूल पूर्ण केले आहे. आपले प्रमाणपत्र आपल्या मित्र आणि कुटुंबासह सामायिक करा.',
    PART_OF_SHEROES: 'SHEROES सह तुमचा वाढीचा प्रवास सुरू ठेवा',
    NEXT: 'पुढिल',
    UNLOCK_MY_CERTIFICATE:
      'मी नुकतेच WomenWill प्रमाणपत्र अनलॉक केले! तुम्ही हे प्रमाणपत्र SHEROES वर देखील जिंकू शकता:  ',
    DOWNLOAD: 'डाउनलोड',
    ENTER_NAME: 'नाव प्रविष्ट करा',
    CONGRATULATIONS: 'अभिनंदन!',
    BLT_COURSE: 'तुम्ही बीएलटी कोर्स पूर्ण केला आहे.',
    WOMENWILL_CERTIFICATE: 'WomenWill प्रमाणपत्र',
    USER_HAS_NOT_COMPLETED_THE_COURSE:
      'वापरकर्त्याने अभ्यासक्रम पूर्ण केलेला नाही. कृपया प्रथम अभ्यासक्रम पूर्ण करा.',
    YOUR_COURSE_PROGRESS_WILL_APPEAR_HERE:
      'तुमची अभ्यासक्रमाची प्रगती येथे दिसून येईल.',
    SHARE_POST: 'शेअर करा',
    BOOKMARK_POST: 'बुकमार्क करा',
    REMOVE_BOOKMARK: 'बुकमार्क काढा',
    BLOCK_USER: 'वापरकर्ता अवरोधित करा',
    MY_BOOKMARKS: 'माझे बुकमार्क',
    RECOMMENDED_POST: 'शिफारस केलेले पोस्ट',
    VIEW_MORE_COMMENTS: 'अधिक टिप्पण्या पहा...',
    LOAD_MORE_COMMENTS: 'अधिक टिप्पण्या लोड करा',
    DAY_AGO: 'एक दिवस अगोदर',
    HRS_AGO: 'एक तास अगोदर',
    HEY_YOU_ARE_NOW_A_PART_OF_SHEROES:
      'अहो, आता तुम्ही SHEROES चा एक भाग आहात.',
    TELL_YOUR_FRIENDS: 'तुमच्या मित्रांना सांगा',
    RATE_US: 'आम्हाला रेटिंग द्या.',
    COMMUNITY_GUIDELINES: 'समुदाय दिशानिर्देशक.',
    ADVERTORIAL_POLICY: 'जाहिरात धोरण',
    PRIVACY_POLICY: 'गोपनियता धोरण',
    TERMS_CONDITIONS: 'अटी आणि नियम',
    SETTINGS: 'सेटिंग्ज',
    APP_LOCK: 'अॅप लॉक',
    SECURE_ACCESS:
      'फोन अनलॉक करण्यासाठी तुम्ही जो पासवर्ड किंवा बायोमेट्रिक्स वापरतात तोच वापरुन प्रवेश सुरक्षित करा.',
    WELCOME_TO_THE: 'तुमचे स्वागत आहे.',
    WHY_IT_IS_APPROPRIATE: 'हे अनुचित का आहे?',
    THIS_POST_IS_SPAM: 'ही पोस्ट स्पॅम आहे',
    THIS_POST_CONTAINS_PERSONAL_DETAILS:
      'या पोस्ट मध्ये इतरांचे वैयक्तिक तपशील आहेत',
    THIS_POST_HAS_BEEN_DONE_BY_MAN: 'ही पोस्ट एका माणसाने केली आहे',
    THIS_POST_CONTAINS_FAKE_CONTENT:
      'या पोस्ट मध्ये बनावट/ घोटाळा/ मार्केटिंग सामग्री आहे',
    THIS_POST_CONTAINS_ABUSIVE_CONTENT:
      'या पोस्ट मध्ये अपमानास्पद/ हिंसक/ पोर्नोग्राफीक सामग्री आहे ',
    OTHERS: 'इतर',
    SHARE_PROFILE: 'प्रोफाइल शेअर करा',
    VIEW: 'पहा',
    POSTS: 'पोस्ट्स',
    NO_POSTS_YET: 'अद्याप कोणतीही पोस्ट नाही',
    SOMETHING_ON_YOUR_MIND:
      'तुमच्या मनात काही आहे का? आमच्या सोबत शेअर करा आणि तुमचा पहिला exciting badge अनलॉक करा',
    NAME: 'नाव',
    LOCATION: 'स्थान',
    ABOUT_ME: 'माझ्या बद्दल',
    MAX_WORDS: '(कमाल २५० शब्द)',
    PERSONAL_INFORMATION: 'वैयक्तिक माहिती',
    ONLY_VISIBLE_TO_YOU: '(फक्त तुमच्यासाठी दृश्यमान)',
    DATE_OF_BIRTH: 'जन्म तारीख',
    RELATIONSHIP_STATUS: 'नातेसंबंधांची सद्यस्थिती',
    SELECT_STATUS: 'स्थिती निवडा',
    NUMBER_OF_CHILDREN: 'मुलांची संख्या',
    MOBILE_NUMBER: 'मोबाईल नंबर',
    EMAIL_ID: 'ई-मेल आयडी',
    SAVE: 'जतन करा',
    NO_BADGE_AVAILABLE: 'कोणताही बॅज उपलब्ध नाही.',
    FEATURE_POST: 'हे पोस्ट वैशिष्ट्यीकृत करा',
    UNFEATURE_POST: 'हे पोस्ट अनफिचर करा',
    EDIT_PROFILE: 'प्रोफाईल संपादित करा',
    BLOCKED_ACCOUNTS: 'ब्लोक केले गेलेले अकाउंट्स',
    YOU_HAVE_NOT_BLOCKED_ANY_USER:
      'तुम्ही कोणत्याही वापरकर्त्याला ब्लॉक केलेले नाही.',
    BADGE_OF_HONOUR: 'बॅज ऑफ ऑनर',
    SAY_HI: 'हाय म्हणा',
    CONFIRM: 'पुष्टी',
    PENDING: 'प्रलंबित',
    ABOUT: 'बद्दल',
    COMMUNITY_JOINED: 'समुदाय जोडले',
    CHAMPIONS_FOLLOWED: 'चॅम्पियन्स फॉलो केले',
    VIEW_MORE: 'अधिक पहा',
    PEOPLE_WHO_FOLLOW_ME: 'माझे अनुसरण करणारे लोक',
    NO_FOLLOWERS: 'दर्शविण्यासाठी कोणतेही अनुयायी नाहीत',
    NO_FOLLOWINGS: 'दर्शविण्यासाठी कोणतेही अनुसरण नाही',
    PEOPLE_WHO_FOLLOW_HER: 'तिचे अनुसरण करणारे लोक',
    PEOPLE_I_FOLLOW: 'मी फॉलो करणारे लोक',
    PEOPLE_SHE_FOLLOWS: 'ती माणसे फॉलो करते',
    COMMUNITIES: 'समुदाय',
    NO_BOOKMARKS_TO_SHOW: 'दर्शविण्यासाठी कोणतेही बुकमार्क नाहीत',
    ADMIN: 'अॅडमिन',
    ASSIGNMENT: 'असाइनमेंट',
    SHEROES_IS_A_WOMEN_ONLY_PLATFORM: 'SHEROES हे केवळ महिलांचें व्यासपीठ आहे',
    CHECK_SELFIE: 'सेल्फी तपासा',
    MAKE_SURE_YOUR_FACE_IS_IN_THE_OVAL: 'खत्री करा की तुमचा चेहरा वलयात आहे',
    MAKE_SURE_YOU_ARE_THE_ONLY_ONE_IN_THE_PICTURE:
      'चित्रात फक्त तुम्हीच आहात याची खात्री करा',
    MAKE_SURE_YOU_ARE_NOT_WEARING_A_MASK_IN_THE_PICTURE:
      'आपण चित्रात मुखवटा घातलेला नाही याची खात्री करा.',
    CLICK_PHOTO_IN_GOOD_LIGHTING: 'चांगल्या प्रकाशात फोटो क्लिक करा',
    SORRY_IMAGE_NOT_VERIFIED: 'क्षमस्व ! प्रतिमा प्रमाणित झाली नाही',
    HERE_ARE_SOME_SELFIE_POINTERS: 'येथे काही सेल्फी पॉइंटर्स आहेत',
    UNABLE_TO_IDENTIFY_AS_FEMALE:
      'आमची सिस्टीम तुम्हाला महिला म्हणून ओळखण्यात अक्षम आहे.',
    WRITE_TO_US_IF_YOU_THINK_THIS_IS_AN_ERROR:
      'जर तुम्हाला ही चूक वाटत असेल तर आम्हाला लिहा',
    WE_WOULD_LOVE_FOR_YOU_TO_SPREAD:
      'तुम्ही ह्या वव्यासपीठाबाद्दल तुमच्या मैत्रिणींना सांगितल तरी आम्हाला आवडेल',
    SHARE_WITH_FRIENDS: 'मित्रांसोबत शेयर करा',
    SORRY: 'क्षमस्व !',
    MAKE_SURE_YOUR_SELFIE_CLEARLY_SHOWS_YOUR_FACE:
      'खात्री करा की तुमचा सेल्फी तुमचा चेहरा स्पष्टपणे दाखवतो आहे',
    ACHIEVEMENTS: 'उपलब्धी',
    SIGN_UP: 'साईन अप करा',
    PERSONAL_DETAILS: 'व्यक्तिगत माहिती',
    OTP_VERIFICATION: 'ओ टी पी पडताळणी',
    PLEASE_ENTER_CODE_SENT_TO: 'कृपया आलेला कोड दाखल करा',
    RESEND_OTP: 'ओ टी पी पुन्हा पाठवा',
    IN: 'in',
    SOME_SELFIE_POINTERS: 'काही सेल्फी पॉइंटर्स',
    MAKE_SURE_YOUR_FACE_IS_IN_THE_OVAL_AND_CLEARLY_VISIBLE:
      'खत्री करा की तुमचा चेहरा वलयात आहे आणि स्पष्ट दिसत आहे',
    HI_I_FOUND_THIS_WOMEN_ONLY_APP_FOR_YOU:
      'नमस्कार, मला तुमच्यासाठी हे फक्त महिलांसाठीचे  ॉप  सापडले आहे. त्याचे नाव SHEROES (महिला हिरोज) आहे. तुम्ही इथे काहीही बोलू शकता आणि काहीही विचारू शकता (अगदी निनावीपणेही) आणि इतर महिलांना चांगला सल्ला देऊन मदत करू शकता.',
    TO_CORRECTLY_IDENTIFY_YOU:
      'तुमची अचूक ओळख करण्यासाठी, आम्हाला तुमचा सेल्फी कॅप्चर करणे आवश्यक आहे आणि ते करण्यासाठी आम्हाला डिव्हाइस कॅमेरामध्ये प्रवेश करण्यासाठी आम्हाला तुमची परवानगी आवश्यक आहे.',
    GET_STARTED_WITH_EMAIL_ADDRESS: 'ईमेल द्वारे प्रारम्भ करा',
    GET_STARTED_WITH_MOBILE_NUMBER: 'मोबाईल द्वारे प्रारम्भ करा',
    USE_MOBILE_NUMBER: 'मोबाईल नंबर वापरा',
    USE_EMAIL_ADDRESS: 'ईमेल पत्ता वापरा',
    SEND_OTP: 'ओटीपी पाठवा',
    CONTINUE_WITH_EMAIL_MOBILE_NO: 'ईमेल / मोबाईल नं. सह सुरू ठेवा.',
    CONTINUE_WITH_FACEBOOK: 'फेसबुक सह सुरु ठेवा',
    CONTINUE_WITH_GOOGLE: 'गूगल सह सुरु ठेवा',
    WELCOME_TO_SHEROES: 'Welcome to SHEROES',
    HI: 'Hi,',
    CHAMPIONS: 'चॅम्पियन्स',
    REPORT_USER: 'वापरकर्ता तक्रार करा',
    WARNING: 'चेतावणी!!!',
    ARE_YOU_WANT_TO_BLOCK_USER:
      'तुम्हाला खात्री आहे की तुम्ही वापरकर्त्याला ब्लॉक करू इच्छिता?',
    CANCEL: 'रद्द करा',
    BLOCK: 'ब्लॉक',
    USER: 'वापरकर्ता',
    THIS_CONTENT_IS_UNAVAILABLE: 'आपण शोधत असलेली ही सामग्री अनुपलब्ध आहे.',
    ARE_YOU_WANT_TO_UNBLOCK_USER:
      'तुम्हाला खात्री आहे की तुम्ही वापरकर्त्याला अनब्लॉक करू इच्छिता?',
    UNBLOCK: 'अनब्लॉक',
    CANCEL_REQUEST: 'विनंती रद्द करा',
    UNFRIEND: 'अनफ्रेंड',
    UNFOLLOW: 'अनफ़ॉलो',
    POLL: 'पोल',
    VIDEO: 'व्हिडिओ',
    CHOOSE_YOUR_TYPE_OF_POLL: 'तुमचा मतदानाचा प्रकार निवडा',
    TEXT_POLL: 'टेक्स्ट पोल',
    IMAGE_POLL: 'इमेज पोल',
    CREATE_POLL: 'पोल बनायें',
    ADD_MORE_OPTIONS: 'अधिक पर्याय जोडा',
    OTHER_COMMUNITIES: 'अधिक समुदाय',
  },

  //gujarati
  gu: {
    SELECT_YOUR_PREFERRED_LANGUAGE: 'તમારી ભાષા પસંદ કરો',
    ID_MY_COMMUNITIES: 'મારા સમુદાયો',
    PLEASE_TRY_AGAIN_AFTER_SOMETIME: 'કૃપા કરીને થોડીવાર પછી ફરી પ્રયાસ કરો.',
    POST_BOOKMARKED: 'બુકમાર્ક કરેલ પોસ્ટ',
    POST_UNBOOKMARKED: 'પોસ્ટ અનબુકમાર્ક કરેલ',
    SOCIAL: 'સામાજિક',
    SHOP: 'દુકાન',
    GWW: 'WomenWill',
    DASHBOARD: 'ડેશબોર્ડ',
    REPORT: 'અહેવાલ',
    HELP_US_UNDERSTAND: 'અમને સમજવામાં મદદ કરો',
    WHY_IS_IT_INAPPROPRIATE: 'તે શા માટે અયોગ્ય છે?',
    EXPLORE_ALL: 'બધાનું અન્વેષણ કરો',
    COURSE_PROGRESS: 'અભ્યાસક્રમ',
    COURSE_FEED: 'ફીડ',
    LEARN: 'શીખો',
    SKIP: 'અવગણો',
    TAKE_SURVEY: 'સર્વે લો',
    BONUS_MODULE: 'બોનસ મોડ્યુલ',
    gwwFeed: 'Gww ફીડ',
    ASK_QUESTION: 'પ્રશ્ન પૂછો',
    TAKE_ASSIGNMENT: 'પ્રશ્નોતરી લો',
    NEXT_VIDEO: 'આગળ નો વિડિયો',
    REPORT_SPAM: 'સ્પામ ની સૂચના આપો',
    EDIT: 'સંગ્રહ કરો',
    DELETE: 'હટાવો',
    LIKE: 'પસંદ',
    LIKED: 'ગમ્યું',
    LIKES: 'પસંદ કરે છે',
    COMMENT: 'કઈંક લખો',
    VIEW_REPLY: 'જવાબ જુઓ',
    REPOST: 'ફરી પોસ્ટ કરો',
    TYPE_YOUR_QUESTION_HERE: 'તમારા પ્રશ્નો અહીં લખો',
    QUIZ: 'પ્રશ્નોતરી લો',
    WATCH_VIDEO: 'વિડિયો જુઓ',
    ANSWERED: 'આપેલા જવાબો',
    UN_ANSWERED: 'નહીં આપેલા જવાબો',
    NO_QUESTION: 'વાહ!તમારી પાસે નવા જવાબ નથી',
    UN_ANSWERED_QUESTION: 'જવાબ નહી આપેલા પ્રશ્નો',
    ASSIGNED_USERS: 'વપરાશકર્તા',
    QUESTION_FOR_ME: 'મારા માટે પ્રશ્ન',
    ESCALATED_QUESTIONS: 'જટિલ પ્રશ્ન',
    POST_ON_WOMENWILL: 'WomenWill वर पोस्ट करा',
    ASK_YOUR_FACILITATOR: 'ફેસીલેટીટર ને પૂછો',
    WOMENWILL_BADGE_WINNERS: 'WomenWill બેજ વિજેતા',
    MENTORS: 'માર્ગદર્શકો',
    COMPLETED_BLT_COURSE:
      'સરસ! તમે વ્યાપાર સાક્ષરતા અભ્યાસક્રમ પૂર્ણ કર્યો છે!',
    PART_OF_THE_COMMUNITY: 'સમુદાયનો એક મહત્વપૂર્ણ ભાગ બનવા બદલ આભાર',
    ACCESS_COURSE_VIDEO:
      'SHEROES ના મેનૂ બારમાંથી કોર્સ વિડિઓઝ અને પ્રમાણપત્રોની ઍક્સેસ મેળવો',
    COMMUNITIES_MY_INTEREST: 'તમને SHEROES પર આ સમુદાયો રસપ્રદ લાગશે',
    EXPLORE_SHEROES: 'SHEROES વિશે વધુ જાણો',
    WOMENWILL_COURSE_PROGRESS: 'WomenWill કોર્સ પ્રોગ્રેસ',
    VIEW_CERTIFICATE: 'પ્રમાણપત્ર જુઓ',
    ONBOARDING_INTRODUCE_YOURSELF: 'તમારા વિષે માહિતી આપો',
    ONBOARDING_WOMEN_KNOW: 'સ્ત્રીઓને જણાવો કે તમે કોણ છો!',
    ONBOARDING_TYPE_NAME: 'તમારું પૂરું નામ લખો',
    ONBOARDING_CHOOSE_GENDER: 'શું તમે સ્ત્રી તરીકે ઓળખો છો?',
    ONBOARDING_MALE: 'ના',
    onboardingFemale: 'હા',
    ONBOARDING_MALE_RESTRICTED_LINE1:
      'માફ કરશો! અમારી સિસ્ટમ તમને સ્ત્રી તરીકે ઓળખવામાં અસમર્થ હતી',
    ONBOARDING_MALE_RESTRICTED_LINE2:
      'અમે ફક્ત મહિલાઓ અને જેઓ સ્ત્રી તરીકે ઓળખાય છે તેમના માટે એક વિશિષ્ટ સમુદાય છીએ',
    ONBOARDING_MALE_SORRY:
      'જો તમને લાગે કે આ એક ભૂલ છે, તો કૃપા કરીને અમને લખો.',
    ONBOARDING_GENDER_VERIFICATION: 'જાતિ ચકાસણી',
    ONBOARDING_GENDER_VERIFICATION_SUBTEXT:
      'SHEROES ને તમારા માટે સુરક્ષિત જગ્યા બનાવવા માટે અમને તમારી મદદની જરૂર છે.',
    ONBOARDING_SELFIE_GUIDELINES: 'સેલ્ફી માર્ગદર્શિકા',
    ONBOARDING_CLICK_SELFIE:
      'ઉપર બતાવ્યા પ્રમાણે આગલી સ્ક્રીન પર સેલ્ફી પર ક્લિક કરો',
    ONBOARDING_USE_YOUR_SELFIE:
      'કૃપા કરીને ચકાસણી માટે અન્ય અથવા જૂથ છબીઓનો ઉપયોગ કરશો નહીં કારણ કે અમે તમારું લિંગ ચકાસી શકતા નથી',
    ONBOARDING_SELFIE_PRIVACY:
      'અમે ચકાસણી હેતુ માટે તમારી સેલ્ફીનો ઉપયોગ કરીશું અને ક્યારેય ક્યાંય પોસ્ટ કરીશું નહીં',
    ONBOARDING_TAKE_SELFIE: 'સેલ્ફી લો',
    ONBOARDING_CAPTURE_PHOTO: 'ફોટો કેપ્ચર કરો',
    ONBOARDING_SELFIE_TEXT:
      'તમારો ફોટો હાથની લંબાઈ પર લો\nખાતરી કરો કે તમારો આખો ચહેરો દેખાય છે',
    ONBOARDING_AFTER_SELFIE_VERIFICATION:
      'અમે તમારા ફોટાની ચકાસણી કરીશું કે તરત જ અમે તમારો સંપર્ક કરીશું',
    ONBOARDING_RETAKE: 'ફરીથી લો',
    ONBOARDING_CLOSE: 'બંધ',
    SUBMIT: 'સબમિટ કરો',
    ONBOARDING_WHAT_YOUR_INTEREST: 'તમારી રુચિ શું છે',
    ONBOARDING_INTEREST_SUBTITLE:
      'તમારી ફીડને વ્યક્તિગત કરવા માટે તમારી રુચિઓ પસંદ કરો',
    CONTINUE: 'Continue',
    GO_TO_WOMWNWILL_COMMUNITY: 'WomenWill Community પર જાઓ',
    BUY_NOW: 'હમણાં જ ખરીદો',
    FREE: 'મફત',
    DELIVERY: 'ડિલિવરી',
    NOTIFICATION_TITLE: 'સૂચનાઓ',
    ARTICLE: 'લેખો',
    LOGOUT: 'લૉગ આઉટ',
    helpline: 'હેલ્પલાઇન',
    FOLLOWER: 'અનુયાયી',
    FOLLOWERS: 'અનુયાયીઓ',
    FOLLOWING: 'અનુસરે છે',
    FRIENDS: 'મિત્રો',
    MY_SHOP: 'મારી દુકાન',
    SOCIAL_FEED: 'સામાજિક ફીડ',
    WOMEN_WILL_FEED: 'WomenWill ફીડ',
    PROFILE: 'પ્રોફાઇલ',
    FOLLOW: 'અનુસરો',
    ADD_FRIEND: 'મિત્ર બનાવો',
    SEARCH: 'શોધો',
    CREATE_POST: 'પોસ્ટ બનાવો',
    CHOOSE_COMMUNTIY: 'તમારો સમુદાય પસંદ કરો',
    POST_TO_FOLLOWERS: 'અનુયાયીઓને પોસ્ટ કરો',
    POST_AS_ANONYMOUS: 'અનામી તરીકે પોસ્ટ કરો',
    POST_AS_COMMUNITY: 'સમુદાય તરીકે પોસ્ટ કરો',
    SURVEY: 'સર્વે',
    CREATE: 'બનાવો',
    POST: 'પોસ્ટ',
    PHOTO: 'ફોટો',
    CAMERA: 'કેમેરા',
    DISCARD_CHANGES: 'શું તમે ખરેખર તમારા ફેરફારોને કાઢી નાખવા માંગો છો?',
    DISCARD_POST: 'પોસ્ટ કાઢી નાખીએ?',
    YES: 'હા',
    NO: 'ના',
    ID_POST: 'પોસ્ટ કરો',
    ANONYMOUS: 'અનામી',
    HERE_TO_ASK: 'ખચકાટ વિના પૂછવા/શેર કરવા માટે અહીં ટૅપ કરો',
    ADD_YOUR_COMMENT: 'તમારી ટિપ્પણી અહીં ઉમેરો',
    ID_MIN_READ: 'મિનિટ વાંચો',
    PUBLISHED_ON: 'પર પ્રકાશિત',
    ID_JUST_NOW: 'હમણાજ',
    COMPLETED_BLT:
      'હા! તમે વ્યાપાર સાક્ષરતા તાલીમ મોડ્યુલ પૂર્ણ કર્યું છે. તમારું પ્રમાણપત્ર તમારા મિત્રો અને પરિવાર સાથે શેર કરો.',
    PART_OF_SHEROES: 'SHEROES સાથે તમારી વિકાસ યાત્રા ચાલુ રાખો',
    NEXT: 'આગળ',
    UNLOCK_MY_CERTIFICATE:
      'મેં હમણાં જ WomenWill પ્રમાણપત્ર અનલૉક કર્યું છે! તમે SHEROES પર આ પ્રમાણપત્ર પણ જીતી શકો છો:  ',
    DOWNLOAD: 'ડાઉનલોડ કરો',
    ENTER_NAME: 'નામ દાખલ કરો',
    CONGRATULATIONS: 'અભિનંદન!',
    BLT_COURSE: 'તમે BLT કોર્સ પૂર્ણ કર્યો છે.',
    WOMENWILL_CERTIFICATE: 'WomenWill પ્રમાણપત્ર',
    USER_HAS_NOT_COMPLETED_THE_COURSE:
      'વપરાશકર્તાએ અભ્યાસક્રમ પૂર્ણ કર્યો નથી. કૃપા કરીને પહેલા કોર્સ પૂર્ણ કરો.',
    YOUR_COURSE_PROGRESS_WILL_APPEAR_HERE:
      'તમારા અભ્યાસક્રમની પ્રગતિ અહીં દેખાશે.',
    SHARE_POST: 'શેર કરો',
    BOOKMARK_POST: 'બુકમાર્ક',
    REMOVE_BOOKMARK: 'બુકમાર્ક દૂર કરો',
    BLOCK_USER: 'વપરાશકર્તાને અવરોધિત',
    MY_BOOKMARKS: 'મારા બુકમાર્ક્સ',
    DAY_AGO: 'એક દિવસ પહેલા',
    HRS_AGO: 'એક કલાક પહેલા',
    HEY_YOU_ARE_NOW_A_PART_OF_SHEROES: 'અરે, તમે હવે SHEROES નો એક ભાગ છો!',
    TELL_YOUR_FRIENDS: 'તમારા મિત્રોને કહો',
    RATE_US: 'અમને રેટ કરો',
    COMMUNITY_GUIDELINES: 'સમુદાય માર્ગદર્શિકા',
    ADVERTORIAL_POLICY: 'જાહેરાત નીતિ',
    PRIVACY_POLICY: 'ગોપનીયતા નીતિ',
    TERMS_CONDITIONS: 'નિયમો અને શરત',
    SETTINGS: 'સેટિંગ્સ',
    APP_LOCK: 'એપ લોક',
    SECURE_ACCESS:
      'ફોનને અનલૉક કરવા માટે ઉપયોગમાં લેવાતા પાસકોડ અથવા બાયોમેટ્રિક સાથે જ સુરક્ષા ઍક્સેસ',
    BLOCKED_ACCOUNTS: 'બ્લોક કરેલ એકાઉન્ટ્સ',
    UNBLOCK: 'અનાવરોધિત કરો',
    YOU_HAVE_NOT_BLOCKED_ANY_USER: 'તમે કોઈપણ વપરાશકર્તાને અવરોધિત કર્યા નથી.',
    BADGE_OF_HONOUR: 'બેજ ઓફ ઓનર',
    SAY_HI: 'હાય કહો',
    CONFIRM: 'પુષ્ટિ કરો',
    PENDING: 'બાકી',
    ABOUT: 'વિશે',
    COMMUNITY_JOINED: 'સમુદાય કે જેમાં તમે સામેલ છો',
    CHAMPIONS_FOLLOWED: 'ચેમ્પિયન ફોલોડ',
    VIEW_MORE: 'વધુ જુઓ',
    PEOPLE_WHO_FOLLOW_ME: 'જે લોકો મને અનુસરે છે',
    NO_FOLLOWERS: 'કોઈ અનુયાયીઓ બતાવવા માટે નથી',
    NO_FOLLOWINGS: 'કોઈ અનુસરણ બતાવવા માટે નથી',
    PEOPLE_WHO_FOLLOW_HER: 'જે લોકો તેને અનુસરે છે',
    PEOPLE_I_FOLLOW: 'જે લોકોને હું અનુસરું છું',
    PEOPLE_SHE_FOLLOWS: 'તે જે લોકોને અનુસરે છે',
    COMMUNITIES: 'સમુદાયો',
    NOT_JOINED: 'હજુ સુધી કોઈપણ સમુદાયમાં જોડાયો નથી',
    NOT_FOLLOWING: 'હજુ સુધી કોઈ ચેમ્પિયનને ફોલો કરી રહી નથી',
    NO_BOOKMARKS_TO_SHOW: 'બતાવવા માટે કોઈ બુકમાર્ક્સ નથી',
    POSTS: 'પોસ્ટ્સ',
    ADMIN: 'એડમિન',
    ASSIGNMENT: 'સોંપણી',
    SHARE_PROFILE: 'પ્રોફાઇલ શેર કરો',
    VIEW: 'જુઓ',
    NO_BADGE_AVAILABLE: 'કોઈ બેજ ઉપલબ્ધ નથી',
    SUPER_SHEROES: 'SuperSheroes',
    MILESTONE: 'માઈલસ્ટોન',
    SHEROES_IS_A_WOMEN_ONLY_PLATFORM: 'SHEROES એ માત્ર મહિલાઓનું પ્લેટફોર્મ છે',
    CHECK_SELFIE: 'સેલ્ફી તપાસો',
    MAKE_SURE_YOUR_FACE_IS_IN_THE_OVAL:
      'ખાતરી કરો કે તમારો ચહેરો અંડાકારની અંદર છે.',
    MAKE_SURE_YOU_ARE_THE_ONLY_ONE_IN_THE_PICTURE:
      'ખાતરી કરો કે ચિત્રમાં તમે એકલા જ છો.',
    MAKE_SURE_YOU_ARE_NOT_WEARING_A_MASK_IN_THE_PICTURE:
      'ખાતરી કરો કે તમે ચિત્રમાં માસ્ક પહેર્યા નથી.',
    CLICK_PHOTO_IN_GOOD_LIGHTING: 'સારી લાઇટિંગમાં ફોટો પર ક્લિક કરો.',
    SORRY_IMAGE_NOT_VERIFIED: 'માફ કરશો! છબી ચકાસાયેલ નથી',
    HERE_ARE_SOME_SELFIE_POINTERS: 'અહીં કેટલાક સેલ્ફી પોઇન્ટર છે',
    UNABLE_TO_IDENTIFY_AS_FEMALE:
      'અમારી સિસ્ટમ તમને સ્ત્રી તરીકે ઓળખવામાં અસમર્થ હતી.',
    WRITE_TO_US_IF_YOU_THINK_THIS_IS_AN_ERROR:
      'જો તમને લાગે કે આ એક ભૂલ છે તો અમને લખો',
    WE_WOULD_LOVE_FOR_YOU_TO_SPREAD:
      ' અમને ગમશે કે તમે તમારી સ્ત્રી મિત્રોમાં આ શબ્દ ફેલાવો.',
    SHARE_WITH_FRIENDS: 'મિત્રો સાથે વહેંચવું',
    SORRY: 'માફ કરશો !',
    MAKE_SURE_YOUR_SELFIE_CLEARLY_SHOWS_YOUR_FACE:
      'ખાતરી કરો કે તમારી સેલ્ફી સ્પષ્ટ રીતે તમારો ચહેરો દર્શાવે છે.',
    WHY_IT_IS_APPROPRIATE: 'શા માટે તે અન્યાયી છે?',
    SAVE: 'સેવ',
    SOMETHING_ON_YOUR_MIND:
      'તમે કંઈક વિચારી રહ્યા છો? અમારી સાથે શેર કરો અને તમારો પ્રથમ અદ્ભુત  બેજ અનલૉક કરો',
    NAME: 'નામ',
    EMAIL_ID: 'ઈમેલ આઈડી',
    NUMBER_OF_CHILDREN: 'બાળકોની સંખ્યા',
    SELECT_STATUS: 'સ્થિતિ પસંદ કરો',
    RELATIONSHIP_STATUS: 'સંબંધો સ્થિતિ',
    DATE_OF_BIRTH: 'જન્મ તારીખ',
    PERSONAL_INFORMATION: 'વ્યક્તિગત માહિતી',
    ABOUT_ME: 'મારા વિશે',
    LOCATION: 'સ્થાન',
    NO_POSTS_YET: 'હજુ સુધી કોઈ પોસ્ટ નથી',
    OTHERS: 'અન્ય',
    THIS_POST_CONTAINS_ABUSIVE_CONTENT:
      'આ પોસ્ટમાં અપમાનજનક/હિંસા/જાતીય સામગ્રી છે',
    THIS_POST_CONTAINS_FAKE_CONTENT:
      'આ પોસ્ટમાં ભ્રામક / કૌભાંડ / માર્કેટિંગ સામગ્રી છે',
    THIS_POST_HAS_BEEN_DONE_BY_MAN: 'આ પોસ્ટ એક પુરુષ દ્વારા કરવામાં આવી હતી',
    THIS_POST_CONTAINS_PERSONAL_DETAILS:
      'આ પોસ્ટમાં અન્ય લોકોની અંગત માહિતી છે',
    THIS_POST_IS_SPAM: 'આ પોસ્ટ સ્પામ છે',
    WELCOME_TO_THE: 'તમારું સ્વાગત છે',
    ACHIEVEMENTS: 'સિદ્ધિઓ',
    SIGN_UP: 'સાઇન અપ કરો',
    PERSONAL_DETAILS: 'અંગત વિગતો',
    OTP_VERIFICATION: 'OTP ચકાસણી',
    PLEASE_ENTER_CODE_SENT_TO: 'કૃપા કરીને પર મોકલેલ કોડ દાખલ કરો',
    RESEND_OTP: 'OTP ફરીથી મોકલો',
    IN: 'in',
    SOME_SELFIE_POINTERS: 'કેટલાક સેલ્ફી પોઇન્ટર',
    MAKE_SURE_YOUR_FACE_IS_IN_THE_OVAL_AND_CLEARLY_VISIBLE:
      'ખાતરી કરો કે તમારો ચહેરો અંડાકારની અંદર છે અને સ્પષ્ટપણે દૃશ્યમાન છે.',
    HI_I_FOUND_THIS_WOMEN_ONLY_APP_FOR_YOU:
      'હાય, મને તમારા માટે આ ફક્ત મહિલાઓની એપ્લિકેશન મળી છે. તેનું નામ SHEROES (મહિલા હીરોઝ) છે. તમે ખચકાટ વિના કંઈપણ વિશે વાત કરી શકો છો અને પૂછી શકો છો (અનામી પણ) અને અન્ય મહિલાઓને સારી સલાહ આપીને મદદ કરી શકો છો.',
    TO_CORRECTLY_IDENTIFY_YOU:
      'તમને યોગ્ય રીતે ઓળખવા માટે, અમારે તમારી સેલ્ફી કેપ્ચર કરવાની જરૂર છે અને તે કરવા માટે અમને ઉપકરણ કૅમેરાને ઍક્સેસ કરવાની મંજૂરી આપવા માટે અમને તમારી પરવાનગીની જરૂર છે.',
    GET_STARTED_WITH_EMAIL_ADDRESS: 'Email એડ્રેસ સાથે પ્રારંભ કરો',
    GET_STARTED_WITH_MOBILE_NUMBER: 'Mobileનંબર સાથે પ્રારંભ કરો',
    USE_MOBILE_NUMBER: 'Mobileનંબર ઉપયોગ કરો',
    USE_EMAIL_ADDRESS: 'Email એડ્રેસનો ઉપયોગ કરો',
    SEND_OTP: 'Send OTP',
    CONTINUE_WITH_EMAIL_MOBILE_NO: 'ઈમેલ / મોબાઈલ નંબર સાથે ચાલુ રાખો.',
    CONTINUE_WITH_FACEBOOK: 'Facebook સાથે ચાલુ રાખો',
    CONTINUE_WITH_GOOGLE: 'Google સાથે ચાલુ રાખો',
    WELCOME_TO_SHEROES: 'Welcome to SHEROES',
    HI: 'Hi,',
    CHAMPIONS: 'ચેમ્પિયન',
    REPORT_USER: 'વપરાશકર્તાની જાણ કરો',
    WARNING: 'ચેતવણી!!!',
    ARE_YOU_WANT_TO_BLOCK_USER:
      'શું તમે ખરેખર વપરાશકર્તાને અવરોધિત કરવા માંગો છો?',
    CANCEL: 'રદ કરો',
    BLOCK: 'બ્લોક',
    USER: 'વપરાશકર્તા',
    THIS_CONTENT_IS_UNAVAILABLE: 'તમે શોધી રહ્યાં છો તે આ સામગ્રી અનુપલબ્ધ છે.',
    ARE_YOU_WANT_TO_UNBLOCK_USER:
      'શું તમે ખરેખર વપરાશકર્તાને અનાવરોધિત કરવા માંગો છો?',
    CANCEL_REQUEST: 'વિનંતી રદ કરો',
    UNFRIEND: 'અનફ્રેન્ડ',
    UNFOLLOW: 'અનફોલો',
    POLL: 'પોલ',
    VIDEO: 'વિડિયો',
    CHOOSE_YOUR_TYPE_OF_POLL: 'તમારા મતદાનનો પ્રકાર પસંદ કરો',
    TEXT_POLL: 'ટેક્સ્ટ મતદાન',
    IMAGE_POLL: 'છબી મતદાન',
    CREATE_POLL: 'પોલ બનાવો',
    ADD_MORE_OPTIONS: 'વધુ વિકલ્પો ઉમેરો',
    OTHER_COMMUNITIES: 'અન્ય સમુદાયો',
  },

  //assamese
  as: {
    SELECT_YOUR_PREFERRED_LANGUAGE: 'আপোনাৰ ভাষা বাছি লওক',
    SUPER_SHEROES: 'ছুপাৰশ্বিৰোজ',
    MILESTONE: 'মাইলৰ খুঁটি',
    ID_MY_COMMUNITIES: 'মোৰ সম্প্ৰদায়সমূহ',
    PLEASE_TRY_AGAIN_AFTER_SOMETIME:
      'অনুগ্ৰহ কৰি কিছু সময়ৰ পিছত পুনৰ চেষ্টা কৰক।',
    POST_BOOKMARKED: 'পোষ্ট বুকমাৰ্ক কৰা হৈছে',
    POST_UNBOOKMARKED: 'পোষ্ট আনবুকমাৰ্ক কৰা হৈছে',
    REPORT: 'প্ৰতিবেদন',
    HELP_US_UNDERSTAND: 'আমাক বুজিবলৈ সহায় কৰক',
    WHY_IS_IT_INAPPROPRIATE: 'কিয় অনুচিত?',
    SOCIAL: 'সামাজিক',
    SHOP: 'দোকান',
    GWW: 'WomenWill',
    DASHBOARD: `ডেচব'ৰ্ড`,
    EXPLORE_ALL: 'সকলো অন্বেষণ কৰক',
    COURSE_PROGRESS: 'পাঠ্যক্ৰম',
    COURSE_FEED: 'ফীড',
    LEARN: 'শিকা',
    SKIP: 'বাদ দিয়া',
    TAKE_SURVEY: 'জৰীপ লওক',
    SURVEY: 'জৰীপ',
    BONUS_MODULE: 'বোনাছ মডিউল',
    gwwFeed: 'Gww ফীড',
    ASK_QUESTION: 'প্ৰশ্ন সোধক',
    TAKE_ASSIGNMENT: 'এছাইনমেণ্ট কৰক',
    NEXT_VIDEO: "পৰৱৰ্তী ভিডিঅ'",
    REPORT_SPAM: 'স্পাম প্ৰতিবেদন',
    EDIT: 'সম্পাদনা কৰক',
    DELETE: 'বিলোপ কৰক',
    LIKE: 'লাইক',
    LIKED: 'লাইকড',
    LIKES: 'লাইক',
    COMMENT: 'মন্তব্য',
    VIEW_REPLY: 'উত্তৰ দৰ্শন কৰক',
    REPOST: 'পুনৰ পোষ্ট কৰক',
    TYPE_YOUR_QUESTION_HERE: 'আপোনাৰ প্ৰশ্ন ইয়াত টাইপ কৰক',
    QUIZ: 'কুইজ',
    WATCH_VIDEO: "ভিডিঅ' চাওক",
    ANSWERED: 'উত্তৰ দিয়া হৈছে',
    UN_ANSWERED: 'উত্তৰ দিয়া হোৱা নাই',
    NO_QUESTION: 'ৱাহু! আপোনাৰ কোনো নতুন প্ৰশ্ন নাই',
    UN_ANSWERED_QUESTION: 'উত্তৰ নোপোৱা প্ৰশ্নসমূহ',
    ASSIGNED_USERS: 'আৱণ্টিত ব্যৱহাৰকাৰী',
    QUESTION_FOR_ME: 'মোৰ বাবে প্ৰশ্ন',
    ESCALATED_QUESTIONS: 'বৰ্ধিত প্ৰশ্নসমূহ',
    POST_ON_WOMENWILL: 'WomenWill পোষ্ট কৰক',
    ASK_YOUR_FACILITATOR: 'আপোনাৰ সহায়কক সোধক',
    WOMENWILL_BADGE_WINNERS: 'WomenWill বেজ বিজয়ী',
    MENTORS: 'পৰামৰ্শদাতা',
    COMPLETED_BLT_COURSE:
      'ৱাহু! আপুনি ব্যৱসায় সাক্ষৰতা পাঠ্যক্ৰম সম্পূৰ্ণ কৰিছে!',
    PART_OF_THE_COMMUNITY:
      'কমিউনিটিৰ এক গুৰুত্বপূৰ্ণ অংশ হোৱাৰ বাবে আপোনাক ধন্যবাদ।',
    ACCESS_COURSE_VIDEO:
      "SHEROES প্লেটফৰ্মত মেনু বাৰযোগে পাঠ্যক্ৰম ভিডিঅ' আৰু প্ৰমাণপত্ৰ প্ৰাপ্ত কৰক",
    COMMUNITIES_MY_INTEREST:
      'SHEROESৰ এই সম্প্ৰদায়বোৰে আপোনাক আগ্ৰহী কৰিব পাৰে',
    EXPLORE_SHEROES: 'SHEROES অন্বেষণ কৰক',
    WOMENWILL_COURSE_PROGRESS: 'WomenWill পাঠ্যক্ৰমৰ প্ৰগতি',
    VIEW_CERTIFICATE: 'প্ৰমাণপত্ৰ দৰ্শন কৰক',
    ONBOARDING_INTRODUCE_YOURSELF: 'নিজৰ পৰিচয় দিয়ক',
    ONBOARDING_WOMEN_KNOW: 'মহিলাসকলক জনাওক আপুনি কোন!',
    ONBOARDING_TYPE_NAME: 'আপোনাৰ সম্পূৰ্ণ নাম টাইপ কৰক',
    ONBOARDING_CHOOSE_GENDER: 'আপুনি মহিলা হিচাপে পৰিচয় দিয়েনে?',
    ONBOARDING_MALE: 'নহয়',
    ONBOARDING_FEMALE: 'হয়',
    ONBOARDING_MALE_RESTRICTED_LINE1:
      'দুঃখিত! আমাৰ ব্যৱস্থাই আপোনাক মহিলা বুলি চিনাক্ত কৰিব পৰা নাছিল',
    ONBOARDING_MALE_RESTRICTED_LINE2:
      'আমি কেৱল মহিলা আৰু মহিলা বুলি পৰিচয় দিয়াসকলৰ বাবে এক একচেটিয়া সম্প্ৰদায়',
    ONBOARDING_MALE_SORRY: 'এইটো ভুল বুলি ভাবিলে আমাক লিখক।',
    ONBOARDING_GENDER_VERIFICATION: 'লিংগ পৰীক্ষণ',
    ONBOARDING_GENDER_VERIFICATION_SUBTEXT:
      'SHEROES আপোনাৰ বাবে নিৰাপদ স্থান হিচাপে গঢ়ি তুলিবলৈ আমাক আপোনাৰ সহায়ৰ প্ৰয়োজন।',
    ONBOARDING_SELFIE_GUIDELINES: 'চেলফি গাইডলাইন',
    ONBOARDING_CLICK_SELFIE:
      'ওপৰত দেখুওৱাৰ দৰে পৰৱৰ্তী পৰ্দাত চেলফি এখন ক্লিক কৰক',
    ONBOARDING_USE_YOUR_SELFIE:
      'অনুগ্ৰহ কৰি সত্যাপনৰ বাবে আন বা গোটৰ ছবি ব্যৱহাৰ নকৰিব কাৰণ আমি আপোনাৰ লিংগ পৰীক্ষা কৰিব নোৱাৰো',
    ONBOARDING_SELFIE_PRIVACY:
      "আমি আপোনাৰ চেলফি ভেৰিফিকেচনৰ উদ্দেশ্যে ব্যৱহাৰ কৰিম আৰু কেতিয়াও ক'তো পোষ্ট নকৰো",
    ONBOARDING_TAKE_SELFIE: 'চেলফি লওক',
    ONBOARDING_CAPTURE_PHOTO: 'ফটো কেপচাৰ কৰক',
    ONBOARDING_SELFIE_TEXT:
      'হাতৰ দৈৰ্ঘ্যত ফটো তুলি লওক\nগোটেই মুখখন দেখা পোৱাটো নিশ্চিত কৰক',
    ONBOARDING_AFTER_SELFIE_VERIFICATION:
      'আপোনাৰ ফটো ভেৰিফাই কৰাৰ লগে লগে আমি আপোনাৰ লগত যোগাযোগ কৰিম',
    ONBOARDING_RETAKE: 'পুনৰ গ্ৰহণ কৰক',
    ONBOARDING_CLOSE: 'বন্ধ কৰক',
    SUBMIT: 'দাখিল কৰক',
    ONBOARDING_WHAT_YOUR_INTEREST: 'আপোনাৰ আগ্ৰহ কি?',
    ONBOARDING_INTEREST_SUBTITLE:
      'আপোনাৰ ফিড ব্যক্তিগতকৰণ কৰিবলৈ আপোনাৰ আগ্ৰহ বাছক',
    CONTINUE: 'অব্যাহত ৰাখক',
    GO_TO_WOMWNWILL_COMMUNITY: 'WomenWill Community লৈ যাওক',
    BUY_NOW: 'এতিয়াই কিনি লওক',
    FREE: 'বিনামূলীয়া',
    DELIVERY: 'বিলি কৰা',
    NOTIFICATION_TITLE: 'জাননীসমূহ',
    ARTICLE: 'প্ৰবন্ধ',
    LOGOUT: 'লগআউট',
    helpline: 'হেল্পলাইন',
    FOLLOWER: 'অনুগামী',
    FOLLOWERS: 'অনুগামীসকল',
    FOLLOWING: 'অনুসৰণ কৰি আছে',
    FRIENDS: 'বন্ধু',
    MY_SHOP: 'মোৰ দোকান',
    SOCIAL_FEED: 'সামাজিক খাদ্য',
    WOMEN_WILL_FEED: 'WomenWill ফিড',
    PROFILE: "প্ৰ'ফাইল",
    FOLLOW: 'অনুসৰণ কৰক',
    ADD_FRIEND: 'বন্ধু যোগ কৰক',
    SEARCH: 'সন্ধান',
    CREATE_POST: 'পোষ্ট সৃষ্টি কৰক',
    CHOOSE_COMMUNTIY: 'আপোনাৰ সম্প্ৰদায় বাছি লওক',
    POST_TO_FOLLOWERS: 'অনুসৰণকাৰীসকললৈ পোষ্ট কৰক',
    POST_AS_ANONYMOUS: 'বেনামী হিচাপে পোষ্ট কৰক',
    POST_AS_COMMUNITY: 'কমিউনিটি হিচাপে পোষ্ট কৰক',
    CREATE: 'সৃষ্টি কৰা',
    POST: 'ডাক',
    PHOTO: 'ফটো',
    CAMERA: 'কেমেৰা',
    DISCARD_CHANGES:
      'আপুনি নিশ্চিত নেকি যে আপুনি আপোনাৰ পৰিবৰ্তনসমূহ বাতিল কৰিব বিচাৰে?',
    DISCARD_POST: 'পোষ্ট বাতিল কৰক?',
    YES: 'হয়',
    NO: 'নহয়',
    ID_POST: 'পোষ্ট হিচাপে',
    ANONYMOUS: 'নামহীন',
    HERE_TO_ASK: 'ইয়াত টেপ কৰক যাতে কোনো সংকোচ নকৰাকৈ Ask/Share কৰিব পাৰে',
    ADD_YOUR_COMMENT: 'আপোনাৰ মন্তব্য ইয়াত যোগ কৰক',
    ID_MIN_READ: 'min পঢ়ক',
    PUBLISHED_ON: 'প্ৰকাশ কৰা হৈছে',
    ID_JUST_NOW: 'মাত্ৰ এতিয়া',
    COMPLETED_BLT:
      'য়েই! আপুনি ব্যৱসায়িক সাক্ষৰতা প্ৰশিক্ষণ মডিউল সম্পূৰ্ণ কৰিছে। আপোনাৰ প্ৰমাণ পত্ৰ আপোনাৰ বন্ধু-বান্ধৱী & পৰিয়ালৰ সৈতে শ্বেয়াৰ কৰক।',
    PART_OF_SHEROES: 'SHEROES ৰ সৈতে আপোনাৰ বৃদ্ধিৰ যাত্ৰা অব্যাহত ৰাখক',
    NEXT: 'পৰৱৰ্তী',
    UNLOCK_MY_CERTIFICATE:
      'মই মাত্ৰ WomenWill ৰ এটা Certificate আনলক কৰিলোঁ! এই প্ৰমাণপত্ৰ আপুনি SHEROES তও জিকিব পাৰে:',
    DOWNLOAD: 'ডাউনলোড কৰক',
    ENTER_NAME: 'নাম লিখক',
    CONGRATULATIONS: 'অভিনন্দন!',
    BLT_COURSE: 'আপুনি BLT পাঠ্যক্ৰম সম্পূৰ্ণ কৰিছে।',
    WOMENWILL_CERTIFICATE: 'WomenWill প্ৰমাণপত্ৰ',
    USER_HAS_NOT_COMPLETED_THE_COURSE:
      'ব্যৱহাৰকাৰীয়ে পাঠ্যক্ৰম সম্পূৰ্ণ কৰা নাই। অনুগ্ৰহ কৰি প্ৰথমে পাঠ্যক্ৰম সম্পূৰ্ণ কৰক।',
    YOUR_COURSE_PROGRESS_WILL_APPEAR_HERE:
      'আপোনাৰ পাঠ্যক্ৰমৰ অগ্ৰগতি ইয়াত দেখা যাব।',
    SHARE_POST: 'ভাগ-বতৰা কৰা',
    BOOKMARK_POST: 'বুকমাৰ্ক',
    REMOVE_BOOKMARK: 'বুকমাৰ্ক আঁতৰাওক',
    BLOCK_USER: 'ব্যৱহাৰকাৰীক ব্লক কৰক',
    MY_BOOKMARKS: 'মোৰ বুকমাৰ্কসমূহ',
    RECOMMENDED_POST: 'পৰামৰ্শ দিয়া পোষ্ট',
    VIEW_MORE_COMMENTS: 'View আরো মন্তব্য...',
    LOAD_MORE_COMMENTS: 'অধিক মন্তব্য লোড কৰক',
    DAY_AGO: 'দিনৰ আগত',
    HRS_AGO: 'ঘণ্টা আগত',
    HEY_YOU_ARE_NOW_A_PART_OF_SHEROES: 'হেই, আপুনি এতিয়া SHEROESৰ অংশ!',
    TELL_YOUR_FRIENDS: 'আপোনাৰ বন্ধুবৰ্গক কওঁক',
    RATE_US: 'আমাক মূল্যাঙ্কন কৰক',
    COMMUNITY_GUIDELINES: 'সম্প্ৰদায়ৰ নিৰ্দেশনাসমূহ',
    ADVERTORIAL_POLICY: 'বিজ্ঞাপননীতি',
    PRIVACY_POLICY: 'গোপনীয়তা নীতি',
    TERMS_CONDITIONS: 'চৰ্তাৱলী আৰু নিয়মাৱলী',
    SETTINGS: 'সংহতিসমূহ',
    APP_LOCK: 'এপ লক',
    SECURE_ACCESS:
      'ফোনটো আনলক কৰিবলৈ আপুনি ব্যৱহাৰ কৰা একেপাছকোড বা বায়োমেট্ৰিক ব্যৱহাৰ কৰি প্ৰৱেশাধিকাৰ সুৰক্ষিত কৰক',
    WELCOME_TO_THE: 'স্বাগতম!',
    WHY_IT_IS_APPROPRIATE: 'এইটো কিয় অনুপযুক্ত?',
    THIS_POST_IS_SPAM: 'এই পোষ্টটো স্পাম',
    THIS_POST_CONTAINS_PERSONAL_DETAILS: 'এই পোষ্টত আনৰ ব্যক্তিগত বিৱৰণ আছে',
    THIS_POST_HAS_BEEN_DONE_BY_MAN: 'এই পোষ্টটো এজন পুৰুষে কৰিছে',
    THIS_POST_CONTAINS_FAKE_CONTENT: 'এই পোষ্টটোত ভুৱা/কেলেংকাৰী/বিপণন সমল আছে',
    THIS_POST_CONTAINS_ABUSIVE_CONTENT:
      'এই পোষ্টটোত আপত্তিজনক/হিংসাত্মক/অশ্লীল সমল আছে',
    OTHERS: 'আনবোৰ',
    SHARE_PROFILE: "প্ৰ'ফাইল ভাগ বতৰা কৰক",
    VIEW: 'দৰ্শন',
    POSTS: 'পোষ্টসমূহ',
    NO_POSTS_YET: 'এতিয়ালৈকে কোনো পোষ্ট নাই',
    SOMETHING_ON_YOUR_MIND:
      'আপোনাৰ মনত কিবা আছে নেকি? আমাৰ সৈতে ভাগ বতৰা কৰক আৰু আপোনাৰ প্ৰথম ৰোমাঞ্চকৰ বেজআনলক কৰক।',
    NAME: 'নাম',
    LOCATION: 'অৱস্থান',
    ABOUT_ME: 'মোৰ বিষয়ে',
    MAX_WORDS: '(সৰ্বাধিক ২৫০ শব্দ)',
    PERSONAL_INFORMATION: 'ব্যক্তিগত তথ্য',
    ONLY_VISIBLE_TO_YOU: '(কেৱল আপোনাৰ বাবে দেখা যায়)',
    DATE_OF_BIRTH: 'জন্মৰ তাৰিখ',
    RELATIONSHIP_STATUS: 'সম্পৰ্কৰ স্থিতি',
    SELECT_STATUS: 'স্থিতি নিৰ্বাচন কৰক',
    NUMBER_OF_CHILDREN: 'শিশুৰ সংখ্যা',
    MOBILE_NUMBER: 'মোবাইল নম্বৰ',
    EMAIL_ID: 'ইমেইল আইডি',
    SAVE: 'সংৰক্ষণ কৰক',
    NO_BADGE_AVAILABLE: 'কোনো বেজ উপলব্ধ নহয়।',
    FEATURE_POST: 'এই পোষ্টটো বৈশিষ্ট্যযুক্ত কৰক',
    UNFEATURE_POST: 'এই পোষ্টটো UnFeature কৰক',
    EDIT_PROFILE: 'প্ৰফাইল সম্পাদনা কৰক',
    BLOCKED_ACCOUNTS: 'অৱৰোধ কৰা একাওন্টসমূহ',
    UNBLOCK: 'আনব্লক কৰক',
    YOU_HAVE_NOT_BLOCKED_ANY_USER: 'আপুনি কোনো ব্যৱহাৰকাৰীক ব্লক কৰা নাই।',
    BADGE_OF_HONOUR: 'BADGE OF HONOUR',
    SAY_HI: 'হাই কওক',
    CONFIRM: 'নিশ্চিত',
    PENDING: 'বাকী থকা',
    ABOUT: 'বিষয়ে',
    COMMUNITY_JOINED: 'সম্প্ৰদায়সমূহ যোগদান কৰা হৈছে',
    CHAMPIONS_FOLLOWED: 'চেম্পিয়নসকলে অনুসৰণ কৰিলে',
    VIEW_MORE: 'অধিক চাওক',
    PEOPLE_WHO_FOLLOW_ME: 'মোক অনুসৰণ কৰা মানুহ',
    NO_FOLLOWERS: 'দেখুৱাবলৈ কোনো অনুগামী নাই',
    NO_FOLLOWINGS: 'দেখুৱাবলৈ কোনো অনুসৰণ নাই',
    PEOPLE_WHO_FOLLOW_HER: 'তাইক অনুসৰণ কৰা মানুহ',
    PEOPLE_I_FOLLOW: 'মই অনুসৰণ কৰা মানুহবোৰ',
    PEOPLE_SHE_FOLLOWS: 'তাই অনুসৰণ কৰা মানুহবোৰ',
    COMMUNITIES: 'সম্প্ৰদায়সমূহ',
    NO_BOOKMARKS_TO_SHOW: 'দেখুৱাবলৈ কোনো বুকমাৰ্ক নাই',
    ADMIN: 'প্ৰশাসক',
    ASSIGNMENT: 'এচাইনমেণ্ট',
    SHEROES_IS_A_WOMEN_ONLY_PLATFORM: 'SHEROES হৈছে এক মাত্ৰ মহিলা মঞ্চ',
    CHECK_SELFIE: 'ছেলফি পৰীক্ষা কৰক',
    MAKE_SURE_YOUR_FACE_IS_IN_THE_OVAL:
      'নিশ্চিত কৰিব যে আপোনাৰ মুখখন ডিম্বাকৃতিৰ ভিতৰত আছে।',
    MAKE_SURE_YOU_ARE_THE_ONLY_ONE_IN_THE_PICTURE:
      'নিশ্চিত কৰক যে ছবিখনত আপুনি একমাত্ৰ।',
    MAKE_SURE_YOU_ARE_NOT_WEARING_A_MASK_IN_THE_PICTURE:
      'নিশ্চিত কৰক যে আপুনি ছবিখনত মুখা পিন্ধি থকা নাই।',
    CLICK_PHOTO_IN_GOOD_LIGHTING: 'ভাল পোহৰত ফটো ক্লিক কৰক।',
    SORRY_IMAGE_NOT_VERIFIED: 'ক্ষমা কৰিব! ছবি প্ৰমাণিত হোৱা নাই',
    HERE_ARE_SOME_SELFIE_POINTERS: 'ইয়াত কিছুমান চেলফি পইণ্টাৰ আছে',
    UNABLE_TO_IDENTIFY_AS_FEMALE:
      'আমাৰ প্ৰণালীয়ে আপোনাক এগৰাকী মহিলা হিচাপে চিনাক্ত কৰিবলৈ অক্ষম হৈছিল।',
    WRITE_TO_US_IF_YOU_THINK_THIS_IS_AN_ERROR: 'এইটো ভুল বুলি ভাবিলে আমাক লিখক',
    WE_WOULD_LOVE_FOR_YOU_TO_SPREAD:
      'আমি আপোনাৰ মহিলা বন্ধুসকলৰ মাজত এই শব্দটো প্ৰচাৰ কৰিবলৈ আপোনাৰ বাবে ভাল পাম।',
    SHARE_WITH_FRIENDS: 'বন্ধু-বান্ধৱীৰ লগত শ্বেয়াৰ কৰক',
    SORRY: 'ক্ষমা কৰিব !',
    MAKE_SURE_YOUR_SELFIE_CLEARLY_SHOWS_YOUR_FACE:
      'নিশ্চিত কৰক যে আপোনাৰ চেলফিয়ে আপোনাৰ মুখখন স্পষ্টভাৱে দেখুৱায়।',
    ACHIEVEMENTS: 'কৃতিত্ব',
    SIGN_UP: 'ছাই আপ',
    PERSONAL_DETAILS: 'ব্যক্তিগত বিৱৰণ',
    OTP_VERIFICATION: 'OTP প্ৰমাণীকৰণ',
    PLEASE_ENTER_CODE_SENT_TO: "অনুগ্ৰহ কৰি লৈ প্ৰেৰণ কৰা ক'ডটো দিয়ক",
    RESEND_OTP: 'OTP পুনৰ প্ৰেৰণ কৰক',
    IN: 'in',
    SOME_SELFIE_POINTERS: 'কিছুমান চেল্ফি পইণ্টাৰ',
    MAKE_SURE_YOUR_FACE_IS_IN_THE_OVAL_AND_CLEARLY_VISIBLE:
      'নিশ্চিত কৰিব যে আপোনাৰ মুখখন ডিম্বাকৃতিৰ ভিতৰত আছে আৰু স্পষ্টভাৱে দৃশ্যমান।',
    HI_I_FOUND_THIS_WOMEN_ONLY_APP_FOR_YOU:
      "হাই, মই এই মহিলাসকলক কেৱল আপোনাৰ বাবে এপটো পাইছিলো। ইয়াৰ নাম হৈছে SHEROES (Women heroes)। আপুনি কোনো দ্বিধা নকৰাকৈ যিকোনো কথা ক'ব পাৰে আৰু সুধিব পাৰে (আনকি বেনামীভাৱেও) আৰু আন মহিলাসকলক ভাল পৰামৰ্শ দি সহায় কৰিব পাৰে।",
    TO_CORRECTLY_IDENTIFY_YOU:
      'আপোনাক সঠিকভাৱে চিনাক্ত কৰিবলৈ, আমি আপোনাৰ চেলফি কেপচাৰ কৰিব লাগিব আৰু সেইটো কৰিবলৈ আমাক ডিভাইচ কেমেৰাত প্ৰৱেশ কৰিবলৈ অনুমতি দিবলৈ আপোনাৰ অনুমতিৰ প্ৰয়োজন।',
    GET_STARTED_WITH_EMAIL_ADDRESS: 'ইমেইল ঠিকনাৰে আৰম্ভ কৰক',
    GET_STARTED_WITH_MOBILE_NUMBER: "ম'বাইল নম্বৰ আৰম্ভ কৰক",
    USE_MOBILE_NUMBER: "ম'বাইল নম্বৰ ব্যৱহাৰ কৰক",
    USE_EMAIL_ADDRESS: 'ই-মেইল ঠিকনা ব্যৱহাৰ কৰক',
    SEND_OTP: 'Send OTP',
    CONTINUE_WITH_EMAIL_MOBILE_NO: "ইমেইল / ম'বাইল নম্বৰৰ সৈতে অব্যাহত ৰাখক।",
    CONTINUE_WITH_FACEBOOK: 'ফেচবুকৰ সৈতে অব্যাহত ৰাখক',
    CONTINUE_WITH_GOOGLE: 'গুগলৰ সৈতে অব্যাহত ৰাখক',
    WELCOME_TO_SHEROES: 'Welcome to SHEROES',
    HI: 'Hi,',
    CHAMPIONS: 'চেম্পিয়নসকলে',
    REPORT_USER: 'ব্যৱহাৰকাৰীৰ প্ৰতিবেদন',
    WARNING: 'সতৰ্কবাণী!!!',
    ARE_YOU_WANT_TO_BLOCK_USER:
      'আপুনি নিশ্চিত নেকি যে আপুনি ব্যৱহাৰকাৰীক ব্লক কৰিব বিচাৰে?',
    CANCEL: 'বাতিল কৰক',
    BLOCK: 'ব্লক',
    USER: 'ব্যৱহাৰকাৰী',
    THIS_CONTENT_IS_UNAVAILABLE: 'আপুনি বিচৰা এই বিষয়বস্তু উপলব্ধ নহয়।',
    ARE_YOU_WANT_TO_UNBLOCK_USER:
      'আপুনি নিশ্চিত নেকি যে আপুনি ব্যৱহাৰকাৰীক আনব্লক কৰিব বিচাৰে?',
    CANCEL_REQUEST: 'অনুৰোধ বাতিল কৰক',
    UNFRIEND: 'অনফ্ৰেণ্ড',
    UNFOLLOW: 'আনফল',
    POLL: 'পল',
    VIDEO: 'ভিডিঅ',
    CHOOSE_YOUR_TYPE_OF_POLL: 'আপোনাৰ ভোটৰ ধৰণ বাছক',
    TEXT_POLL: 'পাঠ্য সমীক্ষা',
    IMAGE_POLL: 'চিত্ৰ সমীক্ষা',
    CREATE_POLL: "প'ল সৃষ্টি কৰক",
    ADD_MORE_OPTIONS: 'অধিক বিকল্প যোগ কৰক',
    OTHER_COMMUNITIES: 'অন্য সম্প্ৰদায়',
  },
};

export default language;
