import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  makeStyles,
} from '@material-ui/core';
import { useStoreActions, useStoreState } from 'easy-peasy';

/**
 * Author: Shubh Kirti
 * Date: 23 Oct., 2023
 * @param {String} title
 * @param {Array} options [{name:"",icon:""},{name:"",icon:""}]
 * @param {Boolean} open
 * @param {Boolean} verticalOptions //if verticalOptions is true then it will show your options in vertical stack otherwise horizontal.
 * @returns Modal box
 */
const CommonModal = () => {
  const { title, options, open, verticalOptions } = useStoreState(
    (state) => state.commonModal
  );
  const { setModalOptions } = useStoreActions((state) => state.commonModal);
  const classes = useStyles({ verticalOptions });
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  return (
    <React.Fragment>
      {open && (
        <Dialog
          TransitionComponent={Transition}
          open={open}
          classes={{ paper: 'paperDialog' }}
          onClose={() => setModalOptions({ open: false })}
        >
          <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
          <Divider />
          <div className={classes.dialogContent}>
            {options &&
              options.length &&
              options.map((option, i) => {
                return (
                  <DialogActions key={i}>
                    <ListItem
                      className={classes.listItem}
                      onClick={() => option.onClickAction()}
                    >
                      {option.icon && (
                        <ListItemIcon>{option.icon}</ListItemIcon>
                      )}
                      {option.name && (
                        <ListItemText style={{ marginTop: '10px' }}>
                          {option.name}
                        </ListItemText>
                      )}
                    </ListItem>
                  </DialogActions>
                );
              })}
          </div>
        </Dialog>
      )}
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: '5px 10px',
    textAlign: 'left',
    display: 'block',
    color: 'black',
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
  },
  listItem: {
    cursor: 'pointer',
    flexDirection: ({ verticalOptions }) =>
      verticalOptions ? 'column' : 'row',
  },
}));
export default CommonModal;
