import React, { useState, useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import useReactRouter from 'use-react-router';
import ActionToggle from '../common/actionToggle';
import { useBookmarkOnPost } from '../../hooks/postHooks';
import MoengageService from '../../services/moEngageService';
import { getPostLanguage } from '../../services/authService';
import languages from '../../utils/languages';
import { useSnackBar } from '../../hooks/snackBarHooks';
import { getCurrentLocation } from '../../utils/textUtil';
import pollService from '../../services/pollService';

const OptionsMenu = ({
  comment,
  isPostDetail,
  post,
  postId,
  postEntityId,
  isCommentDetail,
  commentReplyIndex,
  parentCommentIndex,
  lectureEntityId,
  isLecture,
  fromHeader,
  user,
  isArticleDetail,
  isFromReplies,
  commentIndex,
  isCommentActive,
  isParentComment,
  replyParentCommentIndex,
  isLastComment,
  postSlug,
}) => {
  const isPollPost = post && post.subType && post.subType === 'PL';
  const deletePost = useStoreActions((actions) => actions.posts.deletePost);
  const isBookmarked = useBookmarkOnPost(postId);
  const webLanguage = getPostLanguage();
  const languageText = languages[webLanguage ? webLanguage : 'en'];
  const location = window.location.pathname.split('/');
  const isReplies = location.includes('replies');
  const featurePost = useStoreActions((actions) => actions.posts.featurePost);
  const deleteCommentPost = useStoreActions(
    (actions) => actions.posts.deleteCommentPost
  );
  const { setDialogBoxOpen, setDialogContent } = useStoreActions(
    (actions) => actions.dialogBox
  );

  const setScrollPostId = useStoreActions(
    (actions) => actions.feed.setScrollPostId
  );
  const { history } = useReactRouter();
  const [optionsMenu, setOptionsMenu] = useState([]);
  const { updateSpamState } = useStoreActions((actions) => actions.reportSpam);
  const setErrorMessage = useStoreActions(
    (actions) => actions.snackBar.setMessage
  );
  useEffect(() => {
    let currentComp = true;
    currentComp &&
      setOptionsMenu(post ? post.optionsMenu : comment.optionsMenu);
    return () => {
      currentComp = false;
    };
  }, [post, comment]);

  const setEditCommentObj = useStoreActions(
    (state) => state.posts.setEditCommentObj
  );

  const setToastMessage = useSnackBar('');
  const { bookmarkOnPost } = useStoreActions((actions) => actions.posts);

  function handleBookmark() {
    const isMyBookmarksPage = getCurrentLocation[1] === 'my-bookmarks';
    bookmarkOnPost({
      postId,
      entityId: post?.parentId,
      isBookmark: !isBookmarked,
      setToastMessage: setToastMessage,
      languageText,
      isMyBookmarksPage,
    });
  }

  function handleBlockUser() {
    setDialogBoxOpen(true);
    setDialogContent({
      dialogTitle: languageText.WARNING,
      dialogContentText: languageText.ARE_YOU_WANT_TO_BLOCK_USER,
      btnPrimaryText: languageText.CANCEL,
      btnSecondaryText: languageText.BLOCK,
      btnSecondaryAction: blockUser,
    });
  }

  async function blockUser() {
    try {
      await pollService.toggleBlockedUser(post.authorId, true, setErrorMessage);
      window.location.reload();
    } catch (error) {
      setErrorMessage(languageText?.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }

  function handleDeletePost(e) {
    deletePost({
      postEntityId: post.entityId,
      postId: post.id,
      isPostDetail: isPostDetail,
      history: history,
      setToastMessage: setToastMessage,
    });
    MoengageService.trackPostDeleted(post);
  }

  //TODO handle edit comment.
  function handleEditComment() {
    setEditCommentObj({
      isCommentEditable: true,
      comment,
      commentIndex,
      isCommentActive,
      isParentComment,
      replyParentCommentIndex,
      isLastComment: isLastComment,
    });

    isLastComment && postSlug && !isReplies && history.push(postSlug);
  }

  function handleEditAction() {
    if (post) {
      const postStr = JSON.stringify(post);
      const fromScreen = window.location.pathname;
      history.push('/edit-post', { postStr, fromScreen });
      MoengageService.trackPostEdited(post);
    } else {
      handleEditComment();
    }
  }

  function handleDeleteComment() {
    const reqBody = {
      commentId: comment.id,
      postId: postId,
      postEntityId: isLecture ? lectureEntityId : postEntityId,
      isCommentDetail: isCommentDetail,
      commentReplyIndex: commentReplyIndex,
      parentCommentIndex: parentCommentIndex,
      history: history,
      setToastMessage: setToastMessage,
    };
    deleteCommentPost(reqBody);
  }

  function handleReportSpam() {
    const docInfo = {
      docId: post ? post.entityId : isLecture ? lectureEntityId : postEntityId,
      communityId: post ? post.communityId : 0, //0 if doc is comment.
      docAuthorId: post ? post.author.id : comment.authorId,
    };
    updateSpamState({
      showSpamModal: true,
      contentType: post ? 'post' : 'comment',
      docInfo: docInfo,
    });

    if (fromHeader) {
      MoengageService.trackProfileReported(user, fromHeader);
    } else if (isFromReplies) {
      MoengageService.trackReplyOnCommentReported(post, isArticleDetail);
    } else {
      MoengageService.trackCommentReported(post, isArticleDetail);
    }
  }

  function handleFeaturePostAction() {
    const currentIsTopPostStatus = post.isTopPost === null ? false : true;
    MoengageService.trackPostFeatured(post);

    featurePost({
      isTopPost: currentIsTopPostStatus,
      entityId: post.entityId,
      postId: post.id,
      setToastMessage: setToastMessage,
    });
    setScrollPostId(post.id);
  }

  const actionMap = {
    blockedUser: handleBlockUser,
    bookmark: handleBookmark,
    delete: post ? handleDeletePost : handleDeleteComment,
    reportSpam: handleReportSpam,
    edit: handleEditAction,
    featurePost: handleFeaturePostAction,
  };
  optionsMenu &&
    optionsMenu.map((option) => (option.action = actionMap[option.id]));

  return !isPollPost && <ActionToggle options={optionsMenu} postId={postId} />;
};

export default OptionsMenu;
