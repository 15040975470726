import React from 'react';
import { Box, makeStyles, Grid } from '@material-ui/core';
import OwlCarousel from 'react-owl-carousel';
import '../../css/owl.carousel.css';
import '../../css/home.css';
import '../../css/animate.min.css';
import { homeSliderData } from '../../utils/homeUtils';
import {
  getOnboardingBlurEffect,
  setOnboardingSlideIndex,
  getOnboardingSlideIndex,
} from '../../services/onboardingService';

const HomeSlider = () => {
  const onboardingSlideIndex = getOnboardingSlideIndex();
  const classes = useStyles();
  const onboardingBlurEffect = getOnboardingBlurEffect();
  const isHomeScreen = window.location.pathname === '/';
  const setCurrentSlideIndex = (event) => {
    var item = event.item.index - 2;
    setOnboardingSlideIndex(item);
  };

  const applyClass = () => {
    if (isHomeScreen) {
      if (onboardingBlurEffect) {
        return classes.blurContent;
      } else {
        return classes.blurFalse;
      }
    } else {
      if (onboardingBlurEffect) {
        return 'blurContent';
      } else {
        return 'blurFalse';
      }
    }
  };

  return (
    <React.Fragment>
      <Box className="homeCarouselMain">
        <Box className={applyClass()}>
          <OwlCarousel
            className="slider-wrapper"
            items={1}
            autoplay={onboardingBlurEffect ? false : true}
            autoplayTimeout={6000}
            loop={true}
            smartSpeed={6000}
            mouseDrag={false}
            touchDrag={false}
            startPosition={onboardingSlideIndex}
            center={true}
            animateIn="fadeInAnimation"
            onTranslate={setCurrentSlideIndex}
          >
            {homeSliderData.map((slide, index) => {
              return (
                <div
                  key={index}
                  className="slider-content"
                  style={{
                    backgroundImage: `url(${slide.slideUrl})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <img
                    className="image-overlap"
                    src="https://img.sheroes.in/img/uploads/sheroespage/logoes/1681115139clouds.svg"
                    alt="clouds"
                  />
                  <Box className="container" pt={4} style={{ height: '100%' }}>
                    <Grid container spacing={2} style={{ height: '100%' }}>
                      <Grid
                        item
                        xs={12}
                        sm={7}
                        md={7}
                        style={{
                          position: 'relative',
                        }}
                      >
                        <div className="inner">
                          <h1
                            className={
                              onboardingBlurEffect ? 'title' : 'animatedTitle'
                            }
                            style={{ fontSize: '50px' }}
                          >
                            {slide.title}
                          </h1>
                          <p
                            className={
                              onboardingBlurEffect
                                ? 'subTitle'
                                : 'animatedSubTitle'
                            }
                          >
                            {slide.subTitle}
                          </p>
                        </div>

                        <Box className="bubblesContainer">
                          <img
                            src={slide.bubble1}
                            alt="LadyInGrey"
                            width="80px"
                            className={
                              onboardingBlurEffect
                                ? 'slider1_bubble1'
                                : 'slider1_animated_bubble1'
                            }
                          />
                          <img
                            src={slide.bubble2}
                            alt="LadyWithLaptop"
                            width="70px"
                            className={
                              onboardingBlurEffect
                                ? 'slider1_bubble2'
                                : 'slider1_animated_bubble2'
                            }
                          />
                          <img
                            src={slide.bubble3}
                            alt="ChatIcon"
                            width="80px"
                            className={
                              onboardingBlurEffect
                                ? 'slider1_bubble3'
                                : 'slider1_animated_bubble3'
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={5} md={5}></Grid>
                    </Grid>
                  </Box>
                </div>
              );
            })}
          </OwlCarousel>

          <Box className="outsideBubble">
            <img
              src="https://img.sheroes.in/img/uploads/sheroespage/logoes/1674134218rightside-bubble.png"
              alt="Bubble"
              width="42px"
              className={
                onboardingBlurEffect
                  ? 'rightSideBubble'
                  : 'rightSideAnimatedBubble'
              }
            />
            <img
              src="https://img.sheroes.in/img/uploads/sheroespage/logoes/1674134354top-bubble-.png"
              alt="Bubble"
              width="62px"
              className={
                onboardingBlurEffect ? 'topSideBubble' : 'topSideAnimatedBubble'
              }
            />
            <img
              src="https://img.sheroes.in/img/uploads/sheroespage/logoes/1674134401bottom-bubble.png"
              alt="Bubble"
              width="62px"
              className={
                onboardingBlurEffect
                  ? 'bottomSideBubble'
                  : 'bottomSideAnimatedBubble'
              }
            />
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};
const useStyles = makeStyles((theme) => ({
  readMore: {
    background: 'inherit',
    border: 0,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: theme.spacing(2),
    },
  },
  blurFalse: {
    position: 'relative',
    left: 0,
    right: 0,
    height: '100%',
  },
  blurContent: {
    position: 'relative',
    left: 0,
    right: 0,
    height: '100%',
    filter: 'blur(4px)',
    background: 'rgba(0, 0, 0, 0.05)',
  },
}));
export default HomeSlider;
