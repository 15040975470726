import { action } from 'easy-peasy';

export default {
  title: '',
  options: [],
  open: false,
  verticalOptions: false,
  setModalOptions: action(
    (state, { title, options, open, verticalOptions }) => {
      state.title = title;
      state.options = options;
      state.open = open;
      state.verticalOptions = verticalOptions;
    }
  ),
};
