import React from 'react';
import { makeStyles, CircularProgress, Box, Button } from '@material-ui/core';

const CustomButton = ({
  variant,
  size,
  icon,
  text,
  isLoader,
  imgIcon,
  ...rest
}) => {
  const classes = useStyles();
  const buttonSize = size === 'regular' ? classes.regular : classes.small;
  const buttonMap = {
    primary: {
      variant: 'contained',
      color: 'primary',
      className: classes.containedPrimary && buttonSize,
    },
    outlined: {
      variant: 'outlined',
      color: 'primary',
      className: buttonSize,
    },
    secondary: {
      variant: 'outlined',
      className: classes.outlinedSecondary && buttonSize,
    },
    iconButton: {
      className: classes.iconButtonText,
    },
  };

  return (
    <React.Fragment>
      <Button {...buttonMap[variant]} {...rest}>
        {imgIcon && <img src={imgIcon} width="20" height="20" alt="Icon" />}
        {icon}
        <Box display="flex" alignItems="center" pl={1}>
          {isLoader ? (
            <CircularProgress className={classes.loaderIcn} color="inherit" />
          ) : (
            text
          )}
        </Box>
      </Button>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  containedPrimary: {
    borderRadius: 4,
    textTransform: 'capitalize',
    fontWeight: 600,
    letterSpacing: 1,
  },
  outlinedSecondary: {
    border: '1px solid #878787',
    borderRadius: 4,
    textTransform: 'capitalize',
    fontWeight: 600,
    letterSpacing: 1,
  },
  small: {
    fontSize: '12px',
    padding: theme.spacing(0, 2),
    minWidth: 84,
    height: 24,
  },
  regular: {
    fontSize: '16px',
    padding: theme.spacing(0, 2),
    minWidth: 156,
    height: 50,
  },
  iconButtonText: {
    textTransform: 'none',
    padding: theme.spacing(0),
    justifyContent: 'initial',
    display: 'flex',
    minWidth: 'auto',
  },
}));

export default CustomButton;
