import React, { useState } from 'react';
import {
  BottomNavigation,
  Box,
  BottomNavigationAction,
} from '@material-ui/core';
import SearchIcon from '../img/fill-19.svg';
import HomeIcon from '../img/home.svg';
import ActiveHomeIcon from '../img/home-active.svg';
import ActiveNotificationsIcon from '../img/notifications-active.svg';
import ActiveSearchIcon from '../img/search-active.svg';
import ActiveMenuIcon from '../img/menu-active.svg';
import ActiveCreatePostIcon from '../img/red-create-post.svg';
import CreatePostIcon from '../img/create-post.svg';
import NotificationsIcon from '../img/notifications.svg';
import ReadNotificationsIcon from '../img/read-notifications.svg';
import MenuIcon from '../img/more.svg';
import { useLoggedInUser } from '../hooks/userHooks';
import AdapterLink from './common/adapterLink';
import { getPostLanguage } from '../services/authService';
import { getThirdPartyUser } from '../services/authService';

const BottomNavbar = ({ options, notificationsCount }) => {
  const [value, setValue] = useState(options.activeTab);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const user = useLoggedInUser();
  const userFromMM = getThirdPartyUser();
  const webLanguage = getPostLanguage();
  const enHiLang = webLanguage === 'en' || webLanguage === 'hi';
  const showCreatePost =
    user && user.partnerId === null && userFromMM === null && enHiLang
      ? true
      : false;

  /**
   * show search icon
   * @returns
   */
  const showSearchIcon = () => {
    if (userFromMM) {
      return false;
    } else {
      if (enHiLang) {
        return true;
      } else {
        return false;
      }
    }
  };

  const menuItems = [
    {
      key: 'feed',
      to: '/',
      activeIcon: ActiveHomeIcon,
      inactiveIcon: HomeIcon,
      isShow: true,
    },
    {
      key: 'search',
      to: '/search',
      activeIcon: ActiveSearchIcon,
      inactiveIcon: SearchIcon,
      isShow: showSearchIcon(),
    },
    {
      key: 'createpost',
      to: '/create-post',
      activeIcon: ActiveCreatePostIcon,
      inactiveIcon: CreatePostIcon,
      isShow: showCreatePost,
    },
    {
      key: 'notifications',
      to: '/notifications',
      isShow: true,
      activeIcon: ActiveNotificationsIcon,
      inactiveIcon:
        notificationsCount > 0 ? NotificationsIcon : ReadNotificationsIcon,
    },
    {
      key: 'menu',
      to: '/menu',
      activeIcon: ActiveMenuIcon,
      inactiveIcon: MenuIcon,
      isShow: true,
    },
  ];
  return (
    <Box position="fixed" width={1} className="stickToBottom">
      <BottomNavigation value={value} onChange={handleChange} showLabels>
        {menuItems
          .filter((items) => items.isShow === true)
          .map(({ key, to, activeIcon, inactiveIcon }) => (
            <BottomNavigationAction
              className="bottomNavigationAction"
              key={key}
              value={key}
              to={to}
              component={AdapterLink}
              icon={
                <img
                  src={options.activeTab === key ? activeIcon : inactiveIcon}
                  alt=""
                />
              }
            />
          ))}
      </BottomNavigation>
    </Box>
  );
};

export default BottomNavbar;
