import httpService from './httpService';
import apiUrl from '../utils/apiUrl';
import http from './httpService';
import { formRequest } from '../utils/userUtil';
import {
  getCurrentUser,
  getPostLanguage,
  getUserToken,
  setCurrentUser,
} from './authService';
import {
  friendListDataMap,
  followedMentorMapper,
  championsDataMap,
} from '../mappers/followedMapper';
import { mapMentionData } from '../utils/editorUtil';
import language from '../utils/languages';
import { getUserProfileLink } from '../utils/postUtil';

const webLanguage = getPostLanguage();
const languageText = language[webLanguage ? webLanguage : 'en'];

async function getHashtags() {
  const { data } = await httpService.get(apiUrl.GET_TRENDING_HASHTAGS);
  return data;
}
async function editProfile(request, setToastMessage) {
  try {
    const { data } = await http.post(apiUrl.EDIT_USER_PROFILE, request);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return { status: false };
    } else {
      const profileLink = getUserProfileLink(request.firstName, request.id);
      const user = getCurrentUser();
      setCurrentUser({ ...user, userProfileLink: profileLink });
      return { status: true, nextScreen: data.next_screen };
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return { status: false };
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return { status: false };
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return { status: false };
    }
  }
}
async function followProfile(userId, toFollow, setToastMessage) {
  try {
    const requestBody = {
      id_of_entity_or_participant: userId,
    };
    const { data } = await http.post(
      toFollow ? apiUrl.FOLLOW : apiUrl.UNFOLLOW,
      requestBody
    );
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}

async function updateBasicProfile(
  firstName,
  lastName,
  cityMasterId,
  setToastMessage
) {
  try {
    const { data } = await http.post(apiUrl.USER_ADD_EDIT, {
      source: 'web',
      type: 'BASIC_PROFILE',
      subType: 'BASIC_USER_PROFILE_SERVICE',
      first_name: firstName,
      last_name: lastName,
      city_master_id: cityMasterId,
    });
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}
async function updateProfilePicture(image, setToastMessage) {
  try {
    const { data } = await http.post(apiUrl.USER_ADD_EDIT, {
      source: 'web',
      type: 'PROFILE_PHOTO',
      subType: 'USER_PROFILE_PHOTO_SERVICE',
      image_string: image,
    });
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
    } else {
      return data.docs;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}
async function submitSelfie(file) {
  const reqData = formRequest(file);
  const token = getUserToken();
  const data = await fetch(
    process.env.REACT_APP_API_URL + apiUrl.SUBMIT_SELFIE,
    {
      method: 'POST',
      body: reqData,
      headers: { Authorization: token },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
  return data;
}
// This Function is used for fetching friends List of user,
//and nextToken first time null and next time will get from response.
async function getFriendList(userId, nextToken) {
  const requestUrl =
    nextToken === null
      ? apiUrl.FRIEND_LIST + userId
      : apiUrl.FRIEND_LIST + userId + '&next_token=' + nextToken;
  const { data } = await http.get(requestUrl);
  const response = friendListDataMap(data);
  return response;
}

//This Function  is used to fetch list of followers and following.
async function getFollowingList(is_fetch_following, userId, pageNumber) {
  const token = getUserToken();
  const requestBody = {
    is_fetch_following: is_fetch_following,
    is_listing: true,
    user_id: userId,
    page_no: pageNumber,
    page_size: 10,
  };
  const data = await fetch(
    process.env.REACT_APP_API_URL + apiUrl.FOLLOWER_LIST,
    {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    }
  )
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return data && followedMentorMapper(data);
}

//This Function is used to get the response doc for listing the champions.
async function getChampionsList(pageNumber) {
  const requestBody = {
    is_accepted_or_active: false,
    is_anonymous_post_hide: false,
    is_spam_post_b: false,
    only_active: false,
    sub_type: 'W',
    page_no: pageNumber,
    page_size: 10,
    screen_name: 'Feed',
  };
  try {
    const { data } = await http.post(apiUrl.GET_POST, requestBody);
    const response = data && championsDataMap(data);
    return response;
  } catch (error) {
    return error;
  }
}

//This Function is called when user send friend request to another user
async function getAddFriend(userId, participantId, isFeed, setToastMessage) {
  const requestBody = isFeed
    ? {
        user_id: userId,
      }
    : {
        is_achieved_message_included: false,
        user_id: userId,
        user_ids: [participantId],
        page_no: 1,
        page_size: 10,
      };
  try {
    const { data } = await http.post(apiUrl.ADD_FRIEND, requestBody);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}

//This Function is called when a user accept another users Friend Request
async function getConfirmFriend(
  participantId,
  friendRequestId,
  setToastMessage
) {
  const requestBody = {
    friend_request_id: friendRequestId,
    is_achieved_message_included: false,
    user_id: participantId,
    page_no: 1,
    page_size: 10,
  };
  try {
    const { data } = await http.post(apiUrl.ACCEPT_FRIEND, requestBody);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}
async function getMentionData(request, setToastMessage) {
  let mentionData = [];
  try {
    const { data } = await http.post(apiUrl.GET_USER_MENTION, request);
    mentionData = data.user_mention_suggestions.map((user) =>
      mapMentionData(user)
    );
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return { mentionData };
    } else {
      return { mentionData };
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return { mentionData };
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return { mentionData };
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return { mentionData };
    }
  }
}

async function createRequestUrl(req) {
  const defaultValidateUrl = apiUrl.VALIDATE_OTP;
  let newUrl = '';
  if (req.community_ids === 'undefined') {
    return defaultValidateUrl;
  } else {
    if (req.community_ids) {
      newUrl = 'community_ids=' + req.community_ids + '&';
    }
    if (req.activity) {
      newUrl += 'activity=' + req.activity + '&';
    }
    if (req.hashtag) {
      newUrl += 'hashtag=' + req.hashtag + '&';
    }
    if (req.redirectURL) {
      newUrl += 'redirectURL=' + req.redirectURL + '&';
    }
    if (req.communityLang) {
      newUrl += 'communityLang=' + req.communityLang;
    }
    return defaultValidateUrl + '?' + newUrl;
  }
}

async function cancelFriendRequest(friendUserId, setToastMessage) {
  const requestBody = {
    user_id: friendUserId,
    is_achieved_message_included: false,
  };
  try {
    const { data } = await http.post(apiUrl.CANCEL_FRIEND_REQUEST, requestBody);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}

async function unFriendRequest(friendUserId, setToastMessage) {
  const requestBody = {
    friend_user_id: friendUserId,
    is_achieved_message_included: false,
  };
  try {
    const { data } = await http.post(apiUrl.UNFRIEND_REQUEST, requestBody);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}
export default {
  getHashtags,
  editProfile,
  followProfile,
  updateBasicProfile,
  updateProfilePicture,
  submitSelfie,
  getFriendList,
  getFollowingList,
  getChampionsList,
  getConfirmFriend,
  getAddFriend,
  getMentionData,
  createRequestUrl,
  cancelFriendRequest,
  unFriendRequest,
};
