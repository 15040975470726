import React from 'react';
import { Badge, Box, Grid, Toolbar, Typography } from '@material-ui/core';
import AnyLink from '../common/anyLink';
import { ellipsize } from '../../utils/textUtil';
import LanguageMenu from './languageMenu';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ToggleDrawer from './toggleDrawer';
import CustomButton from '../common/customButton';
import SearchBar from '../common/searchBar';
import AdapterLink from '../common/adapterLink';
import { getCurrentUser, getPostLanguage } from '../../services/authService';
import ShareCommunityIcon from '../../img/ic-share.svg';
import NotificationIcon from '../../img/notification-icon.svg';
import { useMobile, useTablet } from '../../hooks/utilHooks';
import SheroesLogo from '../../img/logo.svg';
import { useNavbarItems } from '../../hooks/navBarHooks';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { showLanguageMenuIcon } from '../../utils/quizUtil';

function NavbarItem() {
  const user = getCurrentUser();
  const isMobile = useMobile();
  const isTablet = useTablet();
  const webLanguage = getPostLanguage();
  const enHiLang = webLanguage === 'en' || webLanguage === 'hi';
  const isShowLanguageMenuIcon = showLanguageMenuIcon();
  const { showSearchBar } = useStoreState((state) => state.navBar.options);
  const options = useStoreState((state) => state.navBar.options);
  const shareCommunity = useStoreActions(
    (actions) => actions.community.shareCommunity
  );
  const {
    isOnboardingDone,
    userFromMM,
    isOnboardingUrl,
    history,
    isCreatePost,
    isSheroesMagazine,
    classes,
    handleBackNavigation,
    notificationsCount,
    setNotificationsCount,
  } = useNavbarItems();

  const handleHideBell = () => {
    return setNotificationsCount(0);
  };

  return (
    <Box className="container" px={!isTablet ? 4 : 0}>
      <Grid container>
        {userFromMM === 'true'
          ? ''
          : !isTablet && (
              <Grid item sm={3} md={2} lg={3}>
                <AnyLink to="/">
                  <Box
                    pt={isOnboardingDone || isOnboardingUrl ? 3.5 : 8}
                    pl={isTablet ? 0 : 5}
                    style={{
                      marginLeft: !isTablet && isSheroesMagazine && '-27%',
                    }}
                  >
                    <img
                      src={SheroesLogo}
                      alt="SHEROES"
                      width="110px"
                      height="13px"
                    />
                  </Box>
                </AnyLink>
              </Grid>
            )}
        {isOnboardingUrl && (
          <Grid
            item
            xs={12}
            sm={12}
            md={10}
            lg={userFromMM === 'true' && isCreatePost ? 12 : 9}
          >
            <Toolbar
              className={
                userFromMM === 'true' && isCreatePost
                  ? classes.tollBar
                  : userFromMM === 'true'
                  ? classes.tollBarForMM
                  : classes.tollBar
              }
              style={{
                marginLeft: !isTablet && isSheroesMagazine && '-25%',
              }}
            >
              {options && options.topLeftButton}
              {userFromMM === 'true' ? (
                ''
              ) : (
                <CustomButton
                  className={classes.menuStyle}
                  onClick={() => options.showBack && handleBackNavigation()}
                  icon={
                    options.showBack ? (
                      <ArrowBackIcon />
                    ) : (
                      options.toggleDrawerIcon &&
                      isTablet && (
                        <ToggleDrawer direction={'left'} isCommunity={false} />
                      )
                    )
                  }
                ></CustomButton>
              )}
              <Typography
                variant="h6"
                color="inherit"
                className={
                  userFromMM === 'true' && isTablet
                    ? classes.titleForMMMobile
                    : userFromMM === 'true' && isCreatePost
                    ? classes.title
                    : userFromMM === 'true'
                    ? classes.titleForMM
                    : classes.title
                }
              >
                {ellipsize(options.title, isMobile ? 18 : 60)}
              </Typography>
              {userFromMM === 'true'
                ? ''
                : !showSearchBar &&
                  user &&
                  !isTablet &&
                  enHiLang && (
                    <SearchBar
                      handleSubmit={(value) =>
                        history.push('/search?q=' + value)
                      }
                      fixRight={true}
                    />
                  )}

              {isShowLanguageMenuIcon && <LanguageMenu />}
              {user && (
                <React.Fragment>
                  {userFromMM === 'true'
                    ? ''
                    : options.showBell &&
                      !isTablet && (
                        <Badge
                          to="/notifications"
                          component={AdapterLink}
                          badgeContent={notificationsCount}
                          classes={{ badge: classes.customBadge }}
                          onClick={handleHideBell}
                          className={classes.notificationBell}
                        >
                          <img src={NotificationIcon} alt="NotificationIcon" />
                        </Badge>
                      )}
                  <Box
                    ml={
                      userFromMM === 'true' && isTablet
                        ? 40
                        : userFromMM === 'true'
                        ? 248
                        : 0
                    }
                  >
                    {options && options.topRightButton}
                  </Box>
                  {options.showCommunityShareIcon && (
                    <CustomButton
                      className={classes.shareButton}
                      onClick={shareCommunity}
                      icon={<img src={ShareCommunityIcon} alt="Share" />}
                    />
                  )}
                  {userFromMM === 'true'
                    ? ''
                    : options.showCommunityIcon &&
                      isTablet &&
                      enHiLang && (
                        <ToggleDrawer direction={'right'} isCommunity={true} />
                      )}
                </React.Fragment>
              )}
            </Toolbar>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default NavbarItem;
