import { basePostMap } from './modelMapper';
import { orderDetailsDeepLink } from '../utils/shopUtil';
import { getDeliveryStatus } from './communityMapper';
import { getDateFromTimeStamp } from '../utils/dateUtil';

function shopProductMap(doc) {
  const shopMap = {
    productId: doc.id,
    productPrice: doc.price,
    productDeliveryCost: doc.delivery_cost,
  };
  return basePostMap(doc, shopMap);
}

function shopCategoryMap(docs) {
  docs &&
    docs.map((item) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
  return docs;
}

function orderItemMapper(doc) {
  const orderHistory = {
    type: doc && doc.type,
    buyerName: doc.buyer && doc.buyer.name,
    totalAmount: doc?.totalAmount + '.00',
    productImg: doc?.product?.imageUrl,
    productName: doc?.product?.name,
    shopId: doc?.shop?.id,
    shopName: doc?.shop?.name,
    shopImg: doc?.shop?.imageUrl,
    deepLinkUrl: orderDetailsDeepLink(doc),
    state: doc.state && doc.state[0] && getDeliveryStatus(doc.state[0].name),
    orderedDate: getOrderData(doc),
    trackingUrl: doc.state && doc.state[0] && doc.state[0].trackingUrl,
  };
  return basePostMap(doc, orderHistory);
}

function getOrderData(doc) {
  if (doc && doc.state) {
    const orderDate = doc.state.filter((item) => item.name === 'OP');
    if (orderDate && orderDate.length > 0) {
      const dt = getDateFromTimeStamp(orderDate[0].date);
      return dt;
    }
  }
}
function orderDetailMap(doc) {
  return {
    orderId: doc && doc.id,
    type: doc && doc.type,
    addressLine1: doc.address && doc.address.addressLine1,
    addressLine2: doc.address && doc.address.addressLine2,
    pinCode: doc.address && doc.address.pincode,
    totalAmount: doc && doc.totalAmount + '.00',
    price: doc && doc.price + '.00',
    delivery: doc && doc.delivery + '.00',
    settlementAmount: doc && doc.settlementAmount,
    buyerName: doc.buyer && doc.buyer.name,
    buyerEmail: doc.buyer && doc.buyer.email,
    buyerMobile: doc.buyer && doc.buyer.mobile,
    shopId: doc.shop && doc.shop.id,
    shopImgUrl: doc.shop && doc.shop.imageUrl,
    shopName: doc.shop && doc.shop.name,
    productImgUrl: doc.product && doc.product.imageUrl,
    productName: doc.product && doc.product.name,
    convenienceFee: doc && doc.convenienceFee + '.00',
    paymentGatewayCharges: doc && doc.pgCharges,
    deliveryInstructions: doc && doc.deliveryInstructions,
    state: doc.state && doc.state[0] && getDeliveryStatus(doc.state[0].name),
    orderedDate: getOrderData(doc),
    trackingUrl: doc.state && doc.state[0] && doc.state[0].trackingUrl,
  };
}

function businessDetailMap(docs) {
  return {
    isShop: docs.is_shop,
    isBankDetailExist: docs.is_bank_detail_exist,
    shopId: docs.shop_id,
    shopName: docs.shop_name,
    productype: docs.product_type,
    verificationLevel: docs.verification_level,
  };
}
function locationMap(docs) {
  return (
    docs &&
    docs.map((item) => {
      return {
        id: item.id,
        name: item.city,
      };
    })
  );
}
export {
  shopProductMap,
  shopCategoryMap,
  orderItemMapper,
  orderDetailMap,
  businessDetailMap,
  locationMap,
};
