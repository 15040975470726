import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import auth from '../../services/authService';
import FacebookIcon from '../../img/facebook.svg';
import CustomButton from './customButton';
import { useHistory } from 'react-router-dom';
import { getPostLanguage } from '../../services/authService';
import languages from '../../utils/languages';
import { useSnackBar } from '../../hooks/snackBarHooks';

const FacebookLoginButton = ({ setIsLoading }) => {
  const classes = useStyles();
  const history = useHistory();
  const webLanguage = getPostLanguage();
  const languageText = languages[webLanguage ? webLanguage : 'en'];
  const setToastMessage = useSnackBar();

  const responseFacebook = async (response) => {
    setIsLoading(true);
    const accessToken = response.accessToken;
    await auth.socialLogin(accessToken, 'facebook', history, setToastMessage);
  };
  const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
  return (
    <React.Fragment>
      <FacebookLogin
        appId={appId}
        autoLoad={false}
        fields="name,email,picture"
        scope="public_profile"
        callback={responseFacebook}
        render={(renderProps) => (
          <CustomButton
            variant="primary"
            color="primary"
            size="large"
            className={`loginBtn ${classes.socialBtn}`}
            imgIcon={FacebookIcon}
            text={languageText.CONTINUE_WITH_FACEBOOK}
            onClick={renderProps.onClick}
          />
        )}
      />
    </React.Fragment>
  );
};
const useStyles = makeStyles((theme) => ({
  socialBtn: {
    background: '#FFFFFF',
    color: '#484747',
    verticalAlign: 'inherit',
    '&:hover': {
      background: '#FFFFFF',
      color: '#484747',
    },
  },
}));
export default FacebookLoginButton;
