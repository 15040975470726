import googleAnalytics from 'react-ga';
import CleverTap from '../services/clevertapService';
import MoengageService from '../services/moEngageService';
import TwitterIcon from '../img/twitter-red.png';
import LinkedinRed from '../img/linkedin-red.png';
import FacebookRed from '../img/facebook-red.png';
import InstagramRed from '../img/instagram-red.png';
import TwitterGreen from '../img/twitter-green.png';
import LinkedinGreen from '../img/linkedin-green.png';
import FacebookGreen from '../img/facebook-green.png';
import InstagramGreen from '../img/instagram-green.png';
import PhoneIcon from '../img/digital-phone.svg';
import WebIcon from '../img/web-icn.svg';
import UserIcon from '../img/user.svg';
import EmailIcon from '../img/email-icn.svg';

const clevertapAccountId = process.env.REACT_APP_CLEVERTAP_ACCOUNT_ID;
const gaTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

function initializeServices() {
  if (process.env.NODE_ENV === 'production') {
    googleAnalytics.initialize(gaTrackingId);
  }
  CleverTap.initialize(clevertapAccountId);
  MoengageService.initialize();
}

function AppShare(options) {
  if (navigator.share) {
    navigator
      .share({
        title: options.title,
        text: options.text,
        url: options.url,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  } else {
  }
}

function addToHomeScreen(promptTriggered) {
  let deferredPrompt;
  window.addEventListener('beforeinstallprompt', function (e) {
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI notify the user they can add to home screen
    return false;
  });

  if (promptTriggered) {
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
      } else {
      }
      deferredPrompt = null;
    });
  }
}

const isIosDevice = Boolean(
  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
);

const sheroesSocialMediaList = [
  {
    icon: TwitterIcon,
    name: 'Twitter',
    url: 'https://twitter.com/sheroes',
  },
  {
    icon: LinkedinRed,
    name: 'Linkedin',
    url: 'https://www.linkedin.com/company/sheroesindia/',
  },
  {
    icon: FacebookRed,
    name: 'Facebook',
    url: 'https://www.facebook.com/sheroesindia',
  },
  {
    icon: InstagramRed,
    name: 'Instagram',
    url: 'https://www.instagram.com/sheroesindia/',
  },
];
const mmSocialMediaList = [
  {
    icon: TwitterGreen,
    name: 'Twitter',
    url: 'https://twitter.com/MahilaMoney',
  },
  {
    icon: LinkedinGreen,
    name: 'Linkedin',
    url: 'https://www.linkedin.com/company/mahilamoney/',
  },
  {
    icon: FacebookGreen,
    name: 'Facebook',
    url: 'https://www.facebook.com/MahilaMoney/',
  },
  {
    icon: InstagramGreen,
    name: 'Instagram',
    url: 'https://www.instagram.com/mahila.money/?hl=en',
  },
];

const saireeContactList = [
  {
    icon: PhoneIcon,
    name: 'Phone',
    text: '+91 98101 05861',
    url: 'tel:+919810105861',
  },
  {
    icon: EmailIcon,
    name: 'Email',
    text: 'sairee@sheroes.in',
    url: 'mailto:sairee@sheroes.in',
    text2: 'sairee@mahila.money',
    url2: 'mailto:sairee@mahila.money',
  },
  {
    icon: WebIcon,
    name: 'Web',
    text: 'sheroes.com',
    url: 'https://sheroes.com',
    text2: 'mahila.money',
    url2: 'https://mahila.money',
  },
  {
    icon: UserIcon,
    name: 'Phone',
    text: 'saireechahal.com',
    url: 'https://saireechahal.com',
    text2: 'twitter.com/sairee',
    url2: 'https://twitter.com/sairee',
  },
];

export {
  addToHomeScreen,
  AppShare,
  initializeServices,
  isLocalhost,
  isIosDevice,
  mmSocialMediaList,
  sheroesSocialMediaList,
  saireeContactList,
};
