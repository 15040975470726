import authService, { getReferrerUrl } from '../services/authService';
import { userType, ISD_CODE, onboardingSteps, urlMap } from './constants';
import { mapUserType } from '../mappers/userMapper';
import { getSlug } from './textUtil';
import APIURL from '../utils/apiUrl';
import b64toBlob from 'b64-to-blob';

function setMobileNoLength(mobile) {
  if (mobile.length > 10) {
    mobile = mobile.slice(0, 10);
    return mobile;
  } else {
    return mobile;
  }
}

function getFirstAndLastName(name) {
  let [fName, ...lName] = name.split(' ');
  const firstName = fName ? fName : '';
  const lastName = lName ? lName.join(' ') : '';
  return {
    firstName,
    lastName,
  };
}
function getBase64Image(base64Image) {
  const getBase64Name = base64Image.split(',');
  const decodedImage = getBase64Name[1] ? getBase64Name[1] : '';
  return decodedImage;
}

function checkUserType(typeId) {
  const user = authService.getCurrentUser();
  if (!user) return;
  return user.userTypeId === typeId ? true : false;
}
function isAdminOrModerator() {
  return (
    checkUserType(userType.isAdmin) || checkUserType(userType.isCommunityMod)
  );
}
function checkOpenAdminModalStatus(isSchedulePost, isEditablePost) {
  const IS_SCHEDULE_POST = isSchedulePost;
  const IS_EDITABLE_POST = isEditablePost;
  const IS_ADMIN_OR_MODERATOR = isAdminOrModerator();
  switch ((IS_SCHEDULE_POST, IS_EDITABLE_POST, IS_ADMIN_OR_MODERATOR)) {
    case IS_ADMIN_OR_MODERATOR && !IS_SCHEDULE_POST && IS_EDITABLE_POST:
      return false;
    case IS_ADMIN_OR_MODERATOR && IS_SCHEDULE_POST && IS_EDITABLE_POST:
      return false;
    case IS_ADMIN_OR_MODERATOR:
      return true;
    default:
      return false;
  }
}
function hideNotificationByCategory(category) {
  return category === 'FRIEND_REQUEST' ? true : false;
}
function getUserLinkUrl(user) {
  if (!user) return;
  return (
    mapUserType(user.user_type_id) +
    '/' +
    getSlug(user.name) +
    '/' +
    btoa(user.user_id)
  );
}
function getOtpParams(type, request) {
  const isdCode = encodeURIComponent(ISD_CODE);
  const params =
    type === 'mobile'
      ? '?isd=' + isdCode + '&mobile=' + request
      : '?email=' + request.replace('+', '%2B'); //for backend success response, when emailId have + character in it.
  return APIURL.GET_OTP + params;
}
function formRequest(file) {
  const formData = new FormData();
  formData.append('image', file);
  const emptyString = '{}';
  const requestString = new Blob([emptyString], {
    type: 'text/plain',
  });
  formData.append('request-body', requestString);
  return formData;
}

function selfieRequest(base64image) {
  const block = base64image.split(';');
  const realData = block[1].split(',')[1];
  const contentType = block[0].split(':')[1];
  const blob = b64toBlob(realData, contentType);
  return blob;
}

function getOtpRequest(
  activeForm,
  mobileNo,
  isdCode,
  otp,
  emailId,
  sourceType,
  userSourceId,
  sourceLink
) {
  const request = {};
  if (activeForm === 'mobile') {
    request.mobile = mobileNo;
    request.isd = isdCode;
    request.otp = otp;
    request.source = 'web';
    request.sourceType = sourceType;
    request.userSourceId = userSourceId;
    request.sourceLink = sourceLink;
  } else {
    request.email = emailId;
    request.otp = otp;
  }
  return request;
}

function setNoOfChildLength(num) {
  if (num.length > 2) {
    num = num.slice(0, 2);
    return num;
  } else {
    return num;
  }
}
function getRedirectUrl(responseData) {
  const referrerUrl = getReferrerUrl();
  if (responseData && responseData.forceRedirectURL) {
    return '/update-user-profile';
  } else if (
    responseData.nextScreen === 'intro-gender_verification-community_onboarding'
  ) {
    return urlMap.ONBOARDING_GLOBAL_LANGUAGE;
  } else if (responseData.nextScreen === 'questionnaire') {
    return urlMap.ONBOARDING_INTO_YOURSELF;
  } else if (
    responseData.nextScreen === 'feed' ||
    responseData.nextScreen === 'feed_blt'
  ) {
    return urlMap.FEED;
  } else if (responseData.nextScreen === 'language_selection') {
    return urlMap.ONBOARDING_LANGUAGE;
  } else if (responseData.nextScreen === 'intro-gender_verification') {
    return urlMap.ONBOARDING_GENDER;
  } else if (responseData.nextScreen === 'gender_verification') {
    return urlMap.ONBOARDING_GENDER_VERIFICATION;
  } else if (referrerUrl) {
    return referrerUrl;
  } else {
    return responseData.nextScreenUrl;
  }
}
function setNextScreenUrl(doc) {
  if (doc && doc.forceRedirectURL) {
    return '/update-user-profile';
  } else if (
    doc.next_screen === 'intro-gender_verification-community_onboarding'
  ) {
    return onboardingSteps[0];
  } else if (doc.next_screen === 'questionnaire') {
    return urlMap.ONBOARDING_INTO_YOURSELF;
  } else if (doc.next_screen === 'language_selection') {
    return urlMap.ONBOARDING_LANGUAGE;
  } else {
    return urlMap.FEED;
  }
}
function getUuid() {
  return localStorage.getItem('device_guid');
}

function selfieReqBody(base64image) {
  const block = base64image.split(';');
  const realData = block[1].split(',')[1];
  const contentType = block[0].split(':')[1];
  const blob = b64toBlob(realData, contentType);
  return blob;
}

// Managing redirection for home screen if onboarding is not done
function getHomeRedirectUrl(currentStepIndex) {
  if (currentStepIndex === -1) {
    return urlMap.ONBOARDING_GLOBAL_LANGUAGE;
  } else if (currentStepIndex === 0) {
    return urlMap.ONBOARDING_GENDER;
  } else if (currentStepIndex === 1) {
    return urlMap.ONBOARDING_GENDER_VERIFICATION;
  } else if (currentStepIndex === 2) {
    return urlMap.FEED;
  }
}
const initProfileButtons = {
  addFriend: false,
  pending: false,
  follow: false,
  following: false,
  confirm: false,
  sayHi: false,
};
const initMenuVisibility = {
  showCancelRequest: false,
  showUnFriend: false,
  showFollow: false,
  showUnFollow: false,
};
export {
  setMobileNoLength,
  getFirstAndLastName,
  getBase64Image,
  checkUserType,
  isAdminOrModerator,
  checkOpenAdminModalStatus,
  hideNotificationByCategory,
  getUserLinkUrl,
  getOtpParams,
  formRequest,
  selfieRequest,
  getOtpRequest,
  setNoOfChildLength,
  getRedirectUrl,
  setNextScreenUrl,
  getUuid,
  selfieReqBody,
  getHomeRedirectUrl,
  initProfileButtons,
  initMenuVisibility,
};
