import auth from '../services/authService';
import {
  urlMap,
  onboardingSteps,
  userDeactivatedUrl,
} from '../utils/constants';
import onboarding from '../services/onboardingService';

function getProtectedRedirectUrl(currentPath) {
  const token = auth.getUserToken();
  if (token === '') {
    auth.logout();
    auth.setReferrerUrl(window.location.href);
    return urlMap.HOME;
  }

  const deactivatedUser = auth.isUserDeactivated();
  if (deactivatedUser) return userDeactivatedUrl.USER_DEACTIVATED;

  const user = auth.getCurrentUser();
  if (!user) return urlMap.HOME;
  if (onboarding.getUserGender() === 'male') return urlMap.MALE_RESTRICTED; //if male user redirect user to male restricted page

  const isOnboardingDone = onboarding.getOnboardingStatus();
  if (!isOnboardingDone) return onboardingSteps[0]; //Send to onboarding if not done
  return null;
}

function getOnboardingRedirectUrl(currentPath) {
  const user = auth.getCurrentUser();
  if (!user) return urlMap.HOME;
  if (onboarding.getOnboardingStatus()) return urlMap.FEED;
  if (onboarding.getUserGender() === 'male') return urlMap.MALE_RESTRICTED;
  const currentStateIndex = onboardingSteps.indexOf(currentPath);
  const toGoState =
    currentPath === urlMap.ONBOARDING_GENDER
      ? currentStateIndex
      : onboarding.getCompletedStepIndex() + 1;
  //Forward to the next state if not already in that state
  return currentStateIndex === toGoState ? null : onboardingSteps[toGoState];
}

function getIsOnboardingUrl() {
  // if onboarding is present in url then it will return false to represent that user is still on onboarding
  const splitUrl = window.location.pathname.split('/');
  const isOnboarding = splitUrl[1] === 'onboarding';
  if (isOnboarding) {
    return false;
  } else {
    return true;
  }
}

export {
  getOnboardingRedirectUrl,
  getProtectedRedirectUrl,
  getIsOnboardingUrl,
};
