import apiUrl from './apiUrl';
import { default as _each } from 'lodash/each';

let selectedObj = [];
let remainingObj = [];

const dashboardMenuItem = [
  {
    url: '/shop-on-sheroes/sales-summary',
    icon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1630642977sales-summary.png',
    text: 'Sales Summary',
    isExternalUrl: false,
    isWebLink: false,
  },
  {
    url: '/shop-on-sheroes/shop-orders',
    icon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1630644453shop-order.png',
    text: 'Shop Orders',
    isExternalUrl: false,
    isWebLink: false,
  },
  {
    url: '/shop-on-sheroes/create-payment-link',
    icon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1630644423create-link.png',
    text: 'Create Payment Link',
    isExternalUrl: false,
    isWebLink: false,
  },
  {
    url: '/shop-on-sheroes/edit-shop',
    icon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1630644392edit-shop.png',
    text: 'Edit Shop',
    isExternalUrl: false,
    isWebLink: false,
  },
  {
    url: '/shop-on-sheroes/edit-bank-details',
    icon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1630643866edit-business.png',
    text: 'Edit Bank Details',
    isExternalUrl: false,
    isWebLink: false,
  },
  {
    url: 'https://shecobysheroes.com',
    icon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1630643926browse-sheco.png',
    text: 'Resell Branded SHECO Products',
    isExternalUrl: false,
    isWebLink: true,
  },
  {
    url: '/communities/shop-and-sheco-academy/NDY2MA==',
    icon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1630643977academy.png',
    text: 'SHOP and SHECO Academy',
    isExternalUrl: false,
    isWebLink: false,
  },
  {
    url: '/shop-on-sheroes/shop-guidelines',
    icon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1630643142shop-guidelines.png',
    text: 'Shop Guidelines',
    isExternalUrl: false,
    isWebLink: false,
  },
  {
    url: '/shop-on-sheroes/faq',
    icon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1630643838faq.png',
    text: "FAQ's",
    isExternalUrl: false,
    isWebLink: false,
  },
  {
    url: null,
    icon:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1630643769help.png',
    text: 'Help',
    isExternalUrl: true,
    isWebLink: false,
  },
];

const shareMsg =
  'Thank you for your interest in purchasing our products. When you are supporting a Woman Entrepreneur you are supporting A DREAM ❤:  You can make a purchase by clicking on the link below:';
const poweredBySheroes = 'Powered by SHEROES';
const shopGuidelineUrl =
  'https://web.sheroes.com/shop-on-sheroes-seller-guideline?header=0&footer=0';
const shopFaqUrl =
  'https://web.sheroes.com/shop-on-sheroes-faq?header=0&footer=0';

const shopBannedGuidelines =
  'https://web.sheroes.com/Home/seller_banned_guide?header=0&footer=0';

function getSelectedAddress(items, selectedId) {
  if (items && items.length > 0) {
    remainingObj = [];
    selectedObj = [];
    for (let i = 0; i < items.length; i++) {
      if (items[i].addressId === parseInt(selectedId)) {
        items[i].showDeliveryBtn = true;
        items[i] && selectedObj.push(items[i]);
      } else {
        items[i].showDeliveryBtn = false;
        remainingObj.push(items[i]);
      }
    }
    return [selectedObj, remainingObj];
  }
}

function getItem(items, selectedId) {
  let selectedItem = [];
  if (items && items.length > 0) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].addressId === parseInt(selectedId)) {
        selectedItem.push(items[i]);
      }
    }
    return selectedItem;
  }
}

function mapUserEditData(addressData, user) {
  return {
    name: addressData ? addressData.name : '',
    mobile: addressData ? addressData.mobile : '',
    email: addressData ? addressData.email : '',
    address_line1: addressData.fullAddress
      ? addressData.fullAddress
      : addressData.addressLine1,
    address_line2: addressData ? addressData.addressLine2 : '',
    pincode: addressData ? addressData.pinCode : '',
    user_id: user ? user.id : null,
    full_address: null,
    is_default_address: addressData ? addressData.isDefaultAddress : '',
    address_id: addressData ? addressData.addressId : '',
    address_type: 'HOME',
    is_active: true,
    is_recent_address: addressData ? addressData.isRecentAddress : '',
  };
}

function createRequest(obj, type) {
  if (type === 'delete') {
    return {
      address_id: obj.addressId,
      address_type: 'HOME',
      is_active: false,
      is_default_address: obj.isDefaultAddress,
      mobile: obj.mobile,
      name: obj.name,
      email: obj.email,
      pincode: obj.pinCode,
      address_line1: obj.addressLine1,
      address_line2: obj.addressLine2,
      fullAddress: '',
    };
  }
  if ((type = 'mark_default')) {
    return {
      address_id: obj.addressId,
      address_type: 'HOME',
      is_active: true,
      is_default_address: true,
      mobile: obj.mobile,
      name: obj.name,
      email: obj.email,
      pincode: obj.pinCode,
      address_line1: obj.addressLine1,
      address_line2: obj.addressLine2,
      fullAddress: '',
    };
  }
}

const deliveryStatus = [
  {
    imgUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1628764275nounorder371293.svg',
    text: 'Placed',
    name: 'OP',
  },
  {
    imgUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1628765440nounorder371293-1.svg',
    text: 'Confirmed',
    name: 'OA',
  },
  {
    imgUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1628765593nounshipping2526071.svg',
    text: 'Shipped',
    name: 'OS',
  },
  {
    imgUrl:
      'https://img.sheroes.in/img/uploads/sheroespage/logoes/1628765649shutterstock1987275233.svg',
    text: 'Delivered',
    name: 'OD',
  },
];
function orderDetailsDeepLink(doc) {
  if (!doc) return;
  const orderId = doc?.id;
  const url = '/shop-on-sheroes/order-details/' + btoa(orderId);
  return url;
}
function createCatalogueApiUrl(shopId, token, itemId) {
  const shopQuery = shopId && `shopId=${shopId}`;
  const tokenQuery = token && `&nextToken=${token}`;
  const itemQuery = itemId && `&itemId=${itemId}`;
  let catalogueAPI = `${apiUrl.GET_CATALOGUE}`;
  if (shopQuery) {
    catalogueAPI += `${shopQuery}`;
  }
  if (tokenQuery) {
    catalogueAPI += `${tokenQuery}`;
  }
  if (itemQuery) {
    catalogueAPI += `${itemQuery}`;
  }
  return catalogueAPI;
}

function setNextStage(currentStage) {
  if (currentStage === 'OP') {
    return 'OA';
  } else if (currentStage === 'OA') {
    return 'OS';
  } else if (currentStage === 'OS') {
    return 'OD';
  }
}
function getCatalogueTab(communityDetails) {
  const catalogueTab = communityDetails?.communityTabs.filter(
    (cd) => cd.key === 'catalogue'
  );
  return catalogueTab[0];
}

function mapMediaData(data, type) {
  const mediaObj = [];
  if (type === 'V') {
    _each(data, function (response) {
      const videoObj = {
        type: 'V',
        url: response.sourceVideoUrl,
        id: '',
        thumbnail: response.videoThumbnail,
        metaData: { ...response.videoMetaModel },
      };
      mediaObj.push(videoObj);
    });
  } else {
    _each(data, function (response) {
      const imageObj = {
        type: 'I',
        url: response.url,
        id: '',
        metaData: {
          duration: 0.0,
          width: response.width,
          height: response.height,
          frameCount: 0,
          fps: 0,
          orientation: '',
        },
      };
      mediaObj.push(imageObj);
    });
  }
  return mediaObj;
}
function updateRequest(request, getDeletedId, isEdit, isService, itemService) {
  if (!request) return;
  const imgObj = request?.sku?.mediaList.filter((media) => media.id === '');
  request.sku.mediaList = imgObj;
  if (isEdit) {
    request.sku.deletedMedia = [...getDeletedId];
  }
  if (isService || itemService) {
    request.type = 'S';
  } else {
    request.type = 'P';
  }
  return request;
}

function countMedia(mediaList) {
  let imageCount = 0;
  let videoCount = 0;
  mediaList &&
    mediaList.forEach((media) => {
      media.type === 'I' ? imageCount++ : videoCount++;
    });
  return { imageCount, videoCount };
}
function sortByaName(data) {
  return data.sort(function (a, b) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}
function sortAddress(data) {
  const sortName = sortByaName(data);
  const getDefaultAdd = getDefaultAddress(sortName);
  const filterData = removeDuplicateData(data, getDefaultAdd);
  getDefaultAdd &&
    getDefaultAdd.length > 0 &&
    filterData.unshift(...getDefaultAdd);
  return filterData;
}
function getDefaultAddress(data) {
  return data.filter((item) => item.isDefaultAddress === true);
}
function removeDuplicateData(data, defaultData) {
  const filterData =
    defaultData &&
    defaultData.length > 0 &&
    data.filter(
      (item) => item.name.toLowerCase() !== defaultData[0].name.toLowerCase()
    );
  return filterData;
}

function getCount(count) {
  if (count >= 0 && count < 1000) {
    return count;
  } else if (count >= 1000 && count < 1000000)
    return Math.floor(count / 1000) + 'K';
  else if (count >= 1000000) return Math.floor(count / 1000000) + 'M';
  return '';
}
export {
  getSelectedAddress,
  getItem,
  createRequest,
  mapUserEditData,
  deliveryStatus,
  orderDetailsDeepLink,
  dashboardMenuItem,
  shopGuidelineUrl,
  shopFaqUrl,
  shareMsg,
  poweredBySheroes,
  createCatalogueApiUrl,
  setNextStage,
  getCatalogueTab,
  mapMediaData,
  updateRequest,
  countMedia,
  getDefaultAddress,
  sortAddress,
  shopBannedGuidelines,
  getCount,
};
