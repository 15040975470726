import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AnyLink from '../common/anyLink';

const ArticleDetailHeader = ({ post, languageText }) => {
  const classes = useStyles();

  return (
    <Box px={4} py={2}>
      <Typography
        gutterBottom
        variant="subtitle2"
        color="textPrimary"
        component="h1"
        className={classes.articleHeading}
      >
        {post.title}
      </Typography>
      <Box>
        <Typography variant="body1" color="textSecondary" component="span">
          {languageText?.PUBLISHED_ON} &nbsp;
          {post.author && post.createdAt} &nbsp;
        </Typography>
        <Typography variant="body1" color="textSecondary" component="span">
          <FiberManualRecordIcon className={classes.dotIcon} />
          &nbsp;{post.charCount} {languageText?.ID_MIN_READ}
        </Typography>
      </Box>
      <AnyLink to={post.articleCategoryUrl}>
        <Typography component="span" className={classes.articleCategory}>
          {post.articleCategoryName}
        </Typography>
      </AnyLink>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  articleHeading: {
    fontSize: '24px',
    lineHeight: '34px',
  },
  dotIcon: {
    fontSize: '5px',
    verticalAlign: 'middle',
    color: theme.palette.secondary.main,
  },
  articleCategory: {
    marginTop: theme.spacing(3),
    background: '#f1f1f1',
    padding: '4px 8px',
    width: 'auto',
    borderRadius: theme.spacing(3),
    display: 'inline-block',
  },
}));

export default ArticleDetailHeader;
