import dayjs from 'dayjs';
const DAY_FIRST_FORMAT = 'DD MMM YYYY HH:mm';
const DATE_FIRST_FORMAT = 'DD-MM-YYYY';
const YEAR_FIRST_FORMAT = 'YYYY-MM-DDThh:mm:ss';
const DATE_FIRST_FORMAT_WITHOUT_TIME = 'DD MMM YYYY';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function convertDaysToGMTString(days) {
  var date = new Date();
  // Get unix milliseconds at current time plus number of days
  date.setTime(+date + days * 86400000); //24 * 60 * 60 * 1000

  return date.toGMTString();
}
function convertDateToString(date) {
  if (!date) return '';
  return dayjs(date).format('YYYY-MM-DD');
}
function convertDateToUTC(date) {
  const iSODate = dayjs(date).toISOString();
  return iSODate;
}
function getTodayDate(format) {
  return dayjs().format(format);
}
function getDateFormatByValue(date, format) {
  return dayjs(date).format(format);
}
function getDateFromTimeStamp(timestamp) {
  var date = new Date(timestamp);
  return dayjs(date).format('D-MMMM-YYYY, h:mm A');
}

function getCurrentMonthName() {
  return monthNames[dayjs().month()];
}

function getPreviousMonthName() {
  return monthNames[dayjs().month() - 1];
}

function getDateTimeToMilliseconds() {
  const date = new Date();
  return date.getMilliseconds();
}

function dateFromTimeStamp(timestamp) {
  var date = new Date(timestamp);
  return dayjs(date).format('D MMMM YYYY, h:mm A');
}

function getDobMaxRange() {
  var date = new Date();
  let year = date.getFullYear() - 13;
  let day = date.getDate();
  let month = date.getMonth() + 1;
  if (month < 10 && day < 10) {
    return `${year}-0${month}-0${day}`;
  } else if (month < 10) {
    return `${year}-0${month}-${day}`;
  } else if (day < 10) {
    return `${year}-${month}-0${day}`;
  } else {
    return `${year}-${month}-${day}`;
  }
}

function getDobMinRange(value) {
  const year = value && value.split('-')[0];
  return year >= 1900;
}

export {
  convertDaysToGMTString,
  convertDateToString,
  convertDateToUTC,
  getTodayDate,
  getDateFormatByValue,
  DAY_FIRST_FORMAT,
  YEAR_FIRST_FORMAT,
  getDateFromTimeStamp,
  getCurrentMonthName,
  getPreviousMonthName,
  DATE_FIRST_FORMAT,
  getDateTimeToMilliseconds,
  dateFromTimeStamp,
  DATE_FIRST_FORMAT_WITHOUT_TIME,
  getDobMaxRange,
  getDobMinRange,
};
