import React from 'react';
import { Typography, Box, makeStyles } from '@material-ui/core';
import PostActionButton from '../postActionButton';
import LikeIcon from '../../../img/heart.svg';
import LikedIcon from '../../../img/heart-liked.svg';
import CommentIcon from '../../../img/comment.svg';

const CommentLike = ({
  isShowLike,
  likesCount,
  isLiked,
  likeOnComment,
  likeStatus,
  isShowViewReply,
  comment,
  openReplies,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.countSet}>
      {isShowLike && (
        <PostActionButton
          text={likesCount > 0 ? likesCount : ''}
          icon={
            isLiked ? (
              <img src={LikedIcon} alt="LikedIcon" width="14px" height="12px" />
            ) : (
              <img src={LikeIcon} alt="LikeIcon" width="14px" height="12px" />
            )
          }
          handleAction={(e) => likeOnComment(e, comment.commentId, likeStatus)}
          activeStatus={isLiked}
        />
      )}
      {isShowViewReply && (
        <PostActionButton
          icon={<img src={CommentIcon} alt="CommentIcon" width="15px" />}
          handleAction={(e) => openReplies(e, comment.commentId)}
        />
      )}
      <Typography variant="caption" color="textSecondary" component="p">
        {comment.createdAt}
      </Typography>
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  countSet: {
    padding: theme.spacing(1.5, 0),
    display: 'flex',
    alignItems: 'center',
    '& button': {
      position: 'relative',
      '&::after': {
        content: '"."',
        display: 'inline-block',
        padding: theme.spacing(0, 1.5),
        fontSize: theme.spacing(5),
        position: 'relative',
        top: '-5px',
      },
      '&:last-child': {
        '&::after': {
          display: 'none',
        },
      },
    },
  },
}));
export default CommentLike;
