import http from './httpService';
import APIURL from '../utils/apiUrl';
import { userMap, userDetailsMap, mapBadgeData } from '../mappers/userMapper';
import { entityMasterMap } from '../mappers/entityMasterMapper';
import { getPostLanguage, processLoginResponse } from '../services/authService';
import { getOtpParams } from '../utils/userUtil';
import { getUserToken } from './authService';
import createRequestUrl from './profileService';
import language from '../utils/languages';
const webLanguage = getPostLanguage();
const languageText = language[webLanguage ? webLanguage : 'en'];

export async function updateUserDOB(dob, setToastMessage) {
  try {
    const { data } = await http.post(APIURL.USER_ADD_EDIT, {
      source: 'web',
      type: 'BASIC_PROFILE',
      subType: 'BASIC_USER_PROFILE_SERVICE',
      dob: dob,
    });
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}
export async function getUserDetailsByToken(token, clientSource) {
  http.setUserToken(token);
  try {
    const { data } = await http.post(APIURL.USER_DETAIL_URL, { clientSource });

    const response = userMap(data);
    return response;
  } catch (ex) {
    return null;
  }
}

export async function getUserDetails(userId) {
  const requestBody = {
    id_of_entity_or_participant: userId,
    sub_type: 'U',
  };
  const { data } = await http.post(APIURL.GET_POST, requestBody);
  const response = data?.docs[0] && userDetailsMap(data.docs[0]);
  return response;
}

export async function updateUserSummary(summary, setToastMessage) {
  try {
    const { data } = await http.post(APIURL.USER_ADD_EDIT, {
      source: 'web',
      type: 'SUMMARY',
      subType: 'USER_SUMMARY_SERVICE',
      summary: summary,
    });
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}
export async function updateUserAdditionalInfo(
  mobile,
  maritalStatus,
  dob,
  setToastMessage
) {
  try {
    const { data } = await http.post(APIURL.USER_ADD_EDIT, {
      source: 'web',
      type: 'ADD_INFO',
      subType: 'ADDITIONAL_INFO_SERVICE',
      mobile: mobile,
      marital_status: maritalStatus,
      dob: dob,
    });
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}
export async function getCityMaster() {
  const { data } = await http.post(APIURL.GET_ENTITY_MASTER_DATA, {
    source: 'web',
    master_data_type: 'city',
    q: '',
    page_no: 1,
    page_size: 20,
    test_user_type: 'organisations_test_user',
  });
  return entityMasterMap(data.docs);
}

export async function getUserDeactivationReasons() {
  const { data } = await http.post(APIURL.USER_DEACTIVATION_REASONS);
  return data.deactivationReason;
}

async function userDeactivate(userId, reasonId, reasonText, setToastMessage) {
  try {
    const reqObj = {
      user_id: userId,
      deactivation_reason: reasonId,
      modify_posts: true,
      modify_comments: true,
      reason_for_inactive: reasonText,
    };
    const { data } = await http.post(APIURL.USER_DEACTIVATION, reqObj);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}

async function userReactivate(userId, setToastMessage) {
  try {
    const reqObj = {
      user_id: userId,
      modify_posts: true,
      modify_comments: true,
      reason_for_inactive: 'reasonText',
      reactivate: true,
    };
    const { data } = await http.post(APIURL.USER_DEACTIVATION, reqObj);

    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}

async function getOtp(type, request) {
  const OtpUrl = getOtpParams(type, request);
  const data = await fetch(process.env.REACT_APP_API_URL + OtpUrl, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return data;
}

async function validateOtp(request, history, state, setToastMessage) {
  let validateUrl = '';
  if (state && state.community_ids) {
    validateUrl = await createRequestUrl(state);
  } else {
    validateUrl = APIURL.VALIDATE_OTP;
  }
  http.setUserToken(null);
  const data = await fetch(process.env.REACT_APP_API_URL + validateUrl, {
    method: 'POST',
    body: JSON.stringify(request),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

  if (data.status === 'SUCCESS') {
    data && http.setUserToken(data.token);
    return processLoginResponse(data, 'otp', history, setToastMessage);
  }
  return data;
}

async function updateUserConsent(request, setToastMessage) {
  let requestData = [];
  requestData.push(request);
  try {
    const { data } = await http.post(APIURL.UPDATE_USER_CONSENT, requestData);
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      return data;
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
      return null;
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
      return null;
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
      return null;
    }
  }
}

async function getPayTmAccessToken(authId) {
  //let config = { headers: { 'User-Agent': 'SheroesSDK/1.0.0/Android/9.0.0' } };
  const { data } = await http.post(APIURL.PAYTM_ACCESS_TOKEN, {
    code: authId,
  });
  return data;
}
async function getUserInfo(accessToken) {
  const { data } = await http.get(APIURL.PAYTM_GET_USER_INFO + accessToken);
  return data;
}

async function markGender(userId, isFemale) {
  const { data } = await http.post(APIURL.MARK_GENDER, {
    userId: userId,
    isFemale: isFemale,
  });
  return data.docs;
}

async function getSuperSheroesBadges(endPointUrl, request, type) {
  const { data } = await http.post(endPointUrl, request);
  return mapBadgeData(data.user_badges_list, type);
}

async function updateUserLanguage(language, reqData) {
  const token = getUserToken();
  const data = await fetch(
    process.env.REACT_APP_API_URL + APIURL.SAVE_PUSH_TOKEN,
    {
      method: 'POST',
      body: JSON.stringify(reqData),
      headers: {
        Authorization: token,
        'X-Language': language,
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
  return data;
}
async function updateDeviceData(request) {
  const { data } = await http.post(APIURL.SAVE_PUSH_TOKEN, request);
  return data;
}

async function addPreferences(language, user_id) {
  const token = getUserToken();
  const data = await fetch(
    process.env.REACT_APP_API_URL + APIURL.USER_PREFERENCES,
    {
      method: 'POST',
      body: JSON.stringify({ language, user_id }),
      headers: {
        Authorization: token,
        'X-Language': language,
        'Content-Type': 'application/json',
      },
    }
  )
    .then((res) => res.json())
    .then((response) => {
      return response;
    });
  return data;
}
export default {
  updateUserDOB,
  getUserDetailsByToken,
  getUserDetails,
  updateUserSummary,
  updateUserAdditionalInfo,
  getCityMaster,
  getUserDeactivationReasons,
  userDeactivate,
  userReactivate,
  getOtp,
  validateOtp,
  updateUserConsent,
  getPayTmAccessToken,
  getUserInfo,
  markGender,
  getSuperSheroesBadges,
  updateUserLanguage,
  updateDeviceData,
  addPreferences,
};
