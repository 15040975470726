export default {
  SIGN_IN_BY_EMAIL: '/participant/auth/signin',
  FEED_STREAM_URL: '/participant/feed/stream',
  USER_DETAIL_URL: '/participant/user/getUserSummarry',
  POST_BOOKMARK_URL: '/participation/reaction/bookmark',
  POST_UNBOOKMARK_URL: '/participation/reaction/unbookmark',
  BOOKMARK_STREAM_URL: '/participant/feed/stream?setOrderKey=BookmarkStream',
  BLOCKED_USER_URL: '/participant/user/block',
  UNBLOCKED_USER_URL: '/participant/user/unblock',
  BLOCKED_ACCOUNTS_LIST_URL: '/participant/user/getBlocked',
  POST_LIKE_URL: '/participation/reaction/like',
  POST_UNLIKE_URL: '/participation/reaction/unlike',
  SIGN_IN_BY_GOOGLE: '/participant/user/gpsignup',
  SIGN_IN_BY_FACEBOOK: '/participant/user/fbsignup',
  GET_COMMUNITY_INTEREST: '/entity/master/get_interests',
  JOIN_COMMUNITY_INTEREST: '/participant/community/join/interest',
  USER_ADD_EDIT: '/participant/user/add_or_edit',
  BELL_NOTIFICATION: '/participation/notification/bell',
  BELL_NOTIFICATION_COUNT: '/participation/notification/bell/unread',
  GET_POST: '/participant/feed/',
  REPOST: '/participation/reaction/repost',
  UNREPOST: '/participation/reaction/unrepost',
  DELETE_POST: '/participation/post/delete',
  SAVE_COMMENT: '/participation/reaction/add_comment',
  GET_POST_COMMENTS: '/participation/reaction/get_comments',
  DELETE_POST_COMMENT: '/participation/reaction/delete_comment',
  MY_COMMUNITIES: '/participant/feed/my_communities',
  MUTUAL_COMMUNITIES: '/participant/feed/mutual_communities',
  CREATE_POST: '/participation/post/add_post_with_multipart_image',
  EDIT_POST: '/participation/post/edit_post_with_multipart_image',
  APP_CONFIG: '/participant/remote_config/?config_type=AppConfig',
  REPORT_SPAM: '/participation/post/spam_report',
  SUBMIT_USER_POLL: '/participation/reaction/poll/vote',
  GET_USER_POLL: '/participant/feed/stream?setOrderKey=PollStream&poll_id=',
  SAVE_PUSH_TOKEN: '/participant/user/device_details', //'/participant/user/update_user_device',
  COMMUNITY_CATEGORY: '/participant/feed/community_category_home',
  JOIN_COMMUNITY: '/participant/community/join',
  UNJOIN_COMMUNITY: '/participant/community/unjoin',
  COMMUNITIES_BY_TYPE_ID:
    '/participant/feed/community_category_listing?sub_type=C&community_type_id=',
  FOLLOW: '/participation/reaction/follow',
  UNFOLLOW: '/participation/reaction/unfollow',
  GET_USER_POST:
    '/participant/feed/stream?setOrderKey=ProfilePostStream&userId=',
  GET_BRANCH_LINK_DATA: 'https://api2.branch.io/v1/url?url=',
  GET_ALL_THEME: '/participation/post/get_themes',
  GET_ENTITY_MASTER_DATA: '/entity/master/get_data',
  APPROVE_POST: '/participation/post/approve_or_delete',
  // IMPRESSION_API_URL: '/user/event/producer',
  USER_DEACTIVATION_REASONS:
    '/participant/user/fetch_user_deactivation_reasons',
  USER_DEACTIVATION: '/participant/user/deactivate_or_reactivate',
  GET_ALL_CATEGORIES: '/entity/master/all_data',
  GET_ARTICLES: '/participant/feed/stream?setOrderKey=ArticleStream',
  GET_TRENDING_ARTICLES: '/participant/feed/trending_article',
  GET_SIMILAR_CONTENTS: '/participant/feed/similar_contents',
  GET_TRENDING_HASHTAGS: '/participant/search/getHashtags',
  NOTIFICATION_MARK_AS_READ:
    '/participation/notification/update_read_notifications',
  FEATURE_POST: '/participation/post/feature_post_creator',
  GET_USER_MENTION: '/entity/master/user_mention_suggestions',
  LINK_RENDER: '/participation/link/render',
  GET_REPLY_COMMENT: '/participation/reaction/get_reply_comments',
  GET_REACTIONS: '/participation/reaction/get_reactions',
  GET_OTP: '/participant/user/verification/request',
  VALIDATE_OTP: '/participant/user/verification/validate',
  SUBMIT_SELFIE: '/participant/user/verification/infer-gender',
  MARS_USER_PROFILE: '/mars/profile/marsusers',
  MARS_SECTION_QUESTION: '/mars/exam/questions?section=',
  MARS_ANSWER_SUBMIT: '/mars/exam/answers',
  UPDATE_USER_CONSENT: '/participant/user/update_user_consent',
  PAYTM_ACCESS_TOKEN: '/participant/user/paytmAccessToken',
  PAYTM_GET_USER_INFO: '/participant/user/v2/PaytmUserSignUp?code=',
  MARK_GENDER: '/participant/user/verification/mark/gender',
  UPLOAD_IMAGE: '/participation/post/upload_multiple_image',
  UPLOAD_VIDEO: '/participation/post/upload_video',
  EXPLORE_MEDIA_STREAM: '/participant/feed/stream?setOrderKey=MediaStream',
  EDIT_USER_PROFILE: '/participant/user/editProfile',
  SUPER_SHEROES_BADGE: '/participant/user/badges',
  MILESTONES_BADGE: '/participant/user/milestones',
  DASHBOARD_CAROUSEL:
    '/participant/feed/stream?setOrderKey=ShopDashboardCarouselStream',
  GET_USER_DETAILS_NEW: '/participant/user/details',
  GWW_STREAM_URL: '/participant/feed/stream?setOrderKey=GwwBltStream',
  LECTURE_DETAILS: '/participation/course/chapter/lecture/details?lectureId=',
  UPDATE_QUIZ_STATUS: '/participation/course/chapter/updateQuizStatus',
  COURSE_PROGRESS: '/participation/course/progress?courseId=',
  FACILITATOR_DASHBOARD_DETAILS:
    '/participant/usercoordinator/facilitatorDashboardDetails?dataType=',
  MENTOR_DASHBOARD_DETAILS:
    '/participant/usercoordinator/mentorDashboardDetails?dataType=',
  UPDATE_VIDEO_STATUS: '/participation/course/chapter/updateVideoStatus',
  ADD_LECTURE_COMMENT: '/participation/reaction/addLectureComment',
  ADD_FRIEND: '/participation/friend/request/send',
  ACCEPT_FRIEND: '/participation/friend/request/accept',
  FRIEND_LIST: '/participation/friend/?user_id=',
  FOLLOWER_LIST: '/participant/feed/followed_mentor_list',
  GET_COMMENT_BY_ID: '/participation/reaction/get_comments_by_id?id=',
  APP_MENU: '/participant/appmenu/',
  VIEW_CERTIFICATE: '/participation/course/viewCertificate',
  UPLOAD_MULTIPLE_IMAGE:
    '/participation/post/upload_multiple_image_admin_panel',
  MARS_USER_STATUS: '/mars/profile/details',
  EDIT_COMMENT_POST: '/participation/reaction/edit_comment',
  USER_PREFERENCES: '/participant/user/preferences',
  PEOPLE_STREAM_URL: '/participant/feed/stream?setOrderKey=PeopleCarousel',
  CANCEL_FRIEND_REQUEST: '/participation/friend/request/cancel',
  UNFRIEND_REQUEST: '/participation/friend/remove',
  POLL_POST: '/participation/poll/add',
  GLOBAL_IMAGE_ADD: '/participation/global/image/add',
  VIDEO_POST: '/participation/post/video',
  EDIT_VIDEO_POST: '/participation/post/video/edit',
  ALL_COMMUNITIES: '/participant/feed/all_communities',

  // GET_USER_DETAILS: '/participant/user/get_details',
  // USER_ADDRESS_DETAILS: '/participant/user/user_address_details',
  // OLD_CREATE_POST_URL: 'https://services.sheroes.in/participation/post/add',
  // PAYTM_FETCH_FEED: '/participant/feed/stream?setOrderKey=paytmUsers',
  // PAYTM_PARTNER_AUTH: '/participant/user/partnerauth',
  // COMMUNITY_SHOP_ABOUT: '/participant/community/about?community_id=',
  // PRODUCT_SHOP_POST_URL:
  //  'https://dashboard.sheroes.com/api/queries/5370/results.json?api_key=efsUK0pSUvhxyYGRYKcZUPXoHxPMf6R1XAfwuXwI',
  // SHOP_FEED: '/participant/feed/stream?setOrderKey=BazaarStream',
  // SHOP_MEDIA_STREAM: '/participant/feed/stream?setOrderKey=ShopMediaStream',
  // GET_BUSINESS_TYPE: '/participant/shop/get_business_type',
  // GET_BUSINESS_DETAIL: '/participant/shop/business_detail',
  // CREATE_BUSINESS: '/participant/shop/create_business',
  // EDIT_BUSINESS: '/participant/shop/edit_business',
  // GET_CATALOGUE: '/participation/shop/catalogue/getCatalogue?',
  // ADD_BANK_DETAILS: '/participant/shop/add_bank_account',
  // GET_BANK_ACCOUNT: '/participant/shop/bank_account_details',
  // ADD_PRODUCT_ITEM: '/participation/shop/catalogue/addItem',
  // SALES_SUMMARY: '/participant/shop/salesSummary?userType=seller',
  // GET_SELLER_SHOP_ORDER: '/participant/shop/orderHistory?userType=seller',
  // GET_SELLER_ORDER_SUMMARY:
  // '/participant/shop/orderHistory?userType=seller&orderId=',
  // GENERATE_PAYMENT_LINK: '/participant/shop/generate_payment_link',
  // SHARE_TO_FEED: '/participation/shop/catalogue/shareToFeed',
  // UPDATE_ORDER_STATE: '/participant/shop/update/orderState',
  // SHOP_INIT_PAYMENT: '/participant/shop/init_payment',
  // ADD_UPDATE_USER_ADDRESS: '/participant/user/add_update_user_address',
  // CONFIRM_ORDER_DETAIL: '/participant/shop/order_detail',
  // GET_SHOP_LIST: '/participant/feed/stream?setOrderKey=ShopStream',
  // GET_ORDER_HISTORY: '/participant/shop/orderHistory?userType=buyer',
  // GET_ORDER_SUMMARY: '/participant/shop/orderHistory?userType=buyer&orderId=',
  // CONFIRM_PAYMENT_ORDER: '/participant/shop/confirm_payment_link_order',
  // GET_SHOP_PRODUCT_DETAIL: '/participation/post/get_product_detail',
  // GET_ALL_SHOPS_LIST: '/participant/feed/all_businesses',
  // GET_ENTITY_MASTER_LOCATION_DATA:
  // '/entity/master/get_location?&page_no=1&page_size=400',
  // GET_ALL_LOCATIONS: '/entity/master/get_location?&page_no=1&page_size=400',
  // SUMO_BAR: '/participation/global/image/get_sumo_bar',
};
