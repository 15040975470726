import { default as _map } from 'lodash/map';
import { getSlug } from '../utils/textUtil';
import { basePostMap } from './modelMapper';
import { imageUrls, userDeactivatedUrl, urlMap } from '../utils/constants';
import { getUserLinkUrl, setNextScreenUrl } from '../utils/userUtil';
import { stripDomain, getUserProfileLink } from '../utils/postUtil';
import { getDateFormatByValue } from '../utils/dateUtil';
import { getRemoteConfig } from '../services/remoteConfigService';
import authService from '../services/authService';
import { stripMenuDomain } from '../hooks/menuHooks';

const appConfig = getRemoteConfig();
const userTypeMap = {
  1: 'Customer',
  2: 'Admin',
  3: 'Company',
  4: 'Content Writer',
  7: 'Mentor',
  9: 'Campus Sheroes',
  10: 'Public Profile',
  11: 'Sheroes BlogService',
  12: 'MARS Project Manager',
};

function userProfileMap(doc) {
  return {
    id: doc.id,
    userTypeId: doc.userTypeId,
    noOfChildren: doc.noOfChildren,
    userType: userTypeMap[doc.userTypeId],
    image: doc.photoUrlPath ? doc.photoUrlPath : imageUrls.DEFAULT_USER_IMG,
    name: doc.name,
    isActive: doc.isActive,
    firstName: doc.first_name,
    lastName: doc.last_name,
    gender: doc.gender,
    isNewUser: doc.is_new_user,
    nextScreen: doc.next_screen,
    token: doc.token,
    mobileNumber: doc.mobile,
    emailId: doc.email_id,
    createdDate: doc.crdt,
    dateOfBirth: doc.dob,
    status: doc.status,
    isOnboardingDone: true,
    location: doc.location,
    cityMasterId: doc.cityMasterId,
    summary: doc.userSummary,
    maritalStatus: doc.maritalStatus,
    userParticipantId: doc.participantId,
    userProfileBranchLink: doc.shortBranchUrl,
  };
}
function userDetailsMap(doc) {
  const profileLink = stripDomain(
    doc.solr_ignore_deep_link_url
      ? doc.solr_ignore_deep_link_url
      : doc.solr_ignore_deep_link_url
  );
  return {
    imageUrl: doc.image_url,
    name: doc.name,
    participantId: doc?.id_of_entity_or_participant,
    totalPost: doc.solr_ignore_total_no_of_post_created,
    followersCount: doc.solr_ignore_no_of_followers,
    followingCount: doc.solr_ignore_no_of_following,
    friendsCount: doc.solr_ignore_friends_count,
    description: doc.list_description,
    location: doc.location_s,
    isBlockedProfile: doc && doc.available === 'FB',
    badgesList: userBadgesMap(doc.solr_ignore_user_badges_list),
    mileStonesBadgesList: userBadgesMap(
      doc.solr_ignore_user_badges_and_milestones_list
    ),
    showProfileBadge: doc.solr_ignore_show_profile_badge,
    profileBadgeUrl: doc.solr_ignore_profile_badge_url,
    typeId: doc.entity_or_participant_type_id_i,
    shareProfileInfo: {
      text: appConfig && appConfig.profileShareText,
      url: doc.short_branch_url_s,
      title: 'SHEROES',
    },
    isActive: doc.p_is_active,
    userProfileLink: profileLink,
    userBadgeUrl: '/badges' + profileLink,
    shortBranchUrl: doc.short_branch_url_s,
    friendListUrl: '/friend-list/' + btoa(doc.id_of_entity_or_participant),
    followersListUrl:
      doc &&
      '/users/' +
        getSlug(doc.name) +
        '/' +
        btoa(doc.id_of_entity_or_participant) +
        '/followers-list',
    followingsListUrl:
      doc &&
      '/users/' +
        getSlug(doc.name) +
        '/' +
        btoa(doc.id_of_entity_or_participant) +
        '/following-list',
    messageDetailUrl:
      doc &&
      '/users/' +
        getSlug(doc.name) +
        '/' +
        btoa(doc.id_of_entity_or_participant) +
        '/message',
    championsListUrl:
      doc &&
      '/users/' +
        doc.name.replace(' ', '-') +
        '/' +
        btoa(doc.id_of_entity_or_participant) +
        '/champions-list',
    isRequestSent: doc && doc.solr_ignore_is_friend_request_sent_to,
    receivedFriendRequestId: doc && doc.solr_ignore_received_friend_request_id,
    isFriend: doc && doc.solr_ignore_is_friend,
    participantTypeId: doc && doc.entity_or_participant_type_id_i,
    isRequestReceived: doc && doc.solr_ignore_is_friend_request_received_from,
    userDeeplinkUrl: doc && doc.solr_ignore_user_deep_link_url,
    sheroesDeeplink: doc && doc.sheroes_deep_link_s,
    mentorFollowersCount: doc && doc.solr_ignore_no_of_mentor_followers,
    mentorDeeplinkUrl: doc && doc.solr_ignore_mentor_deep_link_url,
    isAuthorMentor:
      doc &&
      (doc.solr_ignore_is_author_mentor ||
        doc.entity_or_participant_type_id_i === 7),
    isFollowed: doc && doc.solr_ignore_is_user_followed,
    isAuthorFriend: doc && doc.solr_ignore_is_author_friend,
    mutualFriendsInfo: doc && doc.solr_ignore_mutual_friends_info,
    profileString: doc && doc.solr_ignore_custom_profile_string,
  };
}

function userMap(doc) {
  //gender_verification-community_onboarding
  let isOnboardingDone = false;
  if (
    doc.next_screen === 'intro-gender_verification-community_onboarding' ||
    doc.next_screen === 'intro-gender_verification' ||
    doc.next_screen === 'gender_verification'
  ) {
    isOnboardingDone = false;
  } else {
    isOnboardingDone = true;
  }
  if (!doc)
    return {
      image: imageUrls.DEFAULT_USER_IMG,
    };
  return {
    id: doc.user_summary.user_id,
    userTypeId: doc.user_summary.user_details.userTypeId,
    noOfChildren: doc.user_summary.user_details.noOfChildren,
    userType: userTypeMap[doc.user_summary.user_details.userTypeId],
    image: doc.user_summary.photo_url
      ? doc.user_summary.photo_url
      : imageUrls.DEFAULT_USER_IMG,
    name: doc.user_summary.user_details.name,
    isActive: doc.user_summary.user_details.isActive,
    firstName: doc.user_summary.first_name,
    lastName: doc.user_summary.last_name,
    gender: doc.gender,
    isNewUser: doc.is_new_user,
    nextScreen: doc.next_screen,
    token: doc.token,
    mobileNumber: doc.user_summary.user_details.mobile,
    emailId: doc.user_summary.email_id,
    createdDate: doc.user_summary.user_details.crdt,
    dateOfBirth: doc.user_summary.user_details.dob,
    status: doc.status,
    nextScreenUrl: setNextScreenUrl(doc),
    isOnboardingDone: isOnboardingDone,
    userProfileLink: getUserProfileLink(
      doc.user_summary.user_details.name,
      doc.user_summary.user_details.id
    ),
    location: doc.user_summary.user_details.location,
    cityMasterId: doc.user_summary.user_details.cityMasterId,
    summary: doc.user_summary.user_details.userSummary,
    maritalStatus: doc.user_summary.user_details.maritalStatus,
    userParticipantId: doc.user_summary.user_details.participantId,
    communityLang: doc.communityLang,
    forceRedirectURL: doc.forceRedirectURL,
    hashTagForNextEvent: doc.hashTagForNextEvent,
    nextActivityEvent: doc.nextActivityEvent,
    isPopupOpen: false,
    userProfileBranchLink: doc.user_summary.user_profile_branch_url,
    isTourComplete: doc.is_new_user ? false : true,
  };
}
/**
 * This mapper  is used for cross domain/sub domain mapping
 */
function getUserInfoMap(doc) {
  return {
    id: doc.id_of_entity_or_participant,
    userTypeId: doc.entity_or_participant_type_id_i,
    userImage: doc.image_url,
    name: doc.name,
    isActive: doc.p_is_active,
    gender: doc.gender_s,
    status: doc.status,
    nextScreenUrl: urlMap.FEED,
    isOnboardingDone: true,
  };
}
/**
 *
 * @param {In} userType
 * set user profile from user type
 */
function mapUserType(userType) {
  if (userType === 7) {
    return '/champions';
  } else {
    return '/users';
  }
}
function userErrorMap(doc) {
  if (doc.status === 'FAILED' && doc.fieldErrorMessageMap !== null) {
    const errorMessageMap = doc.fieldErrorMessageMap;
    return {
      status: doc.status,
      message: errorMessageMap.INVALID_DATA,
      isDeactivated: errorMessageMap.IS_DEACTIVATED,
      nextScreenUrl: userDeactivatedUrl.USER_DEACTIVATED,
    };
  }
}
function userBadgesMap(doc) {
  //const badgeList = doc.solr_ignore_user_badges_list;
  return (
    doc &&
    doc.map((list) => ({
      badgeName: list.name,
      ...list,
    }))
  );
}
function mapUserMention(users) {
  const mappedUser = [];
  users &&
    _map(users, function (user) {
      const userLinkUrl = getUserLinkUrl(user);
      const data = { ...user, userLinkUrl };
      mappedUser.push(data);
    });
  return mappedUser;
}
function mentionUsersMap(data) {
  const users =
    data &&
    data.splice(0, 10).map((user) => ({
      id: user?.user_id,
      display: user?.name,
      ...user,
    }));
  return users;
}
function mapBadgeData(docs, type) {
  return (
    docs &&
    docs.map((item) => {
      return {
        id: item.id,
        name: item.name,
        badgeSubTitle:
          'For becoming Super SHEROES in <b>' + item.communityName + '</b>',
        wonTitle:
          type === 'S'
            ? 'Won : ' +
              getDateFormatByValue(item.latestStartDate, 'DD') +
              ' - ' +
              getDateFormatByValue(item.latestEndDate, 'DD') +
              ' ' +
              getDateFormatByValue(item.latestEndDate, 'MMM') +
              ' ' +
              getDateFormatByValue(item.latestEndDate, 'YYYY') +
              ',' +
              item.count +
              ' Times'
            : '',
        type: type,
        ...item,
      };
    })
  );
}

function mentorMap(docs) {
  const chapterObj = {
    subType: 'MD',
    answeredQC: docs.answeredQC,
    status: docs.status,
  };
  return basePostMap(docs, chapterObj);
}

function isCurrentUserAuthor(doc, postType) {
  const user = authService.getCurrentUser();
  if (!doc || !user) return;
  const authorId = postType === 'comment' ? doc.participant_id : doc.author_id;
  const userId =
    postType === 'comment' ? user && user.userParticipantId : user && user.id;
  return authorId === userId;
}

function menuMapper(doc) {
  return {
    menuType: doc && doc.menu_type,
    menuUrl:
      doc && doc.menu_key === 'community_guidelines'
        ? '/community-guidelines'
        : doc && doc.menu_key === 'my_bookmarks'
        ? '/my-bookmarks'
        : doc && stripMenuDomain(doc.menu_url),
    isActive: doc && doc.is_active,
    isNative: doc && doc.is_native,
    isNew: doc && doc.is_new,
    menuCategory: doc && doc.menu_category,
    menuDesc: doc && doc.menu_desc,
    menuId: doc && doc.menu_id,
    selectedIconUrl: doc && doc.menu_item_icon_selected_url,
    selectedSvgIconUrl: doc && doc.menu_item_icon_selected_url_svg,
    iconUrl: doc && doc.menu_item_icon_url,
    svgIconUrl: doc && doc.menu_item_icon_url_svg,
    menuKey: doc && doc.menu_key,
    menuName: doc && doc.menu_name,
  };
}
export {
  userProfileMap,
  userDetailsMap,
  userMap,
  getUserInfoMap,
  userErrorMap,
  mapUserType,
  mapUserMention,
  mentionUsersMap,
  mapBadgeData,
  userTypeMap,
  mentorMap,
  isCurrentUserAuthor,
  menuMapper,
};
