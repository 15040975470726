import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import PostActionButton from './postActionButton';
import LikeIcon from '../../img/heart.svg';
import LikedIcon from '../../img/heart-liked.svg';
import CommentIcon from '../../img/comment.svg';
import RepostedIcon from '../../img/reposted.svg';
import RepostIcon from '../../img/repost.svg';
import ShareIcon from '../../img/share.svg';
import LikeList from '../common/likeList';
import { RWebShare } from 'react-web-share';
import { getPostLanguage } from '../../services/authService';
import language from '../../utils/languages';
import { useTablet } from '../../hooks/utilHooks';

function PostEngagementOptions({
  isLiked,
  handleLike,
  post,
  classes,
  handleShareClick,
  isReposted,
  handleCommentClick,
  handleRepost,
}) {
  const webLanguage = getPostLanguage();
  const languageText = language[webLanguage ? webLanguage : 'en'];
  const isTablet = useTablet();
  return (
    <Grid item xs={8} lg={9}>
      <Box display="flex" justifyContent="space-between">
        <PostActionButton
          icon={
            isLiked ? (
              <img src={LikedIcon} width="24px" height="24px" alt="Liked" />
            ) : (
              <img src={LikeIcon} width="24px" height="24px" alt="Like" />
            )
          }
          handleAction={handleLike}
          activeStatus={isLiked}
          text={
            !isTablet
              ? isLiked
                ? `${languageText?.LIKED}`
                : `${languageText?.LIKE}`
              : ''
          }
        />
        <PostActionButton
          icon={
            <img src={CommentIcon} alt="Comment" width="24px" height="24px" />
          }
          handleAction={handleCommentClick}
          text={!isTablet ? `${languageText?.COMMENT}` : ''}
        />
        {post.subType !== 'PL' && post.subType !== 'A' && (
          <PostActionButton
            icon={
              isReposted ? (
                <img
                  src={RepostedIcon}
                  alt="reposted"
                  width="24px"
                  height="24px"
                />
              ) : (
                <img src={RepostIcon} alt="repost" width="24px" height="24px" />
              )
            }
            handleAction={handleRepost}
            text={!isTablet ? `${languageText?.REPOST}` : ''}
          />
        )}
        <Box
          className={classes.share}
          display="flex"
          justifyContent="space-between"
        >
          <RWebShare
            data={{
              text: post?.sharePostInfo?.text,
              url: post?.sharePostInfo?.url,
              title: post?.sharePostInfo?.title,
            }}
            onClick={() => handleShareClick()}
          >
            <img src={ShareIcon} alt="Share" width="24px" height="24px" />
          </RWebShare>
          {!isTablet && (
            <Typography
              variant="body1"
              component="span"
              className="displayInlineBlock"
            >
              {languageText?.SHARE_POST}
            </Typography>
          )}
        </Box>
      </Box>
      <Box className={classes.countSet}>
        {post.likesCount > 0 && (
          <LikeList className={classes.likeText} post={post} />
        )}

        {post.commentsCount > 0 && (
          <Typography variant="caption" color="textSecondary" component="span">
            {post.commentsCount}
            {post.commentsCount > 1 ? ' comments' : ' comment'}
          </Typography>
        )}

        {post.repostsCount > 0 && (
          <Typography variant="caption" color="textSecondary" component="span">
            {post.repostsCount} {post.repostsCount > 1 ? ' reposts' : ' repost'}
          </Typography>
        )}
      </Box>
    </Grid>
  );
}

export default PostEngagementOptions;
