import React, { useEffect } from 'react';
import { useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import useReactRouter from 'use-react-router';
import { postToFollowerObj } from '../mappers/communityMapper';
import { useCreateOrEditPostRequest } from '../hooks/createOrEditPostRequest';
import { ReactComponent as SorryIcon } from '../img/sorry.svg';
import MoengageService from '../services/moEngageService';
import { screenNamesSet } from '../utils/screenUtils';
import { postToWomenWillObj } from '../mappers/communityMapper';
import { base64decode } from 'nodejs-base64';
import communityService from '../services/communityService';
import { useLastLocation } from 'react-router-last-location';
import { useSnackBar } from './snackBarHooks';
import postService from '../services/postService';

function useCreatePost(sourceName) {
  const [text, setText] = useState('');
  const [community, setCommunity] = useState('');
  const { history } = useReactRouter();
  const lastLocation = useLastLocation();
  const postOnWomenWill =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.postOnWomenWill;
  const askYourFacilitator =
    history &&
    history.location &&
    history.location.state &&
    history.location.state.askYourFacilitator;
  const post =
    history.location.state && history.location.state.postStr
      ? JSON.parse(history.location.state.postStr)
      : '';

  const fromScreen = history.location.state
    ? history.location.state.fromScreen
    : '';
  const getMoeSource = sourceName;

  let {
    buildRequestBody,
    handleUserAnonymity,
    handlePostAsCommunity,
    selectedTheme,
    isThemePost,
    validateCreatePost,
    files,
    setErrorMessage,
    createPostAnalyticsTrack,
    handleDeleteItem,
    handleSelectCommunity,
    handleSetPostDescription,
    handleSetUserMention,
    handleSetEditorState,
    renderLinkUrl,
    userMentions,
  } = useCreateOrEditPostRequest(post);
  const createPostOptions = useStoreState(
    (state) => state.createPost.postObject
  );

  const isEditablePost = post ? true : false;
  const isSchedulePost = post && post.isSchedulePost ? true : false;
  const isEditableVideoPost = post && post.subType === 'V';
  const savePost = useStoreActions((actions) => actions.posts.savePost);
  const { setDialogBoxOpen } = useStoreActions((actions) => actions.dialogBox);
  const {
    setErrorDialogBoxOpen,
    setHandleErrorMessage,
    setErrorDialogContent,
  } = useStoreActions((actions) => actions.errorHandler);
  let {
    communityId,
    communityName,
    postDescription,
    postCreatorType,
    deletedImageIds,
    isPostCreatorChange,
    isFromCommunityDetails,
    selectedCommunity,
    branchData,
    isBranchLink,
    isPollPost,
    pollObject,
    isVideoPost,
    deleteVideoIds,
  } = useStoreState((state) => state.createPost.postObject);

  const {
    setIsThemePost,
    setFiles,
    setDeletedImageIds,
    resetCreatePostObject,
    setCreatePostOptions,
  } = useStoreActions((state) => state.createPost);

  if (branchData && isBranchLink) {
    communityId = branchData.entity_id;
    communityName = branchData.entity_name;
    selectedCommunity = {
      id: branchData.entity_id,
      name: branchData.entity_name,
    };
    postDescription = branchData.prefill_text;
    isThemePost =
      branchData && branchData.theme_id && branchData.theme_id ? true : false;
  }
  if (community) {
    communityId = community?.id;
    communityName = community?.name;
  } else if (postOnWomenWill || askYourFacilitator) {
    communityId = postToWomenWillObj[0].id;
    communityName = postToWomenWillObj[0].name;
    selectedCommunity = {
      id: communityId,
      name: communityName,
    };
  } else if (post) {
    communityId = post.communityId;
    communityName =
      post.communityId === postToFollowerObj[0].id
        ? postToFollowerObj[0].name
        : post.communityName;
    selectedCommunity = {
      id: communityId,
      name: communityName,
      isCommunityOwner: post.isCommunityOwner,
    };
  }

  if (post && post.description && !postDescription) {
    postDescription = post.description;
  }
  if (
    post &&
    isEditableVideoPost &&
    post.videoId &&
    files.length === 0 &&
    deleteVideoIds.length === 0
  ) {
    setCreatePostOptions({
      ...createPostOptions,
      isVideoPost: true,
      isThemePost: false,
      isPollPost: false,
    });
    setFiles(
      post.videoUrl
        ? [{ src: post.videoUrl, id: post.videoId, hii1: 1 }]
        : [{ src: post.sourceVideoUrl, id: post.videoId }]
    );
  }
  const { setHandleThemeChange } = useStoreActions((state) => state.createPost);
  if (
    post &&
    !isEditableVideoPost &&
    post.images &&
    files.length === 0 &&
    deletedImageIds.length === 0
  ) {
    files = post.images;
  }

  if (files.length > 0) {
    isThemePost = false;
  }

  postCreatorType =
    !isPostCreatorChange && post ? post.author.name : postCreatorType;
  const { setSelectedCommunity, setCreatePostLoading, setIsCommentSubmit } =
    useStoreActions((state) => state.createPost);
  const listOfTheme =
    selectedCommunity && selectedCommunity.id
      ? communityService.getThemesByCommunityId(selectedCommunity.id)
      : communityService.getAllThemes();
  const randomThemeIndex =
    listOfTheme?.length > 0 &&
    Math.floor(Math.random() * listOfTheme?.length - 1) + 1;

  useEffect(() => {
    if (!communityId) {
      const communityObj =
        history.location.state && history.location.state.communityObj;
      async function getCommunityData() {
        const res = await communityService.getCommunityDetail(
          base64decode(communityObj?.communityId)
        );
        res && setCommunity(res);
      }
      communityObj && getCommunityData();
    }
    if (listOfTheme && listOfTheme.length > 0 && randomThemeIndex !== false) {
      setHandleThemeChange({ selectedThemeObj: listOfTheme[randomThemeIndex] });
    }
    communityId &&
      communityName &&
      setSelectedCommunity({
        id: communityId,
        name: communityName,
      });
    // eslint-disable-next-line
  }, [communityId, communityName, setSelectedCommunity, community]);

  //submit form
  async function handleSavePost({ scheduleDate }) {
    setDialogBoxOpen(false);
    let createPostRequestBody = buildRequestBody({
      scheduleDate,
      post,
      selectedTheme,
      isThemePost,
      selectedCommunity,
      postCreatorType,
      deletedImageIds,
      userMentions,
      isPollPost,
      pollObject,
      deleteVideoIds,
    });
    if (
      !validateCreatePost(
        selectedCommunity,
        files,
        postDescription,
        isPollPost,
        pollObject,
        setCreatePostLoading
      )
    ) {
      return;
    }
    createPostAnalyticsTrack({
      selectedCommunity,
      postDescription,
      postCreatorType,
      createPostRequestBody,
    });

    await savePost({
      createPostRequestBody,
      files,
      history,
      isEditablePost,
      setErrorMessage,
      lastLocation,
      fromScreen,
      setHandleErrorMessage,
      isPollPost,
      isVideoPost,
      setCreatePostLoading,
    });
    setIsCommentSubmit(true);
    setDialogBoxOpen(false);
    resetCreatePostObject();
    setErrorDialogBoxOpen(true);
    setErrorDialogContent({
      btnPrimaryText: 'Not Now',
      btnSecondaryText: 'Verify',
      dialogIcon: <SorryIcon />,
      dialogTitle: 'Sorry!',
    });
  }
  MoengageService.trackPostCreated(
    files,
    screenNamesSet.createPost,
    isThemePost,
    selectedCommunity.id,
    communityName,
    isSchedulePost,
    getMoeSource
  );

  const postObj = {
    communityId,
    communityName,
    selectedCommunity,
    postCreatorType,
    handleUserAnonymity,
    handlePostAsCommunity,
    handleSelectCommunity,
    handleSavePost,
    handleSetPostDescription,
    postDescription,
    setFiles,
    files,
    deletedImageIds,
    setDeletedImageIds,
    setIsThemePost,
    isThemePost,
    selectedTheme,
    isEditablePost,
    handleDeleteItem,
    isSchedulePost,
    resetCreatePostObject,
    isFromCommunityDetails,
    userMentions,
    handleSetUserMention,
    handleSetEditorState,
    renderLinkUrl,
    isBranchLink,
    setText,
    text,
    listOfTheme,
  };
  return postObj;
}

function useThemePost({ files, selectedTheme, postDescription }) {
  const { checkThemePostStatus, setHandleThemeChange } = useStoreActions(
    (state) => state.createPost
  );
  function handleThemeChange(e, selectedThemeObj) {
    setHandleThemeChange({ e, selectedThemeObj });
    checkThemePostStatus({ files, selectedTheme, postDescription });
  }
  return {
    handleThemeChange,
  };
}

function useLikeList({ post, drawerRef }) {
  const [drawerPosition, setDrawerPosition] = useState({
    bottom: false,
  });
  let [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [dataLength, setDataLength] = useState(0);
  const [likesData, setLikesData] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const setToastMessage = useSnackBar();
  const postEntityId = post.parentId;

  // for first time calling of api
  async function getLikesData() {
    setIsLoading(true);
    const likeList = await postService.getPostReactions(
      postEntityId,
      pageNumber,
      setToastMessage
    );
    setIsLoading(false);

    if (likeList) {
      likeList.length > 0 && setLikesData(likeList);
      setDataLength(likeList.length);
      setHasMore(!likeList.length ? false : true);
      likeList.length > 0 && setPageNumber(pageNumber + 1);
    }
  }
  const toggleDrawer = (side, open) => (event) => {
    event.stopPropagation();
    setDrawerPosition({ ...drawerPosition, [side]: open });
    open && getLikesData();
  };

  // For fetching more data if hasMore is true
  async function fetchData() {
    setIsLoading(true);
    try {
      const likeList = await postService.getPostReactions(
        postEntityId,
        pageNumber,
        setToastMessage
      );
      setIsLoading(false);
      setLikesData([...likesData, ...likeList]);
      setPageNumber(pageNumber + 1);
      const totalLength = parseInt(dataLength) + parseInt(likeList?.length);
      const isHasMore =
        (likeList && likeList?.length === 0) || !likeList ? false : true;
      setHasMore(isHasMore);
      setDataLength(totalLength);
    } catch (err) {
      setIsLoading(false);
      return err;
    }
  }

  // Check if the drawer scroll position is at the bottom
  const checkIfReachedBottom = () => {
    if (!drawerRef.current) return;
    const drawerElement = drawerRef.current;
    const isAtBottom =
      drawerElement.scrollTop + drawerElement.clientHeight ===
      drawerElement.scrollHeight;
    isAtBottom && hasMore && fetchData();
  };
  return {
    toggleDrawer,
    isLoading,
    checkIfReachedBottom,
    likesData,
    drawerPosition,
  };
}

export { useCreatePost, useThemePost, useLikeList };
