import apiUrl from '../utils/apiUrl';
import language from '../utils/languages';
import { getUserToken } from './authService';
import { getPostLanguage } from './authService';

const webLanguage = getPostLanguage();
const languageText = language[webLanguage ? webLanguage : 'en'];

async function getRemoteConfigFromServer(language, setToastMessage) {
  let url = '';
  if (language === 'en') {
    url = apiUrl.APP_CONFIG;
  } else if (language === 'hi') {
    url = apiUrl.APP_CONFIG + '_hi';
  } else if (language === 'bn') {
    url = apiUrl.APP_CONFIG + '_bn';
  } else if (language === 'mr') {
    url = apiUrl.APP_CONFIG + '_mr';
  } else if (language === 'gu') {
    url = apiUrl.APP_CONFIG + '_gu';
  } else if (language === 'te') {
    url = apiUrl.APP_CONFIG + '_te';
  } else if (language === 'as') {
    url = apiUrl.APP_CONFIG + '_as';
  } else {
    url = apiUrl.APP_CONFIG;
  }

  const token = getUserToken();
  try {
    const data = await fetch(process.env.REACT_APP_API_URL + url, {
      headers: {
        Authorization: token,
        'X-Language': language,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((response) => {
        return response;
      });
    if (data && data.status === 'FAILED') {
      setToastMessage &&
        setToastMessage(
          data.error_message
            ? data.error_message
            : languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME
        );
      return null;
    } else {
      data &&
        data.configuration &&
        data.configuration.config_data &&
        localStorage.setItem(
          'appConfig',
          JSON.stringify(data.configuration.config_data)
        );
    }
  } catch (ex) {
    if (
      ex &&
      ex.response &&
      ex.response.data &&
      ex.response.data.error_message
    ) {
      setToastMessage && setToastMessage(ex.response.data.error_message);
    } else if (ex && ex.error_message) {
      setToastMessage && setToastMessage(ex.error_message);
    } else {
      setToastMessage &&
        setToastMessage(languageText.PLEASE_TRY_AGAIN_AFTER_SOMETIME);
    }
  }
}

function getRemoteConfig() {
  return JSON.parse(localStorage.getItem('appConfig'));
}

export { getRemoteConfigFromServer, getRemoteConfig };
